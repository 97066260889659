import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  isSearchDisabled: PropTypes.bool.isRequired,
};

const Cta15ListingNoDataMessage = ({ isSearchDisabled }) => (
  <>
    {isSearchDisabled ? (
      <>
        <p>
          Looks like you don’t have any CTAs yet. Click the{' '}
          <span className="no-data-create-cta">Build CTA</span> button to add your first CTA.
        </p>
      </>
    ) : (
      <p>No results to display. Please try adjusting your search terms or filters.</p>
    )}
  </>
);

Cta15ListingNoDataMessage.propTypes = propTypes;

export default Cta15ListingNoDataMessage;
