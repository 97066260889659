import Button from '../../shared/button/Button';
import CtaListingDeleteButton from '../../ctaListingDeleteButton/CtaListingDeleteButton';
import CtaListingStatusButton from './CtaListingStatusButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  handleToasterLifecycle: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isCtaActive: PropTypes.bool.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const CtaListingButtons = ({
  hubId,
  ctaId,
  ctaName,
  isCtaActive,
  handleToasterLifecycle,
  triggerRefresh,
}) => (
  <>
    <CtaListingStatusButton
      ctaId={ctaId}
      ctaName={ctaName}
      isCtaActive={isCtaActive}
      handleToasterLifecycle={handleToasterLifecycle}
      triggerRefresh={triggerRefresh}
    />

    <Button
      href={`/hubs/editCta/${hubId}/${ctaId}`}
      icon="pencil"
      id={`edit-call-to-action-${ctaId}`}
      label="edit call-to-action"
      title="Edit CTA"
    />

    <CtaListingDeleteButton
      ctaId={ctaId}
      ctaName={ctaName}
      handleToasterLifecycle={handleToasterLifecycle}
      triggerRefresh={triggerRefresh}
    />
  </>
);

CtaListingButtons.propTypes = propTypes;

export default CtaListingButtons;
