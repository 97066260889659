import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  decrementWebsiteWidgetTemplateCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
  websiteWidgetTemplateId: PropTypes.number.isRequired,
  websiteWidgetTemplateName: PropTypes.string.isRequired,
  websiteWidgetTemplateRuleCount: PropTypes.number.isRequired,
};

const WebsiteWidgetTemplatesDeleteButton = ({
  decrementWebsiteWidgetTemplateCount,
  dispatchToasterAction,
  triggerRefresh,
  websiteWidgetTemplateId,
  websiteWidgetTemplateName,
  websiteWidgetTemplateRuleCount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteWebsiteWidgetTemplate = async (csrfToken) => {
    try {
      setIsDeleting(true);
      /* eslint-disable sort-keys */
      await axios({
        method: 'DELETE',
        url: `/api/v2/website-widget-templates/${websiteWidgetTemplateId}`,
        headers: { 'X-Csrf-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetTemplateId,
          text: 'Site Engager Template was deleted!',
          type: 'success',
        }),
      );
      decrementWebsiteWidgetTemplateCount?.();
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetTemplateId,
          text: 'Unable to delete Site Engager Template.',
          type: 'error',
        }),
      );
    }
    setIsDeleting(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: websiteWidgetTemplateId }));
    }, 2000);
  };

  const confirmationBody = (
    <>
      <p>
        Are you sure you want to <b>permanently</b> delete this Template &quot;
        {websiteWidgetTemplateName}&quot;?
      </p>
      {websiteWidgetTemplateRuleCount > 0 ? (
        <p>
          This template is being used by {websiteWidgetTemplateRuleCount} Rule
          {websiteWidgetTemplateRuleCount === 1 ? '. It ' : 's. They '}
          will be updated to use the default template instead.
        </p>
      ) : null}
    </>
  );

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              handleConfirm={() => deleteWebsiteWidgetTemplate(csrfToken)}
              header="Delete Template?"
              body={confirmationBody}
              primaryButtonText="Delete Template"
            />

            <Button
              icon="bin"
              title="Delete Template"
              label="Delete Template"
              id={`delete-website-widget-rule-${websiteWidgetTemplateId}`}
              className="ufr-delete-button"
              disabled={isDeleting}
              onClick={() => setIsModalOpen(true)}
            />
          </>
        )}
      </CsrfContextConsumer>
    </>
  );
};

WebsiteWidgetTemplatesDeleteButton.propTypes = propTypes;

export default WebsiteWidgetTemplatesDeleteButton;
