import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canCreateCta: PropTypes.bool.isRequired,
  formattedCtaType: PropTypes.string.isRequired,
  isSearchDisabled: PropTypes.bool.isRequired,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
};

const CtaListingNoDataMessage = ({ isSearchDisabled, formattedCtaType }) => (
  <>
    {isSearchDisabled ? (
      <>
        <p>
          Looks like you don’t have any {formattedCtaType} CTAs yet. Click the{' '}
          <span className="no-data-create-cta">Build {formattedCtaType} CTA</span> button to add
          your first {formattedCtaType} CTA.
        </p>
      </>
    ) : (
      <p>No results to display. Please try adjusting your search terms or filters.</p>
    )}
  </>
);

CtaListingNoDataMessage.propTypes = propTypes;

export default CtaListingNoDataMessage;
