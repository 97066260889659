import '../salesforceDropdown.less';
import './customToggle.less';
import * as React from 'react';
import { bool, func, string } from 'prop-types';

const CustomToggle = ({ className: error, children, toggleMenu, menuIsOpen }) => (
  <button
    type="button"
    className={`toggle-dropdown ${error}`}
    onClick={(e) => {
      e.preventDefault();
      toggleMenu(!menuIsOpen);
    }}
  >
    {children}
    <span className="caret" />
  </button>
);

export default CustomToggle;

CustomToggle.defaultProps = {
  className: null,
};

CustomToggle.propTypes = {
  children: string.isRequired,
  className: string,
  menuIsOpen: bool.isRequired,
  toggleMenu: func.isRequired,
};
