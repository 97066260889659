import { get } from 'axios';

/**
 * Iterates through a paged api2 endpoint
 * if it is a single item response it will return the single item's data
 * @param {*} endpoint - /api/v2/*
 */
export async function* api2ListingIterator(endpoint, params = {}) {
  let page = 0;
  let totalPages = 1;

  while (totalPages - page++ > 0) {
    let response = await get(endpoint, {
      params: {
        ...params,
        page,
      },
    });

    const {
      data: { data, meta },
    } = response;

    if (data && meta) {
      // endpoint provides listing
      for (const record of data) {
        yield record;
      }

      totalPages = meta.total_pages;
    } else {
      // otherwise just return the data
      yield response.data;
      break;
    }
  }
}
