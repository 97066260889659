import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import axios from 'axios';
import Eloqua from './Eloqua';
import HubSpot from './HubSpot';
import MailChimp from './MailChimp';
import Marketo from './Marketo';
import Pardot from './Pardot';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const propTypes = {
  csrfToken: PropTypes.string,
  cta: PropTypes.shape({}),
  dispatchToasterAction: PropTypes.func,
};
const Integration = ({ csrfToken, cta, dispatchToasterAction }) => {
  const getCtaIntegrationLists = async (
    integration,
    setDropDownData,
    csrfToken,
    setIsLoading,
    setDefaultValue,
    defaultValue,
    setErrorMessage,
    setLoadingMessage,
    setIsPickingFormId,
  ) => {
    setLoadingMessage(`Loading ${integration} Forms`);
    const url = `/hubs/ajax_getCtaIntegrationLists`;
    const data = { service: integration };

    await axios({
      method: 'post',
      url,
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        const forms = response.data.response.lists.map((form) => ({
          text: form.name,
          value: String(form.id),
        }));

        let findDefaultValue = forms.find((content) => content.value === defaultValue);

        if (['Hubspot', 'Marketo', 'Pardot', 'Mailchimp'].includes(integration)) {
          let hasNoListOption = forms.find((content) => content.value === '0');

          const noListOption = { text: 'No List', value: '0' };
          if (!hasNoListOption) {
            forms.unshift(noListOption);
          }
          if (!findDefaultValue) {
            findDefaultValue = noListOption;
          }
        }

        setDefaultValue(findDefaultValue);
        setDropDownData(forms);
        if (setIsPickingFormId) setIsPickingFormId(true);
      })
      .catch(() => {
        const errorToaster = {
          id: `loading-${integration}-${cta.HubCta.id}`,
          text: `Unable to load ${integration} forms`,
          type: 'error',
        };

        dispatchToasterAction(addToaster(errorToaster));
        setTimeout(() => {
          dispatchToasterAction(slideDownAndRemoveToaster(errorToaster));
        }, 2000);
        setErrorMessage(`No valid lists for ${integration} integration`);
      });

    setIsLoading(false);
    setLoadingMessage('');
  };

  const setCtaIntegrationLists = async (
    formId,
    hubId,
    ctaId,
    csrfToken,
    setIsLoading,
    integration,
    setValue,
    setLoadingMessage,
    setIsPickingFormId,
  ) => {
    setLoadingMessage(`Saving ${integration} Form`);
    setIsLoading(true);
    const url = `/hubs/ajax_updateCtaIntegration/${hubId}`;
    const data = { service: integration, listID: formId.value, ctaID: ctaId };
    let toaster = {
      id: `saving-${integration}-${ctaId}`,
    };

    await axios({
      method: 'post',
      url,
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
      .then(() => {
        toaster.type = 'success';
        toaster.text = 'Saved';
        setValue(formId);
      })
      .catch(() => {
        toaster.type = 'error';
        toaster.text = 'Unable to save';
      });

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
    setIsLoading(false);
    setLoadingMessage('');
    if (setIsPickingFormId) setIsPickingFormId(false);
  };

  const renderIntegration = () => {
    if (csrfToken === null) return;
    switch (cta.HubCtasForm.service_type) {
      case 'eloqua':
        return (
          <Eloqua
            cta={cta}
            csrfToken={csrfToken}
            dispatchToasterAction={dispatchToasterAction}
            getCtaIntegrationLists={getCtaIntegrationLists}
            setCtaIntegrationLists={setCtaIntegrationLists}
          />
        );
      case 'mailchimp':
        return (
          <MailChimp
            cta={cta}
            csrfToken={csrfToken}
            getCtaIntegrationLists={getCtaIntegrationLists}
            setCtaIntegrationLists={setCtaIntegrationLists}
            dispatchToasterAction={dispatchToasterAction}
          />
        );
      case 'hubspot':
        return (
          <HubSpot
            cta={cta}
            csrfToken={csrfToken}
            getCtaIntegrationLists={getCtaIntegrationLists}
            setCtaIntegrationLists={setCtaIntegrationLists}
          />
        );
      case 'marketo':
        return (
          <Marketo
            cta={cta}
            csrfToken={csrfToken}
            getCtaIntegrationLists={getCtaIntegrationLists}
            setCtaIntegrationLists={setCtaIntegrationLists}
          />
        );
      case 'pardot':
        return (
          <Pardot
            cta={cta}
            csrfToken={csrfToken}
            getCtaIntegrationLists={getCtaIntegrationLists}
            setCtaIntegrationLists={setCtaIntegrationLists}
          />
        );
      case 'form_cta_type':
        return (
          <>
            <div className="integration-setup-sprite form_cta_type-text" />
            <h2>Custom Form CTA - {JSON.parse(cta.ExtsCredentials.data)['name']}</h2>
          </>
        );
      default:
        return <div>This integration is not available</div>;
    }
  };

  return <>{renderIntegration()}</>;
};

Integration.propTypes = propTypes;

export default Integration;
