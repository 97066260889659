import DatatableThumbnail from '../../datatable/DatatableThumbnail';
import LangLinkDatatable from './LangLinkDatatable';
import LangLinkDeleteButton from './LangLinkDeleteButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  readonly: PropTypes.bool,
  streamId: PropTypes.number.isRequired,
};

const defaultProps = {
  readonly: true,
};

const getEndpointBuilder = (streamId) => ({
  createLink: (id) => `/api/v2/streams/${streamId}/language-links/${id}`,
  deleteLink: (id) => `/api/v2/streams/${id}/language-links`,
  deleteSelf: () => `/api/v2/streams/${streamId}/language-links`,
  getLinkables: () => `/api/v2/streams/${streamId}/language-linkable-streams`,
  getLinks: () => `/api/v2/streams/${streamId}/language-links`,
  getLocales: () => `/api/v2/streams/${streamId}/language-linkable-locales`,
});

const cellClass = (key) => `ufr-dt-langlink-${key}-cell`;
const headerClass = (key) => `ufr-dt-langlink-${key}-header`;

/* eslint-disable sort-keys */
const columns = [
  {
    Header: 'Stream Name',
    accessor: 'stream',
    id: 'stream.title', // The id must match the name of the column we want to sort by on the api
    className: cellClass('streamname'),
    headerClassName: headerClass('streamname'),
    minWidth: 200,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => [
      <DatatableThumbnail key={1} type={value.service} imageUrl={value.thumbnail_url} />,
      <div key={2} className="ufr-link-title ufr-link-stream-title">
        {value.title}
      </div>,
    ],
  },
  {
    Header: 'Locale',
    accessor: 'stream',
    id: 'stream.locale.display_name',
    className: cellClass('locale'),
    headerClassName: headerClass('locale'),
    Cell: ({ value }) => value.locale.display_name,
  },
  {
    Header: 'Date Created',
    accessor: 'created_at',
    className: cellClass('created'),
    headerClassName: headerClass('created'),
    maxWidth: 150,
    Cell: ({ value: date }) => date.slice(0, 10),
  },
  {
    Header: '',
    accessor: 'stream',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    width: 40,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value, popToast, readonly, refresh, deleteLink }) =>
      !readonly && (
        <LangLinkDeleteButton
          // eslint-disable-next-line react/prop-types
          entityId={value.id}
          header="Delete Stream link?"
          // eslint-disable-next-line react/prop-types
          message={`Are you sure you want to delete this Streams's link with Stream "${value.title}?"`}
          getEndpoint={deleteLink}
          showMessage={popToast}
          triggerRefresh={refresh}
        />
      ),
  },
];
/* eslint-enable sort-keys */

const StreamLangLinksDatatable = ({ streamId, readonly }) => (
  <LangLinkDatatable
    readonly={readonly}
    endpointBuilder={getEndpointBuilder(streamId)}
    type="stream"
    columns={columns}
  />
);

StreamLangLinksDatatable.propTypes = propTypes;
StreamLangLinksDatatable.defaultProps = defaultProps;

export default StreamLangLinksDatatable;
