import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import ToggleSwitch from '@/components/formInputs/toggle/ToggleSwitch';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  canEnableCustomCode: PropTypes.bool.isRequired,
  customCodeId: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  getEnableCustomCodeEndpoint: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
};

const CustomCodeListingEnabled = ({
  enabled,
  customCodeId,
  canEnableCustomCode,
  getEnableCustomCodeEndpoint,
}) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const toggleEnabled = async (enabled, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: getEnableCustomCodeEndpoint(customCodeId),
        headers: {
          'X-Csrf-Token': csrfToken,
        },
        data: { enabled },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: `Custom Code ${enabled ? 'enabled' : 'disabled'}.`,
          type: 'success',
        }),
      );
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: 'Cannot update Custom Code.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: customCodeId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <ToggleSwitch
            id={`custom-code-${customCodeId}-enabled`}
            disabled={!canEnableCustomCode}
            name={`${customCodeId}`}
            submitData={({ propertyValue: enabled }) => toggleEnabled(enabled, csrfToken)}
            value={enabled}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

CustomCodeListingEnabled.propTypes = propTypes;

export default CustomCodeListingEnabled;
