import CancelButton from '../SecondaryButton/CancelButton';
import Modal from '../Modal/Modal';
import ModalBody from '../ModalBody/ModalBody';
import ModalFooter from '../ModalFooter/ModalFooter';
import ModalHeader from '../ModalHeader/ModalHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TextArea from '@/components/formInputs/textArea/TextArea';
import TextInput from '@/components/formInputs/textInput/TextInput';

const propTypes = {
  data: PropTypes.object.isRequired,
  description: PropTypes.string,
  fieldIds: PropTypes.shape({
    descriptionFieldId: PropTypes.string,
    nameFieldId: PropTypes.string,
  }).isRequired,
  fieldLabels: PropTypes.shape({
    descriptionFieldLabel: PropTypes.string,
    nameFieldLabel: PropTypes.string,
  }).isRequired,
  fieldNameAttributes: PropTypes.shape({
    descriptionFieldName: PropTypes.string,
    nameFieldName: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  primaryButtonText: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const NameAndDescriptionModal = ({
  title,
  isOpen,
  description,
  handleClose,
  handleSubmit,
  primaryButtonText,
  fieldLabels,
  fieldIds,
  setData,
  data,
  maxLength,
  fieldNameAttributes,
}) => {
  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '');
  }, [isOpen]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const { descriptionFieldId, nameFieldId } = fieldIds;
  const { descriptionFieldLabel, nameFieldLabel } = fieldLabels;
  const { descriptionFieldName, nameFieldName } = fieldNameAttributes;

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <ModalHeader title={title} />
      <ModalBody>
        <TextInput
          id={nameFieldId}
          title={nameFieldLabel}
          description={description || ''}
          handleChange={handleChange}
          value={data[nameFieldName]}
          autoFocus
          name={nameFieldName}
        />

        <TextArea
          id={descriptionFieldId}
          title={descriptionFieldLabel}
          handleChange={handleChange}
          rows={2}
          maxLength={maxLength}
          value={data[descriptionFieldName]}
          name={descriptionFieldName}
        />
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={handleClose} />
        <PrimaryButton label={primaryButtonText} onClick={() => handleSubmit()}>
          {primaryButtonText}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

NameAndDescriptionModal.propTypes = propTypes;

export default NameAndDescriptionModal;
