import PropTypes from 'prop-types';
import React from 'react';
import SeoOptionsEnabled from '@/components/advancedOptions/SeoOptionsEnabled';

const propTypes = {
  data: PropTypes.any,
  seoOptions: PropTypes.any,
};

class SeoOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { hubId, collectionId, data } = this.props.data[0];
    return (
      <div className="options-wrapper">
        <table className="table bs3 api-dt" id="stream-seo-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th className="no-sort">Enabled</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(data).map((value) => (
              <tr>
                <td>{value.name}</td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: value.description }} />
                </td>
                <td>
                  <SeoOptionsEnabled
                    seoKey={value.key}
                    enabled={value.enabled}
                    hubId={hubId}
                    collectionId={collectionId}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

SeoOptions.propTypes = propTypes;

export default SeoOptions;
