import PropTypes from 'prop-types';

const shapes = {
  item: {
    active: PropTypes.bool,
    children: PropTypes.array,
    defaultIcon: PropTypes.bool,
    faviconUrl: PropTypes.string,
    icon: PropTypes.string,
    iconRight: PropTypes.string,
    id: PropTypes.string.isRequired,
    onClick: PropTypes.function,
    onClickCallback: PropTypes.shape({
      func: PropTypes.string.isRequired,
      namespace: PropTypes.string.isRequired,
    }),
    text: PropTypes.string.isRequired,
    topDivider: PropTypes.bool,
    url: PropTypes.string,
  },
  user: {
    avatarUrl: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  },
};

export default shapes;
