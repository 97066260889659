import AccountInstalledAppsListingDatatable from '../datatables/installedApps/account/AccountInstalledAppsListingDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint-disable sort-keys */
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

const AccountInstalledAppsPage = ({ title, subtitle }) => {
  const headerText = {
    title: { text: title },
    subtitle: { text: subtitle },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <AccountInstalledAppsListingDatatable />
    </Page>
  );
};

AccountInstalledAppsPage.propTypes = propTypes;

export default AccountInstalledAppsPage;
