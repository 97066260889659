import PropTypes from 'prop-types';
import React from 'react';

const Label = ({ htmlFor, children, ...props }) => (
  <label className="react-input-title" htmlFor={htmlFor} {...props}>
    {children}
  </label>
);

// Force minimum a11y attributes
Label.propTypes = {
  children: PropTypes.oneOfType([
    // the text or html for the inside of the label
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]).isRequired,
  htmlFor: PropTypes.string.isRequired, // required, must match id of the input it labels
};

export default Label;
