import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import Toggle from '@/components/formInputs/toggle/Toggle';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  hasAuthenticationUpdate: PropTypes.bool.isRequired,
  isAuthenticationEnabled: PropTypes.bool.isRequired,
  streamId: PropTypes.number.isRequired,
};

const PassphrasesAuthenticationEnabledToggle = ({
  hasAuthenticationUpdate,
  isAuthenticationEnabled,
  streamId,
}) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [authenticationValue, setAuthenticationValue] = useState(isAuthenticationEnabled);

  const toggleAuthentication = async (authentication, csrfToken) => {
    const toggleAuthenticationId = 'toggle-authentication';
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: `/api/v2/streams/${streamId}`,
        headers: { 'X-CSRF-TOKEN': csrfToken },
        data: { authentication },
      });
      setAuthenticationValue(!!authentication);
      dispatchToasterAction(
        addToaster({
          id: toggleAuthenticationId,
          text: 'Saved!',
          type: 'success',
        }),
      );
      /* eslint-enable sort-keys */
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: toggleAuthenticationId,
          text: error?.response?.data?.errors[0]?.message,
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: toggleAuthenticationId }));
    }, 3000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <Toggle
            id="ufr-dynamic-id-authentication-toggle-banner"
            title="Enable Authentication"
            description={[
              'Users will be asked to enter a passphrase in order to view this Stream.',
              'Once enabled the SEO settings for this stream will be set to no-index/no-follow.',
            ]}
            value={authenticationValue}
            name="authentication"
            disabled={!hasAuthenticationUpdate}
            submitData={({ propertyValue: authentication }) =>
              toggleAuthentication(authentication, csrfToken)
            }
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

PassphrasesAuthenticationEnabledToggle.propTypes = propTypes;

export default PassphrasesAuthenticationEnabledToggle;
