import PropTypes from 'prop-types';
import React from 'react';

import './appIcon.less';

const formats = {
  large: {
    height: 120,
    iconWidth: 50,
    width: 120,
  },
  narrow: {
    height: 40,
    iconWidth: 12,
    width: 16,
  },
  small: {
    height: 24,
    iconWidth: 16,
    width: 24,
  },
};

const AppIcon = ({ imgUrl, format }) => {
  const dimensions = formats[format];
  const { width, height } = dimensions;
  const backgroundImage = `url(${imgUrl})`;
  const backgroundSize = 'cover';

  return (
    <div
      className="uf-app-icon"
      style={{
        backgroundImage,
        backgroundSize,
        height,
        width,
      }}
    />
  );
};

AppIcon.defaultProps = {
  format: 'small',
};

AppIcon.propTypes = {
  format: PropTypes.oneOf(['small', 'large', 'narrow']),
  imgUrl: PropTypes.string.isRequired,
};

export default AppIcon;
