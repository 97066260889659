import Button from '../shared/button/Button';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const defaultProps = {
  endDate: null,
  handleClear: null,
  handleSubmit: null,
  limitDateRange: false,
  startDate: null,
  uniqueId: undefined,
};

const propTypes = {
  endDate: PropTypes.instanceOf(moment().constructor),
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  limitDateRange: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment().constructor),
  uniqueId: PropTypes.string,
};

export const CalendarFooter = ({
  startDate,
  endDate,
  uniqueId,
  handleClear,
  handleSubmit,
  limitDateRange,
}) => (
  <div className="calendarFooter">
    {limitDateRange && startDate && !endDate && (
      <p className="datepicker-tooltip">Select dates within a 31 day range.</p>
    )}
    <Button
      id={`uf-date-picker-${uniqueId}-clear`}
      ufrStyle="secondary"
      label="Clear the date picker"
      onClick={handleClear}
      className="uf-date-picker-clear"
    >
      Clear
    </Button>
    <Button
      id={`uf-date-picker-${uniqueId}-submit`}
      label="Confirm the date picker selection"
      onClick={handleSubmit}
      className="uf-date-picker-select"
    >
      Select
    </Button>
  </div>
);

CalendarFooter.defaultProps = defaultProps;
CalendarFooter.propTypes = propTypes;
