import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './itemTile.less';
import SelectedIcon from './SelectedIcon';
import ServiceIcon from '../shared/serviceIcon/ServiceIcon';

const { string, bool, func, number } = PropTypes;

const propTypes = {
  bottomText: string,
  disabled: bool,
  id: number.isRequired,
  onClick: func,
  selected: bool,
  thumbnailUrl: string,
  title: string.isRequired,
  tooltip: string,
  type: string.isRequired,
};

const defaultProps = {
  bottomText: null,
  disabled: false,
  onClick: () => {},
  selected: false,
  thumbnailUrl: null,
  tooltip: '',
};

const ItemTile = ({
  id,
  title,
  type,
  thumbnailUrl,
  tooltip,
  selected,
  onClick,
  bottomText,
  disabled,
}) => {
  const classes = ['ufr-item-tile', disabled && 'ufr-item-tile-disabled'];

  const imageStyle = thumbnailUrl
    ? { backgroundImage: `url(${thumbnailUrl})` }
    : { background: '#fff' };

  return (
    <div
      id={`ufr-item-tile-${id}`}
      role="button"
      title={tooltip}
      tabIndex={disabled ? -1 : 0}
      className={classnames(classes)}
      onClick={disabled ? undefined : onClick}
      onKeyDown={
        disabled
          ? undefined
          : (e) => {
              if (e.keyCode === 13) onClick();
            }
      }
    >
      {selected && <SelectedIcon />}
      <div className="ufr-item-tile-image" style={imageStyle} />
      <div className="ufr-item-tile-header">
        <p>{title}</p>
      </div>
      <ServiceIcon service={type} size="small" />
      {bottomText && <div className="ufr-item-tile-bottom-text">{bottomText}</div>}
    </div>
  );
};

ItemTile.propTypes = propTypes;
ItemTile.defaultProps = defaultProps;

export default ItemTile;
