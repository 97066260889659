import HubInstalledAppsListingDatatable from '../datatables/installedApps/hub/HubInstalledAppsListingDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint-disable sort-keys */
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  hubId: PropTypes.number.isRequired,
};

const HubInstalledAppsPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    title: { text: title },
    subtitle: { text: subtitle },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <HubInstalledAppsListingDatatable {...props} />
    </Page>
  );
};

HubInstalledAppsPage.propTypes = propTypes;

export default HubInstalledAppsPage;
