import './itemsListingDatatable.less';
import { api2ListingIterator } from '@/utils/helpers';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Avatar from '@/components/shared/avatar/Avatar';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import DatatableThumbnail from '@/components/datatable/DatatableThumbnail';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import handleClick from '@/utils/handleClick';
import ItemsListingButtons from './ItemsListingButtons';
import ItemsListingSearchByTagsMultiSelect from './ItemsListingSearchByTagsMultiSelect';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  allItemsCount: PropTypes.number.isRequired,
  canAddTags: PropTypes.bool.isRequired,
  canCreateItems: PropTypes.bool.isRequired,
  canDeleteItems: PropTypes.bool.isRequired,
  canEditItems: PropTypes.bool.isRequired,
  featuringItemsAtStreamLevel: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  hubsList: PropTypes.array.isRequired,
  internalNameBetaEnabled: PropTypes.bool,
  itemsCountByType: PropTypes.array.isRequired,
  itemTypes: PropTypes.object.isRequired,
  openStreamsLinkModal: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  openTagsModal: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  tagsMgmt: PropTypes.bool.isRequired,
};

const itemsDatatableId = 'items';

const entityName = {
  plural: 'Items',
  singular: 'Item',
};

const TAG_GROUPS_DEFAULT = {
  text: 'All Tag Groups',
  value: null,
};

// used to cache previously selected tag groups to unnecassary limit api calls
const tagGroupTagsCache = {};

const searchPlaceholder = (itemsCount) =>
  `Search ${itemsCount} ${itemsCount === 1 ? entityName.singular : entityName.plural}`;

/* eslint-disable sort-keys */
const filterTypes = (itemTypes, itemsCountByType) =>
  Object.values(itemsCountByType).map((key) => ({
    id: key.code,
    text: itemTypes[key.code].label,
    queryValue: key.code,
  }));

/**
 * generate hub dropdown if more than 2 hubs exist
 * @param {Object[]} hubsList
 * @param {String} hubsList[].id
 * @param {String} hubsList[].name
 * @returns {Object[]}
 */
const generateHubFilterDropdown = (hubsList) => {
  const hasMultipleHubs = hubsList && Array.isArray(hubsList) && hubsList.length >= 2;

  if (!hasMultipleHubs) {
    return [];
  }

  const items = hubsList.map((hub) => ({
    id: `hub-${hub.id}`,
    text: `${hub.name}`,
    queryValue: `${hub.id}`,
  }));

  return [
    {
      queryKey: 'hubId',
      toggleId: 'hubs',
      toggleText: 'Hub: All Hubs',
      items: [
        {
          id: 'all-hubs',
          text: 'Hub: All Hubs',
          queryValue: null,
        },
        ...items,
      ],
    },
  ];
};

const filterDropdowns = (servicesList, hubServices, hubsList) => [
  {
    queryKey: 'service',
    toggleId: 'item-types',
    toggleText: 'Type: All Types',
    items: [
      {
        id: 'all-types',
        text: 'Type: All Types',
        queryValue: null,
      },
      ...filterTypes(servicesList, hubServices),
    ],
  },
  ...generateHubFilterDropdown(hubsList),
];
/* eslint-enable sort-keys */

const cellClass = (key) => `ufr-dt-items-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-items-listing-${key}-header`;

const handleStreamsLink = (streamsLink) => {
  handleClick({ streamsLink });
};

/* eslint-disable react/prop-types */
/* eslint-disable sort-keys */
const columns = (
  isSearchDisabled,
  openTagsModal,
  openStreamsLinkModal,
  canDeleteItems,
  canEditItems,
  canAddTags,
  dispatchToasterAction,
  internalNameBetaEnabled,
) => {
  const columnsRendered = [
    {
      id: 'title',
      Header: 'Item Title',
      accessor: 'title',
      className: cellClass('item-name'),
      headerClassName: headerClass('item-name'),
      minWidth: internalNameBetaEnabled ? 160 : 265,
      sortable: !isSearchDisabled,
      Cell: ({ original }) => (
        <>
          {(function () {
            if (!internalNameBetaEnabled) {
              return (
                <a
                  href={`/hubs/itemEditContents/${original.hub_id}/${original.id}/${original.stream_id}`}
                >
                  <DatatableThumbnail type={original.service} imageUrl={original.thumbnail_url} />
                </a>
              );
            }
          })()}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="ufr-item-name-tooltip" placement="top">
                {original.title}
              </Tooltip>
            }
          >
            <div className="ufr-item-name">
              <a
                href={`/hubs/itemEditContents/${original.hub_id}/${original.id}/${original.stream_id}`}
              >
                {original.title}
              </a>
            </div>
          </OverlayTrigger>
        </>
      ),
    },
    {
      id: 'author.full_name',
      Header: 'Author',
      accessor: 'author.full_name',
      className: cellClass('item-author'),
      headerClassName: headerClass('item-author'),
      minWidth: 160,
      sortable: !isSearchDisabled,
      Cell: ({ original: { author } }) =>
        author.first_name ? (
          [
            <Avatar
              key={1}
              firstName={author.first_name}
              lastName={author.last_name}
              avatarUrl={author.avatar_url}
            />,
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="ufr-item-author-tooltip" placement="top">
                  {`${author.first_name} ${author.last_name ? author.last_name : ''}`}
                </Tooltip>
              }
            >
              <div key={2} className="truncate">
                {`${author.first_name} ${author.last_name ? author.last_name : ''}`}
              </div>
            </OverlayTrigger>,
          ]
        ) : (
          <div className="ufr-item-author">No Author</div>
        ),
    },
    {
      id: 'stream_count',
      Header: 'Streams',
      accessor: 'stream_count',
      className: cellClass('item-streams-count'),
      headerClassName: headerClass('item-streams-count'),
      minWidth: 80,
      sortable: !isSearchDisabled,
      Cell: (cell) => (
        <a
          href="#"
          data-action="list-streams"
          data-item-id={cell.original.id}
          onClick={() => handleStreamsLink(openStreamsLinkModal)}
        >
          {cell.original.stream_count.toLocaleString('EN-us')}
        </a>
      ),
    },
    {
      id: 'id',
      Header: 'ID',
      accessor: 'id',
      className: cellClass('item-id'),
      headerClassName: headerClass('item-id'),
      minWidth: 80,
      sortable: !isSearchDisabled,
      Cell: ({ original }) => original.id,
    },
    {
      id: 'published_at',
      Header: 'Published',
      accessor: 'published_at',
      className: cellClass('item-published-date'),
      headerClassName: headerClass('item-published-date'),
      minWidth: 110,
      sortable: !isSearchDisabled,
      Cell: ({ original }) =>
        original.published ? getHumanReadableDate(original.published_at) : 'Not Published',
    },
    {
      id: 'modified_at',
      Header: 'Updated',
      accessor: 'modified_at',
      className: cellClass('item-updated-at'),
      headerClassName: headerClass('item-updated-at'),
      minWidth: 110,
      sortable: !isSearchDisabled,
      Cell: ({ value }) => getHumanReadableDate(value),
    },
    {
      Header: '',
      accessor: 'controls',
      className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
      headerClassName: headerClass('buttons'),
      minWidth: 130,
      sortable: false,
      Cell: (cell) => (
        <ItemsListingButtons
          itemId={cell.original.id}
          itemTitle={cell.original.title}
          hubId={cell.original.hub_id}
          streamId={cell.original.stream_id}
          itemUrl={cell.original.url}
          triggerRefresh={cell.refresh}
          openTagsModal={openTagsModal}
          decrementItemsCount={cell.decrementItemsCount}
          canDeleteItems={canDeleteItems}
          canEditItems={canEditItems}
          canAddTags={canAddTags}
          dispatchToasterAction={dispatchToasterAction}
        />
      ),
    },
  ];

  if (internalNameBetaEnabled) {
    return [
      {
        id: 'name',
        Header: 'Internal Item Name',
        accessor: 'name',
        className: cellClass('item-name'),
        headerClassName: headerClass('item-name'),
        minWidth: 265,
        sortable: !isSearchDisabled,
        Cell: ({ original }) => (
          <>
            <a
              href={`/hubs/itemEditContents/${original.hub_id}/${original.id}/${original.stream_id}`}
            >
              <DatatableThumbnail type={original.service} imageUrl={original.thumbnail_url} />
            </a>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="ufr-item-name-tooltip" placement="top">
                  {original.name}
                </Tooltip>
              }
            >
              <div className="ufr-item-name">
                <a
                  href={`/hubs/itemEditContents/${original.hub_id}/${original.id}/${original.stream_id}`}
                >
                  {original.name}
                </a>
              </div>
            </OverlayTrigger>
          </>
        ),
      },
      ...columnsRendered,
    ];
  } else {
    return columnsRendered;
  }
};
/* eslint-enable react/prop-types */
/* eslint-enable sort-keys */

const getEndpointBuilder = () => ({
  getHubItems: (hubId) => `/api/v2/hubs/${hubId}/items`,
  getItems: () => '/api/v2/items',
  getTagGroupById: (tagGroupId) => `/api/v2/tag-groups/${tagGroupId}`,
  getTagGroups: () => '/api/v2/tag-groups',
  getTagGroupTags: (tagGroupId) => `/api/v2/tag-groups/${tagGroupId}/tags`,
  getTags: () => '/api/v2/tags',
});

const ItemsListingDatatable = ({
  hubId,
  hubsList,
  itemTypes,
  itemsCountByType,
  allItemsCount,
  openTagsModal,
  openStreamsLinkModal,
  canCreateItems,
  canDeleteItems,
  canEditItems,
  canAddTags,
  featuringItemsAtStreamLevel,
  tagsMgmt,
  internalNameBetaEnabled,
}) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [tagState, setTagState] = useState({
    selectedTagGroup: null,
    selectedTags: [],
    tagGroups: null,
    tags: [],
  });

  const { getItems, getHubItems, getTags, getTagGroups, getTagGroupTags } = getEndpointBuilder();

  const buttonText = (selectedTags) =>
    selectedTags.length ? `Tags: ${selectedTags.length}` : 'Tags: All';

  const searchByTagsButton = (selectedTags) => ({
    icon: 'chevron-down',
    id: 'search-by-tags',
    onClick: () => setIsMultiSelectOpen((isOpen) => !isOpen),
    text: buttonText(selectedTags),
  });

  const getAllTagGroups = async () => {
    const tagGroupIterator = api2ListingIterator(getTagGroups());
    const tagGroups = [TAG_GROUPS_DEFAULT];
    const tagGroupIdMapping = {};

    for await (const tagGroup of tagGroupIterator) {
      const transformedTagGroup = {
        text: tagGroup.name === 'CONTENT' ? 'Ungrouped' : tagGroup.name,
        value: tagGroup.id,
      };

      tagGroups.push(transformedTagGroup);
      tagGroupIdMapping[tagGroup.id] = transformedTagGroup;
    }

    return [tagGroups, tagGroupIdMapping];
  };

  const getAllTags = async (tagGroupId = null, tagGroupIdMapping = null) => {
    if (tagGroupTagsCache[tagGroupId]) {
      return tagGroupTagsCache[tagGroupId];
    }

    const tagsIterator = api2ListingIterator(tagGroupId ? getTagGroupTags(tagGroupId) : getTags());

    const tags = [];

    for await (const tag of tagsIterator) {
      const tagGroup = tagGroupIdMapping?.[tag.tag_group_id];

      tags.push({
        label: `${tagGroup && tagGroup.text !== 'Ungrouped' ? tagGroup.text + ':' : ''} ${
          tag.name
        }`,
        value: tag.id,
      });
    }

    tagGroupTagsCache[tagGroupId] = tags;

    return tagGroupTagsCache[tagGroupId];
  };

  const initTagState = async () => {
    const [tagGroups, tagGroupIdMapping] = tagsMgmt ? await getAllTagGroups() : [null, null];

    let selectedTagGroup =
      typeof history !== 'undefined' &&
      history.state?.[itemsDatatableId]?.initialTagState?.selectedTagGroup;

    // history set in Datatable component
    let selectedTags =
      typeof history !== 'undefined' && history.state?.dataTables?.[itemsDatatableId]?.tagged_with;

    if (selectedTagGroup) {
      selectedTagGroup = tagGroups.find((tg) => tg.text === selectedTagGroup);
    } else {
      selectedTagGroup = tagGroups ? tagGroups[0] : null;
    }

    const tags = await getAllTags(selectedTagGroup?.value, tagGroupIdMapping);

    if (selectedTags) {
      selectedTags = selectedTags.split(',').map((tagId) => ({
        label: tags.filter((item) => item.value === parseInt(tagId))[0]['label'],
        value: tagId,
      }));
    } else {
      selectedTags = [];
    }

    setTagState({
      ...tagState,
      selectedTagGroup,
      selectedTags,
      tagGroups,
      tags,
    });
  };

  if (isInitialLoad) {
    initTagState();

    // always initialize irregardless of whether tag state initializes to prevent entire component failure
    setIsInitialLoad(false);
    allItemsCount === 0 ? setIsSearchDisabled(true) : setIsSearchDisabled(false);
  }

  const dropdownMenuItems = (canCreateItems, featuringItemsAtStreamLevel) => {
    const dropDownArray = [];
    if (canCreateItems) {
      dropDownArray.push({
        id: 'deleted-items',
        text: 'Manage Deleted Items',
        url: `/hubs/manage/${hubId}/deleted`,
      });
    }
    if (!featuringItemsAtStreamLevel) {
      dropDownArray.push({
        id: 'featured-items',
        text: 'Manage Featured Items',
        url: `/hubs/manage/${hubId}/featured`,
      });
    }
    return dropDownArray;
  };

  const getData = async (queries) => {
    // shallow clone queries
    const cleanedQueries = { ...queries };
    const hubId = cleanedQueries.hubId;

    // remove hubId from query before making request
    delete cleanedQueries.hubId;

    const {
      data: { data, meta },
    } = await axios.get(hubId ? getHubItems(hubId) : getItems(), {
      params: cleanedQueries,
    });

    setItemsCount(meta.count);

    return { data, meta };
  };

  const handleTagGroupSelect = async (selectedOptions) => {
    const selectedTagGroup = tagState?.tagGroups.find((tg) => tg.text === selectedOptions);

    const sameSelection = Object.is(selectedTagGroup, tagState.selectedTagGroup);

    if (!sameSelection) {
      const tags = await getAllTags(selectedTagGroup?.value);

      // store selected group for refresh
      history.replaceState(
        {
          ...history.state,
          [itemsDatatableId]: {
            initialTagState: {
              selectedTagGroup: selectedTagGroup.text,
            },
          },
        },
        'initialTagGroup',
      );

      setTagState({
        selectedTagGroup,
        selectedTags: [],
        tagGroups: tagState.tagGroups,
        tags,
      });
    }
  };

  const handleSetSelectedTags = (selectedTags) => {
    // tagState keeps everything in one state variable to avoid multiple refreshes
    setTagState({
      ...tagState,
      selectedTags,
    });
  };

  const multiSelect = ({ handleSelect }) => (
    <ItemsListingSearchByTagsMultiSelect
      items={tagState.tags}
      tagGroups={tagState.tagGroups}
      handleSelect={handleSelect}
      handleTagGroupSelect={handleTagGroupSelect}
      selectedTagGroup={tagState.selectedTagGroup?.text}
      setSelectedTags={handleSetSelectedTags}
      selectedTags={tagState.selectedTags}
      isMultiSelectOpen={isMultiSelectOpen}
      setIsMultiSelectOpen={setIsMultiSelectOpen}
    />
  );

  return (
    <>
      <CsrfProvider>
        <DataTable
          id={itemsDatatableId}
          useStateHandling
          entityName={entityName}
          actionButtons={[searchByTagsButton(tagState.selectedTags)]}
          filterDropdowns={filterDropdowns(itemTypes, itemsCountByType, hubsList)}
          searchPlaceholder={searchPlaceholder(itemsCount)}
          columns={columns(
            isSearchDisabled,
            openTagsModal,
            openStreamsLinkModal,
            canDeleteItems,
            canEditItems,
            canAddTags,
            dispatchToasterAction,
            internalNameBetaEnabled,
          )}
          getData={getData}
          initialSort={{ desc: true, id: 'published_at' }}
          showLoadingState
          noSearchResultsMessage={
            <p>
              {isSearchDisabled
                ? 'There are no Items in this Hub yet.'
                : 'No items in all hubs match your search.'}
            </p>
          }
          hidePagination={isSearchDisabled}
          dropdownMenuItems={dropdownMenuItems(canCreateItems, featuringItemsAtStreamLevel)}
          disableSearch={isSearchDisabled}
          getCellProps={() => ({
            decrementItemsCount: () => {
              setItemsCount(itemsCount - 1);
            },
          })}
          MultiSelect={multiSelect}
        />
        <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
      </CsrfProvider>
    </>
  );
};

ItemsListingDatatable.propTypes = propTypes;

export default ItemsListingDatatable;
