import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../../shared/button/Button';
import ConfirmationModal from '../../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  entityId: PropTypes.number.isRequired,
  getEndpoint: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showMessage: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const defaultProps = {};

const LangLinkDeleteButton = ({
  entityId,
  header,
  message,
  getEndpoint,
  showMessage,
  triggerRefresh,
}) => {
  const deleteLink = async (csrfToken) => {
    showMessage('info', 'Deleting...', entityId);

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'delete',
        url: getEndpoint(entityId),
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      showMessage('success', 'Language Link deleted.', entityId);
      triggerRefresh();
    } catch {
      showMessage('error', 'There was an error deleting the language link.', entityId);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <div>
          <ConfirmationModal
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
            handleConfirm={() => deleteLink(csrfToken)}
            body={message}
            header={header}
          />

          <Button
            type="button"
            label="delete language link"
            icon="bin"
            id={`delete-langlink-${entityId}`}
            onClick={() => setIsOpen(true)}
          />
        </div>
      )}
    </CsrfContextConsumer>
  );
};

LangLinkDeleteButton.propTypes = propTypes;
LangLinkDeleteButton.defaultProps = defaultProps;

export default LangLinkDeleteButton;
