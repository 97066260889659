import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const defaultProps = {
  month: undefined,
};

const propTypes = {
  month: PropTypes.instanceOf(moment().constructor),
};

export const CalendarHeader = ({ month }) => (
  <div className="uf-month-header">
    <p>{month.format('MMM YYYY')}</p>
  </div>
);

CalendarHeader.defaultProps = defaultProps;
CalendarHeader.propTypes = propTypes;
