import PropTypes from 'prop-types';
import React from 'react';

import Cta15ListingDatatable from '@/components/datatables/cta15Listing/Cta15ListingDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';

const propTypes = {
  canCreateFormCta: PropTypes.bool.isRequired,
  canCreateLinkCta: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalCtaCount: PropTypes.number.isRequired,
};

const Cta15ListingPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <Cta15ListingDatatable {...props} />
    </Page>
  );
};

Cta15ListingPage.propTypes = propTypes;

export default Cta15ListingPage;
