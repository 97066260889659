import './appearanceTab.less';
import axios from 'axios';
import ButtonCtaAccordion from '../accordions/ButtonCtaAccordion/ButtonCtaAccordion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TileDesignAccordion from '../accordions/TileDesignAccordion';

const propTypes = {
  csrfToken: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    background_color: PropTypes.string.isRequired,
    button_background_color: PropTypes.string.isRequired,
    button_font_color: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    embed_url: PropTypes.string.isRequired,
    font_aspect_ratio: PropTypes.number.isRequired,
    font_color: PropTypes.string.isRequired,
    has_background: PropTypes.bool.isRequired,
    lead_url: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  ctaId: PropTypes.string.isRequired,
  handleToasterLifecycle: PropTypes.func,
};

/* eslint-disable sort-keys */
export const uploadBackground = async (ctaId, prevData, newData, setData, csrfToken) => {
  const res = await axios({
    method: 'post',
    url: `/api/v2/cta-background/${ctaId}`,
    data: newData.file,
    headers: {
      'X-Csrf-Token': csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  setData({ ...prevData, has_background: true, background_url: res.data.url });
};

export const removeBackground = async (ctaId, prevData, setData, csrfToken) => {
  await axios({
    method: 'delete',
    url: `/api/v2/cta-background/${ctaId}`,
    headers: {
      'X-Csrf-Token': csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  setData({ ...prevData, has_background: false });
};

export const updateCta = async (ctaId, newData, setData, csrfToken) => {
  const res = await axios({
    method: 'patch',
    url: `/api/v2/cta/${ctaId}`,
    data: newData,
    headers: {
      'X-Csrf-Token': csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  setData(res.data);
};
/* eslint-enable sort-keys */

const AppearanceTab = ({ cta, csrfToken, ctaId, handleToasterLifecycle }) => {
  const [data, setData] = useState(cta);
  const [iframeKey, setIframeKey] = useState(0);
  const [openAccordion, setOpenAccordion] = useState('tile-design');

  const handleSubmit = async (newData, action, csrfToken) => {
    let toaster = {
      id: ctaId,
    };

    try {
      switch (action) {
        case 'upload-bg':
          await uploadBackground(ctaId, data, newData, setData, csrfToken);
          break;
        case 'remove-bg':
          await removeBackground(ctaId, data, setData, csrfToken);
          break;
        default:
          await updateCta(ctaId, newData, setData, csrfToken);
      }

      toaster = {
        ...toaster,
        text: 'Saved',
        type: 'success',
      };
    } catch {
      toaster = {
        ...toaster,
        text: 'Something went wrong. Please try again.',
        type: 'error',
      };
    }
    if (handleToasterLifecycle) handleToasterLifecycle(toaster, ctaId);
  };

  useEffect(() => setIframeKey(iframeKey + 1), [data]);

  return (
    <div className="tab-container">
      <div className="cta-accordions">
        <TileDesignAccordion
          form={{
            background_color: data.background_color,
            font_aspect_ratio: data.font_aspect_ratio,
            font_color: data.font_color,
            has_background: data.has_background,
            tagline: data.tagline,
          }}
          handleSubmit={(form, action) => handleSubmit(form, action, csrfToken)}
          isOpen={openAccordion === 'tile-design'}
          setOpenAccordion={setOpenAccordion}
        />
        <ButtonCtaAccordion
          ctaType={cta.type}
          data={{
            button_background_color: data.button_background_color,
            button_font_color: data.button_font_color,
            button_label: data.button_label,
          }}
          handleSubmit={(form) => handleSubmit(form, '', csrfToken)}
          isOpen={openAccordion === 'button'}
          setOpenAccordion={setOpenAccordion}
        />
      </div>
      <div className="preview-container">
        <h3 className="preview-text">Preview</h3>
        <iframe
          key={iframeKey}
          title={'CTA Preview'}
          src={cta.embed_url}
          style={{ border: 'none', overflow: 'hidden' }}
          border="0"
          frameBorder="0"
          width="250"
          height="330"
        />
      </div>
    </div>
  );
};

AppearanceTab.propTypes = propTypes;

export default AppearanceTab;
