import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  cell: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  columnName: PropTypes.string.isRequired,
};

const CtaListingTruncatedCell = ({ cell, columnName, children }) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`full-value-${columnName}-${cell.original.id}`}>{cell.value}</Tooltip>}
  >
    {React.cloneElement(children, { className: 'ufr-cta-truncated-cell-value' })}
  </OverlayTrigger>
);

CtaListingTruncatedCell.propTypes = propTypes;

export default CtaListingTruncatedCell;
