import React from 'react';

const getArchiveStreamConfirmationMessage = (streamType) => {
  const modalMessage = (
    <p>
      <b>Are you sure you want to Archive this Stream?</b>
    </p>
  );

  const defaultMessage = (
    <>
      <p>
        Some settings may be lost, but this Stream can still be Restored. The Items in this Stream
        will also be archived, and hidden from all Marketing and Sales Streams that use them.
      </p>
      <p>
        If Items in this Stream were imported from a remote source (e.g. an external blog), the
        remote items will <b>not</b> be deleted.
      </p>
    </>
  );

  const customOrTargetedStreamMessage = (
    <>
      <p>
        Some settings may be lost, but this Stream can still be Restored. The Items in this Stream
        will be accessible from their originating Stream.
      </p>
    </>
  );

  return (
    <>
      {modalMessage}
      {streamType === 'custom' || streamType === 'targeted'
        ? customOrTargetedStreamMessage
        : defaultMessage}
    </>
  );
};

export default getArchiveStreamConfirmationMessage;
