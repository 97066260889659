import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import NameAndDescriptionModal from '@/components/modal/NameAndDescriptionModal/NameAndDescriptionModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isAddTemplateModalOpen: PropTypes.bool.isRequired,
};

const AddRecommendationTemplateModal = ({ isAddTemplateModalOpen, handleClose, hubId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [data, setData] = useState({});

  /* eslint-disable sort-keys */
  const handleSubmit = async (csrfToken) => {
    const recommendationTemplateId = 'create-recommendation-template';

    data['hub_id'] = hubId;

    try {
      await axios({
        method: 'post',
        url: '/api/v2/recommendation-templates',
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: data,
      }).then((response) => {
        dispatchToasterAction(
          addToaster({
            id: recommendationTemplateId,
            text: 'Template Created, redirecting...',
            type: 'success',
          }),
        );
        window.location.href = `/recommendation_templates/edit/${hubId}/${response.data.id}`;
      });
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: recommendationTemplateId,
          text:
            error?.response?.data?.errors[0]?.message || 'Cannot create Recommendation Template.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: recommendationTemplateId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <NameAndDescriptionModal
            fieldIds={{
              descriptionFieldId: 'ufr-new-recommendation-template-description',
              nameFieldId: 'ufr-new-recommendation-template-name',
            }}
            fieldLabels={{ descriptionFieldLabel: 'Description', nameFieldLabel: 'Name' }}
            fieldNameAttributes={{
              descriptionFieldName: 'description',
              nameFieldName: 'name',
            }}
            title="Add New Recommendation Template"
            handleSubmit={() => handleSubmit(csrfToken)}
            primaryButtonText="Add Template"
            handleClose={handleClose}
            isOpen={isAddTemplateModalOpen}
            setData={setData}
            data={data}
            maxLength={200}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

AddRecommendationTemplateModal.propTypes = propTypes;

export default AddRecommendationTemplateModal;
