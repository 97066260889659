import classNames from 'classnames';
import DownshiftInput from './DownshiftInput';
import handleInputReady from '../../../utils/handleInputReady';
import InputContainer from '../inputContainer/InputContainer';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  // Defines if dropdown has glyphicon instead of visible text | boolean
  glyphicon: PropTypes.bool,
  // function to call every time the input value changes
  handleChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  // for app config settings
  inheritedValue: PropTypes.number,
  // List of items from API, formatted structured should be: {text: 'string', value: 'int|string'}
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  placeholder: PropTypes.string,
  resetData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  showReset: PropTypes.bool,
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]).isRequired,
  title: PropTypes.string,
  useConfigFieldEventHandler: PropTypes.bool,
  // Optional: Should match a string value in the items array {text: 'ABM'}, pass in 'ABM'
  value: PropTypes.string,
};

const defaultProps = {
  description: '',
  disabled: false,
  glyphicon: false,
  inheritedValue: null,
  name: '',
  placeholder: '',
  resetData: {},
  showReset: false,
  title: '',
  useConfigFieldEventHandler: false,
  value: '',
};

class SelectDropdown extends React.Component {
  handleSelect = (selectedItem) => {
    const { submitData, disabled, items, name, useConfigFieldEventHandler } = this.props;

    if (disabled) {
      return;
    }

    if (useConfigFieldEventHandler) {
      const selectedItemIndex = items.findIndex((i) => i.text === selectedItem);

      const componentData = {
        propertyName: name,
        propertyValue: selectedItemIndex,
      };

      handleInputReady(submitData, componentData);
    } else {
      // find the select item obj from items array
      // downshift only returns the label for the selected item
      const item = items.filter((i) => i.text === selectedItem)[0];

      // pass the selected item/object for processing
      handleInputReady(submitData, item);

      if ('handleChange' in this.props && typeof this.props.handleChange === 'function') {
        this.props.handleChange(item.value);
      }
    }
  };

  render() {
    const {
      id,
      title,
      description,
      disabled,
      items,
      value,
      glyphicon,
      name,
      inheritedValue,
      resetData,
      showReset,
      placeholder,
    } = this.props;
    const inputContainerClassName = [disabled && 'ufr-dropdown-disabled'];

    return (
      <InputContainer
        id={`searchable-select-${id}`}
        title={title}
        description={description}
        className={classNames(inputContainerClassName)}
        disabled={disabled}
      >
        <DownshiftInput
          id={`ufr-select-${id}`}
          items={items}
          defaultSelectedItem={value || (items.length && items[0].text)}
          handleSelect={this.handleSelect}
          glyphicon={glyphicon}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          inheritedValue={inheritedValue}
          showReset={showReset}
          resetData={resetData}
        />
      </InputContainer>
    );
  }
}

SelectDropdown.propTypes = propTypes;
SelectDropdown.defaultProps = defaultProps;

export default SelectDropdown;
