import './salesforceDropdown.less';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Dropdown, MenuItem } from 'react-bootstrap';
import React from 'react';

export const DropdownTitle = React.memo(({ children }) => (
  <p className="dropdown-title">{children}</p>
));

DropdownTitle.propTypes = {
  children: string.isRequired,
};

export const defaultOption = { label: '-- None --', value: null };

const SalesforceDropdown = ({
  selected,
  loading,
  menuItems,
  selectDropdownItem,
  noDefault,
  error,
}) => {
  const dropdownItems = noDefault ? menuItems : [defaultOption, ...(menuItems || [])];
  const currentValue =
    menuItems
      // value might be a number, but selected needs to be a string
      ?.find(({ value }) => String(value) === selected)?.label || defaultOption.label;
  return (
    <div className="dropdown-container">
      <Dropdown disabled={loading} className="salesforce-dropdown">
        <Dropdown.Toggle className={error && 'dropdownError'}>
          {currentValue || defaultOption.label}
        </Dropdown.Toggle>
        <Dropdown.Menu className="salesforce-dropdown-menu">
          {!!dropdownItems?.length &&
            dropdownItems.map(({ value, label }) => (
              <MenuItem
                className={selected === value && 'selected-item'}
                key={value}
                eventKey={value}
                onClick={() => selectDropdownItem(value)}
              >
                <span>{label}</span>
              </MenuItem>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      {loading ? <div className="loading-spinner" /> : null}
    </div>
  );
};

SalesforceDropdown.propTypes = {
  error: bool,
  loading: bool,
  menuItems: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ),
  noDefault: bool,
  selectDropdownItem: func.isRequired,
  selected: string,
};

SalesforceDropdown.defaultProps = {
  error: false,
  loading: false,
  menuItems: [],
  noDefault: false,
  selected: null,
};

export default SalesforceDropdown;
