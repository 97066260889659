import './pageHeader.less';
import Breadcrumbs from './Breadcrumbs';
import PageHeaderTitleAndSubtitle from './PageHeaderTitleAndSubtitle';
import PropTypes from 'prop-types';
import React from 'react';

const PageHeader = ({ breadcrumbs, title, subtitle }) => (
  <div id="ufr-page-header">
    {breadcrumbs.length > 0 && <Breadcrumbs crumbs={breadcrumbs} currentPageName={title.text} />}

    <PageHeaderTitleAndSubtitle
      icon={title.icon}
      title={{
        editable: title.onSubmit && title.editable,
        nullable: false,
        onSubmit: title.onSubmit,
        onSubmitCallback: title.onSubmitCallback,
        text: title.text,
      }}
      subtitle={{
        editable: subtitle.onSubmit && subtitle.editable,
        nullable: false,
        onSubmit: subtitle.onSubmit,
        onSubmitCallback: subtitle.onSubmitCallback,
        placeholder: subtitle.placeholder,
        text: subtitle.text,
      }}
    />
  </div>
);

PageHeader.defaultProps = {
  breadcrumbs: [],
  subtitle: {},
};

PageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  subtitle: PropTypes.shape({
    editable: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSubmitCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    text: PropTypes.string,
  }),
  title: PropTypes.shape({
    editable: PropTypes.bool,
    icon: PropTypes.string,
    onSubmit: PropTypes.func,
    onSubmitCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default PageHeader;
