import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Cta from '@/components/cta/Cta';
import PlaceCtaModal from '@/components/modal/PlaceCtaModal/PlaceCtaModal';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

const propTypes = {
  ctas: PropTypes.arrayOf(PropTypes.shape(Cta.propTypes.cta)).isRequired,
  hubId: PropTypes.number.isRequired,
  initialPlacedCtaIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  streamId: PropTypes.number.isRequired,
  streamName: PropTypes.string.isRequired,
};

const defaultCtaType = 'all';

const AddCtaPlacement = ({ hubId, streamId, streamName, ctas, initialPlacedCtaIds }) => {
  const ctaHeight = 293;
  const ctaWidth = 225;

  const [isOpen, setIsOpen] = useState(false);

  const placedCtaIds = useRef(initialPlacedCtaIds);
  const ctaType = useRef(defaultCtaType);
  const query = useRef('');

  const getAvailableCtas = () => {
    const availableCtas = [];

    ctas.forEach((cta) => {
      if (placedCtaIds.current.includes(cta.id)) {
        return;
      }

      const lowerCaseQuery = query.current.toLowerCase();
      const ctaTagline = cta.tagline ? cta.tagline : '';

      if (
        lowerCaseQuery !== '' &&
        cta.name.toLowerCase().indexOf(lowerCaseQuery) === -1 &&
        cta.id.toString().toLowerCase().indexOf(lowerCaseQuery) === -1 &&
        ctaTagline.toLowerCase().indexOf(lowerCaseQuery) === -1
      ) {
        return;
      }

      if (ctaType.current !== 'all' && ctaType.current !== cta.type) {
        return;
      }

      availableCtas.push(cta);
    });

    return availableCtas;
  };

  const [availableCtas, setAvailableCtas] = useState(getAvailableCtas);

  const handleClose = () => {
    // reset state
    query.current = '';
    ctaType.current = defaultCtaType;

    setIsOpen(false);
  };

  const handleAnimationEnd = (animation) => {
    if (animation === 'slide-out') {
      // reset state after modal close animation
      setAvailableCtas(getAvailableCtas());
    }
  };

  const handleSubmit = async (csrfToken, selectedCtaId) => {
    await axios({
      data: {
        cta_id: selectedCtaId,
        position: 1,
        position_active: 1,
        stream_id: streamId,
      },
      headers: {
        'X-Csrf-Token': csrfToken,
      },
      method: 'post',
      url: `/api/v2/cta-placements`,
    });

    window.getCtaPlacementData('.hubs_section_wrapper', streamId);
    setIsOpen(false);
    placedCtaIds.current.push(selectedCtaId);
    setAvailableCtas(getAvailableCtas());
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleSearchChange = (e) => {
    query.current = e.target.value;
    setAvailableCtas(getAvailableCtas());
  };

  const handleCtaTypeChange = (e) => {
    ctaType.current = e.value;
    setAvailableCtas(getAvailableCtas());
  };

  return (
    <>
      <a onClick={handleClick} className="ufr-btn ufr-btn-primary">
        <span className="glyphicons glyphicons-bullhorn" aria-hidden="true" />
        Add CTA
      </a>

      <CsrfProvider>
        <CsrfContextConsumer>
          {/* eslint-disable-next-line */}
          {(csrfToken) => {
            return (
              <PlaceCtaModal
                isOpen={isOpen}
                hubId={hubId}
                title={`Place a CTA on ${streamName}`}
                ctas={availableCtas}
                handleAnimationEnd={handleAnimationEnd}
                handleClose={handleClose}
                handleSubmit={async (selectedCtaId) => {
                  await handleSubmit(csrfToken, selectedCtaId);
                }}
                handleSearchChange={handleSearchChange}
                handleCtaTypeChange={handleCtaTypeChange}
                ctaHeight={ctaHeight}
                ctaWidth={ctaWidth}
              />
            );
          }}
        </CsrfContextConsumer>
      </CsrfProvider>
    </>
  );
};

AddCtaPlacement.propTypes = propTypes;

export default AddCtaPlacement;
