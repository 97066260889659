import './integrations.less';
import './mailchimp.less';
import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios/index';
import Checkbox from '@/components/formInputs/checkbox/Checkbox';
import Icon from '@/components/shared/icon/Icon';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import SelectDropdown from '@/components/formInputs/selectDropdown/SelectDropdown';
import Spinner from '@/components/modal/Spinner/Spinner';

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const propTypes = {
  csrfToken: PropTypes.string,
  cta: PropTypes.shape({}),
  getCtaIntegrationLists: PropTypes.func,
  setCtaIntegrationLists: PropTypes.func,
};

const tooltip = (
  <Tooltip id="tooltip" className="integration-tooltip">
    List must be created in the <b>Lead Database view in Marketo</b>. Lists created in Marketing
    Activities section <i>cannot</i> be used for this integration.
  </Tooltip>
);

const weeklyScheduleDaysDropdownData = [
  { text: 'Sunday', value: '0' },
  { text: 'Monday', value: '1' },
  { text: 'Tuesday', value: '2' },
  { text: 'Wednesday', value: '3' },
  { text: 'Thursday', value: '4' },
  { text: 'Friday', value: '5' },
  { text: 'Saturday', value: '6' },
];

const weeklyScheduleHoursDropdownData = [
  { text: '12:00AM', value: '0' },
  { text: '1:00AM ', value: '1' },
  { text: '2:00AM', value: '2' },
  { text: '3:00AM', value: '3' },
  { text: '4:00AM', value: '4' },
  { text: '5:00AM', value: '5' },
  { text: '6:00AM', value: '6' },
  { text: '7:00AM', value: '7' },
  { text: '8:00AM', value: '8' },
  { text: '9:00AM', value: '9' },
  { text: '10:00AM', value: '10' },
  { text: '11:00AM', value: '11' },
  { text: '12:00PM', value: '12' },
  { text: '1:00PM', value: '13' },
  { text: '2:00PM', value: '14' },
  { text: '3:00PM', value: '15' },
  { text: '4:00PM', value: '16' },
  { text: '5:00PM', value: '17' },
  { text: '6:00PM', value: '18' },
  { text: '7:00PM', value: '19' },
  { text: '8:00PM', value: '20' },
  { text: '9:00PM', value: '21' },
  { text: '10:00PM', value: '22' },
  { text: '11:00PM', value: '23' },
];

const MailChimp = ({
  csrfToken,
  cta,
  getCtaIntegrationLists,
  setCtaIntegrationLists,
  dispatchToasterAction,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [mailChimpDropdownData, setMailChimpDropdownData] = useState([]);
  const [listId, setListId] = useState({});

  const [serviceFollowing, setServiceFollowing] = useState(
    cta.HubCtasForm.service_following === '1' ? true : false,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const getContentToFollow = () => {
    const contentToFollow = [{ text: 'Hub (All Streams)', value: cta.HubCta.hub_id, type: 'hub' }];
    for (const placementData of cta.HubCta.possiblePlacementData)
      contentToFollow.push({
        text: placementData.HubCollection.title,
        value: placementData.HubCollection.id,
        type: placementData.HubCollection.type,
      });
    for (const placementData of cta.HubCta.currentPlacementData)
      contentToFollow.push({
        text: placementData.HubCollection.title,
        value: placementData.HubCollection.id,
        type: placementData.HubCollection.type,
      });
    return contentToFollow;
  };

  const contentToFollowDropdownData = getContentToFollow();

  const [contentToFollow, setContentToFollow] = useState(() => {
    let defaultValue = contentToFollowDropdownData.find(
      (content) => content.value === cta?.HubCtasForm?.service_following_id,
    );
    if (typeof defaultValue === 'undefined') {
      defaultValue = contentToFollowDropdownData.find(
        (content) => content.text === cta?.HubCtasForm?.service_following_name,
      );
    }
    return defaultValue;
  });

  const [weeklyScheduleDay, setWeeklyScheduleDay] = useState(() =>
    weeklyScheduleDaysDropdownData.find(
      (content) => content.value === cta?.HubCtasForm?.service_email_day,
    ),
  );

  const [weeklyScheduleHour, setWeeklyScheduleHour] = useState(() =>
    weeklyScheduleHoursDropdownData.find(
      (content) => content.value === cta?.HubCtasForm?.service_email_time,
    ),
  );

  useEffect(() => {
    getCtaIntegrationLists(
      'Mailchimp',
      setMailChimpDropdownData,
      csrfToken,
      setIsLoading,
      setListId,
      cta.HubCtasForm.service_list_id,
      setErrorMessage,
      setLoadingMessage,
      null,
    );
  }, []);

  const submitDropdown = (selectedItem) => {
    setCtaIntegrationLists(
      selectedItem,
      cta.HubCta.hub_id,
      cta.HubCta.id,
      csrfToken,
      setIsLoading,
      'Mailchimp',
      setListId,
      setLoadingMessage,
      null,
    );
  };

  const submitFollowFeature = async (url, data, selectedItem, setSelectedItem) => {
    setLoadingMessage('Saving Mailchimp Form');
    setIsLoading(true);

    const toaster = {
      id: `saving-Mailchimp-${cta.HubCta.id}`,
    };

    await axios({
      method: 'post',
      url,
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
      .then(() => {
        toaster.type = 'success';
        toaster.text = 'Saved';
        setSelectedItem(selectedItem);
      })
      .catch(() => {
        toaster.type = 'error';
        toaster.text = 'Unable to save';
      });

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);

    setIsLoading(false);
    setLoadingMessage('');
  };

  const renderMailChimpForm = () => {
    if (errorMessage.length > 1) {
      return <div>{errorMessage}</div>;
    }

    return (
      <>
        <div className="integration-setup-sprite mailchimp-text" />
        <label className="integration-label" htmlFor="marketo-dropdown-id">
          Select a Mailing List
        </label>

        <div id="mailchimp-form" className="integration-form">
          <SelectDropdown
            id="mailchimp-dropdown-id"
            items={mailChimpDropdownData}
            submitData={submitDropdown}
            value={listId.text}
          />
        </div>
        <hr />
        <div className="mailchimp-checkbox">
          <Checkbox
            id="mailchimp-checkbox"
            title="Enable Follow Feature"
            onChange={(value) =>
              submitFollowFeature(
                `/hubs/ajax_autoUpdateField/${cta.HubCta.hub_id}/MailchimpCta/${cta.HubCta.id}`,
                {
                  data: { MailchimpCta: { service_following: value ? 1 : 0 } },
                },
                value,
                setServiceFollowing,
              )
            }
            value={serviceFollowing}
          />
          <OverlayTrigger placement="top" overlay={tooltip}>
            <div className="overlay-container">
              <Icon icon="info-sign" />
            </div>
          </OverlayTrigger>
        </div>
        <div className="content-to-follow">
          <label className="content-to-follow-label" htmlFor="content-to-follow-dropdown">
            Content to Follow
          </label>
          <SelectDropdown
            id="content-to-follow-dropdown"
            items={contentToFollowDropdownData}
            submitData={(selectedItem) =>
              submitFollowFeature(
                `/hubs/ajax_updateCtaRssValue/${cta.HubCta.hub_id}`,
                {
                  ctaID: cta.HubCta.id,
                  updateInfo: JSON.stringify({ id: selectedItem.value, type: selectedItem.type }),
                  confirm: false,
                },
                selectedItem,
                setContentToFollow,
              )
            }
            value={contentToFollow.text}
            disabled={!serviceFollowing}
          />
        </div>
        <div className="weekly-schedule">
          <label className="content-to-follow-label" htmlFor="weekly-schedule-day-dropdown">
            Weekly Schedule
          </label>
          <div className="content-to-follow-label-z">
            <SelectDropdown
              id="weekly-schedule-day-dropdown"
              items={weeklyScheduleDaysDropdownData}
              submitData={(selectedItem) =>
                submitFollowFeature(
                  `/hubs/ajax_updateCtaRssValue/${cta.HubCta.hub_id}`,
                  {
                    ctaID: cta.HubCta.id,
                    updateInfo: JSON.stringify({
                      day: selectedItem.value,
                    }),
                    confirm: false,
                  },
                  selectedItem,
                  setWeeklyScheduleDay,
                )
              }
              value={weeklyScheduleDay.text}
              disabled={!serviceFollowing}
            />
            <label htmlFor="weekly-schedule-time-dropdown">@</label>
            <SelectDropdown
              id="weekly-schedule-time-dropdown"
              items={weeklyScheduleHoursDropdownData}
              submitData={(selectedItem) =>
                submitFollowFeature(
                  `/hubs/ajax_updateCtaRssValue/${cta.HubCta.hub_id}`,
                  {
                    ctaID: cta.HubCta.id,
                    updateInfo: JSON.stringify({ time: selectedItem.value }),
                    confirm: false,
                  },
                  selectedItem,
                  setWeeklyScheduleHour,
                )
              }
              value={weeklyScheduleHour.text}
              disabled={!serviceFollowing}
            />
            <label htmlFor="weekly-schedule-time-dropdown">ET</label>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="integration-container" data-loading={isLoading}>
      {isLoading ? (
        <>
          <Spinner />
          <span>{loadingMessage}</span>
        </>
      ) : (
        <div className="mailchimp-inputs">{renderMailChimpForm()}</div>
      )}
    </div>
  );
};

MailChimp.propTypes = propTypes;

export default MailChimp;
