import './slider.less';
import handleInputReady from '../../../utils/handleInputReady';
import InputContainer from '../inputContainer/InputContainer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SliderInputs from './SliderInputs';

const propTypes = {
  debounceTime: PropTypes.number,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  // Add a readOnly postfix to the input
  postfix: PropTypes.string,
  // Value increments when sliding, set to "any" to have it be more precise
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['any'])]),
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]).isRequired,
  title: PropTypes.string,
  // Add a readonly validation rules to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  debounceTime: 500,
  description: '',
  disabled: false,
  id: '',
  max: 100,
  min: 0,
  name: '',
  postfix: '%',
  step: 1,
  title: '',
  validation_rules: {},
  value: 50,
};

class Slider extends Component {
  state = {
    timeoutId: 0,
    value: parseInt(this.props.value, 10) || 0,
  };

  /* adapt the number input depending on how many chars there might be */
  getWidth = () => {
    const { max, step } = this.props;
    if (max > 999 || step === 'any') {
      // four digits
      return 85;
    }

    if (max > 99 || (step && step < 1)) {
      // three digits
      return 73;
    }
    // two digits
    return 62;
  };

  handleChange = (e) => {
    const {
      submitData,
      name,
      validation_rules: validationRules,
      max,
      disabled,
      debounceTime,
    } = this.props;
    if (disabled) {
      return;
    }

    let newValue = Number(e.target.value);
    newValue = newValue > max ? max : newValue;

    const { timeoutId } = this.state;

    const componentData = {
      propertyName: name,
      propertyValue: newValue,
      validationRules,
    };

    if (debounceTime) {
      // Debounce the slider
      clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(
        () => handleInputReady(submitData, componentData),
        debounceTime,
      );

      this.setState({
        timeoutId: newTimeoutId,
        value: newValue,
      });
    } else {
      handleInputReady(submitData, componentData);
      this.setState({
        value: newValue,
      });
    }
  };

  render() {
    const { id, title, description, postfix, disabled, min, max, step } = this.props;
    const { value } = this.state;

    const style = { width: this.getWidth() };

    return (
      <InputContainer id={id} title={title} description={description}>
        <SliderInputs
          id={id}
          min={min}
          max={max}
          value={value}
          step={step}
          handleChange={this.handleChange}
          handleDone={this?.handleDone}
          disabled={disabled}
          postfix={postfix}
          style={style}
        />
      </InputContainer>
    );
  }
}

Slider.defaultProps = defaultProps;
Slider.propTypes = propTypes;

export default Slider;
