import { Modal, ModalBody, ModalFooter, ModalHeader, PrimaryButton } from '@/components/modal';
import PropTypes, { shape } from 'prop-types';
import React, { useEffect, useState } from 'react';

const propTypes = {
  errors: PropTypes.arrayOf(shape()),
  handleClose: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const defaultProps = {
  handleClose: () => {},
  isOpen: false,
};

const StreamsListingStreamDependencyModal = ({ hubId, isOpen, handleClose, errors }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const HUB_HOMEPAGE_DEPENDENCY = 'hub_homepage';
  const WEBSITE_WIDGET_DEPENDENCY = 'site_engager_rule';
  const RECO_RULE_DEPENDENCY = 'recommendation_rule';
  const SMART_FILTER_DEPENDENCY = 'smart_filter';

  const link = (label, url) => (
    <a target="_blank" rel="noreferrer" href={url}>
      {label}
    </a>
  );

  const getLinks = (urlBase, entities = []) =>
    entities.map((entity) => {
      const url = `${urlBase}/${hubId}/${entity.id}`;
      return <li>{link(entity.name, url)}</li>;
    });

  const getHubHomepageMessage = () => {
    const url = `/hubs/advanced/${hubId}`;
    return (
      <div className="ufr-hub-options-dependency ufr-stream-dependency">
        <div>
          <b>Hub Options</b>
        </div>
        <ul>
          <li>
            This Marketing Stream is used as your Hub Homepage, see {link('Advanced Options', url)}{' '}
            to change.
          </li>
        </ul>
      </div>
    );
  };

  const getWidgetRuleDependencyMessage = (entities) => {
    const urlBase = '/hubs/site_engager/rules/edit';
    return (
      <div className="ufr-widget-rule-dependencies ufr-stream-dependency">
        <b>Site Engager Rules</b>
        <ul>{getLinks(urlBase, entities)}</ul>
      </div>
    );
  };

  const getRecoRuleDependencyMessage = (entities) => {
    const urlBase = '/recommendation_rules/edit';
    return (
      <div className="ufr-reco-rule-dependencies ufr-stream-dependency">
        <b>Recommendation Rules</b>
        <ul>{getLinks(urlBase, entities)}</ul>
      </div>
    );
  };

  const sortByHubId = (entities) => {
    const filtersByHubId = {};
    entities.forEach((entity) => {
      const { hub_id: hubId } = entity;
      const keyExists = filtersByHubId[hubId];
      filtersByHubId[hubId] = keyExists ? [...filtersByHubId[hubId], entity] : [entity];
    });
    return filtersByHubId;
  };

  const getListHtml = (list) => list.map(({ name }) => <li>{name}</li>);

  const getFilterDependencyErrorHtml = (entities) => {
    const urlBase = '/hubs/enhanced_filters/';
    const getUrl = (hubId) => `${urlBase}${hubId}`;
    const filtersByHub = sortByHubId(entities);
    const hubs = Object.keys(filtersByHub);

    const filterSections = hubs.map((hubId) => (
      <div className="ufr-smart-filter-dependencies ufr-stream-dependency">
        {link(`Smart Filters in hub with ID ${hubId}`, getUrl(hubId))}
        <ul>{getListHtml(filtersByHub[hubId])}</ul>
      </div>
    ));

    return filterSections;
  };

  const getters = {
    [HUB_HOMEPAGE_DEPENDENCY]: getHubHomepageMessage,
    [RECO_RULE_DEPENDENCY]: getRecoRuleDependencyMessage,
    [SMART_FILTER_DEPENDENCY]: getFilterDependencyErrorHtml,
    [WEBSITE_WIDGET_DEPENDENCY]: getWidgetRuleDependencyMessage,
  };

  const getDependenciesErrorHtml = (dependencies) => (
    <div id="ufr-stream-dependencies-error">
      <p>
        This Stream has one or more dependencies. You must change or delete them before archiving.
      </p>
      {dependencies
        .filter(({ type }) => getters[type])
        .map(({ type, entities }) => getters[type](entities))}
    </div>
  );

  const handleError = (error) => {
    const {
      code,
      fields: { dependencies },
    } = error;
    const errorCode = parseInt(code, 10);

    if (errorCode === 1023) {
      setTitle('An error occurred');
      setContent(<p>Can not archive stream</p>);
      return;
    }

    if (errorCode === 1022 && dependencies) {
      setTitle('Error: Unable to Archive Stream');
      setContent(getDependenciesErrorHtml(dependencies));
      return;
    }

    if (errorCode === 1020) {
      setTitle('Error: Stream is protected');
      setContent(<p>This stream is protected and cannot be archived.</p>);
      return;
    }

    if (errorCode === 1019) {
      setTitle('Error: Stream is locked');
      setContent(<p>This stream is locked and cannot be archived.</p>);
      return;
    }
  };

  useEffect(() => {
    errors.forEach(handleError);
  }, [errors]);

  return (
    <Modal
      size="small"
      isOpen={isOpen}
      handleClose={handleClose}
      className="ufr-stream-dependency-modal"
    >
      <ModalHeader title={title} />
      <ModalBody hubId={hubId} errors={errors}>
        {content}
      </ModalBody>
      <ModalFooter>
        <PrimaryButton id="close" label="close" onClick={handleClose}>
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

StreamsListingStreamDependencyModal.propTypes = propTypes;
StreamsListingStreamDependencyModal.defaultProps = defaultProps;

export default StreamsListingStreamDependencyModal;
