import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import ToggleSwitch from '@/components/formInputs/toggle/ToggleSwitch';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  enabled: PropTypes.bool.isRequired,
  recommendationRuleId: PropTypes.number.isRequired,
};

const RecommendationRulesEnabled = ({ enabled, recommendationRuleId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const toggleEnabled = async (enabled, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'put',
        url: `/api/v2/recommendation-rules/${recommendationRuleId}`,
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
          enabled,
        },
      });
      /* eslint-enable */
      dispatchToasterAction(
        addToaster({
          id: recommendationRuleId,
          text: `Recommendation Rule has been ${enabled ? 'enabled' : 'disabled'}.`,
          type: 'success',
        }),
      );
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: recommendationRuleId,
          text: 'Cannot update Recommendation Rule.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: recommendationRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <ToggleSwitch
            id={`recommendation-rule-${recommendationRuleId}-enabled`}
            name={`${recommendationRuleId}`}
            submitData={({ propertyValue: enabled }) => toggleEnabled(enabled, csrfToken)}
            value={enabled}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

RecommendationRulesEnabled.propTypes = propTypes;

export default RecommendationRulesEnabled;
