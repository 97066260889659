const userHasPermissionTo = (
  permission,
  type,
  ownerId,
  currentUserId,
  streamPermissions,
  isPrimary,
) => {
  if (!streamPermissions[permission]) {
    return false;
  }

  switch (type) {
    case 'targeted':
      return (ownerId === currentUserId || isPrimary) && streamPermissions[permission].sales;
    case 'custom':
      return streamPermissions[permission].marketing;
    default:
      return streamPermissions[permission].native;
  }
};

export default userHasPermissionTo;
