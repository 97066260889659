import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './actions.less';
import { uberPink } from '../../constants/constants';
import handleClick from '../../utils/handleClick';
import Icon from '../shared/icon/Icon';
import shapes from './propShapes';

const Actions = ({ actions, footer, brandColor }) => {
  const classes = ['sidenav-actions', footer && 'sidenav-footer'];

  return (
    <div className={classNames(classes)}>
      {actions.map((action) => {
        const { id, url, text, icon, target, onClick, onClickCallback } = action;

        const boundHandleClick = (e) =>
          handleClick({
            e,
            id,
            onClick,
            onClickCallback,
            target,
            url,
          });

        const button = (
          <button
            type="button"
            id={`${id}-action`}
            key={id}
            style={{ color: brandColor }}
            className="sidenav-action"
            onClick={boundHandleClick}
          >
            <Icon icon={icon} />
          </button>
        );

        if (text) {
          const makeItPink = brandColor === uberPink;
          const tooltip = (
            <Tooltip
              id={`${id}-tooltip`}
              placement="top"
              className={makeItPink ? 'uberpink-tooltip' : ''}
            >
              {text}
            </Tooltip>
          );

          return (
            <OverlayTrigger key={id} placement="top" overlay={tooltip}>
              {button}
            </OverlayTrigger>
          );
        }

        return button;
      })}
      <div className="narrow-sidenav-icon">
        <Icon icon={footer ? 'eye-open' : 'option-horizontal'} />
      </div>
    </div>
  );
};

Actions.defaultProps = {
  footer: false,
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(shapes.actionIcon)).isRequired,
  brandColor: PropTypes.string.isRequired,
  footer: PropTypes.bool,
};

export default Actions;
