import './buttonCtaAccordion.less';

import Accordion from '@/components/shared/accordion/Accordion';
import ColorPicker from '@/components/formInputs/colorPicker/ColorPicker';
import LinkInput from '@/components/formInputs/linkInput/LinkInput';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import TextArea from '@/components/formInputs/textArea/TextArea';

/* eslint-disable sort-keys */
const ButtonCtaAccordion = ({ ctaType, data, handleSubmit, isOpen, setOpenAccordion }) => {
  const formData = useRef(data);

  const handleChange = (currData) => {
    const { propertyName, propertyValue } = currData;
    formData.current[propertyName] = propertyValue;
    if (handleSubmit) handleSubmit(formData.current);
  };
  const parseUrl = (leadUrl) => {
    if (!leadUrl) {
      return {
        prefix: '',
        url: '',
      };
    }
    const separator = leadUrl.includes('//') ? '//' : ':';
    const split = leadUrl.split(separator);
    return {
      prefix: split[0] + separator,
      url: split[1],
    };
  };
  return (
    <Accordion
      id="ufr-button-cta-accordion"
      title="Button CTA"
      isOpen={isOpen}
      onClick={() => setOpenAccordion('button')}
    >
      <TextArea
        id="button-label"
        name="button_label"
        title="Button Label"
        resize="vertical"
        submitData={handleChange}
        value={formData.current.button_label}
        rows={2}
      />
      <ColorPicker
        id="banner-color-picker"
        name="button_font_color"
        cta={true}
        colorFor={'Font'}
        submitData={handleChange}
        value={formData.current.button_font_color}
      />
      <ColorPicker
        id="button-color-picker"
        cta={true}
        colorFor={'Button'}
        name="button_background_color"
        submitData={handleChange}
        value={formData.current.button_background_color}
      />
      {ctaType === 'url' && (
        <>
          <hr />
          <LinkInput
            handleChangePrefix={(e) =>
              handleChange({
                propertyName: 'lead_url',
                propertyValue: e + parseUrl(formData.current.lead_url).url,
              })
            }
            handleChangeUrl={(e) =>
              handleChange({
                propertyName: 'lead_url',
                propertyValue: parseUrl(formData.current.lead_url).prefix + e.target.value,
              })
            }
            prefixValue={parseUrl(formData.current.lead_url).prefix}
            urlValue={parseUrl(formData.current.lead_url).url}
          />
        </>
      )}
    </Accordion>
  );
};

ButtonCtaAccordion.propTypes = {
  ctaType: PropTypes.string.isRequired,
  data: PropTypes.shape({
    button_background_color: PropTypes.string.isRequired,
    button_font_color: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    lead_url: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  setOpenAccordion: PropTypes.func,
};

export default ButtonCtaAccordion;
