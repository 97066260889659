import './ctaPreview.less';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ButtonReact from '../shared/button/Button';
import Icon from '../shared/icon/Icon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  className: PropTypes.string,
  ctaIdEditPage: PropTypes.number,
  ctaTitle: PropTypes.string.isRequired,
  icon: PropTypes.bool,
  isCtaEditPage: PropTypes.string,
  src: PropTypes.string.isRequired,
};

const defaultProps = {
  className: 'ufr-cta-preview-popover',
};

function CtaPreview({
  src,
  ctaTitle,
  className,
  icon = true,
  isCtaEditPage = false,
  ctaIdEditPage,
}) {
  const [show, setShow] = useState(false);
  const color = show ? '#ce0058' : '';

  const previewCtaEditPage = () => {
    if (isCtaEditPage) {
      return (
        <ButtonReact
          label="preview call-to-action"
          id={`preview-call-to-action-${ctaIdEditPage}`}
          icon={'zoom-in'}
          isIconText={isCtaEditPage}
          onClick={() => setShow(!show)}
          title="Preview CTA"
          text={'Preview'}
          ufrStyle="secondary"
        />
      );
    } else {
      return (
        <a href="javascript:void(0)" onClick={() => setShow(!show)}>
          Preview
        </a>
      );
    }
  };

  return (
    <div className="ufr-cta-preview-container">
      <OverlayTrigger
        container={this}
        trigger="click"
        placement="right"
        onExit={() => setShow(false)}
        rootClose
        overlay={
          <Popover id="ufr-cta-preview-popover" bsClass={className}>
            <iframe
              title={ctaTitle}
              src={src}
              style={{ border: 'none', overflow: 'hidden' }}
              border="0"
              frameBorder="0"
              width="250"
              height="330"
            />
          </Popover>
        }
      >
        {icon ? (
          <Button bsStyle="link" onClick={() => setShow(!show)}>
            <Icon icon="zoom-in" color={color} />
          </Button>
        ) : (
          previewCtaEditPage()
        )}
      </OverlayTrigger>
    </div>
  );
}

CtaPreview.propTypes = propTypes;
CtaPreview.defaultProps = defaultProps;

export default CtaPreview;
