import { useReducer } from 'react';

const initialState = [];

/**
 * update a toast if it exists
 */
const updateActiveToaster = (state, id, toasterUpdates) =>
  state.map((toaster) => {
    const exists = toaster.id === id;
    if (exists) {
      return { ...toaster, ...toasterUpdates };
    }
    return { ...toaster };
  });

/**
 * Add a toast to the state, or update it if it exists.
 */
const addToState = (state, newToaster) => {
  const toasterExists = state.find((toaster) => toaster.id === newToaster.id);
  if (toasterExists) {
    return updateActiveToaster(state, newToaster.id, newToaster);
  }
  return [...state, newToaster];
};

const reducer = (state = initialState, { action, toaster, transitionState, toasterId } = {}) => {
  switch (action) {
    case 'add':
      return addToState(state, toaster);
    case 'update':
      return updateActiveToaster(state, toaster.id, toaster);
    case 'slideDown':
      return updateActiveToaster(state, toaster.id, { isVisible: false });
    case 'remove':
      return state.filter((t) => t.id !== toaster.id);
    case 'clear':
      return [];
    case 'transitionEnd':
      if (transitionState === 'exiting') {
        return state.filter((t) => t.id !== toasterId);
      }
      return state;
    default:
      return state;
  }
};

const toasterDefault = {
  isVisible: true,
};

const getToasterAction = (action, toaster) => ({
  action,
  toaster: { ...toasterDefault, ...toaster },
});

export const addToaster = (toaster) => getToasterAction('add', toaster);
export const removeToaster = (toaster) => getToasterAction('remove', toaster);
export const updateToaster = (toaster) => getToasterAction('update', toaster);
export const slideDownAndRemoveToaster = (toaster) => getToasterAction('slideDown', toaster);

const useToasterStack = () => useReducer(reducer, initialState);

export default useToasterStack;
