import Button from '../shared/button/Button';
import ConfirmationModal from '../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  domainsConfig: PropTypes.string.isRequired,
};

const CtaEmbedButton = ({ ctaId, domainsConfig }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const embedLink = `<iframe src="${domainsConfig}hubsFront/embed_cta/${ctaId}" scroll="none" border="0" frameborder="0" width="250" height="330" sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation"></iframe>`;

  const modalBody = () => (
    <>
      <p>Copy and paste this code onto your website or landing page for a full-featured CTA.</p>
      <p>
        <textarea
          className="form-control embed-code"
          id="embed-cta"
          readOnly={true}
          value={embedLink}
        />
      </p>
    </>
  );

  const handleConfirm = () => '';
  const embedIcon = '< >';

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleConfirm={() => handleConfirm()}
        header="Embed CTA"
        body={modalBody()}
        primaryButtonText="Done"
        cancelButton={false}
      />

      <Button
        label="embed button"
        id={`embed-call-to-action-${ctaId}`}
        onClick={() => setIsModalOpen(true)}
        title="Embed CTA"
        ufrStyle="secondary"
      >
        <span className={'cta-edit-embed-icon'}>{embedIcon}</span>
        Embed
      </Button>
    </>
  );
};

CtaEmbedButton.propTypes = propTypes;

export default CtaEmbedButton;
