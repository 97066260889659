import './inlineEditButton.less';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const InlineEditButton = ({ ariaLabel, handleClick, size }) => (
  <button
    aria-label={ariaLabel}
    className={`inline-edit-btn ${size}-inline-edit-btn`}
    onClick={handleClick}
    type="button"
  >
    <Icon icon="pencil" className="inline-edit-btn-icon" />
  </button>
);

InlineEditButton.defaultProps = {
  size: 'large',
};

InlineEditButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'small']),
};

export default InlineEditButton;
