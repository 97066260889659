import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
import RecommendationRulesDatatable from '../datatables/recommendationRules/RecommendationRulesDatatable';

const propTypes = {
  hubId: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const RecommendationRulesPage = ({ title, subtitle, hubId }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <RecommendationRulesDatatable hubId={hubId} />
    </Page>
  );
};

RecommendationRulesPage.propTypes = propTypes;

export default RecommendationRulesPage;
