import './uploadButton.less';
import Button from './Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class UploadButton extends Component {
  handleClick = () => {
    this.fileUploader.click();
  };

  handleChange = (event) => {
    const { onClick } = this.props;
    const file = event.target.files[0];

    const fileObject = {
      type: 'file',
      val: file,
    };

    if (file) onClick(fileObject);
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter key
      event.preventDefault();
      this.fileUploader.click();
    }
  };

  render() {
    const { id, label, labelledby, disabled, useMediaModal, onClick } = this.props;

    const disabledClass = 'ufr-input-component--disabled';

    return (
      <span
        className={classNames('ufr-upload-btn-container', disabled && 'ufr-disabled')}
        aria-disabled={disabled}
      >
        <input
          type="file"
          className={classNames('hidden', disabled && disabledClass)}
          ref={(input) => {
            this.fileUploader = input;
          }}
          onChange={disabled ? null : this.handleChange}
          disabled={disabled}
        />
        <Button
          id={`${id}-upload-file`}
          className={classNames('ufr-upload-btn', disabled && disabledClass)}
          label="upload a file"
          labelledby={labelledby}
          onClick={useMediaModal ? onClick : this.handleClick}
          onKeyDown={this.handleKeyDown}
          disabled={disabled}
        >
          {label}
        </Button>
      </span>
    );
  }
}

UploadButton.defaultProps = {
  disabled: false,
  label: 'Upload',
  labelledby: '',
};

UploadButton.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelledby: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  useMediaModal: PropTypes.bool,
};

export default UploadButton;
