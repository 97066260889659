import axios from 'axios/index';
import PropTypes from 'prop-types';
import React from 'react';

import './enrichmentSourcesImportErrorsDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import DataTable from '@/components/datatable/DataTable';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import ToasterStack from '@/components/toaster/ToasterStack';
import usePopToast from '@/components/toaster/usePopToast';
import useToasterStack from '@/components/toaster/useToasterStack';

const getEndpointBuilder = (accountId, integrationId) => ({
  getEnrichmentImportErrors: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationId}/import-errors`,
});

const entityName = {
  plural: 'Enrichment Data Import Errors',
  singular: 'Enrichment Data Import Error',
};

const cellClass = (key) => `ufr-dt-enrichment-sources-import-errors-${key}-cell`;
const headerClass = (key) => `ufr-dt-enrichment-sources-import-errors-${key}-header`;

const renderErrorMessageByCode = (data) => {
  const error = data.error;
  const integrationId = data.visitorProfileSourceId;
  const failedFields = error.fields?.join(', ') || '';
  const responseLevelErrorsLink = (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://developers.marketo.com/rest-api/error-codes/#response_level_error_codes"
    >
      Marketo Error Code: {error.code}{' '}
      <div className="react-icon glyphicons glyphicons-new-window"></div>
    </a>
  );
  const helpArticleLink = (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://help.uberflip.com/hc/en-us/articles/360022063751"
    >
      Marketo integration
    </a>
  );
  const recordLevelErrorsLink = (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://developers.marketo.com/rest-api/error-codes/#record_level_errors"
    >
      Marketo Error Code: {error.code}{' '}
      <div className="react-icon glyphicons glyphicons-new-window"></div>
    </a>
  );

  const errorCodeMapping = (() => {
    switch (error.code) {
      case '401':
        return (
          <span>
            Could not complete the data import because Marketo rejected the API credentials provided
            ({responseLevelErrorsLink}). Please check that the Client Id and Client Secret in your
            {''} {helpArticleLink} are valid and have been entered correctly.
          </span>
        );

      case '502':
        return (
          <span>
            Could not complete the data import because of a Marketo server error (
            {responseLevelErrorsLink}). We will retry the import automatically. Please check back
            later to see if the error continues to occur.
          </span>
        );

      case '601':
        return (
          <span>
            Could not complete the data import because the access token provided was not valid (
            {responseLevelErrorsLink}). Please check that your {helpArticleLink} is configured
            correctly.
          </span>
        );

      case '607':
        return (
          <span>
            Could not complete the data import because your Marketo account has reached its daily
            request limit for the Marketo API (see {responseLevelErrorsLink} for more information).
          </span>
        );

      case '611':
        return (
          <span>
            Could not complete the data import because Marketo reported a system error (
            {responseLevelErrorsLink}). This is a general Marketo error with an unknown cause. We
            will retry the import automatically. Please check back later to see if the error
            continues to occur.
          </span>
        );

      case '1006':
        return (
          <span>
            Could not complete the data import because the following Marketo field(s) could not be
            found: <span className="import-error-fields">{failedFields}</span>. (
            {recordLevelErrorsLink}). Please review the field(s) in Marketo and update your{' '}
            <a rel="noreferrer" href={`/enrichment/integrations/${integrationId}`}>
              Field Mapping Settings
            </a>{' '}
            as needed.
          </span>
        );

      case '615':
      case '606':
      case '604':
      case '602':
        return (
          <span>
            Marketo reported an error ({responseLevelErrorsLink}). Please check back later to see if
            the error continues to occur.
          </span>
        );
      default:
        return (
          <span>
            Could not complete the data import because Marketo reported an error (
            {responseLevelErrorsLink}). Please check back later to see if the error continues to
            occur.
          </span>
        );
    }
  })();

  return errorCodeMapping;
};

/* eslint-disable sort-keys */
const columns = [
  {
    Header: 'Error Message',
    accessor: 'error',
    id: 'error',
    className: cellClass('data'),
    minWidth: 300,
    headerClassName: headerClass('data'),
    Cell: ({ original }) => renderErrorMessageByCode(original),
    sortable: false,
  },
  {
    Header: 'Date',
    accessor: 'created',
    id: 'created',
    className: cellClass('created'),
    minWidth: 150,
    headerClassName: headerClass('created'),
    Cell: ({ value }) => getHumanReadableDate(value, true),
  },
];
/* eslint-enable sort-keys */

const EnrichmentSourcesImportErrorsDatatable = ({ accountId, integrationId }) => {
  const { getEnrichmentImportErrors } = getEndpointBuilder(accountId, integrationId);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);

  const getTableData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getEnrichmentImportErrors(), { params: queries });
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        entityName={entityName}
        id="enrichment-sources-import-errors"
        showLoadingState
        columns={columns}
        getData={getTableData}
        initialSort={{ desc: true, id: 'created' }}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({
          popToast,
        })}
        noSearchResultsMessage={
          <p>
            Looks like there is no Enrichment activity to display here yet. Please check back later.
          </p>
        }
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

EnrichmentSourcesImportErrorsDatatable.propTypes = {
  accountId: PropTypes.number.isRequired,
  integrationId: PropTypes.number.isRequired,
};

export default EnrichmentSourcesImportErrorsDatatable;
