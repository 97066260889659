import './accordion.less';

import { Collapse } from 'react-bootstrap';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

const defaultProps = {};

const Accordion = ({ children, id, isOpen, onClick, title }) => {
  const contentId = `${id}-content`;
  const icon = isOpen ? 'chevron-up' : 'chevron-down';
  return (
    <div id={id} className="ufr-accordion">
      <div
        className="ufr-accordion-button"
        onClick={() => {
          if (onClick) onClick();
        }}
        aria-controls={contentId}
        aria-expanded={isOpen}
      >
        <p className="ufr-accordion-title">{title}</p>
        <Icon icon={icon} />
      </div>
      <Collapse in={isOpen}>
        <div id={contentId} className="ufr-accordion-content">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default Accordion;
