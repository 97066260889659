import { ChromePicker } from 'react-color';
import classNames from 'classnames';
import Icon from '../../shared/icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string.isRequired,
  disableAlpha: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  reset: PropTypes.elementType,
  showColorPicker: PropTypes.bool.isRequired,
};

const defaultProps = {
  reset: <></>,
};

const ColorPickerInput = ({
  id,
  disabled,
  disableAlpha,
  handleToggle,
  handleKeyDown,
  handleChange,
  reset,
  showColorPicker,
  color,
}) => (
  <div className="ufr-align-input-container">
    <div id={id} className="ufr-color-picker">
      <button
        className={classNames([
          'ufr-color-picker-input',
          { 'ufr-color-picker-input--disabled': disabled },
        ])}
        id={id}
        onClick={disabled ? null : handleToggle}
        onKeyPress={disabled ? null : handleKeyDown}
        disabled={disabled}
        type="button"
      >
        <div className="ufr-color-picker-preview">
          {color ? (
            <div style={{ backgroundColor: color }} />
          ) : (
            <div style={{ display: 'flex', outline: '1px solid' }}>
              <svg
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                transform="scale(-1,1)"
                width="100%"
                height="100%"
              >
                <line
                  x1="0"
                  y1="100"
                  x2="100"
                  y2="0"
                  strokeWidth="7"
                  vectorEffect="non-scaling-size"
                  stroke="#ce0058"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="ufr-color-picker-dropdown-btn">
          <Icon icon="chevron-down" className="dropdown-icon" color="#555" />
        </div>
      </button>
      {showColorPicker && !disabled && (
        <div className="ufr-color-picker-popover">
          <span
            role="button"
            tabIndex="-1"
            className="ufr-color-picker-overlay"
            onClick={disabled ? null : handleToggle}
            onKeyPress={disabled ? null : handleKeyDown}
          />
          <ChromePicker
            disabled={disabled}
            color={color}
            onChange={disabled ? null : handleChange}
            disableAlpha={disableAlpha}
          />
        </div>
      )}
    </div>
    {reset}
  </div>
);

ColorPickerInput.defaultProps = defaultProps;
ColorPickerInput.propTypes = propTypes;

export default ColorPickerInput;
