import Button from '../../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  handleReset: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const Reset = ({ handleReset, id }) => (
  <div className="ufr-reset-setting-container">
    <Button
      className="ufr-reset-setting"
      ufrStyle="secondary"
      id={`reset-setting-${id}`}
      onClick={() => handleReset()}
    >
      Reset
    </Button>
  </div>
);

Reset.propTypes = propTypes;

export default Reset;
