const getHumanReadableDate = (date, isLongFormat = false) => {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  if (isLongFormat) {
    options['hour'] = '2-digit';
    options['minute'] = '2-digit';
  }

  return new Date(date).toLocaleDateString('en-US', options);
};

export default getHumanReadableDate;
