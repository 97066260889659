import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './button.less';
import Icon from '../icon/Icon';

const propTypes = {
  /** Is it block or inline? */
  block: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  /** Show a glyphicon in a square button. */
  icon: PropTypes.string,
  /** Required for acceptance tests and a11y decorators */
  id: PropTypes.string.isRequired,
  isIconText: PropTypes.bool,
  itemId: PropTypes.number,
  /**
   * What is the function of this button? e.g. "edit page title". required for A11y
   */
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  overrideId: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  /** Defines HTML button type attribute */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  /**
   * determines the look of the button.
   * Ignored if icon prop provided.
   */
  ufrStyle: PropTypes.oneOf(['primary', 'secondary', 'card', 'action', 'back']),
};

const defaultProps = {
  block: false,
  children: '',
  className: '',
  disabled: false,
  href: '',
  icon: '',
  isIconText: false,
  onClick: null,
  onKeyDown: null,
  overrideId: false,
  text: null,
  title: '',
  type: 'submit',
  ufrStyle: 'primary',
};

const Button = ({
  id,
  itemId,
  ufrStyle,
  onClick,
  onKeyDown,
  href,
  children,
  label,
  icon,
  isIconText,
  block,
  disabled,
  className,
  text,
  title,
  target,
  type,
  overrideId,
}) => {
  let btnStyleClass;
  let content;

  if (isIconText) {
    btnStyleClass = `ufr-btn-${ufrStyle}`;
    content = [<Icon icon={icon} />, text];
  } else {
    btnStyleClass = `ufr-btn-${icon ? 'icon' : ufrStyle}`;
    content = icon ? <Icon icon={icon} /> : children;
  }

  const classes = classNames([
    'ufr-btn',
    btnStyleClass,
    block && 'ufr-btn-block',
    disabled && 'disabled',
    className,
  ]);

  const hoverText = title ? title : undefined;

  const sharedProps = {
    'aria-label': label,
    className: classNames(classes),
    disabled,
    id: overrideId ? id : `ufr-${id}-btn`,
  };

  if (href) {
    return (
      <a href={href} title={hoverText} target={target} {...sharedProps}>
        {content}
      </a>
    );
  }

  return (
    <button
      onClick={disabled ? undefined : onClick}
      onKeyDown={disabled ? undefined : onKeyDown}
      type={label === 'cancel' ? 'button' : type}
      title={hoverText}
      {...sharedProps}
      data-id={itemId}
    >
      {content}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
