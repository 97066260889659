import DownshiftInput from '@/components/formInputs/selectDropdown/DownshiftInput';
import MultiSelectInput from '@/components/formInputs/selectDropdown/MultiSelectInput';
import PrimaryButton from '@/components/modal/PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const propTypes = {
  handleSelect: PropTypes.func.isRequired,
  handleTagGroupSelect: PropTypes.func.isRequired,
  initialTagsValues: PropTypes.string,
  isMultiSelectOpen: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  selectedTagGroup: PropTypes.string,
  selectedTags: PropTypes.array.isRequired,
  setIsMultiSelectOpen: PropTypes.func.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  tagGroups: PropTypes.array,
};

const ItemsListingSearchByTagsMultiSelect = ({
  handleSelect,
  handleTagGroupSelect,
  items,
  tagGroups,
  setSelectedTags,
  selectedTags,
  selectedTagGroup,
  isMultiSelectOpen,
  setIsMultiSelectOpen,
  initialTagsValues,
}) => {
  const node = useRef();

  const handleSelectValue = (selectedOptions) => {
    let tagIds = [];
    if (selectedOptions) {
      tagIds = selectedOptions.map((selectedOption) => selectedOption.value);
      setSelectedTags(
        selectedOptions.map((selectedOption) => ({
          label: selectedOption.label,
          value: selectedOption.value,
        })),
      );
    } else {
      setSelectedTags([]);
    }

    handleSelect({
      queryKey: 'tagged_with',
      queryValue: [...new Set(tagIds)].toString(),
    });
  };

  const _handleTagGroupSelect = (selectedOption) => {
    if (selectedOption !== selectedTagGroup && selectedTags.length) {
      // refresh items only if there are selected tags
      // because the selection will be wiped in the parent handler
      handleSelect({
        queryKey: 'tagged_with',
        queryValue: '',
      });
    }

    handleTagGroupSelect(selectedOption);
  };

  const handleClickOutside = (e) => {
    if (
      node.current &&
      !node.current.contains(e.target) &&
      e.target.parentNode.className !== 'ufr-action-button'
    ) {
      setIsMultiSelectOpen(!isMultiSelectOpen);
    }
  };

  const handleEscapeKeyPress = (e) => {
    if (e.key === 'Escape') {
      setIsMultiSelectOpen(!isMultiSelectOpen);
    }
  };

  const getSavedTags = () => {
    if (initialTagsValues) {
      const savedTags = initialTagsValues.split(',').map((tagId) => ({
        label: items.filter((item) => item.value === tagId)[0]['label'],
        value: tagId,
      }));
      setSelectedTags(savedTags);
    }
  };

  useEffect(() => {
    window.addEventListener('load', getSavedTags);
    return () => {
      window.removeEventListener('load', getSavedTags);
    };
  }, []);

  useEffect(() => {
    if (isMultiSelectOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKeyPress);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [isMultiSelectOpen]);

  return (
    <>
      {isMultiSelectOpen && (
        <div className="ufr-search-by-tags" ref={node}>
          {tagGroups && (
            <DownshiftInput
              id={'ufr-search-by-tags-groups-select-dropdown'}
              items={tagGroups}
              defaultSelectedItem={selectedTagGroup}
              handleSelect={_handleTagGroupSelect}
              disabled={false}
            />
          )}
          <MultiSelectInput
            id={'ufr-search-by-tags-select-dropdown'}
            items={items}
            handleSelect={handleSelectValue}
            searchable
            defaultSelectedItems={selectedTags}
            placeholder="Search for Item Tags"
          />
          <div className="ufr-search-by-tags-helper-text">
            <p>Start typing Tag name to view search results.</p>
          </div>
          <div className="ufr-search-by-tags-button-container">
            <PrimaryButton label="Done" onClick={() => setIsMultiSelectOpen(!isMultiSelectOpen)}>
              Done
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
};

ItemsListingSearchByTagsMultiSelect.propTypes = propTypes;

export default ItemsListingSearchByTagsMultiSelect;
