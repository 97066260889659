import { CsrfContextConsumer } from '../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../shared/button/Button';
import ConfirmationModal from '../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  handleToasterLifecycle: PropTypes.func.isRequired,
  isCtaEditPage: PropTypes.bool,
  triggerRefresh: PropTypes.func.isRequired,
};

const modalBody = (ctaName) => (
  <>
    <p>
      Deleting a CTA will result in the loss of all associated Legacy Metrics related to that CTA
      (Uberflip Analytics data is not affected).
    </p>
    <p>
      Are you sure you want to delete &quot;
      <span className="cta-name-highlight">{ctaName}</span>
      &quot;?
    </p>
  </>
);

const CtaListingDeleteButton = ({
  ctaId,
  ctaName,
  handleToasterLifecycle,
  isCtaEditPage = false,
  triggerRefresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let addtnlProps;

  if (isCtaEditPage) {
    addtnlProps = {
      text: 'Delete',
      ufrStyle: 'secondary',
    };
  } else {
    addtnlProps = {
      ufrStyle: 'primary',
    };
  }

  const deleteCta = async (csrfToken) => {
    let toaster = {
      id: ctaId,
    };

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'delete',
        url: `/api/v2/cta/${ctaId}`,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */

      toaster = {
        ...toaster,
        text: `"${ctaName}" was successfully deleted.`,
        type: 'success',
      };

      triggerRefresh();
    } catch {
      toaster = {
        ...toaster,
        text: 'Something went wrong. Please try again.',
        type: 'error',
      };
    }

    handleToasterLifecycle(toaster, ctaId);
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => deleteCta(csrfToken)}
            header="Are you sure you want to delete this CTA?"
            body={modalBody(ctaName)}
            primaryButtonText="Delete CTA"
          />

          <Button
            label="delete call-to-action"
            id={`delete-call-to-action-${ctaId}`}
            icon="bin"
            isIconText={isCtaEditPage}
            onClick={() => setIsModalOpen(true)}
            title="Delete CTA"
            {...addtnlProps}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

CtaListingDeleteButton.propTypes = propTypes;

export default CtaListingDeleteButton;
