import './pageHeader.less';
import EditableText from '../shared/editableText/EditableText';
import Icon from './Icon';
import PropTypes from 'prop-types';
import React from 'react';

const defaultProps = {
  subtitle: {},
  title: {},
};

const propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  icon: PropTypes.string,
  subtitle: PropTypes.shape({
    canUpdateStreams: PropTypes.bool,
    editable: PropTypes.bool,
    maxLength: PropTypes.nullable,
    nullable: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSubmitCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    showCharacterCounter: PropTypes.bool,
    text: PropTypes.string,
  }),
  title: PropTypes.shape({
    canUpdateStreams: PropTypes.bool,
    editable: PropTypes.bool,
    maxLength: PropTypes.number,
    nullable: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSubmitCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    showCharacterCounter: PropTypes.bool,
    size: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

const PageHeaderTitleAndSubtitle = ({ icon, title, subtitle }) => (
  <>
    <div className="ufr-page-header-title">
      {icon ? <Icon icon={icon} /> : null}
      <EditableText
        textType="title"
        size={title.size || 'large'}
        text={title.text}
        nullable={title.nullable}
        placeholder={title.placeholder}
        editable={title.onSubmit && title.editable && title.canUpdateStreams}
        showCharacterCounter={title.showCharacterCounter}
        maxLength={title.maxLength}
        onSubmit={title.onSubmit}
        onSubmitCallback={title.onSubmitCallback}
      />
    </div>
    <div className="ufr-page-header-subtitle">
      <EditableText
        textType="subtitle"
        size="small"
        text={subtitle.text}
        nullable={subtitle.nullable}
        placeholder={subtitle.placeholder}
        editable={subtitle.onSubmit && subtitle.editable && subtitle.canUpdateStreams}
        showCharacterCounter={subtitle.showCharacterCounter}
        maxLength={subtitle.maxLength}
        onSubmit={subtitle.onSubmit}
        onSubmitCallback={subtitle.onSubmitCallback}
      />
    </div>
  </>
);

PageHeaderTitleAndSubtitle.defaultProps = defaultProps;

PageHeaderTitleAndSubtitle.propTypes = propTypes;

export default PageHeaderTitleAndSubtitle;
