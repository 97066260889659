import PropTypes from 'prop-types';
import React from 'react';

import handleInputReady from '../../../utils/handleInputReady';
import Input from '../input/Input';
import InputContainer from '../inputContainer/InputContainer';

const propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  // required for acceptance tests and accessibility decorators
  id: PropTypes.string.isRequired,
  // for app config settings
  inheritedValue: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  // Add a readOnly postfix to the input
  postfix: PropTypes.string,
  // Add a readOnly prefix to the input
  prefix: PropTypes.string,
  // readOnly mode, can still be submitted
  readOnly: PropTypes.bool,
  reset: PropTypes.bool,
  resetData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
  title: PropTypes.string,
  // Add a readonly validation rules object to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
  value: PropTypes.number,
  // how many pixels wide the input should be; number inputs don't natively support setting width
  width: PropTypes.number,
};

const defaultProps = {
  description: '',
  disabled: false,
  inheritedValue: 0,
  max: undefined,
  min: 0,
  name: '',
  postfix: '',
  prefix: '',
  readOnly: false,
  reset: false,
  resetData: {},
  submitData: {},
  title: '',
  validation_rules: {},
  value: 0,
  width: 89,
};

class NumberInput extends React.Component {
  state = {
    errorMessage: '',
    status: '',
  };

  onReady = (value) => {
    const { submitData, name, validation_rules: validationRules, disabled } = this.props;

    // structure the component
    const componentData = {
      propertyName: name,
      propertyValue: value,
      validationRules,
    };

    if (!disabled) {
      handleInputReady(submitData, componentData, this.handleCallback);
    }
  };

  handleCallback = (error) => {
    if (error) {
      this.setState({ errorMessage: error, status: 'error' });
    } else {
      // flash success
      this.setState({ errorMessage: '', status: 'success' });
      setTimeout(() => this.setState({ status: '' }), 500);
    }
  };

  render() {
    const { id, width, title, description, inheritedValue, reset, resetData, submitData, ...rest } =
      this.props;
    const style = width ? { width } : {};

    return (
      <InputContainer
        id={id}
        title={title}
        description={description}
        error={this.state.errorMessage}
      >
        <Input
          type="number"
          style={style}
          onReady={this.onReady}
          validationState={this.state.status}
          inheritedValue={inheritedValue}
          reset={reset}
          resetData={resetData}
          submitData={submitData}
          {...rest}
        />
      </InputContainer>
    );
  }
}

NumberInput.defaultProps = defaultProps;
NumberInput.propTypes = propTypes;

export default NumberInput;
