import './streamPageHeader.less';
import { CsrfContextConsumer, CsrfProvider } from '../../DataProviders/CsrfProvider';
import axios from 'axios/index';
import Breadcrumbs from './Breadcrumbs';
import Button from '../shared/button/Button';
import ButtonDropDown from '../shared/buttonDropDown/ButtonDropDown';
import EditableText from '../shared/editableText/EditableText';
import handleClick from '@/utils/handleClick';
import Icon from './Icon';
import PageHeaderTitleAndSubtitle from './PageHeaderTitleAndSubtitle';
import PreviewButtons from './PreviewButtons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ToasterStack from '../../components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
  updateToaster,
} from '../../components/toaster/useToasterStack';

const propTypes = {
  betaEnabled: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  canUpdateStreams: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  preview: PreviewButtons.propTypes,
  published: PropTypes.bool,
  streamId: PropTypes.number.isRequired,
  subtitle: PropTypes.shape({
    editable: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSubmitCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    text: PropTypes.string,
  }),
  title: PropTypes.shape({
    editable: PropTypes.bool,
    externalName: PropTypes.string.isRequired,
    icon: PropTypes.string,
    internalName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onSubmitCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  }).isRequired,
};

const defaultProps = {
  breadcrumbs: [],
  subtitle: {},
};

const StreamPageHeader = ({
  hubId,
  streamId,
  breadcrumbs,
  title,
  subtitle,
  canUpdateStreams,
  preview,
  published,
  betaEnabled,
  onClickCallback,
}) => {
  const [currentInternalNameValue, setCurrentInternalNameValue] = useState(title.internalName);

  const [currentTitleValue, setCurrentTitleValue] = useState(
    title.externalName === null ? '' : title.externalName,
  );
  const [currentDescriptionValue, setCurrentDescriptionValue] = useState(
    subtitle.text === null ? '' : subtitle.text,
  );
  const [toasters, dispatchToasterAction] = useToasterStack();

  const [publishedState, setPublishedState] = useState(published);

  //title.icon is external_service field which represents the stream type
  const isMarketingStream = title.icon === 'custom';
  const isSalesStream = title.icon === 'targeted';

  let showHideMenu;
  let hideShowStream;

  const initializeMenu = () => {
    showHideMenu = document.querySelector('#show-hide-menu-item');

    if (showHideMenu) {
      showHideMenu.style.display = 'none';
    }

    //initialize callback for hideShowStream
    hideShowStream = (e) =>
      handleClick({
        e,
        onClickCallback,
      });
  };

  useEffect(() => {
    if (betaEnabled && isMarketingStream && !publishedState) {
      //wait for the DOM to load and select the showHideMenu
      //it should not be shown if its a marketing stream
      document.addEventListener('DOMContentLoaded', initializeMenu);
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', initializeMenu);
    };
  }, []);

  const displayToasterForPublishAction = (type, message) => {
    //return if type is not success or failure
    if (type !== 'success' && type !== 'failure') {
      return;
    }
    dispatchToasterAction(
      updateToaster({
        id: streamId + '-publish',
        text: message,
        type: type,
      }),
    );
  };

  //if this is a marketing stream, then unhide the showHideMenu
  //if we chose to publish it with the option to show, then trigger hideShowStream
  const updateHideShowMenuAndStream = (show) => {
    if (!isMarketingStream) {
      return;
    }
    if (showHideMenu) {
      showHideMenu.style.display = 'block';
    }
    if (show) {
      hideShowStream();
    }
  };

  const handleSubmit = (type, csrfToken) => async (value) => {
    const formData = new FormData();
    formData.append(`data[HubCollection][${type}]`, value);

    dispatchToasterAction(
      addToaster({
        id: streamId,
        text: 'Saving...',
        type: 'info',
      }),
    );

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: `/hubs/ajax_autoUpdateField/${hubId}/HubCollection/${streamId}`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': csrfToken,
        },
        data: formData,
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        updateToaster({
          id: streamId,
          text: 'Saved!',
          type: 'success',
        }),
      );
      switch (type) {
        case 'name':
          setCurrentInternalNameValue(value);
          break;
        case 'title':
          setCurrentTitleValue(value);
          break;
        case 'description':
          setCurrentDescriptionValue(value);
          break;
      }
    } catch (error) {
      dispatchToasterAction(
        updateToaster({
          id: streamId,
          text: `Error: ${error.data}`,
          type: 'failure',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(
        slideDownAndRemoveToaster({
          id: streamId,
        }),
      );
    }, 2000);
  };

  const publishOnClick = async (csrfToken, show) => {
    dispatchToasterAction(
      addToaster({
        id: streamId + '-publish',
        text: 'Publishing stream...',
        type: 'info',
      }),
    );

    const data = { published: true };

    try {
      /* eslint-disable sort-keys */
      const response = await axios({
        method: 'patch',
        url: `/api/v2/streams/${streamId}`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': csrfToken,
          'content-type': 'application/json',
        },
        data: JSON.stringify(data),
      });

      const streamData = await response.data;

      //if the publish is successful, then set the published state to true
      //this will switch the published status and hide the publish button
      if (streamData.published) {
        setPublishedState(true);
        updateHideShowMenuAndStream(show);
        displayToasterForPublishAction('success', 'Successful Publish');
      } else {
        //if the returned streamData's publish value is anything other than true
        //then something went wrong so display an error message
        displayToasterForPublishAction('failure', 'Error: Could not publish');
      }
    } catch (error) {
      displayToasterForPublishAction('failure', 'Error: ' + error.data);
    }
    setTimeout(() => {
      dispatchToasterAction(
        slideDownAndRemoveToaster({
          id: streamId + '-publish',
        }),
      );
    }, 2000);
  };

  const renderPublishedStatus = () => {
    //dont render if streams 1.5 beta is disabled or if not a landing page stream
    const isLandingPageStream = isMarketingStream || isSalesStream;
    if (!betaEnabled || !isLandingPageStream) {
      return;
    }
    return (
      <p className="ufr-stream-published-status">
        Status: {publishedState ? 'PUBLISHED' : 'DRAFT'}
      </p>
    );
  };

  const renderPublishButton = (csrfToken) => {
    //dont render if streams 1.5 beta is disabled or already published
    if (!betaEnabled || publishedState) {
      return;
    }
    if (isMarketingStream && !publishedState) {
      return (
        <div className="ufr-stream-page-publish">
          <ButtonDropDown
            id="stream-page-publish"
            buttonText="Publish"
            items={[
              {
                id: 'sp-hide',
                onClick: () => publishOnClick(csrfToken, false),
                text: 'Direct URL only (Current)',
              },
              {
                id: 'sp-show',
                onClick: () => publishOnClick(csrfToken, true),
                text: 'Add to Hub',
              },
            ]}
          />
        </div>
      );
    } else if (isSalesStream && !publishedState) {
      return (
        <div className="ufr-stream-page-publish">
          <Button onClick={() => publishOnClick(csrfToken, false)}>Publish</Button>
        </div>
      );
    }
  };

  return (
    <CsrfProvider>
      <div className="ufr-stream-page">
        <CsrfContextConsumer>
          {(csrfToken) => (
            <>
              <div id="ufr-page-header" className="ufr-stream-page-header">
                {breadcrumbs.length > 0 && (
                  <Breadcrumbs crumbs={breadcrumbs} currentPageName={currentInternalNameValue} />
                )}

                <div className="ufr-internal-stream-name">
                  <div className="ufr-stream-info">
                    {title.icon ? <Icon icon={title.icon} /> : null}
                    <p className="ufr-stream-id">ID: {streamId}</p>
                    {renderPublishedStatus()}
                  </div>
                  <EditableText
                    onSubmit={handleSubmit('name', csrfToken)}
                    textType="title"
                    size="large"
                    text={currentInternalNameValue}
                    editable={canUpdateStreams}
                    placeholder="Enter Internal Stream Name"
                    className="ufr-page-header-title-internal"
                    maxLength={140}
                  />
                </div>

                <div className="ufr-external-stream-name">
                  <PageHeaderTitleAndSubtitle
                    title={{
                      canUpdateStreams: canUpdateStreams,
                      editable: true,
                      maxLength: 140,
                      onSubmit: handleSubmit('title', csrfToken),
                      placeholder: 'Enter Stream Page Title',
                      showCharacterCounter: true,
                      size: 'large',
                      text: currentTitleValue,
                    }}
                    subtitle={{
                      canUpdateStreams: canUpdateStreams,
                      editable: true,
                      maxLength: 400,
                      nullable: true,
                      onSubmit: handleSubmit('description', csrfToken),
                      placeholder: 'Add a Stream Page Description',
                      showCharacterCounter: true,
                      text: currentDescriptionValue,
                    }}
                  />
                </div>
                <ToasterStack toasters={toasters} dispatchToasterAction={dispatchToasterAction} />
              </div>
              <div className="ufr-stream-page-actions">
                {renderPublishButton(csrfToken)}
                <div className="ufr-stream-page-preview">
                  <PreviewButtons actions={preview} />
                </div>
              </div>
            </>
          )}
        </CsrfContextConsumer>
      </div>
    </CsrfProvider>
  );
};

StreamPageHeader.propTypes = propTypes;

StreamPageHeader.defaultProps = defaultProps;

export default StreamPageHeader;
