import './externalUrl.less';
import PrimaryButton from '@/components/modal/PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const ExternalUrlConfig = {
  description: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
};

const defaultConfig = {
  description: 'Paste your image URL below.',
  submitText: 'Insert image',
  title: 'External Link',
};

const ExternalUrl = (props) => {
  const { submitCallback, active, title, description, submitText } = { ...defaultConfig, ...props };
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const resetState = () => {
    setValue('');
    setError('');
    setDisabled(true);
  };

  useEffect(() => {
    if (!active) {
      resetState();
    }
  }, [active]);

  const validate = async (value) =>
    new Promise((res, rej) => {
      const img = new Image();
      img.onload = () => {
        res(value);
      };
      img.onerror = () => {
        rej('Unsupported URL.');
      };
      img.src = value;
    });

  const handleChange = async (e) => {
    const url = e.target.value.replace('http://', 'https://');
    setDisabled(true);
    setValue(url);
    try {
      await validate(url);
      setDisabled(false);
      setError('');
    } catch (e) {
      setError(e);
    }
  };

  const handleSubmit = async () => {
    submitCallback({
      type: 'ExternalUrl',
      url: value,
    });
    return;
  };

  const handleFocus = () => {
    setError('');
  };

  return (
    <div className={`ufr-media-upload-external-url ufr-media-upload ${active ? 'active' : ''}`}>
      <div className="ufr-external-url-body">
        <div className="ufr-external-url-title">{title}</div>
        <div className="ufr-external-url-description">{description}</div>

        <div className="ufr-external-url-input-outer-container">
          <div className="ufr-input-fieldset-container">
            <fieldset className={`ufr-input-fieldset ${error && 'has-error'}`}>
              <legend className={`ufr-input-legend ${error && 'has-error'}`}>Image URL</legend>
              <div className="ufr-input-container">
                <svg
                  width="2rem"
                  height="100%"
                  viewBox="0 0 19.00 19.00"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`ufr-svg-icon ${error ? 'has-error' : ''}`}
                  strokeWidth="0.00019"
                >
                  <path d="M16.417 9.57a7.917 7.917 0 1 1-8.144-7.908 1.758 1.758 0 0 1 .451 0 7.913 7.913 0 0 1 7.693 7.907zM5.85 15.838q.254.107.515.193a11.772 11.772 0 0 1-1.572-5.92h-3.08a6.816 6.816 0 0 0 4.137 5.727zM2.226 6.922a6.727 6.727 0 0 0-.511 2.082h3.078a11.83 11.83 0 0 1 1.55-5.89q-.249.083-.493.186a6.834 6.834 0 0 0-3.624 3.622zm8.87 2.082a14.405 14.405 0 0 0-.261-2.31 9.847 9.847 0 0 0-.713-2.26c-.447-.952-1.009-1.573-1.497-1.667a8.468 8.468 0 0 0-.253 0c-.488.094-1.05.715-1.497 1.668a9.847 9.847 0 0 0-.712 2.26 14.404 14.404 0 0 0-.261 2.309zm-.974 5.676a9.844 9.844 0 0 0 .713-2.26 14.413 14.413 0 0 0 .26-2.309H5.903a14.412 14.412 0 0 0 .261 2.31 9.844 9.844 0 0 0 .712 2.259c.487 1.036 1.109 1.68 1.624 1.68s1.137-.644 1.623-1.68zm4.652-2.462a6.737 6.737 0 0 0 .513-2.107h-3.082a11.77 11.77 0 0 1-1.572 5.922q.261-.086.517-.194a6.834 6.834 0 0 0 3.624-3.621zM11.15 3.3a6.82 6.82 0 0 0-.496-.187 11.828 11.828 0 0 1 1.55 5.89h3.081A6.815 6.815 0 0 0 11.15 3.3z"></path>
                </svg>
                <input
                  className="ufr-input-element"
                  type="text"
                  value={value}
                  onChange={handleChange}
                  onFocus={handleFocus}
                />
                <svg
                  width="2rem"
                  height="2rem"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`ufr-svg-icon ${error ? 'has-error' : 'invisible'}`}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.435 8a5.565 5.565 0 1 1 11.13 0 5.565 5.565 0 0 1-11.13 0ZM8 1.185a6.815 6.815 0 1 0 0 13.63 6.815 6.815 0 0 0 0-13.63Zm.625 3.482a.625.625 0 1 0-1.25 0v2.857a.625.625 0 0 0 1.25 0V4.667ZM8 10.38v-.001h.002v.002h-.002v-.001ZM8 9.43a.951.951 0 1 0 0 1.902.951.951 0 0 0 0-1.902Z"
                  ></path>
                </svg>
              </div>
            </fieldset>
          </div>
          <div className="ufr-input-error-text">{error}</div>
        </div>
      </div>

      <div className="ufr-external-url-footer">
        <PrimaryButton label="Place" onClick={handleSubmit} disabled={disabled}>
          {submitText}
        </PrimaryButton>
      </div>
    </div>
  );
};

const propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string,
  submitCallback: PropTypes.func,
  url: PropTypes.string,
  ...ExternalUrlConfig,
};
ExternalUrl.propTypes = propTypes;

export default ExternalUrl;
