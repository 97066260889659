import './inlineSubmitButton.less';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const InlineSubmitButton = ({ ariaLabel, handleClick, size }) => (
  <button
    type="submit"
    aria-label={ariaLabel}
    className={`inline-submit-btn ${size}-submit-btn`}
    onClick={handleClick}
  >
    <Icon icon="ok" className="inline-submit-btn-icon" />
  </button>
);

InlineSubmitButton.defaultProps = {
  size: 'large',
};

InlineSubmitButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'small']),
};

export default InlineSubmitButton;
