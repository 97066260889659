import ArchivedStreamsDatatable from '../datatables/content/streams/ArchivedStreamsDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentUserId: PropTypes.number.isRequired,
  hubId: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  streamPermissions: PropTypes.object.isRequired,
  streamTypes: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const ArchivedStreamsPage = ({ breadcrumbs, title, subtitle, ...props }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader breadcrumbs={breadcrumbs} {...headerText} />
      <ArchivedStreamsDatatable {...props} />
    </Page>
  );
};

ArchivedStreamsPage.propTypes = propTypes;

export default ArchivedStreamsPage;
