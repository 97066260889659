import '../installedAppsListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button.jsx';
import DataTable from '@/components/datatable/DataTable';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import React, { useState } from 'react';
/* eslint-disable sort-keys */
const entityName = {
  singular: 'Installed App',
  plural: 'Installed Apps',
};

const searchPlaceholder = (appsCount) =>
  `Search ${appsCount} ${appsCount === 1 ? entityName.singular : entityName.plural}`;

const cellClass = (key) => `ufr-dt-installed-apps-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-installed-apps-listing-${key}-header`;

/* eslint-disable react/prop-types */
const columns = () => [
  {
    Header: '',
    accessor: 'image',
    className: cellClass('image'),
    headerClassName: headerClass('image'),
    Cell: ({ original }) => {
      const style = {
        backgroundImage: `url(${original.app.icon_url})`,
      };
      return <div className="ufr-app-image" style={style}></div>;
    },
    minWidth: 70,
    sortable: false,
  },
  {
    id: 'name',
    Header: 'App Name',
    accessor: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    Cell: ({ original }) => original.app.name,
    minWidth: 350,
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    Cell: ({ original }) => original.app.description,
    minWidth: 350,
    sortable: false,
  },
  {
    id: 'created_at',
    Header: 'Installed',
    accessor: 'created_at',
    className: cellClass('installed'),
    headerClassName: headerClass('installed'),
    Cell: ({ original }) => getHumanReadableDate(original.created_at),
  },
  {
    id: 'updated_at',
    Header: 'Updated',
    accessor: 'updated_at',
    className: cellClass('updated'),
    headerClassName: headerClass('updated'),
    Cell: ({ original }) => getHumanReadableDate(original.updated_at),
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    Cell: ({ original }) =>
      original.is_editable ? (
        <Button
          href={`/account/app_configuration/${original.id}`}
          icon="pencil"
          id={`edit-app-${original.id}`}
          label="edit app"
        />
      ) : (
        ''
      ),
    sortable: false,
    minWidth: 185,
  },
];
/* eslint-enable react/prop-types */

const getEndpointBuilder = () => ({
  getApps: () => '/api/v2/installed-apps?scope=account',
});

const AccountInstalledAppsListingDatatable = () => {
  const [appsCount, setAppsCount] = useState(0);
  const { getApps } = getEndpointBuilder();

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getApps(), {
      params: queries,
    });
    setAppsCount(meta.count);
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="installed-apps"
        useStateHandling
        entityName={entityName}
        searchPlaceholder={searchPlaceholder(appsCount)}
        columns={columns()}
        getData={getData}
        initialSort={{ id: 'updated_at', desc: true }}
        showLoadingState
      />
    </CsrfProvider>
  );
};

export default AccountInstalledAppsListingDatatable;
