import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import EditableSpan from '@/components/formInputs/editableSpan/EditableSpan';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  priority: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
  websiteWidgetRuleId: PropTypes.number.isRequired,
};

const WebsiteWidgetRulesPriority = ({ priority, websiteWidgetRuleId, triggerRefresh }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const savePriority = async (priority, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'put',
        url: `/api/v2/website-widget-rules/${websiteWidgetRuleId}`,
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
          priority,
        },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetRuleId,
          text: 'Site Engager Rule order updated.',
          type: 'success',
        }),
      );
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetRuleId,
          text: 'Cannot update Site Engager Rule order.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: websiteWidgetRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <EditableSpan
            id={`website-widget-rule-${websiteWidgetRuleId}-priority`}
            min={1}
            onReady={(value) => savePriority(value, csrfToken)}
            type="number"
            validate={(value) => value > 0}
            value={priority}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

WebsiteWidgetRulesPriority.propTypes = propTypes;

export default WebsiteWidgetRulesPriority;
