const imageExists = (imgUrl) => {
  const http = new XMLHttpRequest();

  try {
    http.open('HEAD', imgUrl, false);
    http.send();
  } catch (e) {
    return false;
  }

  return http.status < 400;
};

export default imageExists;
