/**
 * Click handler for all our basic click event needs
 *
 * @param e
 * @param url
 * @param onClick
 * @param onClickCallback
 */

const handleClick = ({ e, url, target, onClick, onClickCallback }) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (url) {
    if (target) {
      window.open(url, target);
      return;
    }

    window.location.href = url;
    return;
  }

  if (onClickCallback) {
    const { namespace, func } = onClickCallback;

    // use global callback if provided
    if (namespace && window[namespace]) {
      window[namespace][func](e);
    } else if ((!namespace || namespace === 'window') && window[func]) {
      window[func](e);
    }
    return;
  }

  if (onClick) onClick();
};

export default handleClick;
