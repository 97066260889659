import './selectDropdown.less';
import { lightGray } from '../../../constants/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const propTypes = {
  defaultSelectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
};

const defaultProps = {
  defaultSelectedItems: [],
  disabled: false,
  items: [],
  searchable: false,
};

function MultiSelectInput({
  id,
  items,
  defaultSelectedItems,
  disabled,
  searchable,
  handleSelect,
  placeholder,
}) {
  const dropDownContainerClassName = 'ufr-multi-select-container';
  const MultiSelectInputClassname = 'ufr-multi-select-input';

  const customStyles = {
    multiValueLabel: (styles) => ({
      ...styles,
      'font-size': '13px',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      ':active': {
        ...styles[':active'],
        backgroundColor: lightGray,
      },
      backgroundColor: isFocused ? lightGray : null,
    }),
  };

  return (
    <div className={classNames(dropDownContainerClassName)}>
      <Select
        id={`${id}-input`}
        className={classNames(MultiSelectInputClassname)}
        classNamePrefix={MultiSelectInputClassname}
        styles={customStyles}
        defaultValue={defaultSelectedItems}
        isDisabled={disabled}
        isMulti
        isSearchable={searchable}
        onChange={disabled ? null : handleSelect}
        options={items}
        placeholder={placeholder}
      />
    </div>
  );
}

MultiSelectInput.propTypes = propTypes;
MultiSelectInput.defaultProps = defaultProps;

export default MultiSelectInput;
