import '../formInputs.less';
import FakeCheckbox from '@/components/formInputs/toggle/FakeCheckbox';
import handleInputReady from '../../../utils/handleInputReady';
import InputContainer from '../inputContainer/InputContainer';
import PropTypes from 'prop-types';
import React from 'react';
import Reset from '@/components/formInputs/reset/Reset';

const propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  ]),
  disabled: PropTypes.bool,
  // required for acceptance tests and a11y decorators
  id: PropTypes.string.isRequired,
  // for app config settings
  inheritedValue: PropTypes.bool,
  localStorageKey: PropTypes.string,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  resetData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  showReset: PropTypes.bool,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
  title: PropTypes.string,
  useLocalStorage: PropTypes.bool,
  // Add a readonly validation rules object to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
  value: PropTypes.bool.isRequired,
};

const defaultProps = {
  description: '',
  disabled: false,
  inheritedValue: false,
  localStorageKey: '',
  name: '',
  resetData: {},
  showReset: false,
  submitData: {},
  title: '',
  useLocalStorage: false,
  validation_rules: {},
};

class Toggle extends React.Component {
  state = {
    val: this.props.value,
  };

  // need to do componentDidMount because we can't access window in constructor
  componentDidMount() {
    if (this.props.useLocalStorage) {
      // unique local storage key per account
      const { accountId } = document.querySelector('.ufa-section-persistent-filters').dataset;
      const lSPassFilterToggleKey = `${this.props.localStorageKey}-${accountId}`;
      const isPassiveFilters = Boolean(Number(window.localStorage.getItem(lSPassFilterToggleKey)));

      if (isPassiveFilters) {
        this.setState({
          val: true,
        });
      }
    }
  }

  handleClick = () => {
    const { disabled, submitData, name, validation_rules: validationRules } = this.props;
    const { val } = this.state;
    const toggleVal = !val;
    const componentData = {
      propertyName: name,
      propertyValue: toggleVal ? 1 : 0,
      validationRules,
    };

    if (disabled) return;

    this.setState({ val: toggleVal });

    handleInputReady(submitData, componentData);
  };

  handleReset = () => {
    const { name, resetData } = this.props;
    const componentData = {
      propertyName: name,
    };

    handleInputReady(resetData, componentData, this.finishReset);
  };

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    if (prevProps.value !== this.state.val) {
      this.setState({
        val: this.props.value,
      });
    }
  }

  finishReset = () => {
    const { inheritedValue } = this.props;

    this.setState({ val: inheritedValue });
  };

  render() {
    const { val } = this.state;
    const { id, title, description, disabled, showReset } = this.props;

    return (
      <InputContainer id={id} title={title} description={description} disabled={disabled}>
        <div className="ufr-align-input-container">
          <FakeCheckbox val={val} disabled={disabled} handleClick={this.handleClick}>
            <span className="ufr-toggle-slider" />
          </FakeCheckbox>
          {showReset && <Reset handleReset={this.handleReset} id={id} />}
        </div>
      </InputContainer>
    );
  }
}

Toggle.defaultProps = defaultProps;
Toggle.propTypes = propTypes;

export default Toggle;
