import PropTypes from 'prop-types';
import React from 'react';
import StreamsListingRestoreButton from './StreamsListingRestoreButton';
import userHasPermissionTo from '@/utils/userHasPermissionTo';

const propTypes = {
  currentUserId: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  ownerId: PropTypes.number.isRequired,
  streamId: PropTypes.number.isRequired,
  streamName: PropTypes.string,
  streamPermissions: PropTypes.object.isRequired,
  streamType: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const ArchivedStreamsListingButtons = ({
  currentUserId,
  isPrimary,
  ownerId,
  streamId,
  streamName,
  streamPermissions,
  streamType,
  triggerRefresh,
}) => (
  <>
    {userHasPermissionTo(
      'create',
      streamType,
      ownerId,
      currentUserId,
      streamPermissions,
      isPrimary,
    ) ? (
      <StreamsListingRestoreButton
        streamId={streamId}
        streamName={streamName}
        triggerRefresh={triggerRefresh}
      />
    ) : (
      ''
    )}
  </>
);

ArchivedStreamsListingButtons.propTypes = propTypes;

export default ArchivedStreamsListingButtons;
