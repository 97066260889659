import axios from 'axios/index';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import './transferList.less';
import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import Icon from '../icon/Icon';

const transferFromHeader = 'Ungrouped Tags';
const transferFromDescription = 'Select from your existing list of ungrouped tags to add.';
const transferToHeader = 'Tags Within Group: ';
const transferToDescription = 'Existing tags within this Tag Group.';
const buttonText = 'New Tag';
const emptyListText = 'List is empty';
const noSearchResultsText = 'No search results';

/* eslint-disable react/prop-types */
/* eslint-disable sort-keys */
const TransferList = (props) => {
  const {
    transferFromData,
    transferFrom,
    setTransferFrom,
    transferTo,
    setTransferTo,
    addTagDisabled,
    setAddTagDisabled,
    setDoneDisabled,
    originalTransferFromData,
    dispatchToasterAction,
  } = props;

  //search text should be persistent between renders so we can render our filtered data
  const searchText = useRef('');

  //filters the loaded data based on whether we are searching or not
  const filterData = () => {
    if (searchText.current.length === 0) {
      setTransferFrom(transferFromData.current);
      return;
    }

    const filteredData = transferFromData.current.filter((item) =>
      item.name.toLowerCase().includes(searchText.current.toLowerCase()),
    );

    //avoid unnecessary renders
    if (filteredData.length !== transferFrom.length) {
      setTransferFrom(filteredData);
    }
  };

  const onChangeSearch = (e) => {
    const text = e.target.value.trim();
    searchText.current = text;
    filterData();
  };

  const onSubmit = async (csrfToken) => {
    setAddTagDisabled(true);
    setDoneDisabled(true);
    const toaster = {
      id: 'new-tag-toaster',
    };

    const url = `/api/v2/tags`;

    const data = { name: searchText.current, description: '' };

    await axios({
      method: 'post',
      url,
      data: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        //double check if proper data recieved
        //otherwise display error toaster
        if ('id' in response.data && typeof response.data.id !== 'undefined') {
          transferFromData.current = [
            ...transferFromData.current,
            { id: response.data.id, name: response.data.name },
          ];
          originalTransferFromData.current = [
            ...originalTransferFromData.current,
            { id: response.data.id, name: response.data.name },
          ];
          filterData();
          toaster.type = 'success';
          toaster.text = 'Tag successfully added';
        } else {
          toaster.type = 'error';
          toaster.text = 'Error: Unable to add tag';
        }
      })
      .catch((error) => {
        toaster.type = 'error';
        toaster.text = 'Error: ' + error.response.data.errors[0].message;
      });
    setAddTagDisabled(false);
    setDoneDisabled(false);
    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
  };

  const renderTransferFrom = () => {
    if (transferFromData.current.length === 0) {
      return <div className="tl-no-data">{emptyListText}</div>;
    }
    if (transferFrom.length === 0) {
      return <div className="tl-no-data">{noSearchResultsText}</div>;
    }
    return transferFrom.map((item) => (
      <div
        className="tl-row"
        key={item.id}
        onClick={() => {
          transferFromData.current = transferFromData.current.filter(
            (transferItem) => transferItem.id !== item.id,
          );
          setTransferTo([...transferTo, item]);
          filterData();
        }}
      >
        <div className="tl-text">{item.name}</div>
        <Icon className="tl-icon" icon="plus" />
      </div>
    ));
  };

  const renderTransferTo = () => {
    if (transferTo.length === 0) {
      return <div className="tl-no-data">{emptyListText}</div>;
    }
    return transferTo.map((item) => (
      <div
        className="tl-row"
        key={item.id}
        onClick={() => {
          setTransferTo(transferTo.filter((transferItem) => transferItem.id !== item.id));
          transferFromData.current = [...transferFromData.current, item];
          filterData();
        }}
      >
        <div className="tl-text">{item.name}</div>
        <Icon className="tl-icon" icon="remove" />
      </div>
    ));
  };

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <div className="transfer-list">
            {/* 'transfer from' list */}
            <div className="tl-box-col">
              <div className="tl-box-text">
                <div className="tl-box-header">{transferFromHeader}</div>
                <div className="tl-box-description">{transferFromDescription}</div>
              </div>
              <div className="tl-box">
                {/* control bar */}
                <div className="tl-control-bar-box">
                  <div className="tl-control-bar">
                    <div className="tl-search-box">
                      <Icon className="tl-search-icon" icon="search" />
                      <input
                        className="tl-search-input"
                        type="text"
                        placeholder="Search Tags"
                        onChange={onChangeSearch}
                      ></input>
                    </div>
                    <button
                      className="tl-button"
                      onClick={() => onSubmit(csrfToken)}
                      disabled={addTagDisabled}
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
                <div className="tl-row-box">{renderTransferFrom()}</div>
              </div>
            </div>
            {/* 'transfer to' list */}
            <div className="tl-box-col">
              <div className="tl-box-text">
                <div className="tl-box-header">{transferToHeader + transferTo.length} </div>
                <div className="tl-box-description">{transferToDescription}</div>
              </div>
              <div className="tl-box">
                <div className="tl-row-box">{renderTransferTo()}</div>
              </div>
            </div>
          </div>
        )}
      </CsrfContextConsumer>
    </CsrfProvider>
  );
};

//repeated tag prop
const tagPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

/* eslint-disable sort-keys */
TransferList.propTypes = {
  //ref that reflects the loaded data in our 'transfer from' list when we are not searching
  transferFromData: PropTypes.shape({
    current: tagPropType,
  }),

  //state that reflects the data we want to show in our 'transfer from' list at any given time
  transferFrom: tagPropType,
  setTransferFrom: PropTypes.func.isRequired,

  //state that reflects the data we want to show in our 'transfer to' list at any given time
  transferTo: tagPropType,
  setTransferTo: PropTypes.func.isRequired,

  tagGroupId: PropTypes.number.isRequired,
  addTagDisabled: PropTypes.bool.isRequired,
  setAddTagDisabled: PropTypes.func.isRequired,
  setDoneDisabled: PropTypes.func.isRequired,
  dispatchToasterAction: PropTypes.func,
};

export default TransferList;
