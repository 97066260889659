import PropTypes from 'prop-types';
import React from 'react';

import EnrichmentSourcesDatatable from '../datatables/enrichment/EnrichmentSourcesDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';

const { number, bool } = PropTypes;

const propTypes = {
  accountId: number.isRequired,
  hasIntegrations: bool.isRequired,
  readonly: bool.isRequired,
};

const EnrichmentPage = ({ accountId, hasIntegrations, readonly }) => {
  const headerText = {
    subtitle: {
      text: 'Import account and prospect information from connected applications to enrich your Uberflip data.',
    },
    title: { text: 'Enrichment' },
  };

  const Alert = (
    <div className="alert alert-info" role="alert">
      Looks like you don&apos;t currently have any connected services that can be used with
      Enrichment. To get started, go to the
      <a href="/account/connected_services"> Connected Services </a> page to integrate apps and
      services from your marketing tech stack with Uberflip.
    </div>
  );

  return (
    <Page>
      <PageHeader {...headerText} />
      {hasIntegrations ? (
        <EnrichmentSourcesDatatable accountId={accountId} readonly={readonly} />
      ) : (
        Alert
      )}
    </Page>
  );
};

EnrichmentPage.propTypes = propTypes;

export default EnrichmentPage;
