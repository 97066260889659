import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  decrementRedirectRuleCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  redirectRuleId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const RedirectRulesDeleteButton = ({
  decrementRedirectRuleCount,
  dispatchToasterAction,
  redirectRuleId,
  triggerRefresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteRedirectRule = async (csrfToken) => {
    try {
      setIsDeleting(true);
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: `/redirect_rules/ajax_delete/${redirectRuleId}`,
        headers: { 'X-Csrf-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: redirectRuleId,
          text: 'Redirect Rule was deleted!',
          type: 'success',
        }),
      );
      decrementRedirectRuleCount?.();
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: redirectRuleId,
          text: 'Unable to delete Redirect Rule.',
          type: 'error',
        }),
      );
    }
    setIsDeleting(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: redirectRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              handleConfirm={() => deleteRedirectRule(csrfToken)}
              header="Delete Redirect Rule"
              body="Are you sure you want to permanently delete this Redirect Rule?"
              primaryButtonText="Delete Rule"
            />

            <Button
              icon="bin"
              title="Delete Redirect Rule"
              label="Delete Redirect Rule"
              id={`delete-redirect-rule-${redirectRuleId}`}
              className="ufr-delete-button"
              disabled={isDeleting}
              onClick={() => setIsModalOpen(true)}
            />
          </>
        )}
      </CsrfContextConsumer>
    </>
  );
};

RedirectRulesDeleteButton.propTypes = propTypes;

export default RedirectRulesDeleteButton;
