import Icon from '@/components/shared/icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import ServiceIcon from '@/components/shared/serviceIcon/ServiceIcon';

const propTypes = {
  hasManagePlacementsPermission: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  service: PropTypes.string.isRequired,
  streamTypes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const defaultProps = {
  hasManagePlacementsPermission: false,
};

const Placement = ({
  name,
  type,
  service,
  streamTypes,
  onDelete,
  hasManagePlacementsPermission,
}) => {
  const handleDelete = (e) => {
    if (onDelete) onDelete(e);
  };

  return (
    <div className="cta-placement">
      <div className="cta-placement-title">
        <div className="sprite-container">
          {service && <ServiceIcon service={service} format="small" />}
        </div>
        <div>
          <span className="cta-placement-title-name">{name}</span>
          <br />
          <span className="cta-placement-title-type">
            {streamTypes[type] ? `Type: ${streamTypes[type].label}` : ''}
          </span>
        </div>
      </div>
      {hasManagePlacementsPermission && (
        <div className="cta-placement-remove-container">
          <span tabIndex={0} className="cta-placement-remove" onClick={handleDelete}>
            <Icon icon="remove" />
            Remove Stream
          </span>
        </div>
      )}
    </div>
  );
};

Placement.propTypes = propTypes;
Placement.defaultProps = defaultProps;

export default Placement;
