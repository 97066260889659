import CountDropdown from './CountDropdown';
import PageControl from './PageControl';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const defaultProps = {};

const Pagination = ({ id, pageSize, pageCount, currentPage, onChange, disabled }) => (
  <div className="ufr-pagination">
    <CountDropdown id={id} value={pageSize} onChange={onChange} disabled={disabled} />

    {pageCount > 1 && (
      <PageControl
        id={id}
        pageCount={pageCount}
        currentPage={currentPage}
        onClick={onChange}
        disabled={disabled}
      />
    )}
  </div>
);

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
