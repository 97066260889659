import { Modal } from './index';
import CancelButton from './SecondaryButton/CancelButton';
import ModalBody from './ModalBody/ModalBody';
import ModalFooter from './ModalFooter/ModalFooter';
import ModalHeader from './ModalHeader/ModalHeader';
import PrimaryButton from './PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  cancelButton: PropTypes.bool,
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  primaryButtonEnabled: PropTypes.bool,
  primaryButtonText: PropTypes.string,
};

const defaultProps = {
  primaryButtonText: 'Confirm',
};

const ConfirmationModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  header,
  body,
  primaryButtonText,
  primaryButtonEnabled = true,
  className = null,
  cancelButton = true,
}) => (
  <Modal isOpen={isOpen} handleClose={handleClose} className={className}>
    <ModalHeader title={header} />
    <ModalBody>{body}</ModalBody>
    <ModalFooter>
      {cancelButton ? <CancelButton onClick={handleClose} /> : ''}
      <PrimaryButton
        label={primaryButtonText}
        onClick={() => {
          handleClose();
          handleConfirm();
        }}
        disabled={!primaryButtonEnabled}
      >
        {primaryButtonText}
      </PrimaryButton>
    </ModalFooter>
  </Modal>
);

ConfirmationModal.propTypes = propTypes;
ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
