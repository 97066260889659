import { Dropdown, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import './buttonDropDown.less';
import handleClick from '@/utils/handleClick';
import Icon from '../icon/Icon';

// eslint-disable-next-line react/prop-types
const MenuContent = React.memo(({ icon, text }) => (
  <div>
    {icon && <Icon icon={icon} />}
    <span className="menu-text">{text}</span>
  </div>
));

const ButtonDropDown = ({ id, buttonText, items, disabled, orientation }) => {
  const renderMenuItems = () =>
    items.map(({ id, icon, text, url, target, onClick }) => {
      const click = (e) => handleClick({ e, onClick });

      return (
        <MenuItem
          key={id}
          id={`${id}-menu-item`}
          eventKey={id}
          href={url || undefined}
          target={target}
          onClick={url ? undefined : click}
          className={icon ? 'with-icon' : ''}
        >
          <MenuContent {...{ icon, text }} />
        </MenuItem>
      );
    });

  return (
    <Dropdown
      id={`${id}-menu-toggle`}
      className="react-btn-dropdown"
      disabled={disabled}
      pullRight={orientation.pullRight}
      dropup={orientation.dropup}
    >
      <Dropdown.Toggle noCaret>{buttonText}</Dropdown.Toggle>
      <Dropdown.Toggle noCaret>
        {orientation.dropup ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
      </Dropdown.Toggle>
      <Dropdown.Menu className="react-dropdown-menu">{renderMenuItems()}</Dropdown.Menu>
    </Dropdown>
  );
};

ButtonDropDown.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
  orientation: PropTypes.shape({
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
  }),
};

ButtonDropDown.defaultProps = {
  buttonText: '',
  disabled: false,
  orientation: {
    dropup: false,
    pullRight: true,
  },
};

export default ButtonDropDown;
