import { element, func, oneOfType, string } from 'prop-types';
import React from 'react';

// The "button" below needs to change based on where it is.
// Bootstrap applies a11y styles to its buttons that we don't want
// for the open button for Salesforce on the integrations pages,
// but we are okay with in the other places it is used

const OpenButton = ({ buttonClass, children, modalType, onClick }) =>
  modalType === 'salesforce' ? (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <a className={buttonClass} onClick={onClick}>
      {children}
    </a>
  ) : (
    <button className={buttonClass} onClick={onClick} type="button">
      {children}
    </button>
  );

OpenButton.propTypes = {
  buttonClass: string.isRequired,
  children: oneOfType([element, string]).isRequired,
  modalType: string.isRequired,
  onClick: func.isRequired,
};

export default OpenButton;
