import './icon.less';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Icon = ({ icon, className, color }) => {
  const classes = classNames('react-icon', 'glyphicons', `glyphicons-${icon}`, className);

  return <span style={color ? { color } : {}} className={classes} aria-hidden="true" />;
};

Icon.defaultProps = {
  className: '',
  color: null,
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default Icon;
