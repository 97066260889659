import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  cta: PropTypes.shape({
    background_color: PropTypes.string.isRequired,
    background_url: PropTypes.string,
    button_background_color: PropTypes.string.isRequired,
    button_font_color: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    font_aspect_ratio: PropTypes.number.isRequired,
    font_color: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    modified_at: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

function Cta({ cta, width, height }) {
  const fontSize = Math.floor(cta.font_aspect_ratio * (width + height));
  let background;
  let border;

  if (cta.background_url) {
    background = {
      background: `${cta.background_color} url(${cta.background_url}) center top / cover`,
    };
    border = { borderBottom: 0 };
  } else {
    const borderColor = window.adjustBrightness(cta.background_color, -15);
    background = { backgroundColor: cta.background_color };
    border = { borderBottom: `1px solid ${borderColor}` };
  }

  return (
    <>
      {cta.type === 'url' ? (
        <div
          className="tile single cta cta-website item"
          id={`hub-cta-${cta.id}`}
          style={background}
        >
          <p
            style={{
              color: cta.font_color,
              fontSize: `${fontSize}px`,
              paddingTop: `${fontSize}px`,
            }}
          >
            {cta.tagline}
          </p>
          <a
            style={{ backgroundColor: cta.button_background_color, color: cta.button_font_color }}
            className="cta-button accent-button"
            href="javascript:void(0)"
          >
            {cta.button_label}
          </a>
        </div>
      ) : (
        <div className="tile single cta cta-form item" id={`hub-cta-${cta.id}`} style={background}>
          <div className="hide-preview-cancel">
            <div className="cta-close">&times;</div>
          </div>
          <div className="run-away cta-transition" style={{ ...border, position: 'relative' }}>
            <p
              style={{
                color: cta.font_color,
                fontSize: `${fontSize}px`,
                paddingTop: `${fontSize}px;`,
              }}
            >
              {cta.tagline}
            </p>

            <div className="hidden-cta-fields">
              <form>
                <div className="cta-button-container cta-activate-button-container">
                  <div className="cta-field-section email-field">
                    <span className="cta-field-name" style={{ color: cta.font_color }}>
                      Email
                    </span>
                    <input type="text" className="preview-form-field activate-button" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Cta.propTypes = propTypes;

export default Cta;
