import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  tagGroup: PropTypes.object.isRequired,
  triggerDelete: PropTypes.func.isRequired,
};

const modalBody = () => (
  <>
    <p>
      <strong>
        This Tag Group will be permanently deleted. All of its tags will remain in Uberflip without
        a group.
      </strong>
    </p>
    <p>
      <strong>Note</strong>: If this creates duplicate ungrouped tags, those tags will be
      automatically numbered to differentiate them, e.g. sampletag, sampletag-1, sampletag-2.
    </p>
    <p>Are you sure you want to delete this Tag Group?</p>
  </>
);

const TagGroupListingButtons = ({ tagGroup, triggerDelete }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { id, name } = tagGroup;

  return (
    <>
      <Button
        onClick={() => setIsDeleteModalOpen(true)}
        icon="bin"
        id={`delete-tag-group-${id}`}
        label="delete tag group"
        title="Delete Tag Group"
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={triggerDelete}
        header={`Delete Tag Group: ${name}`}
        body={modalBody()}
        primaryButtonText="Delete Tag Group"
      />
    </>
  );
};

TagGroupListingButtons.propTypes = propTypes;

export default TagGroupListingButtons;
