import PropTypes from 'prop-types';
import React from 'react';

import handleClick from '../../../utils/handleClick';

const DivLink = ({ url, children, className, target }) => {
  const styles = {
    cursor: 'pointer',
  };

  if (url) {
    const click = () => handleClick({ target, url });
    const keyDown = (e) => {
      if (e.key === 'Enter') handleClick({ e, url });
    };

    return (
      <div
        className={className}
        role="link"
        tabIndex={0}
        onClick={click}
        onKeyDown={keyDown}
        style={styles}
      >
        {children}
      </div>
    );
  }
  return <div className={className}>{children}</div>;
};

DivLink.defaultProps = {
  children: null,
  className: '',
  target: '',
};

DivLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  target: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default DivLink;
