import PropTypes from 'prop-types';
import React from 'react';

import HubEmbedParametersDatatable from '../datatables/embedParameters/HubEmbedParametersDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';

const propTypes = {
  hub: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  permissions: PropTypes.shape({
    create: PropTypes.bool,
    delete: PropTypes.bool,
    update: PropTypes.bool,
  }).isRequired,
};

const HubEmbedParametersPage = ({ hub: { id, name }, permissions }) => {
  const headerText = {
    subtitle: {
      text: 'Configure URL parameters and values that you want to automatically add to all Uberflip embeds.',
    },
    title: { text: 'Embed Parameters' },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <HubEmbedParametersDatatable hub={{ id, name }} permissions={permissions} />
    </Page>
  );
};

HubEmbedParametersPage.propTypes = propTypes;

export default HubEmbedParametersPage;
