import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ArchivedStreamsListingButtons from './ArchivedStreamsListingButtons';
import Avatar from '@/components/shared/avatar/Avatar';
import DatatableThumbnail from '@/components/datatable/DatatableThumbnail';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import React from 'react';
import StreamsListingButtons from './StreamsListingButtons';
import userHasPermissionTo from '@/utils/userHasPermissionTo';

const filterStreamTypes = (streamTypes, collectionsCountByType) =>
  Object.keys(collectionsCountByType).map((key) => ({
    id: key,
    queryValue: key,
    text: streamTypes[key].label,
  }));

export const filterStreamDropdowns = (streamTypes, collectionsCountByType) => [
  {
    items: [
      {
        id: 'all-types',
        queryValue: null,
        text: 'Type: All Types',
      },
      ...filterStreamTypes(streamTypes, collectionsCountByType),
    ],
    queryKey: 'type',
    toggleId: 'stream-types',
    toggleText: 'Type: All Types',
  },
];

export const searchPlaceholder = (streamsCount, entityName) =>
  `Search ${streamsCount} ${entityName[streamsCount === 1 ? 'singular' : 'plural']} in this Hub`;

const cellClass = (key) => `ufr-dt-streams-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-streams-listing-${key}-header`;

const archivedCellClass = (key) => `ufr-dt-archived-streams-listing-${key}-cell`;

/* eslint-disable sort-keys,react/prop-types */
export const getColumns = (
  hubId,
  isSearchDisabled,
  isPrimary,
  streamPermissions,
  currentUserId,
  dispatchToasterAction = null,
  isArchived = false,
) => ({
  NAME: {
    id: 'name',
    Header: 'Internal Stream Name',
    accessor: 'name',
    className: cellClass('stream-name'),
    headerClassName: headerClass('stream-name'),
    minWidth: 265,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => (
      <>
        {userHasPermissionTo(
          'view',
          original.type,
          original.owner.id,
          currentUserId,
          streamPermissions,
          isPrimary,
        ) ? (
          <a href={`/hubs/manage/${hubId}/${original.type}/${original.id}`}>
            <DatatableThumbnail
              type={original.service}
              imageUrl={original.thumbnail_url}
              hidden={original.hidden}
            />
          </a>
        ) : (
          <DatatableThumbnail
            type={original.service}
            imageUrl={original.thumbnail_url}
            hidden={original.hidden}
          />
        )}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-internal-stream-name-tooltip" placement="top">
              {original.name}
            </Tooltip>
          }
        >
          <div className="ufr-stream-internal-name">
            {userHasPermissionTo(
              'view',
              original.type,
              original.owner.id,
              currentUserId,
              streamPermissions,
              isPrimary,
            ) ? (
              <a href={`/hubs/manage/${hubId}/${original.type}/${original.id}`}>{original.name}</a>
            ) : (
              <span>{original.name}</span>
            )}
          </div>
        </OverlayTrigger>
      </>
    ),
  },
  TITLE: {
    id: 'title',
    Header: 'Page Title',
    accessor: 'title',
    className: cellClass('stream-title'),
    headerClassName: headerClass('stream-title'),
    minWidth: 160,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-stream-title-tooltip" placement="top">
            {original.title}
          </Tooltip>
        }
      >
        <div className="ufr-stream-title">{original.title}</div>
      </OverlayTrigger>
    ),
  },
  OWNER: {
    id: 'owner',
    Header: 'Stream Owner',
    accessor: 'owner',
    className: cellClass('stream-owner'),
    headerClassName: headerClass('stream-owner'),
    minWidth: 140,
    sortable: !isSearchDisabled,
    Cell: ({ original: { owner } }) => [
      <Avatar
        key={1}
        firstName={owner.first_name}
        lastName={owner.last_name}
        avatarUrl={owner.avatar_url}
      />,
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-stream-owner-tooltip" placement="top">
            {`${owner.first_name} ${owner.last_name}`}
          </Tooltip>
        }
      >
        <div key={2} className="ufr-stream-owner truncate">
          {`${owner.first_name} ${owner.last_name}`}
        </div>
      </OverlayTrigger>,
    ],
  },
  ITEM_COUNT: {
    id: 'item_count',
    Header: 'Items',
    accessor: 'item_count',
    className: cellClass('stream-items-count'),
    headerClassName: headerClass('stream-items-count'),
    minWidth: 60,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => original.item_count.toLocaleString('EN-us'),
  },
  SEO_STATUS: {
    id: 'seo_status',
    Header: 'SEO',
    accessor: 'seo_status',
    className: cellClass('stream-seo'),
    headerClassName: headerClass('stream-seo'),
    minWidth: 125,
    Cell: ({ original }) => original.seo_status,
    sortable: false,
  },
  ID: {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    className: cellClass('stream-id'),
    headerClassName: headerClass('stream-id'),
    minWidth: 80,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => original.id,
  },
  CREATED_AT: {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    className: cellClass('created-date'),
    headerClassName: headerClass('created-date'),
    minWidth: 90,
    sortable: !isSearchDisabled,
    Cell: ({ value }) => getHumanReadableDate(value),
  },
  MODIFIED_AT: {
    id: 'modified_at',
    Header: 'Last Modified',
    accessor: 'modified_at',
    className: cellClass('modified-date'),
    headerClassName: headerClass('modified-date'),
    minWidth: 90,
    sortable: !isSearchDisabled,
    Cell: ({ value }) => getHumanReadableDate(value),
  },
  // Unused as of ENG-1338
  AUTHENTICATION: {
    id: 'authentication',
    Header: 'Authentication',
    accessor: 'authentication',
    className: cellClass('authentication'),
    headerClassName: headerClass('authentication'),
    minWidth: 100,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => {
      let authenticationStatus;
      if (original.authentication === null) {
        authenticationStatus = 'N/A';
      } else if (original.authentication === 1) {
        authenticationStatus = 'Enabled';
      } else if (original.authentication === 0) {
        authenticationStatus = 'Disabled';
      }
      return authenticationStatus;
    },
  },
  CONTROLS: {
    Header: isArchived ? 'Restore' : '',
    accessor: 'controls',
    className: `${
      cellClass('buttons') + (isArchived ? ' ' + archivedCellClass('buttons') : '')
    } ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    minWidth: 130,
    sortable: false,
    Cell: (cell) =>
      isArchived ? (
        <ArchivedStreamsListingButtons
          currentUserId={currentUserId}
          isPrimary={isPrimary}
          ownerId={cell.original.owner.id}
          streamId={cell.original.id}
          streamName={cell.original.name}
          streamPermissions={streamPermissions}
          streamType={cell.original.type}
          triggerRefresh={cell.refresh}
        />
      ) : (
        <StreamsListingButtons
          hubId={hubId}
          streamId={cell.original.id}
          streamType={cell.original.type}
          streamName={cell.original.name}
          streamUrl={cell.original.url}
          triggerRefresh={cell.refresh}
          streamPermissions={streamPermissions}
          ownerId={cell.original.owner.id}
          currentUserId={currentUserId}
          isPrimary={isPrimary}
          decrementStreamsCount={cell.decrementStreamsCount}
          dispatchToasterAction={dispatchToasterAction}
        />
      ),
  },
});
/* eslint-enable sort-keys,react/prop-types */
