import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios/index';
import CheckBoxModal from '@/components/modal/CheckBoxModal/CheckBoxModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import shortid from 'shortid';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  buttonClass: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  ...CheckBoxModal.propTypes,
};

const defaultProps = {
  body: <></>,
  description: <></>,
  handleClose: () => {},
  handleConfirm: () => {},
  isOpen: false,
};

const DeleteAllAnalyticsDataModal = ({ buttonText, buttonClass, accountId, ...props }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [isOpen, setOpen] = useState(false);

  const onSubmit = async (csrfToken) => {
    const toaster = {
      id: shortid(), // using random id to stack toasts
    };

    const url = `/analytics/delete/${accountId}`;
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      toaster.type = 'success';
      toaster.text = 'Deletion process has started.';
    } catch (err) {
      toaster.type = 'error';
      toaster.text = 'Error deleting Analytics Data, please try again or contact support.';
    }

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
  };

  return (
    <>
      <button type="button" onClick={() => setOpen(true)} className={buttonClass}>
        {buttonText}
      </button>
      <CsrfProvider>
        <CsrfContextConsumer>
          {/* eslint-disable-next-line */}
          {(csrfToken) => {
            return (
              <CheckBoxModal
                {...props}
                isOpen={isOpen}
                handleClose={() => setOpen(false)}
                handleConfirm={() => onSubmit(csrfToken)}
                description={
                  <>
                    This will <b>permanently</b> delete all Personally Identifiable Information data
                    for all Hubs associated with your account. Once deleted, this data cannot be
                    recovered. You will not be able to stop or undo this action.
                  </>
                }
              />
            );
          }}
        </CsrfContextConsumer>
        <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
      </CsrfProvider>
    </>
  );
};

DeleteAllAnalyticsDataModal.propTypes = propTypes;
DeleteAllAnalyticsDataModal.defaultProps = defaultProps;

export default DeleteAllAnalyticsDataModal;
