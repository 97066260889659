import './salesforceModal.less';
import * as React from 'react';
import { func, shape, string } from 'prop-types';
import axios from 'axios';
import SalesforceDropdown, { DropdownTitle } from './SalesforceDropdown';

class PriorityAndStatus extends React.Component {
  state = {
    loadingPriorities: false,
    loadingStatuses: false,
    priorities: [],
    statuses: [],
  };

  componentDidMount() {
    this.loadPriorities();
    this.loadStatuses();
  }

  async loadPriorities() {
    const { handleDisableSubmit } = this.props;
    handleDisableSubmit(true);
    this.setState({ loadingPriorities: true });

    try {
      const {
        data: { response },
      } = await axios.get('/integrations/ajax_maps/providers/salesforce/task-priorities', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      this.setState({ loadingPriorities: false, priorities: response });
      handleDisableSubmit(false);
    } catch (e) {
      this.setState({ loadingPriorities: false });
      // TODO better error handling
      throw e;
    }
  }

  async loadStatuses() {
    const { handleDisableSubmit } = this.props;
    handleDisableSubmit(true);
    this.setState({ loadingStatuses: true });

    try {
      const {
        data: { response },
      } = await axios.get('/integrations/ajax_maps/providers/salesforce/task-statuses', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      this.setState({ loadingStatuses: false, statuses: response });
      handleDisableSubmit(false);
    } catch (e) {
      this.setState({ loadingStatuses: false });
      // TODO better error handling
      throw e;
    }
  }

  render() {
    const {
      salesforceSelections: {
        flipbookDefaultPriority,
        flipbookDefaultStatus,
        pageViewDefaultPriority,
        pageViewDefaultStatus,
        ctaDefaultPriority,
        ctaDefaultStatus,
      },
      selectSalesforceIntegrationItem,
    } = this.props;

    const { loadingPriorities, priorities, loadingStatuses, statuses } = this.state;

    return (
      <>
        <fieldset className="sfi-field-section">
          <h3 className="sfi-field-section-header">Flipbook Activity</h3>
          <div className="sfi-field-section-body">
            <DropdownTitle>Priority</DropdownTitle>
            <SalesforceDropdown
              loading={loadingPriorities}
              selected={flipbookDefaultPriority}
              menuItems={priorities}
              selectDropdownItem={(item) =>
                selectSalesforceIntegrationItem({ flipbookDefaultPriority: item })
              }
            />
          </div>
          <div className="sfi-field-section-body">
            <DropdownTitle>Status</DropdownTitle>
            <SalesforceDropdown
              loading={loadingStatuses}
              selected={flipbookDefaultStatus}
              menuItems={statuses}
              selectDropdownItem={(item) =>
                selectSalesforceIntegrationItem({ flipbookDefaultStatus: item })
              }
            />
          </div>
        </fieldset>
        <fieldset className="sfi-field-section">
          <h3 className="sfi-field-section-header">Page View</h3>
          <div className="sfi-field-section-body">
            <DropdownTitle>Priority</DropdownTitle>
            <SalesforceDropdown
              loading={loadingPriorities}
              selected={pageViewDefaultPriority}
              menuItems={priorities}
              selectDropdownItem={(item) =>
                selectSalesforceIntegrationItem({ pageViewDefaultPriority: item })
              }
            />
          </div>
          <div className="sfi-field-section-body">
            <DropdownTitle>Status</DropdownTitle>
            <SalesforceDropdown
              loading={loadingStatuses}
              selected={pageViewDefaultStatus}
              menuItems={statuses}
              selectDropdownItem={(item) =>
                selectSalesforceIntegrationItem({ pageViewDefaultStatus: item })
              }
            />
          </div>
        </fieldset>
        <fieldset className="sfi-field-section">
          <h3 className="sfi-field-section-header">CTA Activity</h3>
          <div className="sfi-field-section-body">
            <DropdownTitle>Priority</DropdownTitle>
            <SalesforceDropdown
              loading={loadingPriorities}
              selected={ctaDefaultPriority}
              menuItems={priorities}
              selectDropdownItem={(item) =>
                selectSalesforceIntegrationItem({ ctaDefaultPriority: item })
              }
            />
          </div>
          <div className="sfi-field-section-body">
            <DropdownTitle>Status</DropdownTitle>
            <SalesforceDropdown
              loading={loadingStatuses}
              selected={ctaDefaultStatus}
              menuItems={statuses}
              selectDropdownItem={(item) =>
                selectSalesforceIntegrationItem({ ctaDefaultStatus: item })
              }
            />
          </div>
        </fieldset>
      </>
    );
  }
}

PriorityAndStatus.propTypes = {
  handleDisableSubmit: func.isRequired,
  salesforceSelections: shape({
    ctaDefaultPriority: string,
    ctaDefaultStatus: string,
    flipbookDefaultPriority: string,
    flipbookDefaultStatus: string,
    pageViewDefaultPriority: string,
    pageViewDefaultStatus: string,
  }).isRequired,
  selectSalesforceIntegrationItem: func.isRequired,
};

export default PriorityAndStatus;
