import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import RecommendationRulesDeleteButton from './RecommendationRulesDeleteButton';

const propTypes = {
  decrementRecommendationRuleCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  recommendationRuleId: PropTypes.number.isRequired,
  recommendationRuleName: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const RecommendationRulesButtons = ({
  decrementRecommendationRuleCount,
  dispatchToasterAction,
  hubId,
  recommendationRuleId,
  recommendationRuleName,
  triggerRefresh,
}) => (
  <>
    <Button
      href={`/recommendation_rules/edit/${hubId}/${recommendationRuleId}`}
      icon="wrench"
      title="Edit Rule"
      label="Edit Rule"
      id={`edit-recommendation-rule-${recommendationRuleId}`}
      className="ufr-edit-button"
    />
    <RecommendationRulesDeleteButton
      recommendationRuleId={recommendationRuleId}
      recommendationRuleName={recommendationRuleName}
      decrementRecommendationRuleCount={decrementRecommendationRuleCount}
      dispatchToasterAction={dispatchToasterAction}
      triggerRefresh={triggerRefresh}
    />
  </>
);

RecommendationRulesButtons.propTypes = propTypes;

export default RecommendationRulesButtons;
