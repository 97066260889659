import PropTypes from 'prop-types';
import React from 'react';

import './datatableThumbnail.less';
import ServiceIcon from '../shared/serviceIcon/ServiceIcon';

const propTypes = {
  hidden: PropTypes.bool,
  imageUrl: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  hidden: true,
  imageUrl: null,
  type: null,
};

const DatatableThumbnail = ({ type, imageUrl, hidden }) => {
  const style = imageUrl
    ? {
        backgroundImage: `url('${imageUrl}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }
    : {
        background: '#fff',
      };

  return (
    <div className={`${hidden ? 'ufr-datatable-thumbnail-hidden' : ''}`}>
      <div className="ufr-datatable-thumbnail" style={style}>
        <ServiceIcon service={type} format="narrow" />
      </div>
    </div>
  );
};

DatatableThumbnail.propTypes = propTypes;
DatatableThumbnail.defaultProps = defaultProps;

export default DatatableThumbnail;
