import PropTypes from 'prop-types';
import React, { Component } from 'react';

const CsrfContext = React.createContext({
  csrfToken: null,
});

export class CsrfProvider extends Component {
  state = {
    csrfToken: null,
  };

  componentDidMount() {
    // FIXME: This is awful, but for now we have to rely on the old cake way of getting this token
    // TODO: replace me with an ajax request, session storage,
    // or something else that doesn't rely on the non-react DOM
    const csrfMeta = document.querySelector('[name="csrf-token"]');

    if (!csrfMeta) {
      return;
    }

    const csrfToken = csrfMeta.content;
    this.setState({
      csrfToken,
    });
  }

  render() {
    const { children } = this.props;

    return <CsrfContext.Provider value={this.state.csrfToken}>{children}</CsrfContext.Provider>;
  }
}

CsrfProvider.propTypes = {
  children: PropTypes.PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export const CsrfContextConsumer = CsrfContext.Consumer;
