import { Dropdown, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import './menu.less';
import handleClick from '../../../utils/handleClick';
import Icon from '../icon/Icon';

// eslint-disable-next-line react/prop-types
const MenuContent = React.memo(({ icon, text }) => (
  <div>
    {icon && <Icon icon={icon} />}
    <span className="menu-text">{text}</span>
  </div>
));

const MenuDropDown = ({ button, items, disabled }) => {
  const renderMenuItems = () =>
    items.map((item) => {
      const { id, icon, text, url, target, onClick, onClickCallback } = item;

      const click = (e) =>
        handleClick({
          e,
          onClick,
          onClickCallback,
        });

      return (
        <MenuItem
          key={id}
          id={`${id}-menu-item`}
          eventKey={id}
          href={url || undefined}
          target={target}
          onClick={url ? undefined : click}
          className={icon ? 'with-icon' : ''}
        >
          <MenuContent {...{ icon, text }} />
        </MenuItem>
      );
    });

  return (
    <Dropdown id={`${button.id}-menu-toggle`} className="react-dropdown" disabled={disabled}>
      <Dropdown.Toggle noCaret>
        <Icon icon={button.icon} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="react-dropdown-menu">{renderMenuItems()}</Dropdown.Menu>
    </Dropdown>
  );
};

MenuDropDown.propTypes = {
  button: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),

  disabled: PropTypes.bool,

  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      // deprecated
      onClickCallback: PropTypes.shape({
        func: PropTypes.string,
        namespace: PropTypes.string,
      }),
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
};

MenuDropDown.defaultProps = {
  button: {
    icon: 'option-horizontal',
    id: 'dropdown',
  },
  disabled: false,
};

export default MenuDropDown;
