const handleInputReady = (submitData, value, cb) => {
  if (typeof submitData === 'function') {
    submitData(value);
  } else if (submitData.func) {
    const { func, namespace } = submitData;

    if (namespace && namespace !== 'window') {
      window[namespace][func](value, cb);
    } else {
      window[func](value, cb);
    }
  }
};

export default handleInputReady;
