import PropTypes from 'prop-types';
import React from 'react';

import './textArea.less';
import handleInputReady from '../../../utils/handleInputReady';
import Input from '../input/Input';
import InputContainer from '../inputContainer/InputContainer';

const propTypes = {
  // should automatically focus on page load (not recommended)
  autoFocus: PropTypes.bool,
  cols: PropTypes.number,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  // required for acceptance tests and accessibility decorators
  id: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  resize: PropTypes.oneOf(['both', 'horizontal', 'vertical', 'none']),
  rows: PropTypes.number,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
  title: PropTypes.string,
  // Add a readonly validation rules to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  autoFocus: false,
  cols: undefined,
  description: '',
  disabled: false,
  handleChange: undefined,
  maxLength: undefined,
  name: '',
  placeholder: undefined,
  readOnly: false,
  resize: 'vertical',
  rows: 5,
  submitData: {},
  title: '',
  validation_rules: {},
  value: '',
};

class TextArea extends React.Component {
  state = {
    errorMessage: '',
    status: '',
  };

  onReady = (value) => {
    const { submitData, name, validation_rules: validationRules, disabled } = this.props;

    const componentData = {
      propertyName: name,
      propertyValue: value,
      validationRules,
    };

    if (!disabled) {
      handleInputReady(submitData, componentData, this.handleCallback);
    }
  };

  handleCallback = (error) => {
    if (error) {
      this.setState({ errorMessage: error, status: 'error' });
    } else {
      // flash success
      this.setState({ errorMessage: '', status: 'success' });
      setTimeout(() => this.setState({ status: '' }), 500);
    }
  };

  render() {
    const { id, name, resize, title, description, disabled, handleChange, value, ...rest } =
      this.props;

    const style = { resize };

    return (
      <InputContainer
        id={id}
        title={title}
        description={description}
        error={this.state.errorMessage}
        disabled={disabled}
      >
        <Input
          id={id}
          name={name}
          type="textarea"
          onReady={this.onReady}
          style={style}
          disabled={disabled}
          validationState={this.state.status}
          handleChange={handleChange}
          value={value}
          {...rest}
        />
      </InputContainer>
    );
  }
}

TextArea.defaultProps = defaultProps;
TextArea.propTypes = propTypes;

export default TextArea;
