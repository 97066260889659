import './topnavLogo.less';
import Logo from './UBERFLIP-2020-LOGO_Logo_White.svg';
import MobileLogo from './UBERFLIP-2020-LOGO_Favicon_White-Circle.svg';
import PropTypes from 'prop-types';
import React from 'react';

const TopnavLogo = ({ url }) => (
  <div className="topnav-logo">
    <a href={url} title="Home">
      <MobileLogo className="topnav-logo-image-mobile" />
      <Logo className="topnav-logo-image-desktop" />
    </a>
  </div>
);

TopnavLogo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default TopnavLogo;
