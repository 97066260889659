import { Dropdown, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import './filterDropdown.less';
import Icon from '../../shared/icon/Icon';

const { func, shape, string, arrayOf, bool } = PropTypes;

const propTypes = {
  disabled: bool.isRequired,
  initialFilterValue: string,
  items: arrayOf(
    shape({
      id: string.isRequired,
      // value the api is expecting for this ?key=value; not required as we need a dummy value in some cases
      queryValue: string,
      text: string.isRequired,
    }),
  ).isRequired,
  onSelect: func.isRequired,
  // key that the api is expecting for this query e.g. ?key=value
  queryKey: string.isRequired,
  toggle: shape({
    id: string.isRequired,
    text: string.isRequired,
  }).isRequired,
};

class FilterDropdown extends React.Component {
  state = {
    toggleText: this.props.toggle.text,
  };

  getFilterText = (items, filter) => items.filter((item) => item.queryValue === filter)[0]['text'];

  getSavedFilterText = () => {
    let filterText;
    const { items, initialFilterValue } = this.props;

    if (initialFilterValue) {
      filterText = this.getFilterText(items, initialFilterValue);
    } else {
      filterText = this.getFilterText(items, null);
    }
    this.setState({ toggleText: filterText });
  };

  componentDidMount() {
    this.getSavedFilterText();
  }

  renderMenuItems = () => {
    const { items, queryKey, onSelect } = this.props;
    return items.map(({ id, text, queryValue }) => {
      const onClick = () => {
        const value = queryValue || '';
        onSelect({ queryKey, queryValue: value });
        this.setState({ toggleText: text });
      };

      return (
        <MenuItem key={id} id={`${id}-filter-dropdown-item`} eventKey={id} onSelect={onClick}>
          {text}
        </MenuItem>
      );
    });
  };

  render() {
    const { disabled } = this.props;
    return (
      <Dropdown
        id={`ufr-dt-${this.props.toggle.id}-filter-dropdown-toggle`}
        className="ufr-dt-filter-dropdown-toggle"
        pullRight
        disabled={disabled}
      >
        <Dropdown.Toggle noCaret>
          {this.state.toggleText}
          <Icon icon="chevron-down" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="ufr-filter-dropdown-menu react-dropdown-menu">
          {this.renderMenuItems()}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

FilterDropdown.propTypes = propTypes;

export default FilterDropdown;
