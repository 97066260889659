import './salesforceModal.less';
import * as React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { CancelButton, Modal, ModalBody, ModalFooter, ModalHeader, PrimaryButton } from '..';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import MapIntegration from './MapIntegration';
import PriorityAndStatus from './PriorityAndStatus';
import SalesforceLogo from '../../../../../html/src/img/static/integrations/salesforce-logo.png';
import ToasterStack, { toastersPropType } from '@/components/toaster/ToasterStack';
import UberflipActivity from './UberflipActivity';
import usePopToast from '@/components/toaster/usePopToast';
import useToasterStack from '@/components/toaster/useToasterStack';

class SalesforceModal extends React.Component {
  state = {
    isSubmitDisabled: false,
    mapErrors: {
      contactIdError: false,
      leadIdError: false,
      mapTypeError: false,
      noMapError: false,
    },
    salesforceSelections: {},
    savedSalesforceSelections: {},
  };

  componentDidMount() {
    this.loadSalesforceSelections();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      mapType: prevMapType,
      leadIdField: prevLeadIdField,
      contactIdField: prevContactIdField,
    } = prevState.salesforceSelections;

    const { mapType, leadIdField, contactIdField } = this.state.salesforceSelections;

    if (mapType !== prevMapType) {
      this.setState({
        mapErrors: {
          ...prevState.mapErrors,
          mapTypeError: !mapType,
        },
      });
    }

    if (leadIdField !== prevLeadIdField || contactIdField !== prevContactIdField) {
      this.setState({
        mapErrors: {
          ...prevState.mapErrors,
          ...(leadIdField !== prevLeadIdField ? { leadIdError: !leadIdField } : null),
          ...(contactIdField !== prevContactIdField ? { contactIdError: !contactIdField } : null),
        },
      });
    }

    if (!this.state.mapErrors.noMapError && !this.props.maps?.length) {
      this.setState({
        mapErrors: {
          noMapError: true,
        },
      });
    }
  }

  immutableState = (item) => Object.assign({}, this.state.salesforceSelections, item);

  selectSalesforceIntegrationItem = (item) => {
    this.setState({ salesforceSelections: this.immutableState(item) });
  };

  handleDisableSubmit = (disabled) => {
    this.setState({ isSubmitDisabled: disabled });
  };

  async loadSalesforceSelections() {
    // eslint-disable-next-line no-useless-catch
    try {
      const {
        data: { response },
      } = await axios.get('/integrations/ajax_maps/salesforce-integrations', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      const {
        mapType,
        leadIdField,
        contactIdField,
        flipbookDefaultPriority,
        flipbookDefaultStatus,
        ctaDefaultPriority,
        ctaDefaultStatus,
        pageViewDefaultPriority,
        pageViewDefaultStatus,
        useCustomFields,
      } = response || {};

      const salesforceSelections = {
        contactIdField,
        ctaDefaultPriority,
        ctaDefaultStatus,
        flipbookDefaultPriority,
        flipbookDefaultStatus,
        leadIdField,
        mapType,
        pageViewDefaultPriority,
        pageViewDefaultStatus,
        useCustomFields,
      };

      this.setState({
        mapErrors: {
          contactIdError: !contactIdField,
          leadIdError: !leadIdField,
          mapTypeError: !mapType,
          noMapError: !this.props.maps?.length,
        },
        salesforceSelections,
        savedSalesforceSelections: salesforceSelections,
      });
      /* eslint-enable camelcase */
    } catch (e) {
      // TODO better error handling
      throw e;
    }
  }

  handleSubmit = async (csrfToken, accountId, salesforceSelections) => {
    const { handleClose, popToast } = this.props;

    // setting it to 1 min to make sure the popup is visible while we are checking that fields are visible in profiles and layouts
    popToast('info', 'Updating...', 'salesforce-save-progress', 100000);
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'put',
        url: '/integrations/ajax_maps/salesforce-integrations',
        headers: { 'X-CSRF-TOKEN': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
        data: Object.assign({}, salesforceSelections, { accountId: parseInt(accountId, 10) }),
      });
      /* eslint-enable sort-keys */
      this.setState({ savedSalesforceSelections: salesforceSelections });
      popToast('success', 'Updated', 'salesforce-save-progress', 2000);
      handleClose();
    } catch (e) {
      popToast('error', 'Failed to update', 'salesforce-save-progress', 5000);
      // TODO better error handling
      throw e;
    }
  };

  resetAndClose = () => {
    const { handleClose } = this.props;
    this.setState(({ savedSalesforceSelections }) => ({
      salesforceSelections: savedSalesforceSelections,
    }));
    handleClose();
  };

  render() {
    const {
      selectSalesforceIntegrationItem,
      handleSubmit,
      handleDisableSubmit,
      resetAndClose,
      props: { accountId, isOpen, isLoading, maps, toasterStack, dispatchToasterAction },
      state: {
        isSubmitDisabled,
        salesforceSelections,
        salesforceSelections: { mapType, contactIdField, leadIdField, useCustomFields },
        mapErrors,
        mapErrors: { mapTypeError, leadIdError, contactIdError, noMapError },
      },
    } = this;

    const disabledSubmit =
      mapTypeError || (leadIdError && contactIdError) || isSubmitDisabled || noMapError;

    return (
      <CsrfProvider>
        <Modal isOpen={isOpen} isLoading={isLoading} className="salesforce-edit-modal">
          <ModalHeader title="Edit Salesforce Settings" handleClose={resetAndClose} />
          <ModalBody isLoading={isLoading}>
            <img src={SalesforceLogo} alt="Salesforce Logo" />
            <div className="sfi-fields">
              <PriorityAndStatus
                handleDisableSubmit={handleDisableSubmit}
                salesforceSelections={salesforceSelections}
                selectSalesforceIntegrationItem={selectSalesforceIntegrationItem}
              />
              <MapIntegration
                maps={maps}
                mapType={mapType}
                mapErrors={mapErrors}
                contactIdField={contactIdField}
                leadIdField={leadIdField}
                handleDisableSubmit={handleDisableSubmit}
                selectSalesforceIntegrationItem={selectSalesforceIntegrationItem}
              />
              <UberflipActivity
                useCustomFields={useCustomFields}
                selectSalesforceIntegrationItem={selectSalesforceIntegrationItem}
              />
            </div>
          </ModalBody>
          <ModalFooter isLoading={isLoading}>
            <CancelButton onClick={resetAndClose} />
            <CsrfContextConsumer>
              {(csrfToken) => (
                <PrimaryButton
                  disabled={disabledSubmit}
                  onClick={() => {
                    handleSubmit(csrfToken, accountId, salesforceSelections);
                  }}
                  label="Save"
                >
                  Save
                </PrimaryButton>
              )}
            </CsrfContextConsumer>
          </ModalFooter>
        </Modal>

        <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
      </CsrfProvider>
    );
  }
}

SalesforceModal.defaultProps = {
  dispatchToasterAction: () => {},
  isLoading: false,
  popToast: () => {},
  toasterStack: [],
};

SalesforceModal.propTypes = {
  accountId: string.isRequired,
  customFieldsBetaFlag: bool.isRequired,
  dispatchToasterAction: func,
  handleClose: func.isRequired,
  isLoading: bool,
  isOpen: bool.isRequired,
  maps: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ).isRequired,
  popToast: func,
  toasterStack: toastersPropType,
};

function withToast(Component) {
  return function WrappedComponent(props) {
    const [toasterStack, dispatchToasterAction] = useToasterStack();
    const popToast = usePopToast(dispatchToasterAction);

    return (
      <Component
        {...props}
        toasterStack={toasterStack}
        dispathToasterAction={dispatchToasterAction}
        popToast={popToast}
      />
    );
  };
}

export default withToast(SalesforceModal);
