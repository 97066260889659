import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import ToggleSwitch from '@/components/formInputs/toggle/ToggleSwitch';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  enabled: PropTypes.bool.isRequired,
  redirectRuleId: PropTypes.number.isRequired,
};

const RedirectRulesEnabled = ({ enabled, redirectRuleId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const toggleEnabled = async (enabled, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: `/redirect_rules/ajax_updateEnabled/${redirectRuleId}`,
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: new URLSearchParams({
          enabled,
        }).toString(),
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: redirectRuleId,
          text: `Redirect Rule has been ${enabled ? 'enabled' : 'disabled'}.`,
          type: 'success',
        }),
      );
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: redirectRuleId,
          text: 'Cannot update Redirect Rule.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: redirectRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <ToggleSwitch
            id={`redirect-rule-${redirectRuleId}-enabled`}
            name={`${redirectRuleId}`}
            submitData={({ propertyValue: enabled }) => toggleEnabled(enabled, csrfToken)}
            value={enabled}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

RedirectRulesEnabled.propTypes = propTypes;

export default RedirectRulesEnabled;
