import './tagsListingDatatable.less';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import handleClick from '@/utils/handleClick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TagModal from './modals/TagModal';
import TagsListingButtons from './TagsListingButtons';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const DEFAULT_TAG_MODAL_STATE = {
  id: null,
  initialData: {},
  isOpen: false,
};

const propTypes = {
  hubId: PropTypes.number.isRequired,
  tagGroupListingDatatableRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  tags: PropTypes.array.isRequired,
  tagsListingDatatableRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

const entityName = {
  plural: 'Tags',
  singular: 'Tag',
};

const cellClass = (key) => `ufr-dt-tags-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-tags-listing-${key}-header`;

const searchPlaceholder = () => `Search Tags`;

const filterDropdowns = () => [];

const columns = (handleTagEdit, handleTagDelete) => [
  {
    accessor: 'name',
    Cell: (cell) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-tag-name-tooltip" placement="top">
            {cell.original.name}
          </Tooltip>
        }
      >
        <div className="ufr-tag-name">
          <a
            href="javascript:void(0)"
            onClick={(e) => handleClick({ e, onClick: () => handleTagEdit(cell.original) })}
          >
            {cell.original.name}
          </a>
        </div>
      </OverlayTrigger>
    ),
    className: cellClass('tag-name'),
    Header: 'Tag Name',
    id: 'name',
  },
  {
    accessor: 'tag_group_name',
    Cell: (cell) => (
      <>{cell.original.tag_group_name === 'CONTENT' ? 'None' : cell.original.tag_group_name}</>
    ),
    Header: 'In Tag Group',
    id: 'tag_group_name',
  },
  {
    accessor: 'items_count',
    className: cellClass('tag-items-count'),
    Header: 'Items Applied',
    id: 'items_count',
  },
  {
    accessor: 'modified_at',
    Cell: (cell) => <>{getHumanReadableDate(cell.original.modified_at)}</>,
    Header: 'Last Used',
    id: 'modified_at',
  },
  {
    accessor: 'controls',
    Cell: (cell) => (
      <TagsListingButtons
        tag={cell.original}
        triggerEdit={() => handleTagEdit(cell.original)}
        triggerDelete={() => handleTagDelete(cell.original)}
      />
    ),
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    minWidth: 130,
    sortable: false,
  },
];

const newAddTagButton = (handleAddTagClick) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: false,
  id: 'add-tag',
  onClick: handleAddTagClick,
  text: 'Add Tag',
});

const getEndpointBuilder = () => ({
  createTag: () => '/api/v2/tags',
  deleteTag: (id) => `/api/v2/tags/${id}`,
  getTagGroup: (id) => `/api/v2/tag-groups/${id}`,
  getTags: () => `/api/v2/tags`,
  updateTag: (tagId) => `/api/v2/tags/${tagId}`,
});

/**
 * Generates a toaster
 * @param {*} idPrefix - prefix for toaster id
 * @param {*} type - toaster type (success,error,etc.)
 * @param {*} text - text for toaster to display
 * @returns
 */
const getToaster = (idPrefix, type, text) => ({
  id: `${idPrefix}-toaster-${type}`,
  text,
  type,
});

const TagsListingDatatable = ({
  hubId,
  tags,
  tagsListingDatatableRef,
  tagGroupListingDatatableRef,
}) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [tagModalState, setTagModalState] = useState(DEFAULT_TAG_MODAL_STATE);

  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const { createTag, deleteTag, getTags, getTagGroup, updateTag } = getEndpointBuilder();

  if (isInitialLoad) {
    setIsInitialLoad(false);
    setIsSearchDisabled(tags && tags.length ? false : true);
  }

  const dropdownMenuItems = () => [];

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getTags(hubId), {
      params: queries,
    });

    // fetch all related tagGroups
    const tagGroups = {};
    for (const tag of data) {
      const hasTagGroupId = 'tag_group_id' in tag;
      const isCached = tag.tag_group_id in tagGroups;

      if (hasTagGroupId) {
        if (!isCached) {
          const tagGroupResponse = await axios.get(getTagGroup(tag.tag_group_id));

          tagGroups[tag.tag_group_id] = tagGroupResponse.data;
        }
        tag['tag_group_name'] = tagGroups[tag.tag_group_id].name;
      }
    }

    return { data, meta };
  };

  const handleSubmitTagModal =
    (csrfToken) =>
    async ({ name, description }) => {
      const { id } = tagModalState;

      const toaster = await axios({
        data: {
          description,
          name,
        },
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        },
        method: id ? 'PATCH' : 'POST',
        url: id ? updateTag(id) : createTag(),
      })
        .then(() =>
          getToaster('add-tag', 'success', `Tag successfully ${id ? 'updated' : 'added'}`),
        )
        .catch((error) =>
          getToaster('add-tag', 'error', `Error: ${error.response.data.errors[0].message}`),
        );

      dispatchToasterAction(addToaster(toaster));

      setTimeout(() => {
        dispatchToasterAction(slideDownAndRemoveToaster(toaster));
      }, 2000);

      if (toaster.type === 'success') {
        setTagModalState({ ...tagModalState, isOpen: false });
        tagsListingDatatableRef.current.refreshTable();
        tagGroupListingDatatableRef.current.refreshTable();
      }
    };

  const handleAddTag = () => {
    // reset tagModalState and open modal
    // defaults to adding a new tag
    setTagModalState({ ...DEFAULT_TAG_MODAL_STATE, isOpen: true });
  };

  const handleEditTag = (tag) => {
    const { id, name, description } = tag;

    setTagModalState({
      id,
      initialData: {
        description,
        name,
      },
      isOpen: true,
    });
  };

  const handleDeleteTag = (csrfToken) => async (tag) => {
    const toaster = await axios({
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
      method: 'DELETE',
      url: deleteTag(tag.id),
    })
      .then(() => getToaster('delete-tag', 'success', 'Tag successfully deleted'))
      .catch((error) =>
        getToaster('delete-tag', 'error', `Error: ${error.response.data.errors[0].message}`),
      );

    dispatchToasterAction(addToaster(toaster));

    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);

    if (toaster.type === 'success') {
      setTagModalState({ ...tagModalState, isOpen: false });
      tagsListingDatatableRef.current.refreshTable();
      tagGroupListingDatatableRef.current.refreshTable();
    }
  };

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <DataTable
              ref={tagsListingDatatableRef}
              id="tag-tags"
              useStateHandling
              entityName={entityName}
              actionButtons={[newAddTagButton(handleAddTag)]}
              filterDropdowns={filterDropdowns()}
              searchPlaceholder={searchPlaceholder()}
              columns={columns(handleEditTag, handleDeleteTag(csrfToken))}
              getData={getData}
              initialSort={{ desc: true, id: 'id' }}
              showLoadingState
              noSearchResultsMessage={
                <p>
                  {isSearchDisabled
                    ? 'There are no tags in this Hub yet.'
                    : 'No tags in all hubs match your search.'}
                </p>
              }
              hidePagination={isSearchDisabled}
              dropdownMenuItems={dropdownMenuItems()}
              disableSearch={isSearchDisabled}
            />
            <TagModal
              initialData={tagModalState.initialData}
              title={tagModalState.id ? 'Edit Tag' : 'Add Tag'}
              isOpen={tagModalState.isOpen}
              onSubmit={handleSubmitTagModal(csrfToken)}
              onClose={() => setTagModalState({ ...tagModalState, isOpen: false })}
            />
            <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
          </>
        )}
      </CsrfContextConsumer>
    </CsrfProvider>
  );
};

TagsListingDatatable.propTypes = propTypes;

export default TagsListingDatatable;
