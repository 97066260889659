import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './imagePreview.less';
import Button from '../shared/button/Button';

const ImagePreview = ({ id, img, handleDeleteClick, disabled = false }) => {
  const className = ['ufr-image-preview', { 'ufr-input-container--disabled': disabled }];

  return (
    <div aria-disabled={disabled} className={classNames(className)}>
      <img className="image-logo" src={img} alt="" />

      <Button
        className={classNames('ufr-image-delete-btn', {
          'ufr-input-component--disabled': disabled,
        })}
        id={id}
        icon="bin"
        label="delete image"
        ufrStyle="secondary"
        onClick={disabled ? null : handleDeleteClick}
        disabled={disabled}
      />
    </div>
  );
};

ImagePreview.defaultProps = {
  disabled: false,
};

ImagePreview.propTypes = {
  disabled: PropTypes.bool,
  handleDeleteClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default ImagePreview;
