import axios from 'axios/index';
import PropTypes from 'prop-types';
import React from 'react';

import './enrichmentSourcesDatatable.less';
import { CsrfProvider } from '../../../DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../shared/button/Button';
import DataTable from '../../datatable/DataTable';
import ServiceIcon from '../../shared/serviceIcon/ServiceIcon';
import ToasterStack from '../../toaster/ToasterStack';
import usePopToast from '../../toaster/usePopToast';
import useToasterStack from '../../toaster/useToasterStack';

const getEndpointBuilder = (accountId) => ({
  getEnrichmentIntegrations: () => `/api/v2/accounts/${accountId}/enrichment-integrations`,
});

const entityName = {
  plural: 'Enrichment Sources',
  singular: 'Enrichment Source',
};

const cellClass = (key) => `ufr-dt-enrichment-sources-${key}-cell`;
const headerClass = (key) => `ufr-dt-enrichment-sources-${key}-header`;
const renderSettingsActivityButton = (row) => {
  if (row.readonly) {
    return (
      <>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="permission" placement="top">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Your user account doesn't allow updating Enrichment integrations.
            </Tooltip>
          }
        >
          <span>
            <Button icon="wrench" id="view" label="Configure Marketo Enrichment" disabled />
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="permission" placement="top">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Your user account doesn't allow viewing Enrichment import activity.
            </Tooltip>
          }
        >
          <span>
            <Button icon="history" id="import-activity" label="View Import Activity" disabled />
          </span>
        </OverlayTrigger>
      </>
    );
  }
  return (
    <>
      <Button
        icon="wrench"
        id="view"
        label="Configure Marketo Enrichment"
        href={`/enrichment/integrations/${row.original.id}`}
      />
      <Button
        icon="history"
        id="import-activity"
        label="View Import Activity"
        href={`/enrichment/integration/import-activity/${row.original.id}`}
      />
    </>
  );
};

/* eslint-disable sort-keys */
const columns = [
  {
    Header: 'Data Source',
    accessor: 'name',
    id: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    Cell: (row) => [
      <ServiceIcon service={row.original.icon} format="small" />,
      <div className="ufr-dt-enrichment-sources-name">{row.original.name}</div>,
    ],
    sortable: false,
  },
  {
    Header: 'Data Import',
    accessor: 'status',
    id: 'status',
    className: cellClass('status'),
    headerClassName: headerClass('status'),
    Cell: ({ value }) => (value ? 'Enabled' : 'Disabled'),
    sortable: false,
  },
  {
    Header: 'Imported Records',
    accessor: 'enrichedVisitors',
    id: 'enriched-visitors',
    className: cellClass('enriched-visitors'),
    headerClassName: headerClass('enriched-visitors'),
    sortable: false,
  },
  {
    Header: 'Source Fields Mapped',
    accessor: 'fields',
    id: 'fields',
    className: cellClass('fields'),
    headerClassName: headerClass('fields'),
    Cell: ({ value }) => `${value.mapped}/${value.total}`,
    sortable: false,
  },
  {
    Header: '',
    accessor: 'integration',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    Cell: (row) => renderSettingsActivityButton(row),
    sortable: false,
  },
];
/* eslint-enable sort-keys */

const EnrichmentSourcesDatatable = ({ accountId, hideControlBar, hidePagination, readonly }) => {
  const { getEnrichmentIntegrations } = getEndpointBuilder(accountId);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);

  const getTableData = async () => {
    const {
      data: { data, meta },
    } = await axios.get(getEnrichmentIntegrations());

    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        entityName={entityName}
        id="enrichment-sources"
        useStateHandling
        showLoadingState
        columns={columns}
        getData={getTableData}
        readonly={readonly}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({
          popToast,
        })}
        hideControlBar={hideControlBar}
        hidePagination={hidePagination}
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

EnrichmentSourcesDatatable.propTypes = {
  accountId: PropTypes.number.isRequired,
  hideControlBar: PropTypes.bool,
  hidePagination: PropTypes.bool,
  readonly: PropTypes.bool,
};

EnrichmentSourcesDatatable.defaultProps = {
  hideControlBar: true,
  hidePagination: true,
  readonly: false,
};

export default EnrichmentSourcesDatatable;
