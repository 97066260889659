import './recommendationTemplatesDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddRecommendationTemplateModal from './AddRecommendationTemplateModal';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RecommendationTemplateButtons from './RecommendationTemplatesButtons';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hubId: PropTypes.number.isRequired,
};

const cellClass = (key) => `ufr-dt-recommendation-templates-${key}-cell`;
const headerClass = (key) => `ufr-dt-recommendation-templates-${key}-header`;

const entityName = {
  plural: 'Templates',
  singular: 'Template',
};

const createRecommendationTemplateActionButton = (setIsAddTemplateModalOpen) => ({
  className: 'ufr-btn ufr-btn-primary',
  id: 'create-recommendation-template',
  onClick: () => setIsAddTemplateModalOpen(true),
  text: 'Build Template',
});

const searchPlaceholder = (recommendationTemplateCount) =>
  `Search ${recommendationTemplateCount} ${
    entityName[recommendationTemplateCount === 1 ? 'singular' : 'plural']
  }`;

/* eslint-disable sort-keys */
const columns = (dispatchToasterAction) => [
  {
    Header: 'Template Name',
    accessor: 'name',
    id: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    minWidth: 150,
    Cell: (cell) =>
      cell.original.name ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-template-name-tooltip" placement="top">
              {cell.original.name}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.name}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    minWidth: 300,
    Cell: (cell) =>
      cell.original.description ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-template-description-tooltip" placement="top">
              {cell.original.description}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.description}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
    sortable: false,
  },
  {
    Header: 'Rule Count',
    accessor: 'rule_count',
    className: cellClass('rule-count'),
    headerClassName: headerClass('rule-count'),
    width: 100,
    Cell: (cell) => <>{cell.original.rule_count}</>,
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    width: 100,
    Cell: (cell) => (
      <RecommendationTemplateButtons
        hubId={cell.original.hub_id}
        lockDelete={!!cell.original.lock_delete}
        recommendationTemplateId={cell.original.id}
        recommendationTemplateName={cell.original.name}
        triggerRefresh={cell.refresh}
        decrementRecommendationTemplateCount={cell.decrementRecommendationTemplateCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ),
  },
];
/* eslint-enable sort-keys */

const getEndpointBuilder = (hubId) => ({
  getRecommendationTemplates: () => `/api/v2/hubs/${hubId}/recommendation-templates`,
});

const RecommendationTemplatesDatatable = ({ hubId }) => {
  const [recommendationTemplateCount, setRecommendationTemplateCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);

  const { getRecommendationTemplates } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getRecommendationTemplates(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setRecommendationTemplateCount(meta.count);
    }
    return { data, meta };
  };

  const modals = () => [
    <AddRecommendationTemplateModal
      hubId={hubId}
      isAddTemplateModalOpen={isAddTemplateModalOpen}
      handleClose={() => setIsAddTemplateModalOpen(false)}
    />,
  ];

  return (
    <CsrfProvider>
      <DataTable
        id="recommendation-templates"
        useStateHandling
        entityName={entityName}
        columns={columns(dispatchToasterAction)}
        getData={getData}
        actionButtons={[createRecommendationTemplateActionButton(setIsAddTemplateModalOpen)]}
        searchPlaceholder={searchPlaceholder(recommendationTemplateCount)}
        noSearchResultsMessage={<p>{isInitialLoad ? ' ' : 'No templates match your search.'}</p>}
        initialSort={{ desc: false, id: 'name' }}
        showLoadingState={isInitialLoad}
        getCellProps={() => ({
          decrementRecommendationTemplateCount: () => {
            setRecommendationTemplateCount(recommendationTemplateCount - 1);
          },
        })}
        Modals={modals}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

RecommendationTemplatesDatatable.propTypes = propTypes;

export default RecommendationTemplatesDatatable;
