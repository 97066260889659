import Icon from '../shared/icon/Icon';
import React from 'react';

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return () => <Icon icon="ok" color="#468847" />;
    case 'error':
    case 'failure':
      return () => <Icon icon="remove" color="#b94a48" />;
    case 'info':
      return () => <Icon icon="info-sign" color="#2f96b4" />;
    case 'refresh':
      return () => <Icon icon="refresh" color="#2f96b4" />;
    default:
      throw new TypeError(`${type} is not a valid toaster type!`);
  }
};

export default getIcon;
