import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  modalData: PropTypes.exact({
    description: PropTypes.string,
    passphrase: PropTypes.string,
  }).isRequired,
  passphraseId: PropTypes.number.isRequired,
  renderDeletePassphraseModal: PropTypes.func.isRequired,
};

const PassphrasesDeleteButton = ({ modalData, passphraseId, renderDeletePassphraseModal }) => (
  <>
    <>
      <Button
        icon="bin"
        title="Delete Passphrase"
        label="Delete Passphrase"
        id={`delete-passphrase-${passphraseId}`}
        className="ufr-delete-button"
        onClick={() => renderDeletePassphraseModal(passphraseId, modalData)}
      />
    </>
  </>
);
PassphrasesDeleteButton.propTypes = propTypes;

export default PassphrasesDeleteButton;
