import './functionalityListingPrivacyGroupSelect.less';
import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const propTypes = {
  apiEntityName: PropTypes.string.isRequired,
  dispatchToasterAction: PropTypes.func.isRequired,
  functionalityId: PropTypes.number.isRequired,
  functionalityType: PropTypes.string.isRequired,
  hubId: PropTypes.number.isRequired,
  privacyGroups: PropTypes.array.isRequired,
  selectedPrivacyGroup: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
  selectedPrivacyGroupId: PropTypes.number,
  setSelectedPrivacyGroup: PropTypes.func.isRequired,
};

const FunctionalityListingPrivacyGroupSelect = ({
  apiEntityName,
  hubId,
  functionalityId,
  functionalityType,
  privacyGroups,
  dispatchToasterAction,
  setSelectedPrivacyGroup,
  selectedPrivacyGroup,
  selectedPrivacyGroupId,
}) => {
  const getGroupName = () => {
    const group = privacyGroups.find(({ id }) => id === selectedPrivacyGroupId);
    return group ? group.name : null;
  };

  // The default option has the id 0 and we check if it needs to be disabled in privacyGroupsSelectOptions
  const isOptionDisabled = (id, selectedId) =>
    (!selectedPrivacyGroup?.value && selectedPrivacyGroup?.value !== 0 && id === selectedId) ||
    selectedPrivacyGroup?.value === id;

  const groupExists = () =>
    privacyGroups.some(
      (group) => group.id === selectedPrivacyGroup?.value || group.id === selectedPrivacyGroupId,
    );

  const getSelectedPrivacyGroup = () => {
    let selectedGroup;

    if (groupExists()) {
      selectedGroup = {
        label: selectedPrivacyGroup?.label || getGroupName(),
        value: selectedPrivacyGroupId || selectedPrivacyGroup?.value,
      };
    } else {
      selectedGroup = { label: 'No Group Selected', value: 0 };
    }
    return selectedGroup;
  };

  const privacyGroupsOptions = privacyGroups.map((privacyGroup) => ({
    disabled: isOptionDisabled(privacyGroup.id, selectedPrivacyGroupId),
    label: privacyGroup.name,
    value: privacyGroup.id,
  }));

  const privacyGroupsSelectOptions = [
    {
      disabled:
        selectedPrivacyGroup?.value === 0 ||
        !groupExists() ||
        (!selectedPrivacyGroupId && !selectedPrivacyGroup?.value),
      label: 'No Group Selected',
      value: 0,
    },
    ...privacyGroupsOptions,
  ];

  const handleSelectValue = async (csrfToken, functionalityId, selectedGroup) => {
    const privacyGroupId = selectedGroup.value;
    const privacyGroupName = selectedGroup.label;
    const unlinking = privacyGroupId === 0;
    const httpVerb = unlinking ? 'delete' : 'post';
    const groupId = unlinking
      ? selectedPrivacyGroup?.value || selectedPrivacyGroupId
      : privacyGroupId;
    try {
      await axios({
        headers: { 'X-CSRF-TOKEN': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
        method: httpVerb,
        url: `/api/v2/hubs/${hubId}/privacy-groups/${groupId}/${apiEntityName}/${functionalityId}`,
      });
      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text:
            httpVerb === 'post'
              ? 'Functionality Successfully Linked'
              : 'Group Unlinked Successfully',
          type: 'success',
        }),
      );
      setSelectedPrivacyGroup(privacyGroupName, privacyGroupId);
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text: 'Something went wrong. Please try again.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: privacyGroupId }));
    }, 2000);
  };

  const formatOptionLabel = ({ value, label }) => <div data-group-id={value}>{label}</div>;

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <Select
          id={`ufr-privacy-groups-select-${functionalityType}-${functionalityId}`}
          onChange={(selectedGroup) => handleSelectValue(csrfToken, functionalityId, selectedGroup)}
          options={privacyGroupsSelectOptions}
          isOptionDisabled={(option) => option.disabled === true}
          value={getSelectedPrivacyGroup()}
          isSearchable={false}
          className="ufr-privacy-group-select"
          classNamePrefix="ufr-privacy-group-select-dropdown"
          formatOptionLabel={formatOptionLabel}
        />
      )}
    </CsrfContextConsumer>
  );
};

FunctionalityListingPrivacyGroupSelect.propTypes = propTypes;

export default FunctionalityListingPrivacyGroupSelect;
