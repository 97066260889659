import './customCodeListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddCustomCodeModal from './AddCustomCodeModal';
import axios from 'axios';
import CustomCodeListingButtons from './CustomCodeListingButtons';
import CustomCodeListingEnabled from './CustomCodeListingEnabled';
import CustomCodeListingOrder from './CustomCodeListingOrder';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  canCreateCustomCode: PropTypes.bool.isRequired,
  canDeleteCustomCode: PropTypes.bool.isRequired,
  canEditCustomCode: PropTypes.bool.isRequired,
  canEnableCustomCode: PropTypes.bool.isRequired,
  canViewCustomCode: PropTypes.bool.isRequired,
  getEndpointBuilder: PropTypes.shape({
    customCodeEditLink: PropTypes.func,
    customCodesIndex: PropTypes.func,
    sharedCustomCodeEndpoint: PropTypes.func,
  }).isRequired,
  scope: PropTypes.string.isRequired,
};

const cellClass = (key) => `ufr-dt-custom-code-${key}-cell`;
const headerClass = (key) => `ufr-dt-custom-code-${key}-header`;

const entityName = {
  plural: 'Custom Codes',
  singular: 'Custom Code',
};

const addCustomCodeActionButton = (setIsAddCodeModalOpen, canCreateCustomCode) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: !canCreateCustomCode,
  id: 'create-custom-code',
  onClick: () => setIsAddCodeModalOpen(true),
  text: 'Add Code',
});

const searchPlaceholder = (customCodeCount) =>
  `Search ${customCodeCount} ${entityName[customCodeCount === 1 ? 'singular' : 'plural']}`;

/* eslint-disable sort-keys,react/prop-types */
const columns = (
  canEditCustomCode,
  canDeleteCustomCode,
  canEnableCustomCode,
  canViewCustomCode,
  sharedCustomCodeEndpoint,
  customCodeEditLink,
  dispatchToasterAction,
) => [
  {
    id: 'ordinal',
    Header: 'Order',
    accessor: 'ordinal',
    className: cellClass('order'),
    headerClassName: headerClass('order'),
    minWidth: 60,
    Cell: (cell) => (
      <CustomCodeListingOrder
        getUpdateOrderEndpoint={sharedCustomCodeEndpoint}
        key={`custom-code-${cell.original.id}-order`}
        ordinal={cell.original.ordinal}
        customCodeId={cell.original.id}
        hubId={cell.original.hub_id}
        triggerRefresh={cell.refresh}
        canEditCustomCode={canEditCustomCode}
        dispatchToasterAction={dispatchToasterAction}
      />
    ),
  },
  {
    id: 'name',
    Header: 'Code Name',
    accessor: 'name',
    className: cellClass('code-name'),
    headerClassName: headerClass('code-name'),
    minWidth: 180,
    Cell: ({ original }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-custom-code-name-tooltip" placement="top">
            {original.name}
          </Tooltip>
        }
      >
        <div className="ufr-custom-code-name">{original.name}</div>
      </OverlayTrigger>
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    sortable: false,
    minWidth: 180,
    Cell: ({ original }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-custom-code-description-tooltip" placement="top">
            {original.description}
          </Tooltip>
        }
      >
        <div className="ufr-custom-code-description">{original.description}</div>
      </OverlayTrigger>
    ),
  },
  {
    id: 'placement_name',
    Header: 'Placement',
    accessor: 'placement_name',
    className: cellClass('placement'),
    headerClassName: headerClass('placement'),
    minWidth: 170,
    Cell: ({ original }) => original?.placement?.name || 'None',
  },
  {
    id: 'privacy_group_name',
    Header: 'Privacy Group',
    accessor: 'privacy_group_name',
    className: cellClass('privacy-group'),
    headerClassName: headerClass('privacy-group'),
    minWidth: 220,
    Cell: ({ original }) =>
      original?.privacy_group?.name ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-custom-code-privacy-name-tooltip" placement="top">
              {original.privacy_group.name}
            </Tooltip>
          }
        >
          <div className="ufr-custom-code-privacy-name">{original.privacy_group.name}</div>
        </OverlayTrigger>
      ) : (
        'None'
      ),
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    className: cellClass('status'),
    headerClassName: headerClass('status'),
    sortable: false,
    minWidth: 60,
    Cell: ({ original }) => (
      <CustomCodeListingEnabled
        hubId={original.hub_id}
        enabled={!!original.enabled}
        customCodeId={original.id}
        canEnableCustomCode={canEnableCustomCode}
        getEnableCustomCodeEndpoint={sharedCustomCodeEndpoint}
      />
    ),
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    Cell: (cell) => (
      <CustomCodeListingButtons
        customCodeEditLink={customCodeEditLink}
        getDeleteCustomCodeEndpoint={sharedCustomCodeEndpoint}
        canDeleteCustomCode={canDeleteCustomCode}
        canViewCustomCode={canViewCustomCode}
        customCodeId={cell.original.id}
        customCodeName={cell.original.name}
        triggerRefresh={cell.refresh}
        dispatchToasterAction={dispatchToasterAction}
        decrementCustomCodeCount={cell.decrementCustomCodeCount}
      />
    ),
  },
];
/* eslint-enable sort-keys,react/prop-types */

const CustomCodeListingDatatable = ({
  getEndpointBuilder,
  canCreateCustomCode,
  canEditCustomCode,
  canDeleteCustomCode,
  canEnableCustomCode,
  canViewCustomCode,
  scope,
}) => {
  const [customCodeCount, setCustomCodeCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isAddCodeModalOpen, setIsAddCodeModalOpen] = useState(false);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const { sharedCustomCodeEndpoint, customCodeEditLink, customCodesIndex } = getEndpointBuilder;

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(customCodesIndex(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setCustomCodeCount(meta.count);
    }
    return { data, meta };
  };

  const modals = () => [
    <AddCustomCodeModal
      getAddCustomCodeEndpoint={customCodesIndex}
      customCodeEditLink={customCodeEditLink}
      isAddCodeModalOpen={isAddCodeModalOpen}
      handleClose={() => setIsAddCodeModalOpen(false)}
      scope={scope}
    />,
  ];

  return (
    <CsrfProvider>
      <DataTable
        id="custom-code"
        useStateHandling
        entityName={entityName}
        columns={columns(
          canEditCustomCode,
          canDeleteCustomCode,
          canEnableCustomCode,
          canViewCustomCode,
          sharedCustomCodeEndpoint,
          customCodeEditLink,
          dispatchToasterAction,
        )}
        getData={getData}
        actionButtons={[addCustomCodeActionButton(setIsAddCodeModalOpen, canCreateCustomCode)]}
        searchPlaceholder={searchPlaceholder(customCodeCount)}
        noSearchResultsMessage={<p>{isInitialLoad ? ' ' : 'No custom codes match your search.'}</p>}
        initialSort={{ desc: false, id: 'ordinal' }}
        showLoadingState={isInitialLoad}
        getCellProps={() => ({
          decrementCustomCodeCount: () => {
            setCustomCodeCount(customCodeCount - 1);
          },
        })}
        Modals={modals}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

CustomCodeListingDatatable.propTypes = propTypes;

export default CustomCodeListingDatatable;
