import '../installedAppsListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
/* eslint-disable sort-keys */
const propTypes = {
  hubId: PropTypes.number.isRequired,
};

const entityName = {
  singular: 'Installed App',
  plural: 'Installed Apps that are Configurable at Hub Level',
};

const searchPlaceholder = (appsCount) =>
  `Search ${appsCount} ${appsCount === 1 ? entityName.singular : entityName.plural} in this Hub`;

const cellClass = (key) => `ufr-dt-installed-apps-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-installed-apps-listing-${key}-header`;

/* eslint-disable react/prop-types */
const columns = (hubId) => [
  {
    Header: '',
    accessor: 'image',
    className: cellClass('image'),
    headerClassName: headerClass('image'),
    Cell: ({ original }) => {
      const style = {
        backgroundImage: `url(${original.app.icon_url})`,
      };
      return <div className="ufr-app-image" style={style}></div>;
    },
    minWidth: 70,
    sortable: false,
  },
  {
    id: 'name',
    Header: 'App Name',
    accessor: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    Cell: ({ original }) => original.app.name,
    minWidth: 400,
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    Cell: ({ original }) => original.app.description,
    minWidth: 400,
    sortable: false,
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    Cell: ({ original }) => (
      <Button
        href={`/hubs/app_configuration/${hubId}/${original.id}`}
        icon="pencil"
        id={`edit-app-${original.id}`}
        label="edit app"
      />
    ),
    minWidth: 185,
    sortable: false,
  },
];
/* eslint-enable react/prop-types */

const getEndpointBuilder = () => ({
  getApps: () => '/api/v2/installed-apps?scope=hub',
});

const HubInstalledAppsListingDatatable = ({ hubId }) => {
  const [appsCount, setAppsCount] = useState(0);
  const { getApps } = getEndpointBuilder();

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getApps(), {
      params: queries,
    });
    setAppsCount(meta.count);
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="installed-apps"
        useStateHandling
        entityName={entityName}
        searchPlaceholder={searchPlaceholder(appsCount)}
        columns={columns(hubId)}
        getData={getData}
        initialSort={{ id: 'name', desc: false }}
        showLoadingState
      />
    </CsrfProvider>
  );
};

HubInstalledAppsListingDatatable.propTypes = propTypes;

export default HubInstalledAppsListingDatatable;
