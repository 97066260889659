import ItemsListingDatatable from '../datatables/content/items/ItemsListingDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
import StreamsListingDatatable from '../datatables/content/streams/StreamsListingDatatable';
import TabsContainer from '@/components/tabs/TabsContainer';

/* eslint-disable sort-keys */
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  tagsMgmt: PropTypes.bool.isRequired,

  // tabs
  activeTab: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tabContentId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,

  // streams datatable
  hubId: PropTypes.number.isRequired,
  streamTypes: PropTypes.object.isRequired,
  created: PropTypes.string,
  createdBy: PropTypes.string,
  collectionsCount: PropTypes.number.isRequired,
  collectionsCountByType: PropTypes.object.isRequired,
  itemsCount: PropTypes.number,
  canCreateStreams: PropTypes.bool.isRequired,
  streamPermissions: PropTypes.object.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  onClickCallback: PropTypes.shape({
    namespace: PropTypes.string,
    func: PropTypes.string,
  }).isRequired,

  // items datatable
  itemTypes: PropTypes.object.isRequired,
  itemsCountByType: PropTypes.array.isRequired,
  allItemsCount: PropTypes.number.isRequired,
  canCreateItems: PropTypes.bool.isRequired,
  canDeleteItems: PropTypes.bool.isRequired,
  canEditItems: PropTypes.bool.isRequired,
  canAddTags: PropTypes.bool.isRequired,
  featuringItemsAtStreamLevel: PropTypes.bool.isRequired,
  openTagsModal: PropTypes.shape({
    namespace: PropTypes.string,
    func: PropTypes.string,
  }).isRequired,
  openStreamsLinkModal: PropTypes.shape({
    namespace: PropTypes.string,
    func: PropTypes.string,
  }).isRequired,
  tagsList: PropTypes.array,

  //beta flag for Items Internal name feature
  internalNameBetaEnabled: PropTypes.bool,
};

const ContentPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    title: { text: title },
    subtitle: { text: subtitle },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <TabsContainer {...props} />
      <div id="hub-content-tab-streams" className="hidden">
        <StreamsListingDatatable {...props} />
      </div>
      <div id="hub-content-tab-items" className="hidden">
        <ItemsListingDatatable {...props} />
      </div>
    </Page>
  );
};
ContentPage.propTypes = propTypes;

export default ContentPage;
