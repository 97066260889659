import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';

import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import ConfirmationModal from '../../modal/ConfirmationModal';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshTable: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const defaultProps = {};

function DeleteFromGroupModal({ isOpen, handleClose, url, refreshTable, showMessage }) {
  const deleteLink = async (csrfToken) => {
    const toastId = 'deleteHub';
    try {
      showMessage('info', 'Deleting...', toastId);
      /* eslint-disable sort-keys */
      await axios({
        method: 'DELETE',
        url,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      showMessage('success', 'Hub was deleted from the language link group', toastId);
      refreshTable();
    } catch {
      showMessage('error', 'There was an error deleting this Hub from the group.', toastId);
    }
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <ConfirmationModal
          isOpen={isOpen}
          handleClose={handleClose}
          handleConfirm={() => deleteLink(csrfToken)}
          header="Remove this Hub from the Current Language Group"
          body="Are you sure that you want to unlink this Hub from all other Hubs in this group?"
        />
      )}
    </CsrfContextConsumer>
  );
}

DeleteFromGroupModal.propTypes = propTypes;
DeleteFromGroupModal.defaultProps = defaultProps;

export default DeleteFromGroupModal;
