import './DataTable.less';
import ButtonDropDown from '@/components/shared/buttonDropDown/ButtonDropDown';
import ControlBar from './controlBar/ControlBar';
import Pagination from './pagination/Pagination';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTableWrapper, { getSortQueryValue } from './columns/ReactTableWrapper';

const { shape, string, func, arrayOf, bool, element, number } = PropTypes;

const propTypes = {
  // buttons in the control bar
  actionButtons: arrayOf(
    shape({
      className: string,
      icon: string,
      id: string.isRequired,
      Modal: func,
      onClick: func,
      text: string.isRequired,
      tooltip: string,
    }),
  ),
  buttonDropDowns: arrayOf(shape(ButtonDropDown.propTypes)),
  columns: arrayOf(
    shape({
      accessor: string,
      Cell: func,
      Header: string,
      /* the id must match the key to sort by in the api for this column */
      id: string,
      sortable: bool,
    }),
  ).isRequired,
  disableSearch: bool,
  dropdownMenuItems: arrayOf(
    shape({
      icon: string,
      id: string.isRequired,
      onClick: func,
      text: string.isRequired,
    }),
  ),
  entityName: shape({
    plural: string,
    singular: string,
  }),
  // dropdowns in the control bar
  filterDropdowns: arrayOf(
    shape({
      items: arrayOf(
        shape({
          categoryId: string,
          id: string,
          queryValue: string,
          text: string,
          type: string,
        }),
      ),
      queryKey: string,
      toggleId: string,
      toggleText: string,
    }),
  ),
  getCellProps: func,
  getData: func.isRequired,
  handleError: func,
  hideControlBar: bool,
  hidePagination: bool,
  id: string.isRequired,
  initialData: arrayOf(shape),
  initialSort: shape({
    desc: bool,
    id: string,
  }),
  itemsPerPage: number,
  // an array of modals that we'll pass the cell props to
  Modals: func,
  MultiSelect: func,
  noSearchResultsMessage: element,
  readonly: bool,
  refreshModalData: func,
  searchPlaceholder: string,
  showLoadingState: bool,
  // this counter is used to refresh the data table
  triggerRefreshCounter: number,
  useStateHandling: bool,
};

const defaultProps = {
  actionButtons: [],
  buttonDropDowns: [],
  disableSearch: false,
  dropdownMenuItems: [],
  entityName: {},
  filterDropdowns: [],
  getCellProps: () => ({}),
  handleError: null,
  hideControlBar: false,
  hidePagination: false,
  initialData: [],
  initialSort: {},
  itemsPerPage: 10,
  Modals: null,
  MultiSelect: null,
  noSearchResultsMessage: null,
  readonly: false,
  refreshModalData: () => {},
  searchPlaceholder: null,
  showLoadingState: false,
  useStateHandling: false,
};

/**
 * This Component is where we assemble our usual datatable elements like pagination and toolbar.
 */
class DataTable extends React.Component {
  state = {
    error: null,
    loading: false,
    queries: {
      limit: this.props.itemsPerPage,
      page: 1,
      sort: getSortQueryValue(this.props.initialSort),
    },
    tableData: this.props.initialData,
    totalPages: 0,
  };

  componentDidUpdate(prevProps) {
    const { triggerRefreshCounter } = this.props;
    if (prevProps.triggerRefreshCounter !== triggerRefreshCounter) {
      this.refreshAllData();
    }
  }

  componentDidMount() {
    const { id, useStateHandling } = this.props;

    if (typeof history !== 'undefined' && useStateHandling && history.state?.dataTables?.[id]) {
      this.setState({ queries: history.state.dataTables[id] }, () => this.refreshAllData());
    } else {
      this.refreshAllData();
    }
  }

  setLoading = (loading) => this.setState({ loading });

  refreshTable = async (queries = this.state.queries) => {
    const { getData, id, useStateHandling } = this.props;
    this.setLoading(true);
    try {
      const cleanQueries = pickBy(queries);
      const response = await getData(cleanQueries);

      useStateHandling &&
        history.replaceState(
          {
            ...history.state,
            dataTables: { ...history.state?.dataTables, [id]: cleanQueries },
          },
          'queries',
        );

      this.setState({
        queries: cleanQueries,
        tableData: response.data,
        totalPages: response.meta.total_pages,
      });
    } catch (err) {
      this.handleError(err);
    }

    this.setLoading(false);
  };

  refreshAllData = () => {
    this.refreshTable();
    this.props.refreshModalData();
  };

  updateQueries = ({ queryKey, queryValue, type }) => {
    const { query, category_id: categoryId, limit } = this.state.queries;
    const searchHasChanged = queryKey === 'query' && query !== queryValue;
    const filterHasChanged = queryKey === 'category_id' && categoryId !== queryValue;
    const filterTypeHasChanged =
      (queryKey === 'type' || queryKey === 'service') && type !== queryValue;
    const pageLimitHasChanged = queryKey === 'limit' && limit !== queryValue;

    const queryupdates = {};
    if (searchHasChanged || filterHasChanged || filterTypeHasChanged || pageLimitHasChanged) {
      queryupdates.page = 1;
    }

    const queries = {
      ...this.state.queries,
      ...queryupdates,
      [queryKey]: queryValue,
    };

    try {
      this.refreshTable(queries);
    } catch (err) {
      this.handleError(err);
    }
  };

  /*
   * props that we pass down to table cells and modals so they can control the table.
   */
  magicProps = {
    readonly: this.props.readonly,
    refresh: this.refreshAllData,
    setLoading: this.setLoading,
  };

  /**
   * augmentTdProps will modify the "rowInfo" prop, which
   * is passed to all table cells. This gives individual cells in the datatable
   * access to methods or variables stored at a higher level. We also accept a
   * "getTdProps" prop, to allow parents of this datatable to pass additional props
   * to table cells
   *
   */
  augmentTdProps = (state, rowInfo) => {
    const { getCellProps } = this.props;
    const providedProps = getCellProps(state, rowInfo);
    Object.assign(rowInfo, {
      ...providedProps,
      ...this.magicProps,
    });
    return {};
  };

  handleError = (err) => {
    const { handleError } = this.props;

    if (!handleError) {
      return this.setState({
        error: err.message,
      });
    }

    return handleError(err);
  };

  render() {
    const { tableData, totalPages, queries, error, loading } = this.state;

    if (error) {
      return <p>Sorry something went wrong.</p>;
    }

    const {
      id,
      columns,
      readonly,
      disableSearch,
      hideControlBar,
      hidePagination,
      actionButtons,
      buttonDropDowns,
      entityName,
      filterDropdowns,
      dropdownMenuItems,
      showLoadingState,
      Modals,
      searchPlaceholder,
      noSearchResultsMessage,
      MultiSelect,
    } = this.props;

    const pageSize = Math.min(queries.limit, tableData.length);
    const placeholder = searchPlaceholder || `Search ${entityName.plural || ''}`;

    return (
      <div id={`ufr-${id}-data-table`}>
        {!hideControlBar ? (
          <ControlBar
            onChange={this.updateQueries}
            refreshData={this.refreshAllData}
            actionButtons={actionButtons}
            buttonDropDowns={buttonDropDowns}
            dropdownMenuItems={dropdownMenuItems}
            filterDropdowns={filterDropdowns}
            searchConfig={{ placeholder }}
            disabled={disableSearch}
            readonly={readonly}
            initialValues={typeof history !== 'undefined' && history.state?.dataTables?.[id]}
          />
        ) : undefined}

        {MultiSelect && (
          <MultiSelect
            handleSelect={this.updateQueries}
            initialTagsValues={
              typeof history !== 'undefined' && history.state?.dataTables?.[id]?.tagged_with
            }
          />
        )}

        <ReactTableWrapper
          id={id}
          data={tableData}
          loading={showLoadingState && loading}
          columns={columns}
          sort={queries.sort}
          pageSize={pageSize}
          entityName={entityName}
          onUpdateQuery={this.updateQueries}
          getTdProps={this.augmentTdProps}
          getTrProps={(state, rowInfo) => ({
            'data-item-id': rowInfo.original.id,
          })}
          noSearchResultsMessage={noSearchResultsMessage}
        />

        {!hidePagination ? (
          <Pagination
            id={id}
            onChange={this.updateQueries}
            pageSize={queries.limit}
            currentPage={queries.page}
            pageCount={totalPages}
            disabled={loading}
          />
        ) : undefined}

        {Modals && <Modals tableProps={this.magicProps} />}
      </div>
    );
  }
}

DataTable.propTypes = propTypes;
DataTable.defaultProps = defaultProps;

export default DataTable;
