import PropTypes from 'prop-types';
import React from 'react';

import './countDropdown.less';

const propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.number),
  queryKey: PropTypes.string,
  value: PropTypes.number,
};

const defaultProps = {
  disabled: false,
  options: [10, 25, 50, 100],
  queryKey: 'limit',
  value: 10,
};

const CountDropdown = ({ id, value, onChange, options, queryKey, disabled }) => {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);

    if (newValue !== value) {
      onChange({
        queryKey,
        queryValue: newValue,
      });
    }
  };

  return (
    <div id={`ufr-${id}-count-dropdown`} className="ufr-count-dropdown">
      Show
      <select
        value={value}
        onChange={disabled ? null : handleChange}
        disabled={disabled}
        name="pagination-limit-dropdown"
        aria-label="select number of items to show"
      >
        {options.map((option) => (
          <option
            key={option}
            className={option === value ? 'ufr-selected-count' : ''}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
      entries
    </div>
  );
};

CountDropdown.propTypes = propTypes;
CountDropdown.defaultProps = defaultProps;

export default CountDropdown;
