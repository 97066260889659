import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import NameAndDescriptionModal from '@/components/modal/NameAndDescriptionModal/NameAndDescriptionModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

const AddPrivacyGroupModal = ({ isModalOpen, handleClose, hubId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [requestData, setRequestData] = useState({});

  /* eslint-disable sort-keys */
  const handleSubmit = async (csrfToken) => {
    const privacyGroupsId = 'create-privacy-group';

    try {
      const { data } = await axios({
        method: 'post',
        url: `/api/v2/hubs/${hubId}/privacy-groups`,
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: { ...requestData, hub_id: hubId },
      });
      dispatchToasterAction(
        addToaster({
          id: privacyGroupsId,
          text: 'Privacy Group Created, redirecting...',
          type: 'success',
        }),
      );
      window.location.href = `/hubs/privacy/groups/edit/${hubId}/${data.id}`;
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: privacyGroupsId,
          text: error?.response?.data?.errors[0]?.message || 'Cannot create Privacy Group.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: privacyGroupsId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <NameAndDescriptionModal
            fieldIds={{
              descriptionFieldId: 'ufr-new-privacy-groups-description',
              nameFieldId: 'ufr-new-privacy-groups-name',
            }}
            fieldLabels={{ descriptionFieldLabel: 'Description', nameFieldLabel: 'Name' }}
            fieldNameAttributes={{
              descriptionFieldName: 'description',
              nameFieldName: 'name',
            }}
            title="Add Privacy Group"
            handleSubmit={() => handleSubmit(csrfToken)}
            primaryButtonText="Add Group"
            handleClose={handleClose}
            isOpen={isModalOpen}
            setData={setRequestData}
            data={requestData}
            maxLength={200}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

AddPrivacyGroupModal.propTypes = propTypes;

export default AddPrivacyGroupModal;
