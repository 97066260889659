import { CancelButton, Modal, ModalBody, ModalFooter, PrimaryButton } from '..';
import ModalHeader from '../ModalHeader/ModalHeader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class BasicModal extends Component {
  state = {
    error: '',
    isOpen: false,
  };

  handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      error: '',
      isOpen: false,
    });
  };

  handleOpen = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { globalOpenFunction } = this.props;
    if (
      typeof window !== 'undefined' && // don't blow up hypernova
      window.CurrentPage &&
      window.CurrentPage[globalOpenFunction]
    ) {
      const openFunc = window.CurrentPage[globalOpenFunction];

      openFunc()
        .then(() => {
          this.setState({
            isOpen: true,
          });
        })
        .catch(() => {
          this.setState({
            isOpen: false,
          });
        });
    } else {
      this.setState({
        isOpen: true,
      });
    }
  };

  handleSubmit = ({ e, ...data }) => {
    if (e) {
      e.preventDefault();
    }

    const { globalOnSubmitFunction } = this.props;
    if (
      typeof window !== 'undefined' && // don't blow up hypernova
      window.CurrentPage &&
      window.CurrentPage[globalOnSubmitFunction]
    ) {
      const submitFunc = window.CurrentPage[globalOnSubmitFunction];

      submitFunc(data)
        .then(() => {
          this.handleClose();
        })
        .catch((err) => {
          this.setState({
            error: err,
          });
        });
    }
  };

  render() {
    const { title, PrimaryButtonText, buttonClass, buttonText, id, bodyText } = this.props;

    const { isOpen, error } = this.state;

    return (
      <div id={id}>
        <button type="button" onClick={this.handleOpen} className={buttonClass}>
          {buttonText}
        </button>
        <Modal isOpen={isOpen} error={error} handleClose={this.handleClose}>
          <ModalHeader title={title} />
          <ModalBody>{bodyText}</ModalBody>
          <ModalFooter>
            <CancelButton onClick={this.handleClose} />
            <PrimaryButton label={PrimaryButtonText} onClick={this.handleSubmit}>
              {PrimaryButtonText}
            </PrimaryButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

BasicModal.defaultProps = {
  buttonClass: '',
  globalOpenFunction: '',
};

BasicModal.propTypes = {
  bodyText: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  globalOnSubmitFunction: PropTypes.string.isRequired,
  globalOpenFunction: PropTypes.string,
  id: PropTypes.string.isRequired,
  PrimaryButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BasicModal;
