import { ChromePicker } from 'react-color';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  color: PropTypes.string.isRequired,
  colorLabel: PropTypes.string.isRequired,
  disableAlpha: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChangeCta: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  inputChangeCta: PropTypes.func,
  showColorPicker: PropTypes.bool.isRequired,
};

const ColorPickerCta = ({
  id,
  disabled,
  disableAlpha,
  handleToggle,
  handleKeyDown,
  handleChangeCta,
  inputChangeCta,
  showColorPicker,
  colorLabel,
  color,
}) => (
  <div id={id} className="ufr-color-picker-cta">
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label className={'ufr-color-picker-label-cta'}>{`${colorLabel} Color`}</label>
    <input
      type={'text'}
      value={color}
      className={'ufr-color-picker-hex-cta'}
      onChange={inputChangeCta}
      placeholder="#HEX"
    />
    <button
      className={classNames([
        'ufr-color-picker-input-cta',
        { 'ufr-color-picker-input--disabled': disabled },
      ])}
      id={id}
      onClick={disabled ? null : handleToggle}
      onKeyPress={disabled ? null : handleKeyDown}
      disabled={disabled}
      type="button"
    >
      <div className="ufr-color-picker-preview-cta">
        <div style={{ backgroundColor: color }} />
      </div>
    </button>
    {showColorPicker && !disabled && (
      <div className="ufr-color-picker-popover-cta">
        <span
          role="button"
          tabIndex="-1"
          className="ufr-color-picker-overlay"
          onClick={disabled ? null : handleToggle}
          onKeyPress={disabled ? null : handleKeyDown}
        />
        <ChromePicker
          disabled={disabled}
          color={color}
          onChange={disabled ? null : handleChangeCta}
          disableAlpha={disableAlpha}
        />
      </div>
    )}
  </div>
);

ColorPickerCta.propTypes = propTypes;

export default ColorPickerCta;
