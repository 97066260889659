import PropTypes from 'prop-types';
import React from 'react';

import './topnavContainer.less';

const TopnavContainer = ({ whitelabelled, children }) => (
  <nav id="topnav" className={whitelabelled ? 'topnav-whitelabelled' : ''}>
    {children}
  </nav>
);

TopnavContainer.defaultProps = {
  whitelabelled: false,
};

TopnavContainer.propTypes = {
  children: PropTypes.node.isRequired,
  whitelabelled: PropTypes.bool,
};

export default TopnavContainer;
