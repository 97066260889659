import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './topnavDropdownItem.less';
import Avatar from '../shared/avatar/Avatar';
import handleClick from '../../utils/handleClick';
import Icon from '../shared/icon/Icon';

const TopnavDropdownItem = ({
  id,
  url,
  icon,
  text,
  active,
  onClick,
  iconRight,
  topDivider,
  faviconUrl,
  defaultIcon,
  disabled,
  onClickCallback,
}) => {
  const containerClasses = [
    'topnav-dropdown-item',
    active && 'topnav-item-active',
    topDivider && 'topnav-item-top-divider',
    disabled && 'ufr-nav-item-disabled',
  ];

  let graphic = null;
  if (faviconUrl) {
    graphic = <Avatar avatarUrl={faviconUrl} size="small" />;
  } else if (icon) {
    graphic = <Icon icon={icon} className="topnav-icon" />;
  } else if (defaultIcon && !iconRight) {
    graphic = <Avatar firstName={text} size="small" />;
  }

  const click = (e) => handleClick({ e, onClick, onClickCallback });

  return (
    <li
      id={`${id}-topnav-dropdown-item`}
      className={classNames(containerClasses)}
      tabIndex={disabled ? '-1' : ''}
    >
      <a href={url || undefined} onClick={!url ? click : undefined}>
        {graphic}

        {iconRight && (
          <span className="topnav-icon-right">
            <Icon icon={iconRight} />
          </span>
        )}

        <div className={classNames(['topnav-dropdown-item-text', !graphic && 'no-icon'])}>
          {text}
        </div>
      </a>
    </li>
  );
};

TopnavDropdownItem.defaultProps = {
  active: false,
  defaultIcon: false,
  disabled: false,
  faviconUrl: '',
  icon: '',
  iconRight: '',
  onClick: null,
  onClickCallback: null,
  topDivider: false,
  url: '',
};

TopnavDropdownItem.propTypes = {
  active: PropTypes.bool,
  defaultIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  faviconUrl: PropTypes.string,
  icon: PropTypes.string,
  iconRight: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
  }),
  text: PropTypes.string.isRequired,
  topDivider: PropTypes.bool,
  url: PropTypes.string,
};

export default TopnavDropdownItem;
