import { addToaster, slideDownAndRemoveToaster } from './useToasterStack';
import { useState } from 'react';

/**
 * get a function to show toast messages.
 * must be used in conjunction with ToasterStack
 */
const usePopToast = (dispatch) => {
  const [timer, setTimer] = useState(null);

  return (type, text, id, timeout = 2000) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }

    const toaster = {
      id,
      text,
      type,
    };

    dispatch(addToaster(toaster));
    const timerId = setTimeout(() => {
      dispatch(slideDownAndRemoveToaster(toaster));
    }, timeout);

    setTimer(timerId);
  };
};

export default usePopToast;
