import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import EditableSpan from '@/components/formInputs/editableSpan/EditableSpan';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canEditCustomCode: PropTypes.bool.isRequired,
  customCodeId: PropTypes.number.isRequired,
  dispatchToasterAction: PropTypes.func.isRequired,
  getUpdateOrderEndpoint: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  ordinal: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const CustomCodeListingOrder = ({
  customCodeId,
  ordinal,
  triggerRefresh,
  canEditCustomCode,
  getUpdateOrderEndpoint,
  dispatchToasterAction,
}) => {
  const saveOrder = async (ordinal, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: getUpdateOrderEndpoint(customCodeId),
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: { ordinal },
      });
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: 'Custom Code order updated.',
          type: 'success',
        }),
      );
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: 'Cannot update Custom Code order.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: customCodeId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */
  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <EditableSpan
            id={`custom-code-${customCodeId}-order`}
            disabled={!canEditCustomCode}
            min={1}
            onReady={(value) => saveOrder(value, csrfToken)}
            type="number"
            validate={(value) => value > 0}
            value={ordinal}
          />
        )}
      </CsrfContextConsumer>
    </>
  );
};

CustomCodeListingOrder.propTypes = propTypes;

export default CustomCodeListingOrder;
