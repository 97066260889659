import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import ToggleSwitch from '@/components/formInputs/toggle/ToggleSwitch';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  disabled: PropTypes.bool,
  enabled: PropTypes.bool.isRequired,
  websiteWidgetRuleId: PropTypes.number.isRequired,
};

const WebsiteWidgetRulesEnabled = ({ disabled, enabled, websiteWidgetRuleId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const toggleEnabled = async (enabled, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'put',
        url: `/api/v2/website-widget-rules/${websiteWidgetRuleId}`,
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
          enabled,
        },
      });
      /* eslint-enable */
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetRuleId,
          text: `Site Engager Rule has been ${enabled ? 'enabled' : 'disabled'}.`,
          type: 'success',
        }),
      );
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetRuleId,
          text: 'Cannot update Site Engager Rule.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: websiteWidgetRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <ToggleSwitch
            id={`website-widget-rule-${websiteWidgetRuleId}-enabled`}
            name={`${websiteWidgetRuleId}`}
            submitData={({ propertyValue: enabled }) => toggleEnabled(enabled, csrfToken)}
            value={enabled}
            disabled={disabled}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

WebsiteWidgetRulesEnabled.propTypes = propTypes;

export default WebsiteWidgetRulesEnabled;
