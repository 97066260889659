import FakeCheckbox from './FakeCheckbox';
import handleInputReady from '@/utils/handleInputReady';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
  // Add a readonly validation rules object to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
  // value defining whether the toggle is on/off
  value: PropTypes.bool.isRequired,
};

const defaultProps = {
  disabled: false,
  localStorageKey: '',
  name: '',
  submitData: {},
  useLocalStorage: false,
  validation_rules: {},
};

const ToggleSwitch = ({
  disabled,
  id,
  name,
  submitData,
  validation_rules: validationRules,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleClick = () => {
    if (disabled) return;

    const toggleVal = !currentValue;
    const componentData = {
      propertyName: name,
      propertyValue: toggleVal ? 1 : 0,
      validationRules,
    };

    setCurrentValue(toggleVal);

    handleInputReady(submitData, componentData);
  };

  return (
    <FakeCheckbox
      id={`${id}-ufr-toggle-switch`}
      val={currentValue}
      disabled={disabled}
      handleClick={handleClick}
    >
      <span className="ufr-toggle-slider" />
    </FakeCheckbox>
  );
};

ToggleSwitch.defaultProps = defaultProps;
ToggleSwitch.propTypes = propTypes;

export default ToggleSwitch;
