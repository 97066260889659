import './integrations.less';
import './marketo.less';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PrimaryButton } from '@/components/modal';
import Icon from '@/components/shared/icon/Icon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SelectDropdown from '@/components/formInputs/selectDropdown/SelectDropdown';
import Spinner from '@/components/modal/Spinner/Spinner';
import TextInput from '@/components/formInputs/textInput/TextInput';

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const propTypes = {
  csrfToken: PropTypes.string,
  cta: PropTypes.shape({}),
  getCtaIntegrationLists: PropTypes.func,
  setCtaIntegrationLists: PropTypes.func,
};

const tooltip = (
  <Tooltip id="tooltip" className="integration-tooltip">
    List must be created in the <b>Lead Database view in Marketo</b>. Lists created in Marketing
    Activities section <i>cannot</i> be used for this integration.
  </Tooltip>
);

const Marketo = ({ csrfToken, cta, getCtaIntegrationLists, setCtaIntegrationLists }) => {
  const [isPickingFormId, setIsPickingFormId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [marketoDropdownData, setMarketoDropdownData] = useState([]);
  const [listId, setListId] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const defaultList = cta?.HubCtasForm?.service_list_id || '';

  const pickAFormIdOnClick = async () => {
    setIsLoading(true);

    getCtaIntegrationLists(
      'Marketo',
      setMarketoDropdownData,
      csrfToken,
      setIsLoading,
      setListId,
      cta.HubCtasForm.service_list_id,
      setErrorMessage,
      setLoadingMessage,
      setIsPickingFormId,
    );
  };

  const submitDropdown = (selectedItem) => {
    setCtaIntegrationLists(
      selectedItem,
      cta.HubCta.hub_id,
      cta.HubCta.id,
      csrfToken,
      setIsLoading,
      'Marketo',
      setListId,
      setLoadingMessage,
      null,
    );
  };

  const renderMarketoForm = () => {
    if (errorMessage.length > 1) {
      return <div>{errorMessage}</div>;
    }

    if (isPickingFormId) {
      return (
        <SelectDropdown
          id="marketo-dropdown-id"
          items={marketoDropdownData}
          submitData={submitDropdown}
          value={listId.text}
        />
      );
    }

    return (
      <>
        <TextInput
          id="marketo-form-id"
          submitData={submitDropdown}
          value={listId.value || defaultList}
        />
        <span className="or-label">- or -</span>
        <PrimaryButton label="Pick a Form ID" onClick={pickAFormIdOnClick}>
          Pick a Form ID
        </PrimaryButton>
      </>
    );
  };

  return (
    <div className="integration-container" data-loading={isLoading}>
      {isLoading ? (
        <>
          <Spinner />
          <span>{loadingMessage}</span>
        </>
      ) : (
        <>
          <div className="integration-setup-sprite marketo-text" />
          <label
            className="integration-label"
            htmlFor={isPickingFormId ? 'marketo-dropdown-id' : 'marketo-form-id'}
          >
            {isPickingFormId ? 'Select a Marketo Form' : 'Enter a Marketo Form ID'}
            <OverlayTrigger placement="top" overlay={tooltip}>
              <div className="overlay-container">
                <Icon icon="info-sign" />
              </div>
            </OverlayTrigger>
          </label>

          <div id="marketo-form" className="integration-form">
            {renderMarketoForm()}
          </div>
        </>
      )}
    </div>
  );
};

Marketo.propTypes = propTypes;

export default Marketo;
