import './datePickerOpener.less';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { CalendarFooter } from './CalendarFooter'; // This must come before "react-dates"
import { CalendarHeader } from './CalendarHeader';
import { DateRangePicker } from 'react-dates';
import defaultPhrases from 'react-dates/lib/defaultPhrases';
import moment from 'moment';
import PropTypes from 'prop-types';
import QuickLinks from './QuickLinks';
import React, { Component } from 'react';

/* eslint-disable react/no-access-state-in-setstate */

const defaultProps = {
  allowEmptyDates: false,
  description: null,
  endDate: null,
  limitDateRange: false,
  quickLinks: [],
  startDate: null,
  useLocalStorage: false,
};

const phraseOverrides = {
  ...defaultPhrases,
  focusStartDate: 'Interact with the calendar to set a start date.',
};

const propTypes = {
  allowEmptyDates: PropTypes.bool,
  description: PropTypes.string,
  // End date passed down from API
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment().constructor)]),
  limitDateRange: PropTypes.bool,
  // The function or name of the function in the global/CurrentPage namespace to call when submitting (clicking "Select") the date picker
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  /**
   * A series of objects describing what (if any) quick links should be available for the date picker
   * ex.
   * {
   *    preposition: 'last',
   *    number: 7,
   *    unit: 'days',
   * },
   * would give you a "Last 7 Days" quick link
   */
  quickLinks: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      preposition: PropTypes.oneOf(['this', 'last', 'next', 'all', 'today', 'yesterday']),
      unit: PropTypes.oneOf(['days', 'day', 'weeks', 'week', 'months', 'month', 'years', 'year']),
    }),
  ),
  // Start date passed down from API
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment().constructor)]),
  title: PropTypes.string.isRequired,
  // A unique ID to pass to the date picker library
  uniqueId: PropTypes.string.isRequired,
  useLocalStorage: PropTypes.bool,
};

class DatePickerOpener extends Component {
  state = {
    endDate: this.props.endDate ? moment(this.props.endDate) : null,
    focusedInput: null,
    previousEndDate: this.props.endDate ? moment(this.props.endDate) : null,
    previousStartDate: this.props.startDate ? moment(this.props.startDate) : null,
    startDate: this.props.startDate ? moment(this.props.startDate) : null,
  };

  // need to do componentDidMount because we can't access window in constructor
  componentDidMount() {
    if (this.props.useLocalStorage) {
      // unique local storage key per account
      const { accountId } = document.querySelector('.ufa-section-persistent-filters').dataset;
      const localStoragePersistentFiltersKey = `ufa-filters-date-${accountId}`;
      const dates = JSON.parse(window.localStorage.getItem(localStoragePersistentFiltersKey));

      if (dates) {
        const startDate = dates.startDate ? moment(dates.startDate) : null;
        const endDate = dates.endDate ? moment(dates.endDate) : null;

        this.setState({
          endDate,
          previousEndDate: endDate,
          previousStartDate: startDate,
          startDate,
        });
      }
    }
  }

  setPreviousDates = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      previousEndDate: endDate,
      previousStartDate: startDate,
    });
  };

  handleClear = () => {
    this.setState({
      endDate: null,
      focusedInput: 'startDate',
      startDate: null,
    });
  };

  userDateFormat = 'MMM D, YYYY';

  apiDateFormat = 'YYYY-MM-DD';

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { startDate, endDate } = this.state;

    // because setState doesn't update state right away
    // in case when we don't have endDate provided - we need to use startDate as default
    // in multiple places before state gets updated
    let actualStartDate = startDate;
    let actualEndDate = endDate;

    // trying to submit with no dates selected (after clicking clear button)
    if (!startDate && !endDate) {
      // allow setting empty state
      if (this.props.allowEmptyDates) {
        this.setState({
          endDate: null,
          startDate: null,
        });
      } else {
        this.setState({
          endDate: this.state.previousEndDate,
          startDate: this.state.previousStartDate,
        });

        actualStartDate = this.state.previousStartDate;
        actualEndDate = this.state.previousEndDate;
      }
    } else if (startDate && !endDate) {
      // trying to submit with only start date selected
      // if start date is selected and submitted without end date,
      // submit start date as single date (endDate value is set to startDate)
      this.setState({
        endDate: startDate,
      });
      actualEndDate = startDate;
    } else if (!startDate && endDate) {
      this.setState({
        startDate: endDate,
      });
      actualStartDate = endDate;
    }

    this.setState({
      focusedInput: null, // close the calendar
      previousEndDate: actualEndDate,
      previousStartDate: actualStartDate,
    });

    // data to pass to onSubmit function
    let result;
    if (!startDate && !endDate && this.props.allowEmptyDates) {
      result = {
        endDate: null,
        startDate: null,
      };
    } else {
      result = {
        endDate: actualEndDate.format(this.apiDateFormat),
        startDate: actualStartDate.format(this.apiDateFormat),
      };
    }

    // Due to our hacky react-in-cake-php setup,
    // we may have to call a callback on the window/CurrentPage obj
    if (typeof onSubmit === 'string' && window[onSubmit]) {
      return window[onSubmit](result);
    }

    if (typeof onSubmit === 'string' && window.CurrentPage[onSubmit]) {
      return window.CurrentPage[onSubmit](result);
    }

    // If a proper function was passed, just call that
    if (typeof onSubmit === 'function') {
      return onSubmit(result);
    }

    throw new TypeError(`${onSubmit} is not a valid submit handler, 
    no function found on the window or current page with that name and it is not a function`);
  };

  handleDateChange = ({ startDate, endDate }, callback = () => {}, focusedInput) =>
    this.setState({ endDate, startDate }, () => {
      callback();
      if (focusedInput) {
        this.setState({
          focusedInput,
        });
      }
    });

  handleFocusChange = (focusedInput) => this.setState({ focusedInput });

  handleClose = () => {
    this.setState({
      endDate: this.state.previousEndDate,
      startDate: this.state.previousStartDate,
    });
  };

  determineVerticalSpace = (array) => {
    if (array.length === 0) {
      return 0;
    }
    return 37;
  };

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    const { quickLinks, uniqueId, description, title, limitDateRange } = this.props;

    return (
      <div className="date-picker-container">
        <h1 className="title">{title}</h1>
        {description && <p className="description">{description}</p>}
        <DateRangePicker
          verticalSpacing={this.determineVerticalSpace(quickLinks)}
          onClose={this.handleClose}
          phrases={phraseOverrides}
          readOnly // Don't allow users to manually edit dates as a text field
          minimumNights={0} // Allow single day (ie start and end date is the same day)
          daySize={20} // Size in px of the day element
          customArrowIcon={<p className="joiningWord"> to </p>} // element to join start/end date inputs
          keepOpenOnDateSelect // After selecting a date don't close the picker
          displayFormat={this.userDateFormat} // The format of the date EX DD/MM/YYYY
          isOutsideRange={(momentObj) => {
            const distanceFromStartDate = momentObj
              .clone()
              .startOf('day') // needs to be end of day to account for rounding to the nearest day
              .diff(startDate, 'days');

            // check if the date is after upper limit
            const isAfterUpperLimitDate = momentObj.isAfter(
              moment().subtract(1, 'day').endOf('day'),
            );

            // just limit to selecting future dates
            if (!limitDateRange) {
              if (isAfterUpperLimitDate) {
                return true;
              }
              return false;
            }

            if (isAfterUpperLimitDate || distanceFromStartDate > 30 || distanceFromStartDate < 0) {
              return true; // true = day is NOT selectable
            }
            return false;
          }}
          renderCalendarInfo={() => (
            <CalendarFooter
              startDate={startDate}
              endDate={endDate}
              uniqueId={uniqueId}
              handleClear={this.handleClear}
              handleSubmit={this.handleSubmit}
              limitDateRange={limitDateRange}
            />
          )} // Elements to render below the months
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId={`uf-datepicker-start-date-${uniqueId}`} // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId={`uf-datepicker-end-date-${uniqueId}`} // PropTypes.string.isRequired,
          onDatesChange={this.handleDateChange}
          focusedInput={focusedInput} // which, if any, input is being selected for
          onFocusChange={this.handleFocusChange} // handle a request to change focus
          showDefaultInputIcon
          inputIconPosition="after"
          navPrev={
            <button type="button" aria-label="Decrement the month" className="prev-month with-icon">
              <span />
            </button>
          }
          navNext={
            <button type="button" aria-label="Increment the month" className="next-month with-icon">
              <span />
            </button>
          }
          renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
            <CalendarHeader
              onMonthSelect={onMonthSelect}
              onYearSelect={onYearSelect}
              month={month}
            />
          )} // custom header for months (chevrons)
        />

        {quickLinks ? (
          <QuickLinks
            quickLinkData={quickLinks}
            onClick={this.handleDateChange}
            currentlySelectedDates={{ endDate, startDate }}
            handleSubmit={this.handleSubmit}
            setPreviousDates={this.setPreviousDates}
          />
        ) : undefined}
      </div>
    );
  }
}

DatePickerOpener.defaultProps = defaultProps;
DatePickerOpener.propTypes = propTypes;

export default DatePickerOpener;
