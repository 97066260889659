import './cta15ListingDatatable.less';
import { CsrfProvider } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Cta15ListingButtons from './Cta15ListingButtons';
import Cta15ListingNoDataMessage from './Cta15ListingNoDataMessage';
import Cta15ListingTruncatedCell from './Cta15ListingTruncatedCell';
import CtaPreview from '../../ctaPreview/CtaPreview';
import DataTable from '../../datatable/DataTable';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import handleClick from '../../../utils/handleClick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '../../toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '../../toaster/useToasterStack';

const propTypes = {
  canCreateFormCta: PropTypes.bool.isRequired,
  canCreateLinkCta: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  onFormCtaClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  onLinkCtaClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  totalCtaCount: PropTypes.number.isRequired,
};

const getEndpointBuilder = (hubId) => ({
  get: `/api/v2/hubs/${hubId}/ctas`,
});

const entityName = {
  plural: 'Calls-to-Action',
  singular: 'Call-to-Action',
};

const searchPlaceholder = (ctaCount) => `Search ${ctaCount} CTAs by name, ID or tagline`;

/* eslint-disable sort-keys */
const filterDropdowns = [
  {
    queryKey: 'type',
    toggleId: 'cta-type',
    toggleText: 'Type: All Types',
    items: [
      {
        id: 'all-cta-types',
        text: 'Type: All Types',
        queryValue: null,
      },
      {
        id: 'form-cta',
        text: 'Form',
        queryValue: 'form',
      },
      {
        id: 'link-cta',
        text: 'Link',
        queryValue: 'url',
      },
    ],
  },
  {
    queryKey: 'status',
    toggleId: 'cta-status',
    toggleText: 'Status: All',
    items: [
      {
        id: 'all-cta',
        text: 'Status: All',
        queryValue: null,
      },
      {
        id: 'shown-cta',
        text: 'Shown',
        queryValue: '1',
      },
      {
        id: 'hidden-cta',
        text: 'Hidden',
        queryValue: '0',
      },
      {
        id: 'draft-cta',
        text: 'Draft',
        queryValue: '2',
      },
    ],
  },
];
/* eslint-enable sort-keys */

const cellClass = (key) => `ufr-dt-cta-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-cta-listing-${key}-header`;

/* eslint-disable sort-keys */
const columns = (isSearchDisabled) => [
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
    className: cellClass('cta-name'),
    headerClassName: headerClass('cta-name'),
    sortable: !isSearchDisabled,
    Cell: (cell) => (
      <Cta15ListingTruncatedCell cell={cell} columnName="name">
        <a href={`/hubs/editCta/${cell.hubId}/${cell.original.id}`}>{cell.value}</a>
      </Cta15ListingTruncatedCell>
    ),
    minWidth: 150,
  },
  {
    Header: '',
    accessor: 'preview',
    className: cellClass('cta-preview'),
    headerClassName: headerClass('cta-preview'),
    Cell: (cell) => (
      <CtaPreview
        ctaTitle={cell.original.name}
        src={cell.original.embed_url}
        rowIndex={cell.index}
        icon={false}
      />
    ),
    sortable: false,
    width: 110,
  },
  {
    Header: 'Tagline',
    accessor: 'tagline',
    id: 'tagline',
    className: cellClass('cta-tagline'),
    headerClassName: headerClass('cta-tagline'),
    Cell: (cell) =>
      /\S/.test(cell.value) ? (
        <Cta15ListingTruncatedCell cell={cell} columnName="tagline">
          <span>{cell.value}</span>
        </Cta15ListingTruncatedCell>
      ) : (
        '—'
      ),
    sortable: !isSearchDisabled,
    minWidth: 150,
  },
  {
    Header: 'Type',
    accessor: 'type',
    id: 'type',
    className: cellClass('cta-type'),
    headerClassName: headerClass('cta-type'),
    Cell: (cell) =>
      /\S/.test(cell.value) ? (
        <Cta15ListingTruncatedCell cell={cell} columnName="type">
          <span>{cell.value === 'url' ? 'Link' : 'Form'}</span>
        </Cta15ListingTruncatedCell>
      ) : (
        '—'
      ),
    sortable: !isSearchDisabled,
    width: 110,
  },
  {
    Header: 'Status',
    accessor: 'active',
    id: 'active',
    className: cellClass('cta-status'),
    headerClassName: headerClass('cta-status'),
    Cell: ({ value }) => {
      if (value === 0) {
        return 'Hidden';
      }
      if (value === 1) {
        return 'Shown';
      }
      return 'Draft';
    },
    sortable: !isSearchDisabled,
    width: 100,
  },
  {
    Header: 'Last Modified',
    accessor: 'modified_at',
    id: 'modified_at',
    className: cellClass('modified-date'),
    headerClassName: headerClass('modified-date'),
    Cell: ({ value }) => getHumanReadableDate(value),
    sortable: !isSearchDisabled,
    minWidth: 100,
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell text-right`,
    headerClassName: headerClass('buttons'),
    Cell: (cell) => (
      <Cta15ListingButtons
        hubId={cell.hubId}
        ctaId={cell.original.id}
        ctaName={cell.original.name}
        ctaStatus={cell.original.active}
        handleToasterLifecycle={cell.handleToasterLifecycle}
        triggerRefresh={cell.refresh}
      />
    ),
    sortable: false,
  },
];
/* eslint-enable sort-keys */

const createCtaButtonDropDown = (
  canCreateFormCta,
  canCreateLinkCta,
  onFormCtaClickCallback,
  onLinkClickCallback,
) => {
  const items = [];

  if (canCreateFormCta) {
    items.push({
      id: 'create-form-cta',
      onClick: () => handleClick({ onClickCallback: onFormCtaClickCallback }),
      text: 'Build Form CTA',
    });
  }

  if (canCreateLinkCta) {
    items.push({
      id: 'create-link-cta',
      onClick: () => handleClick({ onClickCallback: onLinkClickCallback }),
      text: 'Build Link CTA',
    });
  }

  return {
    buttonText: 'Build CTA',
    disabled: !canCreateFormCta && !canCreateLinkCta,
    id: 'create-cta',
    items,
  };
};

const Cta15ListingDatatable = ({
  hubId,
  totalCtaCount,
  canCreateFormCta,
  canCreateLinkCta,
  onFormCtaClickCallback,
  onLinkCtaClickCallback,
}) => {
  const [ctaCount, setCtaCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [toasterTimers, setToasterTimers] = useState({});
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const { get } = getEndpointBuilder(hubId);

  if (isInitialLoad) {
    setIsInitialLoad(false);
    setIsSearchDisabled(totalCtaCount === 0);
  }

  const getTableData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(get, {
      params: queries,
    });
    setCtaCount(meta.count);
    return { data, meta };
  };

  const handleToasterLifecycle = (toaster, toasterId) => {
    dispatchToasterAction(addToaster(toaster));

    if (toasterTimers[toasterId]) {
      clearTimeout(toasterTimers[toasterId]);
    }

    setToasterTimers((prevState) => ({
      ...prevState,
      [toasterId]: setTimeout(() => {
        dispatchToasterAction(
          slideDownAndRemoveToaster({
            id: toasterId,
          }),
        );

        setToasterTimers(({ [toasterId]: _, ...rest }) => ({
          ...rest,
        }));
      }, 2000),
    }));
  };

  return (
    <CsrfProvider>
      <DataTable
        id="calls-to-action"
        useStateHandling
        buttonDropDowns={[
          createCtaButtonDropDown(
            canCreateFormCta,
            canCreateLinkCta,
            onFormCtaClickCallback,
            onLinkCtaClickCallback,
          ),
        ]}
        entityName={entityName}
        getData={getTableData}
        searchPlaceholder={searchPlaceholder(ctaCount)}
        noSearchResultsMessage={<Cta15ListingNoDataMessage isSearchDisabled={isSearchDisabled} />}
        filterDropdowns={filterDropdowns}
        disableSearch={isSearchDisabled}
        columns={columns(isSearchDisabled)}
        getCellProps={() => ({ handleToasterLifecycle, hubId })}
        initialSort={{ desc: true, id: 'date' }}
        hidePagination={isSearchDisabled}
        showLoadingState
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

Cta15ListingDatatable.propTypes = propTypes;

export default Cta15ListingDatatable;
