import PropTypes from 'prop-types';
import React from 'react';

import './breadcrumbs.less';

const Breadcrumbs = ({ crumbs, currentPageName }) => (
  <nav id="breadcrumbs" aria-label="Breadcrumbs" aria-labelledby="breadcrumb-header">
    <h2 id="breadcrumb-header" className="sr-only">
      You are here:
    </h2>
    <ol>
      {crumbs.map((crumb) => (
        <li key={crumb.text} className="crumb">
          <a href={crumb.url}>{crumb.text}</a>
        </li>
      ))}

      <li className="crumb current-crumb">{currentPageName}</li>
    </ol>
  </nav>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentPageName: PropTypes.string.isRequired,
};

export default Breadcrumbs;
