import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './avatar.less';

const colors = [
  '#5bc0de', // default
  '#ffc107',
  '#ff8a65',
  '#9c83d0',
  '#4db6ac',
  '#3f51b5',
  '#7c909b',
  '#8d6e63',
  '#31708f',
  '#3c763d',
  '#a94443',
  '#8a6d3b',
  '#9c83d0',
  '#777777',
  '#95a5a6',
  '#f39c12',
  '#d35400',
];

const firstChar = (s) => (s ? s.charAt(0) : '');

const getBgColor = (initials) => {
  const charIndex = initials.charCodeAt(0) - 64;
  const colorIndex = charIndex % colors.length;
  return colors[colorIndex - 1] || colors[0];
};

const Avatar = ({ firstName, lastName, avatarUrl, size }) => {
  // Initial(s)
  const initials = firstChar(firstName) + (lastName ? firstChar(lastName) : '');

  const styles = avatarUrl
    ? {
        backgroundColor: 'transparent',
        backgroundImage: `url(${avatarUrl})`,
        backgroundSize: 'cover',
      }
    : {
        backgroundColor: getBgColor(initials),
      };

  const classes = ['react-avatar', `react-avatar-${size}`];

  return (
    <div className={classNames(classes)} style={styles}>
      {!avatarUrl && initials}
    </div>
  );
};

Avatar.defaultProps = {
  avatarUrl: '',
  firstName: null,
  lastName: null,
  size: 'default',
};

Avatar.propTypes = {
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'medium']),
};

export default Avatar;
