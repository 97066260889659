import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import EditableSpan from '@/components/formInputs/editableSpan/EditableSpan';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  hubId: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
  redirectRuleId: PropTypes.number.isRequired,
};

const RedirectRulesPriority = ({ hubId, priority, redirectRuleId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const savePriority = async (priority, csrfToken) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: `/redirect_rules/ajax_updatePriority/${hubId}/${redirectRuleId}`,
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: new URLSearchParams({
          priority,
        }).toString(),
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: redirectRuleId,
          text: 'Redirect Rule priority updated.',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: redirectRuleId,
          text: 'Cannot update Redirect Rule priority.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: redirectRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <EditableSpan
            id={`redirect-rule-${redirectRuleId}-priority`}
            min={1}
            onReady={(value) => savePriority(value, csrfToken)}
            type="number"
            validate={(value) => value > 0}
            value={priority}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

RedirectRulesPriority.propTypes = propTypes;

export default RedirectRulesPriority;
