import axios from 'axios/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './enrichmentSourcesFieldDatatable.less';
import { CsrfContextConsumer, CsrfProvider } from '../../../DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../shared/button/Button';
import ConfirmationModal from '../../modal/ConfirmationModal';
import DataTable from '../../datatable/DataTable';
import TextAutosuggest from '../../formInputs/textAutosuggest/textAutosuggest';
import ToasterStack from '../../toaster/ToasterStack';
import usePopToast from '../../toaster/usePopToast';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
  updateToaster,
} from '../../toaster/useToasterStack';

const getEndpointBuilder = (
  accountId,
  integrationId,
  integrationCode,
  integrationFieldId,
  visitorProfileFieldId,
) => ({
  deleteEnrichmentIntegrationFieldMapping: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationId}/integration-field/${integrationFieldId}/visitor-profile-field/${visitorProfileFieldId}`,
  getEnrichmentIntegrationFields: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationId}/fields`,
  syncVisitorProfileFieldMappings: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationCode}/visitor-profile-fields/sync`,
  updateEnrichmentIntegrationFieldMapping: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationId}/field-mapping`,
});

const entityName = {
  plural: 'Enrichment Source Fields',
  singular: 'Enrichment Source Field',
};

const propTypes = {
  accountId: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  hideControlBar: PropTypes.bool,
  integrationCode: PropTypes.string.isRequired,
  integrationId: PropTypes.number.isRequired,
  integrationName: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  readonly: PropTypes.bool,
};

const defaultProps = {
  canEdit: false,
  hideControlBar: true,
  itemsPerPage: 50,
  readonly: false,
};

const searchPlaceholder = (fieldCount) => `Search ${fieldCount} Enrichment Fields`;

const cellClass = (key) => `ufr-dt-enrichment-source-fields-${key}-cell`;
const headerClass = (key) => `ufr-dt-enrichment-source-fields-${key}-header`;

const resetField = async (
  refreshTable,
  accountId,
  integrationId,
  integrationCode,
  original,
  dispatchToasterAction,
  csrfToken,
) => {
  const { integrationFieldsId, visitorProfileFieldId, displayName } = original;
  const { deleteEnrichmentIntegrationFieldMapping } = getEndpointBuilder(
    accountId,
    integrationId,
    integrationCode,
    integrationFieldsId,
    visitorProfileFieldId,
  );
  const toaster = {
    id: 'enrichment-source-fields-table-reset',
    text: `${displayName} field successfully cleared.`,
    type: 'success',
  };

  const params = {
    uberflip_profile_field_name: displayName,
  };

  try {
    /* eslint-disable sort-keys */
    await axios({
      method: 'delete',
      url: deleteEnrichmentIntegrationFieldMapping(),
      headers: { 'X-CSRF-TOKEN': csrfToken },
      data: params,
    });
    /* eslint-enable sort-keys */

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);

    refreshTable();
  } catch {
    // ignore error
  }
};

const renderLongText = (value) => {
  if (value) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="full-text" placement="top">
            {value}
          </Tooltip>
        }
      >
        <span>{value}</span>
      </OverlayTrigger>
    );
  }
  return <div className="placeholder">&#8212;</div>;
};

const clearMappingModalBody = (fieldName) => (
  <>
    <p>
      This will remove the integration field mapping for the Enrichment field &quot;
      <b>{fieldName}</b>&quot;.
    </p>
    <p> Are you sure you want to proceed? </p>
  </>
);

const renderClearButton = (
  refresh,
  accountId,
  integrationId,
  integrationCode,
  original,
  dispatchToasterAction,
  editPermission,
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (original.isMapped && editPermission) {
    return (
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              handleConfirm={() => {
                resetField(
                  refresh,
                  accountId,
                  integrationId,
                  integrationCode,
                  original,
                  dispatchToasterAction,
                  csrfToken,
                );
              }}
              header="Clear integration field mapping for this field?"
              body={clearMappingModalBody(original.displayName)}
              primaryButtonText="Clear Mapping"
            />
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="reset-field-mapping-tooltip" placement="top">
                  Clear integration field mapping
                </Tooltip>
              }
            >
              <span>
                <Button
                  icon="remove"
                  id="clear"
                  label="clear"
                  onClick={() => setIsModalOpen(true)}
                />
              </span>
            </OverlayTrigger>
          </>
        )}
      </CsrfContextConsumer>
    );
  }
  return null;
};

/* eslint-disable sort-keys */
const columns = (integrationName) => [
  {
    Header: 'Category',
    accessor: 'category',
    id: 'category',
    className: cellClass('category'),
    headerClassName: headerClass('category'),
    sortable: false,
    minWidth: 150,
  },
  {
    Header: 'Enrichment Field',
    accessor: 'displayName',
    id: 'enrichment-field',
    className: cellClass('enrichment-field'),
    headerClassName: headerClass('enrichment-field'),
    Cell: ({ value }) => renderLongText(value),
    sortable: false,
    minWidth: 150,
  },
  {
    Header: 'Data Type',
    accessor: 'type',
    id: 'format',
    className: cellClass('format'),
    headerClassName: headerClass('format'),
    sortable: false,
    minWidth: 100,
  },
  {
    Header: '',
    accessor: 'isMapped',
    id: 'is-mapped',
    className: cellClass('is-mapped'),
    headerClassName: headerClass('is-mapped'),
    Cell: ({ value }) => [
      <div
        className={
          value
            ? 'react-icon glyphicons glyphicons-link is-mapped'
            : 'react-icon glyphicons glyphicons-link'
        }
      />,
    ],
    sortable: false,
    minWidth: 80,
  },
  {
    Header: `${integrationName} Field`,
    accessor: 'visitorProfileFieldName',
    id: 'visitor-profile-field-name',
    className: cellClass('visitor-profile-field-name'),
    headerClassName: headerClass('visitor-profile-field-name'),
    Cell: ({
      original,
      dispatchToasterAction,
      value,
      refresh,
      accountId,
      integrationCode,
      handleSubmit,
      canEdit,
    }) =>
      canEdit
        ? [
            <CsrfContextConsumer>
              {(csrfToken) => (
                <TextAutosuggest
                  accountId={accountId}
                  integrationCode={integrationCode}
                  csrfToken={csrfToken}
                  id={original.integrationFieldsId}
                  submitData={handleSubmit}
                  resetFields={refresh}
                  initialValue={value}
                  placeholder="Search for integration field"
                  dispatchToasterAction={dispatchToasterAction}
                  enrichmentFieldName={original.displayName}
                />
              )}
            </CsrfContextConsumer>,
          ]
        : renderLongText(value),
    sortable: false,
    minWidth: 300,
  },
  {
    Header: 'Format',
    accessor: 'visitorProfileFieldType',
    id: 'visitor-profile-field-type',
    className: cellClass('visitor-profile-field-type'),
    headerClassName: headerClass('visitor-profile-field-type'),
    Cell: ({ value }) => [value || <div className="placeholder">&#8212;</div>],
    sortable: false,
    minWidth: 100,
  },
  {
    Header: 'API Name',
    accessor: 'visitorProfileApiCode',
    id: 'visitor-profile-api-code',
    className: cellClass('visitor-profile-api-code'),
    headerClassName: headerClass('visitor-profile-api-code'),
    Cell: ({ value }) => renderLongText(value),
    sortable: false,
  },
  {
    Header: '',
    accessor: 'reset-field',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    Cell: ({
      refresh,
      accountId,
      integrationId,
      integrationCode,
      original,
      dispatchToasterAction,
      canEdit,
    }) =>
      renderClearButton(
        refresh,
        accountId,
        integrationId,
        integrationCode,
        original,
        dispatchToasterAction,
        canEdit,
      ),
    sortable: false,
  },
];
/* eslint-enable sort-keys */

const EnrichmentSourcesFieldDatatable = ({
  accountId,
  integrationId,
  integrationCode,
  integrationName,
  hideControlBar,
  readonly,
  canEdit,
  itemsPerPage,
}) => {
  const [fieldCount, setFieldCount] = useState(0);
  const { getEnrichmentIntegrationFields } = getEndpointBuilder(accountId, integrationId);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);

  const getTableData = async (params) => {
    const {
      data: { data, meta },
    } = await axios.get(getEnrichmentIntegrationFields(), { params });
    setFieldCount(meta.count);

    return { data, meta };
  };

  const handleSubmit = async (data, refreshTable, csrfToken, enrichmentFieldName) => {
    const params = {
      uberflip_profile_field_id: data.integrationFieldsId,
      uberflip_profile_field_name: enrichmentFieldName,
      visitor_profile_field_id: data.id,
      visitor_profile_source_code: integrationCode,
    };
    const { updateEnrichmentIntegrationFieldMapping } = getEndpointBuilder(
      accountId,
      integrationId,
    );

    const toastId = data.profileFieldCode;
    let toaster;

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'put',
        url: updateEnrichmentIntegrationFieldMapping(),
        headers: { 'X-CSRF-TOKEN': csrfToken },
        data: params,
      });
      /* eslint-enable sort-keys */
      toaster = {
        id: toastId,
        text: `${enrichmentFieldName} field successfully mapped.`,
        type: 'success',
      };
    } catch (err) {
      toaster = {
        id: toastId,
        text: 'Looks like something went wrong. Please try again.',
        type: 'error',
      };
    }

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 6000);

    refreshTable();
  };

  const refreshApiInfo = async (refreshData, csrfToken) => {
    const id = 'refresh-api-data';
    dispatchToasterAction(
      addToaster({
        id,
        text: 'Updating API Info...',
        type: 'refresh',
      }),
    );

    const { syncVisitorProfileFieldMappings } = getEndpointBuilder(
      accountId,
      integrationId,
      integrationCode,
    );

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: syncVisitorProfileFieldMappings(),
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */

      refreshData();

      dispatchToasterAction(
        updateToaster({
          id,
          text: 'API Info Successfully Updated.',
          type: 'success',
        }),
      );
    } catch (err) {
      dispatchToasterAction(
        updateToaster({
          id,
          text: `Error: ${err.message}`,
          type: 'error',
        }),
      );
    }

    setTimeout(() => {
      dispatchToasterAction(
        slideDownAndRemoveToaster({
          id,
        }),
      );
    }, 2000);
  };

  const resetApiInfoButton = {
    disabled: !canEdit,
    icon: 'refresh',
    id: 'refresh-api-data',
    onClick: refreshApiInfo,
    text: 'Refresh API Info',
  };

  return (
    <CsrfProvider>
      <DataTable
        entityName={entityName}
        id="enrichment-source-fields"
        useStateHandling
        actionButtons={[resetApiInfoButton]}
        searchPlaceholder={searchPlaceholder(fieldCount)}
        showLoadingState
        columns={columns(integrationName)}
        getData={getTableData}
        readonly={readonly}
        itemsPerPage={itemsPerPage}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({
          accountId,
          canEdit,
          dispatchToasterAction,
          handleSubmit,
          integrationCode,
          integrationId,
          popToast,
        })}
        hideControlBar={hideControlBar}
        noSearchResultsMessage={
          <p>No results to display. Please try adjusting your search terms.</p>
        }
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

EnrichmentSourcesFieldDatatable.propTypes = propTypes;
EnrichmentSourcesFieldDatatable.defaultProps = defaultProps;

export default EnrichmentSourcesFieldDatatable;
