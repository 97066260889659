import './eloqua.less';
import './integrations.less';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PrimaryButton } from '@/components/modal';
import Icon from '@/components/shared/icon/Icon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SelectDropdown from '@/components/formInputs/selectDropdown/SelectDropdown';
import Spinner from '@/components/modal/Spinner/Spinner';
import TextInput from '@/components/formInputs/textInput/TextInput';

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const propTypes = {
  csrfToken: PropTypes.string,
  cta: PropTypes.shape({}),
  getCtaIntegrationLists: PropTypes.func,
  setCtaIntegrationLists: PropTypes.func,
};

const toolTipHeader = `Can't find the Eloqua Form you're looking for?`;
const toolTipBody = `Inside Eloqua, set "email address" as the form key identifier. Then return here and refresh the page.`;

const tooltip = (
  <Tooltip id="tooltip" className="eloqua-tooltip">
    <b>{toolTipHeader}</b>
    {toolTipBody}
  </Tooltip>
);

const Eloqua = ({ csrfToken, cta, getCtaIntegrationLists, setCtaIntegrationLists }) => {
  const [isPickingFormId, setIsPickingFormId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [eloquaDropdownData, setEloquaDropdownData] = useState([]);
  const [listId, setListId] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const defaultList = cta?.HubCtasForm?.service_form_id || '';

  const pickAFormIdOnClick = async () => {
    setIsLoading(true);

    getCtaIntegrationLists(
      'Eloqua',
      setEloquaDropdownData,
      csrfToken,
      setIsLoading,
      setListId,
      cta.HubCtasForm.service_form_id,
      setErrorMessage,
      setLoadingMessage,
      setIsPickingFormId,
    );
  };

  const submitDropdown = async (selectedItem) => {
    setCtaIntegrationLists(
      selectedItem,
      cta.HubCta.hub_id,
      cta.HubCta.id,
      csrfToken,
      setIsLoading,
      'Eloqua',
      setListId,
      setLoadingMessage,
      setIsPickingFormId,
    );
  };

  const renderEloquaForm = () => {
    if (errorMessage.length > 1) {
      return <div>{errorMessage}</div>;
    }

    if (isPickingFormId) {
      return (
        <SelectDropdown
          id="eloqua-dropdown-id"
          items={eloquaDropdownData}
          submitData={submitDropdown}
          value={listId.text}
        />
      );
    }

    return (
      <>
        <TextInput
          id="eloqua-form-id"
          submitData={submitDropdown}
          value={listId.value || defaultList}
        />
        <span className="or-label">- or -</span>
        <PrimaryButton label="Pick a Form ID" onClick={pickAFormIdOnClick}>
          Pick a Form ID
        </PrimaryButton>
      </>
    );
  };

  return (
    <div className="integration-container" data-loading={isLoading}>
      {isLoading ? (
        <>
          <Spinner />
          <span>{loadingMessage}</span>
        </>
      ) : (
        <>
          <div className="integration-setup-sprite eloqua-text" />
          <label
            className="integration-label"
            htmlFor={isPickingFormId ? 'eloqua-dropdown-id' : 'eloqua-form-id'}
          >
            {isPickingFormId ? 'Select an Eloqua Form' : 'Enter an Eloqua Form ID'}
            <OverlayTrigger placement="top" overlay={tooltip}>
              <div className="overlay-container">
                <Icon icon="info-sign" />
              </div>
            </OverlayTrigger>
          </label>

          <div id="eloqua-form" className="integration-form">
            {renderEloquaForm()}
          </div>
        </>
      )}
    </div>
  );
};

Eloqua.propTypes = propTypes;

export default Eloqua;
