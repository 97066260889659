import './placementTab.less';

import { api2ListingIterator } from '@/utils/helpers';
import axios from 'axios';
import ConfirmModal from '@/components/modal/ConfirmModal/ConfirmModal';
import Placement from './Placement';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import Spinner from '@/components/modal/Spinner/Spinner';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  ctaId: PropTypes.number.isRequired,
  hasManagePlacementsPermission: PropTypes.bool,

  // used for testing/stories
  initialPlacements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  streamTypes: PropTypes.object.isRequired,
};

const defaultProps = {
  initialPlacements: [],
  streamTypes: {},
};

const PlacementTab = ({ ctaId, streamTypes, hasManagePlacementsPermission, initialPlacements }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const [placements, setPlacements] = useState(initialPlacements);
  const [modalState, setModalState] = useState({
    isOpen: false,
    placementId: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const loadPlacements = async () => {
    const newPlacements = [];

    try {
      setIsLoading(true);

      for await (const p of api2ListingIterator(`/api/v2/cta-placements`, {
        cta_id: ctaId,
      })) {
        // fetch service type from stream to determine the icon used in the placement
        const { data } = await axios.get(`/api/v2/streams/${p.stream_id}`);

        newPlacements.push({
          ...p,
          service: data?.service,
        });
      }

      setPlacements(newPlacements);
    } catch (err) {
      const toaster = {
        id: 'edit-cta-placement-toaster',
        text: 'Error Fetching Placements',
        type: 'error',
      };

      dispatchToasterAction(addToaster(toaster));

      setTimeout(() => dispatchToasterAction(slideDownAndRemoveToaster(toaster)), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePlacementClick = async (placementId) => {
    setModalState({
      isOpen: true,
      placementId,
    });
  };

  const handleDeleteCurrentPlacement = (csrfToken) => async () => {
    const { placementId } = modalState;

    setModalState({
      isOpen: false,
      placementId: null,
    });

    const toaster = await axios
      .delete(`/api/v2/cta-placements/${placementId}`, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        },
      })
      .then(() => ({
        id: 'edit-cta-placement-tab',
        text: 'Saved',
        type: 'success',
      }))
      .catch(() => ({
        id: 'edit-cta-placement-tab',
        text: 'Error',
        type: 'error',
      }));

    if (toaster.type === 'success') {
      // update state, no need to fetch placements again
      setPlacements(placements.filter((p) => p.id !== placementId));
    }

    dispatchToasterAction(addToaster(toaster));

    setTimeout(() => dispatchToasterAction(slideDownAndRemoveToaster(toaster)), 2000);
  };

  useEffect(() => {
    loadPlacements();

    try {
      // we can't attach a listener to the legacy modal
      // instead we're observing when the legacy 'Saved' toast appears on the dom
      const observer = new MutationObserver((mutations_list) => {
        mutations_list.forEach((mutation) => {
          mutation.addedNodes.forEach((added_node) => {
            if (added_node?.id === 'saveProgressContainer') {
              loadPlacements();

              // once the container appears it will not be removed.
              // switch to observing only the saveProgressContainer
              // since it will have much less mutations
              observer.disconnect();
              observer.observe(document.querySelector('#saveProgressContainer'), {
                childList: true,
                subtree: false,
              });
            } else if (added_node.className?.includes('save-progress alert')) {
              // this toast will be mutated each save
              loadPlacements();
            }
          });
        });
      });

      // Start observing from the body since the legacy toaster container is added only when the first toast appears
      observer.observe(document.querySelector('body'), {
        childList: true,
        subtree: false,
      });

      // cleanup observer if component is unmounted
      return () => observer.disconnect();
    } catch (e) {
      // If we get here, MutationObserver is not working
      // Likely we are in storybook or cli tests where this doesn't exist
    }
  }, [ctaId]);

  const renderPlacements = () => {
    if (isLoading) {
      return <Spinner />;
    } else if (placements?.length) {
      return placements.map(({ id, stream_name, stream_type, service }) => (
        <Placement
          key={`placement-${id}`}
          streamTypes={streamTypes}
          name={stream_name}
          type={stream_type}
          service={service}
          hasManagePlacementsPermission={hasManagePlacementsPermission}
          onDelete={() => handleDeletePlacementClick(id)}
        />
      ));
    }
  };

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <div id="edit-cta-placement-tab">
            <h1 className="cta-placement-tab-title">
              Where do you want this CTA to appear in your Hub?
            </h1>
            <div className="cta-placement-tab-container">{renderPlacements()}</div>
            {/* Using legacy modal until new modal designs are out */}
            {hasManagePlacementsPermission && (
              <p className="add-new-placement-btn" data-objectid={ctaId}>
                <a href={`#new-placement-modal${ctaId}`} data-toggle="modal" role="button">
                  <span className="halflings halflings-plus" aria-hidden="true"></span> Add New
                  Placement
                </a>
              </p>
            )}
            <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
            <ConfirmModal
              id="placement-confirm-modal"
              isOpen={modalState.isOpen}
              title="Are you sure you want to delete this placement?"
              onClose={() => setModalState({ ...modalState, isOpen: false })}
              onSubmit={handleDeleteCurrentPlacement(csrfToken)}
            />
          </div>
        )}
      </CsrfContextConsumer>
    </CsrfProvider>
  );
};

PlacementTab.propTypes = propTypes;
PlacementTab.defaultProps = defaultProps;

export default PlacementTab;
