import '../salesforceDropdown.less';
import '../salesforceModal.less';
import './searchableMenu.less';
import * as React from 'react';
import { arrayOf, element, func, string } from 'prop-types';
import { FormControl } from 'react-bootstrap';

const SearchableMenu = ({ children, setSearchValue, searchValue }) => (
  <div className="searchable-dropdown-container salesforce-dropdown-menu">
    <FormControl
      autoFocus
      className="searchable-menu-input"
      placeholder="Type to filter..."
      onChange={(e) => setSearchValue(e.target.value)}
      value={searchValue}
    />
    <ul role="menu" className="searchable-menu">
      {children}
    </ul>
  </div>
);

export default SearchableMenu;

SearchableMenu.propTypes = {
  children: arrayOf(element).isRequired,
  searchValue: string.isRequired,
  setSearchValue: func.isRequired,
};
