import { Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import './pageControl.less';

const propTypes = {
  currentPage: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
};

const defaultProps = {
  currentPage: 1,
  disabled: false,
  pageCount: 1,
};

const queryKey = 'page';

class PageControl extends React.Component {
  getItem = (value) => (
    <Pagination.Item
      key={value}
      active={value === this.props.currentPage}
      onClick={this.props.disabled ? null : () => this.handleClick(value)}
      disabled={this.props.disabled}
      data-page-number={value}
    >
      {value}
    </Pagination.Item>
  );

  getItems = (length, offset = 0) =>
    new Array(length)
      // generate an array of a specified length full of undefined
      .fill(undefined)
      // fill with the values that we want for our page buttons
      .map((val, idx) => idx + 1 + offset)
      .map((value) => this.getItem(value));

  getPageButtons = () => {
    const { currentPage, pageCount } = this.props;

    const showAllPages = pageCount <= 5;
    if (showAllPages) {
      return this.getItems(pageCount);
    }

    const showStartEllipsis = currentPage > 4;
    const showEndEllipsis = currentPage < pageCount - 2;
    const setLength = 3;

    let setOffset = 1;
    if (showStartEllipsis && showEndEllipsis) {
      setOffset = currentPage - 2;
    } else if (showStartEllipsis) {
      setOffset = pageCount - 4;
    }

    const middleItems = this.getItems(setLength, setOffset);
    const firstItem = this.getItem(1);
    const lastItem = this.getItem(pageCount);

    return [
      firstItem,
      showStartEllipsis && <Pagination.Ellipsis disabled key="startEllipsis" />,
      ...middleItems,
      showEndEllipsis && <Pagination.Ellipsis disabled key="endEllipsis" />,
      lastItem,
    ];
  };

  handleClick = (value) => this.props.onClick({ queryKey, queryValue: value });

  render() {
    const { id, currentPage, pageCount } = this.props;

    const disablePrevious = currentPage === 1;
    const disableNext = currentPage === pageCount || pageCount === 0;

    return (
      <Pagination id={`ufr-${id}-page-control`} className="ufr-page-control pagination-sm">
        <Pagination.Prev
          disabled={disablePrevious}
          onClick={disablePrevious ? undefined : () => this.handleClick(currentPage - 1)}
        >
          Previous
        </Pagination.Prev>

        {this.getPageButtons()}

        <Pagination.Next
          disabled={disableNext}
          onClick={disableNext ? undefined : () => this.handleClick(currentPage + 1)}
        >
          Next
        </Pagination.Next>
      </Pagination>
    );
  }
}

PageControl.propTypes = propTypes;
PageControl.defaultProps = defaultProps;

export default PageControl;
