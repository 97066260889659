import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import WebsiteWidgetTemplatesDeleteButton from '@/components/datatables/websiteWidgetTemplates/WebsiteWidgetTemplatesDeleteButton';

const propTypes = {
  decrementWebsiteWidgetTemplateCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hasDeletePermission: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
  websiteWidgetTemplateId: PropTypes.number.isRequired,
  websiteWidgetTemplateLockDelete: PropTypes.bool.isRequired,
  websiteWidgetTemplateName: PropTypes.string.isRequired,
  websiteWidgetTemplateRuleCount: PropTypes.number.isRequired,
};

const WebsiteWidgetTemplatesButtons = ({
  decrementWebsiteWidgetTemplateCount,
  dispatchToasterAction,
  hasDeletePermission,
  hasEditPermission,
  hubId,
  triggerRefresh,
  websiteWidgetTemplateId,
  websiteWidgetTemplateLockDelete,
  websiteWidgetTemplateName,
  websiteWidgetTemplateRuleCount,
}) => (
  <>
    {hasEditPermission ? (
      <Button
        href={`/hubs/site_engager/templates/edit/${hubId}/${websiteWidgetTemplateId}`}
        icon="wrench"
        title="Edit Rule"
        label="Edit Rule"
        id={`edit-website-widget-rule-${websiteWidgetTemplateId}`}
        className="ufr-edit-button"
      />
    ) : null}
    {hasDeletePermission && !websiteWidgetTemplateLockDelete ? (
      <WebsiteWidgetTemplatesDeleteButton
        websiteWidgetTemplateId={websiteWidgetTemplateId}
        websiteWidgetTemplateName={websiteWidgetTemplateName}
        websiteWidgetTemplateRuleCount={websiteWidgetTemplateRuleCount}
        decrementWebsiteWidgetTemplateCount={decrementWebsiteWidgetTemplateCount}
        dispatchToasterAction={dispatchToasterAction}
        triggerRefresh={triggerRefresh}
      />
    ) : null}
  </>
);

WebsiteWidgetTemplatesButtons.propTypes = propTypes;

export default WebsiteWidgetTemplatesButtons;
