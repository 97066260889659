import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';

import './controlBarSearch.less';
import Icon from '../../shared/icon/Icon';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  initialSearchQueryValue: PropTypes.string,
  onReady: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  queryKey: PropTypes.string,
};

const defaultProps = {
  placeholder: 'Search',
  queryKey: 'query',
};

class ControlBarSearch extends React.Component {
  state = {
    savedValue: '',
    value: '',
  };

  handleReady = () => {
    const { onReady, queryKey } = this.props;
    const { value, savedValue } = this.state;

    if (value !== savedValue) {
      onReady({
        queryKey,
        queryValue: value.trim(),
      });
      this.setState({ savedValue: value });
    }
  };

  handleKeydown = (e) => {
    if (e.key === 'Enter') {
      this.handleReady();
    }
  };

  debouncedHandleReady = debounce(this.handleReady, 1000);

  handleChange = (e) => {
    this.setState({ value: e.target.value });

    this.debouncedHandleReady();
  };

  getSavedSearchText = () => {
    const { initialSearchQueryValue } = this.props;
    if (initialSearchQueryValue) {
      this.setState({ savedValue: initialSearchQueryValue, value: initialSearchQueryValue });
    }
  };

  componentDidMount() {
    this.getSavedSearchText();
  }

  render() {
    const { value } = this.state;
    const { disabled, placeholder } = this.props;
    return (
      <li className="ufr-control-bar-search">
        <div>
          <Icon icon="search" />
          <input
            value={value}
            onChange={this.handleChange}
            onKeyDown={this.handleKeydown}
            type="text"
            placeholder={placeholder}
            aria-label={placeholder}
            disabled={disabled}
          />
        </div>
      </li>
    );
  }
}

ControlBarSearch.propTypes = propTypes;
ControlBarSearch.defaultProps = defaultProps;

export default ControlBarSearch;
