import './editableSpan.less';
import Input from '../input/Input';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  onReady: PropTypes.func,
  type: PropTypes.oneOf(['text', 'number']),
  validate: PropTypes.function,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  disabled: false,
  type: 'text',
  value: '',
};

const EditableSpan = ({ disabled, id, onReady, validate, value, ...rest }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  if (!isEditing) {
    return (
      <span
        id={`${id}-span`}
        className={`ufr-editable-span ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? null : () => setIsEditing(true)}
      >
        {currentValue}
      </span>
    );
  }

  return (
    <Input
      id={`${id}-input`}
      onDone={(value) => {
        if (!validate || validate(value)) setCurrentValue(value);
        setIsEditing(false);
      }}
      onReady={(value) => {
        if (!validate || validate(value)) onReady(value);
      }}
      value={currentValue}
      autoFocus
      {...rest}
    />
  );
};

EditableSpan.defaultProps = defaultProps;
EditableSpan.propTypes = propTypes;

export default EditableSpan;
