import './backButton.less';
import Button from '../../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const BackButton = ({ onClick }) => (
  <Button
    id="modal-v3-back-btn"
    label="Go Back"
    className="modal-v3-back-btn"
    onClick={onClick}
    ufrStyle="back"
  >
    Go Back
  </Button>
);

BackButton.defaultProps = { onClick: null };

BackButton.propTypes = { onClick: PropTypes.func };

export default BackButton;
