import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const activatePropTypes = {
  ctaId: PropTypes.number.isRequired,
  onActivate: PropTypes.func,
  show: PropTypes.bool,
};

const activateDefaultProps = {
  show: false,
};

const ActivateButton = ({ ctaId, onActivate, show, ...props }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const handleActivate = (csrfToken) => async () => {
    let toaster = {
      id: 'get-tag-group-tags-toaster',
      text: 'CTA is now Active',
      type: 'success',
    };

    const response = await axios(`/api/v2/cta/${ctaId}`, {
      data: {
        active: 1,
      },
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
      },
      method: 'PATCH',
    }).catch((err) => {
      toaster = {
        id: 'get-tag-group-tags-toaster',
        text: 'Error: ' + err.response?.data?.errors?.[0]?.message,
        type: 'error',
      };
    });

    if (onActivate && response?.status === 200) {
      onActivate(response.data);
    }

    dispatchToasterAction(addToaster(toaster));

    setTimeout(() => dispatchToasterAction(slideDownAndRemoveToaster(toaster)), 2000);
  };

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {(csrfToken) =>
          show && (
            <Button label={'activate'} onClick={handleActivate(csrfToken)} {...props}>
              Activate
            </Button>
          )
        }
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

ActivateButton.propTypes = activatePropTypes;
ActivateButton.defaultProps = activateDefaultProps;

const nextPropTypes = {
  onNext: PropTypes.func,
};

const NextButton = ({ onNext, ...props }) => {
  const handleNext = () => {
    if (onNext) {
      onNext();
    }
  };

  return (
    <Button label={'next button'} ufrStyle="primary" onClick={handleNext} {...props}>
      Next
    </Button>
  );
};

NextButton.propTypes = nextPropTypes;

const propTypes = {
  ...activatePropTypes,
  ...nextPropTypes,
  isLastTab: PropTypes.bool,
  showActivate: PropTypes.bool,
};

const defaultProps = {
  isLastTab: false,
  showActivate: false,
};

const CtaContinueButton = ({ isLastTab, ctaId, showActivate, onNext, onActivate, ...props }) => {
  if (isLastTab) {
    return <ActivateButton show={showActivate} ctaId={ctaId} onActivate={onActivate} {...props} />;
  }

  return <NextButton onNext={onNext} {...props} />;
};

CtaContinueButton.propTypes = propTypes;
CtaContinueButton.defaultProps = defaultProps;

export default CtaContinueButton;
