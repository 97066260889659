import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './input.less';
import CharacterCount from './CharacterCount';
import handleInputReady from '../../../utils/handleInputReady';
import Reset from '@/components/formInputs/reset/Reset';

const propTypes = {
  // should automatically focus on page load (not recommended)
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  // width of textArea
  cols: PropTypes.number,
  disabled: PropTypes.bool,
  // function to call every time the input value changes
  handleChange: PropTypes.func,
  // required for acceptance tests and accessibility decorators
  id: PropTypes.string,
  // for app config settings
  inheritedValue: PropTypes.number,
  // The id that the label is looking for
  labelExpectsId: PropTypes.string,
  // max for a number input
  max: PropTypes.number,
  // max length for a text input or textarea */
  maxLength: PropTypes.number,
  // min for a number input
  min: PropTypes.number,
  // used as a value for the name attribute
  name: PropTypes.string,
  // function to call after possibly calling onReady; called whether or not value changed
  onDone: PropTypes.func,
  // function to call with data when ready to submit
  onReady: PropTypes.func,
  placeholder: PropTypes.string,
  // Add a postfix in grey attached to the input
  postfix: PropTypes.string,
  // Add a prefix in grey attached to the input
  prefix: PropTypes.string,
  readOnly: PropTypes.bool,
  resetData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  // height of textArea
  rows: PropTypes.number,
  showReset: PropTypes.bool,
  style: PropTypes.shape({ width: PropTypes.number }),
  submitData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  type: PropTypes.oneOf(['text', 'number', 'textarea', 'password', 'checkbox']),
  validationState: PropTypes.oneOf(['success', 'error', '']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

const defaultProps = {
  autoFocus: false,
  className: '',
  cols: undefined,
  disabled: false,
  handleChange: undefined,
  id: '',
  inheritedValue: 0,
  labelExpectsId: '',
  max: undefined,
  maxLength: undefined,
  min: undefined,
  name: '',
  onReady: undefined,
  placeholder: '',
  postfix: '',
  prefix: '',
  readOnly: false,
  resetData: {},
  rows: undefined,
  showReset: false,
  style: {},
  type: 'text',
  validationState: undefined,
  value: 0,
};

class Input extends React.Component {
  constructor(props) {
    super(props);
    const defaultVal = props.type === 'number' ? 0 : '';
    const value = props.value || defaultVal;
    this.state = {
      savedValue: value,
      value,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleReady = () => {
    const { value, savedValue } = this.state;
    const { onDone, onReady } = this.props;

    if (value !== savedValue) {
      if (onReady) onReady(value);
      this.setState({ savedValue: value });
    }

    if (onDone) onDone(value);
  };

  handleKeydown = (e) => {
    if (e.key === 'Enter') {
      this.handleReady();
    }
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ value });
    if (this.props.handleChange) {
      this.props.handleChange(e);
    }
  };

  handleReset = () => {
    const { name, resetData } = this.props;
    const componentData = {
      propertyName: name,
    };

    handleInputReady(resetData, componentData, this.finishReset);
  };

  finishReset = () => {
    const { inheritedValue } = this.props;
    const value = inheritedValue ? inheritedValue : 0; // set to 0 if falsy, for re-rendering

    this.setState({ savedValue: value, value: value });
  };

  render() {
    const {
      id,
      type,
      prefix,
      postfix,
      validationState,
      className,
      disabled,
      readOnly,
      autoFocus,
      placeholder,
      maxLength,
      style,
      min,
      max,
      cols,
      rows,
      labelExpectsId,
      name,
      showReset,
    } = this.props;

    const { value } = this.state;

    const valProp = type === 'checkbox' ? 'checked' : 'value';
    // Native dom attributes to apply to our input
    const attrs = {
      autoFocus,
      cols,
      disabled,
      max,
      maxLength,
      min,
      placeholder,
      readOnly,
      rows,
      style,
      type,
      [valProp]: value,
    };

    const showCharCount = maxLength > 0 && !disabled;
    const Component = type === 'textarea' ? 'textarea' : 'input';
    const showResetNumber = showReset && type === 'number';

    const classes = [
      'ufr-input-inner',
      `ufr-${type}-inner`,
      prefix && 'ufr-prefixed-input',
      postfix && 'ufr-postfixed-input',
    ];

    const inputClasses = [
      'ufr-input-component',
      validationState && `ufr-input-${validationState}`,
      disabled && 'ufr-input-component--disabled',
    ];

    return (
      <div className={showResetNumber && 'ufr-align-input-container'}>
        <div className={classNames(classes)}>
          {prefix && (
            <p
              className="ufr-input-prefix"
              ref={(el) => {
                this.prefix = el;
              }}
            >
              {prefix}
            </p>
          )}

          <div className="ufr-input-wrapper">
            <Component
              id={labelExpectsId || id}
              name={name || id}
              className={classNames(className || inputClasses)}
              onChange={this.handleChange}
              onBlur={this.handleReady}
              onKeyDown={this.handleKeydown}
              ref={(el) => {
                this.input = el;
              }}
              {...attrs}
            />
            {showCharCount && <CharacterCount max={maxLength} count={value.length} />}
          </div>

          {postfix && <p className="ufr-input-postfix">{postfix}</p>}
        </div>

        {showResetNumber && <Reset handleReset={this.handleReset} id={labelExpectsId || id} />}
      </div>
    );
  }
}

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;

export default Input;
