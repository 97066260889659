import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import './tabs.less';
import handleClick from '../../utils/handleClick';

class TabsList extends React.Component {
  constructor({ activeTab, items }) {
    super();
    this.state = {
      activeTab: activeTab || items[0].id,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;

    if (typeof history !== 'undefined' && history?.state?.tabsLists?.[id]) {
      this.setState({ activeTab: history?.state?.tabsLists?.[id] }, () =>
        this.showInitialTabContent(),
      );
    } else {
      this.showInitialTabContent();
    }
  }

  showInitialTabContent() {
    const { items } = this.props;
    const { activeTab } = this.state;

    // Default to first item provided
    if (!activeTab) this.showTabContent(items[0].tabContentId);

    // Get active item with active tab's id
    const activeItem = items.filter((item) => item.id === activeTab);
    if (activeItem.length) this.showTabContent(activeItem[0].tabContentId);
  }

  showTabContent(tabContentId) {
    const { items } = this.props;

    for (let i = 0; i < items.length; i++) {
      const tabContent = document.getElementById(items[i].tabContentId);
      if (tabContent) {
        tabContent.classList.add('hidden');
      }
    }

    const selectedTabContent = document.getElementById(tabContentId);
    if (selectedTabContent) {
      selectedTabContent.classList.remove('hidden');
    }
  }

  handleSelect(activeTab) {
    this.setState({ activeTab });
    history.replaceState(
      {
        ...history.state,
        tabsLists: { ...history.state?.tabsLists, [this.props.id]: activeTab },
      },
      'activeTab',
    );
    // get active item from an item id
    const activeItem = this.props.items.filter((item) => item.id === activeTab)[0];
    const { tabContentId, onClick, onClickCallback } = activeItem;
    this.showTabContent(tabContentId);
    handleClick({ onClick, onClickCallback });
  }

  getActiveTab() {
    const { id } = this.props;

    if (typeof history !== 'undefined' && history?.state?.tabsLists?.[id]) {
      return history?.state?.tabsLists?.[id];
    }

    return this.state.activeTab;
  }

  render() {
    const { id, items } = this.props;

    return (
      <Tabs
        id={id}
        activeKey={this.state.activeTab}
        onSelect={this.handleSelect}
        className="tabs-list"
      >
        {items.map((item) => (
          <Tab
            key={item.id}
            eventKey={item.id}
            title={item.text}
            disabled={item.disabled}
            animation={false}
            tabClassName={`streams15-${item.id}`}
          />
        ))}
      </Tabs>
    );
  }
}

TabsList.defaultProps = {
  activeTab: null,
};

TabsList.propTypes = {
  activeTab: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      onClickCallback: PropTypes.shape({
        func: PropTypes.string,
        namespace: PropTypes.string,
      }),
      tabContentId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TabsList;
