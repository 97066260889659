import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  customCodeId: PropTypes.number.isRequired,
  customCodeName: PropTypes.string.isRequired,
  decrementCustomCodeCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  getDeleteCustomCodeEndpoint: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const modalBody = (customCodeName) => (
  <p>
    Are you sure you want to <b>permanently</b> delete custom code block{' '}
    <b>
      &quot;
      {customCodeName}
      &quot;
    </b>
    ?
  </p>
);

const CustomCodeListingDeleteButton = ({
  decrementCustomCodeCount,
  customCodeId,
  customCodeName,
  triggerRefresh,
  getDeleteCustomCodeEndpoint,
  dispatchToasterAction,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  /* eslint-disable sort-keys */
  const deleteCustomCode = async (csrfToken) => {
    try {
      setIsDeleting(true);
      await axios({
        method: 'DELETE',
        url: getDeleteCustomCodeEndpoint(customCodeId),
        headers: { 'X-Csrf-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      });
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: 'Custom Code was deleted!',
          type: 'success',
        }),
      );
      decrementCustomCodeCount?.();
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: 'Unable to delete Custom Code.',
          type: 'error',
        }),
      );
    }
    setIsDeleting(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: customCodeId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              handleConfirm={() => deleteCustomCode(csrfToken)}
              header={`Delete Custom Code Block "${customCodeName}"?`}
              body={modalBody(customCodeName)}
              primaryButtonText="Delete Code"
            />

            <Button
              icon="bin"
              type="button"
              title="Delete Custom Code"
              label="Delete Custom Code"
              id={`delete-custom-code-${customCodeId}`}
              className="ufr-delete-button"
              disabled={isDeleting}
              onClick={() => setIsModalOpen(true)}
            />
          </>
        )}
      </CsrfContextConsumer>
    </>
  );
};

CustomCodeListingDeleteButton.propTypes = propTypes;

export default CustomCodeListingDeleteButton;
