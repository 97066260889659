import './privacyGroupsListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddPrivacyGroupModal from './AddPrivacyGroupModal';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import PrivacyGroupsButtons from './PrivacyGroupsButtons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hubId: PropTypes.number.isRequired,
  incrementTriggerRefreshCounter: PropTypes.func.isRequired,
  setPrivacyGroups: PropTypes.func.isRequired,
};

const cellClass = (key) => `ufr-dt-privacy-groups-${key}-cell`;
const headerClass = (key) => `ufr-dt-privacy-groups-${key}-header`;

/* eslint-disable sort-keys */
const columns = (hubId, dispatchToasterAction, incrementTriggerRefreshCounter) => [
  {
    Header: 'Group Name',
    accessor: 'name',
    id: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    minWidth: 90,
    Cell: (cell) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`ufr-privacy-groups-name-tooltip-${cell.original.id}`} placement="top">
            {cell.original.name}
          </Tooltip>
        }
      >
        <div className="ufr-privacy-group-name">{cell.original.name}</div>
      </OverlayTrigger>
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    minWidth: 500,
    Cell: (cell) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id={`ufr-privacy-groups-description-tooltip-${cell.original.id}`}
            placement="top"
          >
            {cell.original.description}
          </Tooltip>
        }
      >
        <span>{cell.original.description}</span>
      </OverlayTrigger>
    ),
  },
  {
    Header: '',
    accessor: 'controls',
    className: cellClass('buttons'),
    headerClassName: headerClass('buttons'),
    minWidth: 100,
    sortable: false,
    Cell: (cell) => (
      <PrivacyGroupsButtons
        hubId={hubId}
        privacyGroupId={cell.original.id}
        privacyGroupName={cell.original.name}
        triggerRefresh={cell.refresh}
        decrementPrivacyGroupsCount={cell.decrementPrivacyGroupsCount}
        dispatchToasterAction={dispatchToasterAction}
        incrementTriggerRefreshCounter={incrementTriggerRefreshCounter}
      />
    ),
  },
];

/* eslint-enable sort-keys */
const getEndpointBuilder = (hubId) => ({
  get: `/api/v2/hubs/${hubId}/privacy-groups`,
});

const entityName = {
  plural: 'Privacy Groups',
  singular: 'Privacy Group',
};

const newPrivacyGroupActionButton = (setIsAddPrivacyGroupModalOpen) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: false,
  id: 'create-new-privacy-group',
  onClick: () => setIsAddPrivacyGroupModalOpen(true),
  text: 'Add Group',
});

const searchPlaceholder = (privacyGroupsCount) =>
  `Search ${privacyGroupsCount} ${
    privacyGroupsCount === 1 ? entityName.singular : entityName.plural
  }`;

const PrivacyGroupsListingDatatable = ({
  hubId,
  setPrivacyGroups,
  incrementTriggerRefreshCounter,
}) => {
  const [privacyGroupsCount, setPrivacyGroupsCount] = useState(0);
  const { get } = getEndpointBuilder(hubId);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isAddPrivacyGroupModalOpen, setIsAddPrivacyGroupModalOpen] = useState(false);
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(get, {
      params: queries,
    });

    if (isInitialLoad) {
      setIsInitialLoad(false);
      setPrivacyGroupsCount(meta.count);
    }
    setPrivacyGroups(data);
    return { data, meta };
  };

  return (
    <>
      <CsrfProvider>
        <DataTable
          id="privacy-group"
          useStateHandling
          getData={getData}
          columns={columns(hubId, dispatchToasterAction, incrementTriggerRefreshCounter)}
          actionButtons={[newPrivacyGroupActionButton(setIsAddPrivacyGroupModalOpen)]}
          searchPlaceholder={searchPlaceholder(privacyGroupsCount)}
          noSearchResultsMessage={
            <p>{isInitialLoad ? '' : 'No privacy groups match your search.'}</p>
          }
          showLoadingState={isInitialLoad}
          initialSort={{ desc: false, id: 'name' }}
          getCellProps={() => ({
            decrementPrivacyGroupsCount: () => {
              setPrivacyGroupsCount(privacyGroupsCount - 1);
            },
          })}
        />
        <AddPrivacyGroupModal
          hubId={hubId}
          isModalOpen={isAddPrivacyGroupModalOpen}
          handleClose={() => setIsAddPrivacyGroupModalOpen(false)}
        />
        <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
      </CsrfProvider>
    </>
  );
};

PrivacyGroupsListingDatatable.propTypes = propTypes;

export default PrivacyGroupsListingDatatable;
