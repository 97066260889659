import PropTypes from 'prop-types';
import React from 'react';

import './sectionHeader.less';

const SectionHeader = ({ text }) => (
  <div role="heading" className="ufr-form-section-header">
    <div>{text}</div>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SectionHeader;
