import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
import RedirectRulesDatatable from '../datatables/redirectRules/RedirectRulesDatatable';

const propTypes = {
  hasManagePermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const RedirectRulesPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <RedirectRulesDatatable {...props} />
    </Page>
  );
};

RedirectRulesPage.propTypes = propTypes;

export default RedirectRulesPage;
