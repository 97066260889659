import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  modalData: PropTypes.exact({
    description: PropTypes.string,
    passphrase: PropTypes.string,
  }).isRequired,
  passphraseId: PropTypes.number.isRequired,
  renderEditPassphraseModal: PropTypes.func.isRequired,
};

const PassphrasesEditButton = ({ modalData, passphraseId, renderEditPassphraseModal }) => (
  <>
    <Button
      icon="pencil"
      title="Edit Passphrase"
      label="Edit Passphrase"
      id={`edit-passphrase-${passphraseId}`}
      className="ufr-edit-button"
      onClick={() => renderEditPassphraseModal(passphraseId, modalData)}
    />
  </>
);
PassphrasesEditButton.propTypes = propTypes;

export default PassphrasesEditButton;
