import PropTypes from 'prop-types';
import React from 'react';

import './reactTableWrapper.less';
import 'react-table/react-table.css';
import ReactTable, { ReactTableDefaults } from 'react-table';

const { string, oneOfType, number, array, shape, func, bool, element } = PropTypes;

const propTypes = {
  columns: oneOfType([
    array,
    shape({
      accessor: string,
      Cell: func,
      className: string,
      Header: string,
      // add as needed
    }),
  ]).isRequired,
  // An array or object of data matching column structure
  data: oneOfType([array, shape]),
  entityName: shape({
    plural: string,
    singular: string,
  }).isRequired,
  getTdProps: func,
  getTrProps: func,
  id: string.isRequired,
  loading: bool,
  noSearchResultsMessage: element,
  onUpdateQuery: func.isRequired,
  // the number of rows to show by default
  pageSize: number,
  sort: string,
};

const defaultProps = {
  data: [],
  getTdProps: () => ({}),
  loading: false,
  noSearchResultsMessage: null,
  pageSize: 10,
  sort: '',
};

const columnDefaults = {
  ...ReactTableDefaults.column,
  multiSort: false,
  resizable: false,
};

const sortQueryKey = 'sort';

/**
 * transform the sort object into the query '-title' that our api expects
 */
export const getSortQueryValue = ({ id, desc = true }) => (id ? `${desc ? '-' : ''}${id}` : '');

/**
 * transform the '-title' sort query value into the {id: title, desc: true} object
 */
const getSortFromQueryValue = (queryValue) => {
  const desc = queryValue.indexOf('-') === 0;
  const id = desc ? queryValue.replace('-', '') : queryValue;
  return { desc, id };
};

/**
 * This Component is our interface for a Data Table library.
 */
class ReactTableWrapper extends React.Component {
  handleSort = (sorts) => {
    const queryValue = getSortQueryValue(sorts[0]);

    this.props.onUpdateQuery({
      queryKey: sortQueryKey,
      queryValue,
    });
  };

  render() {
    const {
      id,
      sort,
      data,
      columns,
      loading,
      pageSize,
      getTdProps,
      getTrProps,
      entityName: { plural },
      noSearchResultsMessage,
    } = this.props;

    const noDataText =
      noSearchResultsMessage || (plural ? `No ${plural} found.` : 'No results found.');

    return (
      <ReactTable
        id={`ufr-${id}-data-table`}
        className="ufr-react-table"
        noDataText={noDataText}
        manual
        data={data}
        columns={columns}
        loading={loading}
        defaultPageSize={pageSize}
        onSortedChange={this.handleSort}
        sorted={[getSortFromQueryValue(sort)]}
        column={columnDefaults}
        showPagination={false}
        getTdProps={getTdProps}
        getTrProps={getTrProps}
      />
    );
  }
}

ReactTableWrapper.defaultProps = defaultProps;
ReactTableWrapper.propTypes = propTypes;

export default ReactTableWrapper;
