import { CancelButton, Modal, ModalFooter, PrimaryButton } from '..';
import ModalHeader from '../ModalHeader/ModalHeader';
import PropTypes from 'prop-types';

import React from 'react';

const propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const ConfirmModal = ({ id, title, isOpen, onClose, onSubmit }) => (
  <Modal id={id} isOpen={isOpen} handleClose={onClose}>
    <ModalHeader title={title} />
    <ModalFooter>
      <CancelButton onClick={onClose} />
      <PrimaryButton label="Ok" onClick={onSubmit}>
        Ok
      </PrimaryButton>
    </ModalFooter>
  </Modal>
);

ConfirmModal.propTypes = propTypes;

export default ConfirmModal;
