import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  streamId: PropTypes.number.isRequired,
  streamName: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const getConfirmationMessage = (streamName) => (
  <p>
    Are you sure you want to restore the Stream <b>&quot;{streamName}&quot;</b> and all its Items?
    <br />
    Note: your account requires sufficient capacity to hold one more stream.
  </p>
);

const StreamsListingRestoreButton = ({ streamId, streamName, triggerRefresh }) => {
  const [isRestoreStreamModalOpen, setIsRestoreStreamModalOpen] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const restoreStream = async (csrfToken) => {
    setIsRestoring(true);
    try {
      await axios({
        headers: { 'X-CSRF-TOKEN': csrfToken },
        method: 'post',
        url: `/api/v2/streams/${streamId}/restore`,
      });
      dispatchToasterAction(
        addToaster({
          id: streamId,
          text: 'Stream restored successfully.',
          type: 'success',
        }),
      );
      triggerRefresh();
    } catch (error) {
      const message = error.response?.data?.errors?.[0]?.message || 'Unable to restore stream';
      dispatchToasterAction(
        addToaster({
          id: streamId,
          text: message,
          type: 'error',
        }),
      );
    }
    setIsRestoring(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: streamId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isRestoreStreamModalOpen}
              handleClose={() => setIsRestoreStreamModalOpen(false)}
              handleConfirm={() => restoreStream(csrfToken)}
              header="Restore Stream?"
              body={getConfirmationMessage(streamName)}
              primaryButtonText="Restore Stream"
            />

            <Button
              className="ufr-restore-button"
              disabled={isRestoring}
              id={`restore-stream-${streamId}`}
              icon="inbox-out"
              label="restore stream"
              onClick={() => setIsRestoreStreamModalOpen(true)}
              title="Restore Stream"
            />
          </>
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

StreamsListingRestoreButton.propTypes = propTypes;

export default StreamsListingRestoreButton;
