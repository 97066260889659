import Cta15ListingCopyButton from './Cta15ListingCopyButton';
import Cta15ListingStatusButton from './Cta15ListingStatusButton';
import CtaListingDeleteButton from '../../ctaListingDeleteButton/CtaListingDeleteButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  ctaStatus: PropTypes.bool.isRequired,
  handleToasterLifecycle: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const Cta15ListingButtons = ({
  hubId,
  ctaId,
  ctaName,
  ctaStatus,
  handleToasterLifecycle,
  triggerRefresh,
}) => (
  <>
    <Cta15ListingCopyButton
      ctaId={ctaId}
      ctaName={ctaName}
      hubId={hubId}
      handleToasterLifecycle={handleToasterLifecycle}
      triggerRefresh={triggerRefresh}
    />

    <Cta15ListingStatusButton
      ctaId={ctaId}
      ctaName={ctaName}
      ctaStatus={ctaStatus}
      handleToasterLifecycle={handleToasterLifecycle}
      triggerRefresh={triggerRefresh}
    />

    <CtaListingDeleteButton
      ctaId={ctaId}
      ctaName={ctaName}
      handleToasterLifecycle={handleToasterLifecycle}
      triggerRefresh={triggerRefresh}
    />
  </>
);

Cta15ListingButtons.propTypes = propTypes;

export default Cta15ListingButtons;
