import CustomCodeListingDatatable from '../CustomCodeListingDatatable';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canCreateCustomCode: PropTypes.bool.isRequired,
  canDeleteCustomCode: PropTypes.bool.isRequired,
  canEditCustomCode: PropTypes.bool.isRequired,
  canEnableCustomCode: PropTypes.bool.isRequired,
  canViewCustomCode: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  streamId: PropTypes.number.isRequired,
};

const CustomCodeListingItemDatatable = ({ hubId, itemId, streamId, ...props }) => {
  const getEndpointBuilder = (hubId, itemId, streamId) => ({
    customCodeEditLink: (codeId) =>
      `/hubs/custom_code/edit/${hubId}/${itemId}/${streamId}/${codeId}`,
    customCodesIndex: () => `/api/v2/items/${itemId}/custom-code-blocks`,
    sharedCustomCodeEndpoint: (codeId) => `/api/v2/items/${itemId}/custom-code-blocks/${codeId}`,
  });

  return (
    <CustomCodeListingDatatable
      scope="item"
      getEndpointBuilder={getEndpointBuilder(hubId, itemId, streamId)}
      {...props}
    />
  );
};

CustomCodeListingItemDatatable.propTypes = propTypes;

export default CustomCodeListingItemDatatable;
