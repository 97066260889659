import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './stackedTile.less';
import cascade from './cascade_chrome_170x20.png';
import SelectedIcon from './SelectedIcon';
import ServiceIcon from '../shared/serviceIcon/ServiceIcon';

const { string, bool, func, number } = PropTypes;

const propTypes = {
  bottomText: string,
  disabled: bool,
  entity: PropTypes.oneOf(['hub', 'stream']).isRequired,
  id: number.isRequired,
  onClick: func,
  selected: bool,
  thumbnailUrl: string,
  title: string.isRequired,
  tooltip: string,
  type: string.isRequired,
};

const defaultProps = {
  bottomText: null,
  disabled: false,
  onClick: () => {},
  selected: false,
  thumbnailUrl: null,
  tooltip: '',
};

const StackedTile = ({
  id,
  title,
  type,
  thumbnailUrl,
  tooltip,
  selected,
  onClick,
  bottomText,
  disabled,
  entity,
}) => {
  const classes = [
    `ufr-${entity}-tile`,
    'ufr-stacked-tile',
    disabled && 'ufr-stacked-tile-disabled',
  ];
  return (
    <div
      id={`ufr-${entity}-tile-${id}`}
      role="button"
      title={tooltip}
      tabIndex={disabled ? -1 : 0}
      className={classnames(classes)}
      onClick={disabled ? undefined : onClick}
      onKeyDown={
        disabled
          ? undefined
          : (e) => {
              if (e.keyCode === 13) onClick();
            }
      }
    >
      <div className="ufr-stacked-tile-main">
        <div className="ufr-stacked-tile-title">
          <p>{title}</p>
          {selected && <SelectedIcon />}
        </div>
        {thumbnailUrl && (
          <div
            className="ufr-stacked-tile-image"
            style={{ backgroundImage: `url(${thumbnailUrl})` }}
          />
        )}
        <ServiceIcon service={type} size="small" />
        {bottomText && <div className="ufr-stacked-tile-bottom-text">{bottomText}</div>}
      </div>
      <div className="ufr-stacked-tile-cascade" style={{ backgroundImage: `url(${cascade})` }} />
    </div>
  );
};

StackedTile.propTypes = propTypes;
StackedTile.defaultProps = defaultProps;

export default StackedTile;
