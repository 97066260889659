import './salesforceModal.less';
import * as React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import axios from 'axios';
import SalesforceDropdown, { DropdownTitle } from './SalesforceDropdown';
import SearchableDropdown from './SearchableDropdown';

class MapIntegration extends React.Component {
  state = {
    loadingMapFields: false,
    mapFields: [],
    mapResponseError: false,
  };

  componentDidMount() {
    this.loadMapFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapType !== this.props.mapType) {
      this.loadMapFields();
    }
  }

  async loadMapFields() {
    const { mapType, handleDisableSubmit } = this.props;
    if (!mapType) return;
    this.setState({ loadingMapFields: true, mapFields: [] });
    handleDisableSubmit(true);

    try {
      const {
        data: { response },
      } = await axios.get(`/account/ajax_getMapFields/${mapType}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      this.setState({
        loadingMapFields: false,
        mapFields: response,
        mapResponseError: false,
      });
      handleDisableSubmit(false);
    } catch (e) {
      this.setState({
        loadingMapFields: false,
        mapResponseError: `Please check your ${mapType} credentials.`,
      });
      // TODO better error handling
      throw e;
    }
  }

  render() {
    const {
      maps,
      mapType,
      selectSalesforceIntegrationItem,
      contactIdField,
      leadIdField,
      mapErrors: { mapTypeError, leadIdError, contactIdError, noMapError },
    } = this.props;

    const mapFieldError = leadIdError && contactIdError;
    const { loadingMapFields, mapResponseError, mapFields } = this.state;

    return (
      <fieldset className="sfi-field-section">
        <h3 className="sfi-field-section-header">
          Marketing Automation Platform (MAP) Integration
        </h3>
        <div className="sfi-field-section-body">
          <DropdownTitle>MAP</DropdownTitle>
          <SalesforceDropdown
            loading={loadingMapFields}
            selected={mapType}
            menuItems={maps}
            error={mapTypeError || noMapError}
            selectDropdownItem={(item) =>
              selectSalesforceIntegrationItem({
                contactIdField: undefined,
                leadIdField: undefined,
                mapType: item,
              })
            }
          />
        </div>
        {mapType && !!mapFields?.length && (
          <>
            <div className="sfi-field-section-body">
              <DropdownTitle>SFDC Contact ID Field</DropdownTitle>
              <SearchableDropdown
                noDefault
                error={mapFieldError}
                selected={contactIdField}
                menuItems={mapFields}
                selectDropdownItem={(item) =>
                  selectSalesforceIntegrationItem({ contactIdField: String(item) })
                }
              />
            </div>
            <div className="sfi-field-section-body">
              <DropdownTitle>SFDC Lead ID Field</DropdownTitle>
              <SearchableDropdown
                noDefault
                error={mapFieldError}
                selected={leadIdField}
                menuItems={mapFields}
                selectDropdownItem={(item) =>
                  selectSalesforceIntegrationItem({ leadIdField: String(item) })
                }
              />
            </div>
          </>
        )}
        {noMapError && <p className="error">Please connect a MAP before connecting Salesforce.</p>}
        {mapResponseError && <p className="error">{mapResponseError}</p>}
        {(mapTypeError || mapFieldError) && !loadingMapFields && !noMapError && (
          <p className="error">Please provide the required information.</p>
        )}
      </fieldset>
    );
  }
}

MapIntegration.defaultProps = {
  contactIdField: null,
  leadIdField: null,
  mapType: null,
};

MapIntegration.propTypes = {
  contactIdField: string,
  handleDisableSubmit: func.isRequired,
  leadIdField: string,
  mapErrors: shape({
    contactIdError: bool,
    leadIdError: bool,
    mapTypeError: bool,
    noMapError: bool,
  }).isRequired,
  maps: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ).isRequired,
  mapType: string,
  selectSalesforceIntegrationItem: func.isRequired,
};

export default MapIntegration;
