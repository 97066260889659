import PropTypes from 'prop-types';

const shapes = {
  actionIcon: {
    icon: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    onClickCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    target: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  },

  header: {
    brandColor: PropTypes.string,
    icon: PropTypes.string,
    iconUrl: PropTypes.string,
    noImgUrl: PropTypes.string,
    rssUrl: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleUrl: PropTypes.string,
    title: PropTypes.string,
  },

  menuItem: {
    active: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    onClickCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    text: PropTypes.string,
    url: PropTypes.string,
  },
};

export default shapes;
