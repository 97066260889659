import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
import TabsContainer from '@/components/tabs/TabsContainer';
import WebsiteWidgetRulesDatatable from '@/components/datatables/websiteWidgetRules/WebsiteWidgetRulesDatatable';
import WebsiteWidgetTemplatesDatatable from '@/components/datatables/websiteWidgetTemplates/WebsiteWidgetTemplatesDatatable';

/* eslint-disable sort-keys */
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,

  // tabs
  activeTab: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tabContentId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,

  // Data tables
  hubId: PropTypes.number.isRequired,

  rulesProps: PropTypes.shape({
    hasAddPermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
  }),
  templatesProps: PropTypes.shape({
    hasAddPermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
  }),

  // embed
  widgetScriptUrl: PropTypes.string.isRequired,
};

const SiteEngagerPage = ({
  title,
  subtitle,
  rulesProps,
  templatesProps,
  widgetScriptUrl,
  ...props
}) => {
  const headerText = {
    title: { text: title },
    subtitle: { text: subtitle },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <TabsContainer {...props} />
      <div id="list-rules" className="hidden">
        <WebsiteWidgetRulesDatatable hubId={props.hubId} {...rulesProps} />
      </div>
      <div id="list-templates" className="hidden">
        <WebsiteWidgetTemplatesDatatable hubId={props.hubId} {...templatesProps} />
      </div>
      <div id="embed-script" className="hidden">
        <div className="embed-script-wrapper" style={{ marginTop: 10 }}>
          <p>
            Copy the following code into your website’s HTML inside the &lt;body&gt; tag. Display
            settings can then be configured from your Uberflip account.
          </p>
          <div className="embed-script-text">
            <textarea
              className="input form-control"
              id="embedScript"
              readOnly="readonly"
              value={`<!-- Uberflip Site Engager v1.0 //-->\n<script src="${widgetScriptUrl}" async></script>`}
            />
            <input
              type="button"
              id="embedScriptBtn"
              className="btn btn-info"
              data-clipboard-target="#embedScript"
              value="Copy to Clipboard"
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

SiteEngagerPage.propTypes = propTypes;

export default SiteEngagerPage;
