import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../../shared/button/Button';
import ConfirmationModal from '../../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TextInput from '../../formInputs/textInput/TextInput';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  endpoint: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
  entityName: PropTypes.string.isRequired,
  entityValue: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  showMessage: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const defaultProps = {};

const modalBody = (name, value) => (
  <>
    <p>
      <TextInput
        title="Parameter Name"
        description="Set the name of this parameter. This is the part of the parameter before the = sign, e.g. utm_campaign."
        id="param-name"
        value={name}
      />
      <TextInput
        title="Parameter Value"
        description="Set the value to assign to this parameter. This is the part of the parameter after the = sign, e.g. mycampaign."
        id="param-val"
        value={value}
      />
    </p>
  </>
);

const EmbedParameterEditButton = ({
  entityId,
  entityName,
  entityValue,
  header,
  endpoint,
  showMessage,
  triggerRefresh,
  disabled,
}) => {
  const editParam = async (csrfToken) => {
    showMessage('info', 'Saving...', entityId);

    const name = document.getElementById('param-name').value;
    const val = document.getElementById('param-val').value;

    const parameterNameUnchanged = name === entityName;
    let bodyRequest = {};

    if (parameterNameUnchanged) {
      bodyRequest = {
        parameter_value: val,
      };
    } else {
      bodyRequest = {
        parameter_name: name,
        parameter_value: val,
      };
    }

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: endpoint(entityId),
        data: bodyRequest,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      showMessage('success', `Embed parameter ${entityName} updated.`, entityId);
      triggerRefresh();
    } catch {
      showMessage(
        'error',
        `There was an error updating the embed parameter ${entityName}.`,
        entityId,
      );
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
            handleConfirm={() => editParam(csrfToken)}
            body={modalBody(entityName, entityValue)}
            header={header}
            primaryButtonText="Save Parameter"
          />

          <Button
            label="edit embed parameter"
            icon="pencil"
            id={`edit-embed-param-${entityId}`}
            onClick={() => setIsOpen(true)}
            disabled={disabled}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

EmbedParameterEditButton.propTypes = propTypes;
EmbedParameterEditButton.defaultProps = defaultProps;

export default EmbedParameterEditButton;
