import Form from '../form/Form';
import handleInputReady from '../../utils/handleInputReady';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SelectDropdown from '../formInputs/selectDropdown/SelectDropdown';

const propTypes = {
  select: PropTypes.shape({
    description: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ).isRequired,
    submitData: PropTypes.shape({
      func: PropTypes.string.isRequired,
      namespace: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const AppConfigurationPage = ({ select: { title, description, options, submitData } }) => {
  const [activeApp, setActiveApp] = useState(null);

  const handleSelect = (selectedApp) => {
    handleInputReady(submitData, selectedApp, (apiResponse) => {
      if (apiResponse.meta.status === 'success') {
        setActiveApp(apiResponse.response.data);
      }
    });
  };

  return (
    <>
      {options.length ? (
        <SelectDropdown
          id="app-configuration"
          title={title}
          description={description}
          items={options}
          value="Select App"
          submitData={(selectedItem) => handleSelect(selectedItem)}
        />
      ) : (
        <p>There are currently no Apps installed that are configurable at the Stream level.</p>
      )}

      {activeApp && (
        <div className="ufr-app-configuration-options">
          <Form
            headerText={activeApp.name}
            fields={activeApp.fields}
            resetAll={activeApp.resetAll}
          />
        </div>
      )}
    </>
  );
};

AppConfigurationPage.propTypes = propTypes;

export default AppConfigurationPage;
