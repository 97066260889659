import PropTypes from 'prop-types';
import React from 'react';

import AppIcon from '../shared/appIcon/AppIcon';
import hubServices from '../../constants/hubServices';
import ServiceIcon from '../shared/serviceIcon/ServiceIcon';

const Icon = ({ icon }) => {
  const serviceTypes = Object.keys(hubServices);
  const isNativeServiceType = serviceTypes.indexOf(icon) !== -1;

  if (isNativeServiceType) {
    return <ServiceIcon format="small" service={icon} />;
  }
  if (icon.startsWith('https')) {
    return <AppIcon format="small" imgUrl={icon} />;
  }
  return null;
};

Icon.propTypes = {
  icon: PropTypes.string,
};

export default Icon;
