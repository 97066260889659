import PropTypes from 'prop-types';
import React from 'react';

import './topnavDropdown.less';
import DropdownItem from './TopnavDropdownItem';
import Link from './TopnavLink';
import shapes from './propShapes';

const TopnavDropdown = ({ item, user, isRightMenu }) => {
  const { id, children, defaultIcon } = item;

  const classes = ['topnav-dropdown', isRightMenu ? 'topnav-dropdown-right' : ''];

  return (
    <li id={`${id}-dropdown`} className={classes.join(' ')}>
      <Link id={id} href={item.url} item={item} user={user} forDropdown />

      <ul className="topnav-dropdown-content">
        {children.map((child) => (
          <DropdownItem key={child.text + child.id} defaultIcon={defaultIcon} {...child} />
        ))}
      </ul>
    </li>
  );
};

TopnavDropdown.defaultProps = {
  isRightMenu: false,
};

TopnavDropdown.propTypes = {
  isRightMenu: PropTypes.bool,
  item: PropTypes.shape(shapes.item).isRequired,
  user: PropTypes.shape(shapes.user).isRequired,
};

export default TopnavDropdown;
