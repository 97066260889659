import DatatableThumbnail from '../../datatable/DatatableThumbnail';
import LangLinkDatatable from './LangLinkDatatable';
import LangLinkDeleteButton from './LangLinkDeleteButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  itemId: PropTypes.number.isRequired,
  readonly: PropTypes.bool,
};

const defaultProps = {
  readonly: true,
};

const getEndpointBuilder = (itemId) => ({
  createLink: (id) => `/api/v2/items/${itemId}/language-links/${id}`,
  deleteLink: (id) => `/api/v2/items/${id}/language-links`,
  deleteSelf: () => `/api/v2/items/${itemId}/language-links`,
  getLinkables: () => `/api/v2/items/${itemId}/language-linkable-items`,
  getLinks: () => `/api/v2/items/${itemId}/language-links`,
  getLocales: () => `/api/v2/items/${itemId}/language-linkable-locales`,
});

const cellClass = (key) => `ufr-dt-langlink-${key}-cell`;
const headerClass = (key) => `ufr-dt-langlink-${key}-header`;

/* eslint-disable sort-keys */
const columns = [
  {
    Header: 'Item Name',
    accessor: 'item',
    id: 'item.title', // The id must match the name of the column we want to sort by on the api
    className: cellClass('itemname'),
    headerClassName: headerClass('itemname'),
    minWidth: 200,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value: item }) => [
      <DatatableThumbnail key={1} type={item.type} imageUrl={item.thumbnail_url} />,
      <div key={2} className="ufr-link-title ufr-link-item-title">
        {item.title}
      </div>,
    ],
  },
  {
    Header: 'Locale',
    accessor: 'item',
    id: 'item.locale.display_name',
    className: cellClass('locale'),
    headerClassName: headerClass('locale'),
    Cell: ({ value: item }) => item.locale.display_name,
  },
  {
    Header: 'Date Created',
    accessor: 'created_at',
    className: cellClass('created'),
    headerClassName: headerClass('created'),
    maxWidth: 150,
    Cell: ({ value: date }) => date.slice(0, 10),
  },
  {
    Header: '',
    accessor: 'item',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    width: 40,
    /* eslint-disable react/prop-types */
    Cell: ({ value: item, popToast, readonly, refresh, deleteLink }) =>
      !readonly && (
        <LangLinkDeleteButton
          entityId={item.id}
          header="Delete Item link?"
          message={`Are you sure you want to delete this Items's link with Item "${item.title}?"`}
          getEndpoint={deleteLink}
          showMessage={popToast}
          triggerRefresh={refresh}
        />
      ),
    /* eslint-enable react/prop-types */
  },
];
/* eslint-enable sort-keys */

const ItemLangLinksDatatable = ({ itemId, readonly }) => (
  <LangLinkDatatable
    readonly={readonly}
    endpointBuilder={getEndpointBuilder(itemId)}
    type="item"
    columns={columns}
  />
);

ItemLangLinksDatatable.propTypes = propTypes;
ItemLangLinksDatatable.defaultProps = defaultProps;

export default ItemLangLinksDatatable;
