import './checkbox.less';
import classNames from 'classnames';
import handleInputReady from '../../../utils/handleInputReady';
import Input from '../input/Input';
import InputContainer from '../inputContainer/InputContainer';
import Label from '../inputContainer/Label/Label';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  // required for acceptance tests and a11y decorators
  id: PropTypes.string.isRequired,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  onChange: PropTypes.func,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
  title: PropTypes.string.isRequired,
  // Add a readonly validation rules object to the input
  validationRules: PropTypes.objectOf(PropTypes.shape),
  // value defining whether the toggle is on/off
  value: PropTypes.bool,
  variant: PropTypes.string,
};

const defaultProps = {
  description: '',
  disabled: false,
  name: '',
  submitData: {},
  validationRules: {},
  value: false,
};

class Checkbox extends React.Component {
  state = {
    val: this.props.value,
  };

  handleChange = () => {
    const { disabled, submitData, name, validationRules } = this.props;

    if (disabled) return;

    const newVal = !this.state.val;
    const componentData = {
      propertyName: name,
      propertyValue: newVal ? 1 : 0,
      validationRules,
    };

    this.setState({ val: newVal });
    handleInputReady(submitData, componentData);

    if (this.props.onChange) {
      this.props.onChange(newVal);
    }
  };

  render() {
    const { id, title, description, disabled } = this.props;

    const classes = ['ufr-checkbox', { 'ufr-input-component--disabled': disabled }];

    const inputContainerClasses = ['ufr-checkbox-input-container'];
    if (this.props.variant) {
      inputContainerClasses.push('ufr-checkbox-input-container--' + this.props.variant);
    }

    return (
      <InputContainer id={id} description={[]} className={inputContainerClasses}>
        <div className="ufr-checkbox-wrapper">
          <Input
            id={id}
            type="checkbox"
            handleChange={this.handleChange}
            className={classNames(classes)}
            value={this.state.val}
            disabled={disabled}
          />
          <Label htmlFor={id}>{title}</Label>
        </div>
        <p id={`${id}-input-description`} className="react-input-description">
          {description}
        </p>
      </InputContainer>
    );
  }
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
