import CustomCodeListingDatatable from '../CustomCodeListingDatatable';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canCreateCustomCode: PropTypes.bool.isRequired,
  canDeleteCustomCode: PropTypes.bool.isRequired,
  canEditCustomCode: PropTypes.bool.isRequired,
  canEnableCustomCode: PropTypes.bool.isRequired,
  canViewCustomCode: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
};

const CustomCodeListingHubDatatable = ({ hubId, ...props }) => {
  const getEndpointBuilder = (hubId) => ({
    customCodeEditLink: (codeId) => `/hubs/custom_code/edit/${hubId}/${codeId}`,
    customCodesIndex: () => `/api/v2/hubs/${hubId}/custom-code-blocks`,
    sharedCustomCodeEndpoint: (codeId) => `/api/v2/hubs/${hubId}/custom-code-blocks/${codeId}`,
  });

  return (
    <CustomCodeListingDatatable
      scope="hub"
      getEndpointBuilder={getEndpointBuilder(hubId)}
      {...props}
    />
  );
};

CustomCodeListingHubDatatable.propTypes = propTypes;

export default CustomCodeListingHubDatatable;
