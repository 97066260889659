import { CsrfContextConsumer } from '../../../../DataProviders/CsrfProvider';
import CancelButton from '../../../modal/SecondaryButton/CancelButton';
import ModalFooter from '../../../modal/ModalFooter/ModalFooter';
import PrimaryButton from '../../../modal/PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  entityName: PropTypes.oneOf(['Item', 'Hub', 'Stream']).isRequired,
  loading: PropTypes.bool.isRequired,
  numberSelected: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const defaultProps = {};

const LinkablesModalFooter = ({ onCancel, onConfirm, loading, numberSelected, entityName }) => {
  const hasSelection = numberSelected > 0;
  const selectedText =
    hasSelection && `${numberSelected} ${entityName}${numberSelected > 1 ? 's' : ''} Selected`;

  return (
    <ModalFooter>
      <p className="ufr-modal-infotext">{selectedText}</p>
      <CancelButton onClick={onCancel} />
      {hasSelection && (
        <CsrfContextConsumer>
          {(csrfToken) => (
            <PrimaryButton label="Add" onClick={() => onConfirm(csrfToken)} disabled={loading}>
              Add
            </PrimaryButton>
          )}
        </CsrfContextConsumer>
      )}
    </ModalFooter>
  );
};

LinkablesModalFooter.propTypes = propTypes;
LinkablesModalFooter.defaultProps = defaultProps;

export default LinkablesModalFooter;
