import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import RecommendationTemplatesDeleteButton from './RecommendationTemplatesDeleteButton';

const propTypes = {
  decrementRecommendationTemplateCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  lockDelete: PropTypes.bool.isRequired,
  recommendationTemplateId: PropTypes.number.isRequired,
  recommendationTemplateName: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const RecommendationTemplateButtons = ({
  decrementRecommendationTemplateCount,
  dispatchToasterAction,
  hubId,
  lockDelete,
  recommendationTemplateId,
  recommendationTemplateName,
  triggerRefresh,
}) => (
  <>
    <Button
      href={`/recommendation_templates/edit/${hubId}/${recommendationTemplateId}`}
      icon="pencil"
      title="Edit Template"
      label="Edit Template"
      id={`edit-recommendation-template-${recommendationTemplateId}`}
      className="ufr-edit-button"
    />
    {lockDelete ? null : (
      <RecommendationTemplatesDeleteButton
        decrementRecommendationTemplateCount={decrementRecommendationTemplateCount}
        dispatchToasterAction={dispatchToasterAction}
        recommendationTemplateId={recommendationTemplateId}
        recommendationTemplateName={recommendationTemplateName}
        triggerRefresh={triggerRefresh}
      />
    )}
  </>
);

RecommendationTemplateButtons.propTypes = propTypes;

export default RecommendationTemplateButtons;
