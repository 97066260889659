import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './toggle.less';

const FakeCheckbox = ({ id, val, disabled, handleClick }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const classes = ['ufr-toggle', val && 'active', disabled && 'disabled'];

  return (
    <div
      id={id}
      role="checkbox"
      className={classNames(classes)}
      aria-checked={val}
      tabIndex={disabled ? null : '0'}
      onClick={disabled ? null : handleClick}
      onKeyDown={disabled ? null : handleKeyDown}
    >
      <span className="ufr-toggle-slider" />
    </div>
  );
};

FakeCheckbox.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  val: PropTypes.bool.isRequired,
};

FakeCheckbox.defaultProps = {
  disabled: false,
};

export default FakeCheckbox;
