import './tagsTransferListModal.less';
import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import _ from 'lodash';
import axios from 'axios/index';
import Modal from '@/components/modal/Modal/Modal';
import ModalBody from '@/components/modal/ModalBody/ModalBody';
import ModalFooter from '@/components/modal/ModalFooter/ModalFooter';
import ModalHeader from '@/components/modal/ModalHeader/ModalHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import TransferList from '@/components/shared/transferList/TransferList';

const ERROR_CODE_RANGE_LOWER = 399;
const ERROR_CODE_RANGE_UPPER = 500;

const formatTags = (data) => data.map((tag) => ({ id: tag.id, name: tag.name }));

const getTagIds = (data) => data.map((tag) => tag.id);

const idsToUpdate = (originalData, newData) => newData.filter((id) => !originalData.includes(id));

const checkForErrorCodes = (tags) => {
  for (const tag of tags) {
    if (tag.code > ERROR_CODE_RANGE_LOWER && tag.code < ERROR_CODE_RANGE_UPPER) {
      return true;
    }
  }
  return false;
};

/* eslint-disable sort-keys */
const TagsTransferListModal = ({ ...props }) => {
  const {
    isOpen,
    handleClose,
    tagGroupId,
    tagGroupName,
    loadedTransferFromData,
    loadedTransferToData,
    dispatchToasterAction,
  } = props;

  const headerTitle = `Add Tags to "${tagGroupName}" Tag Group`;

  const formattedLoadedTransferFromData = formatTags(loadedTransferFromData);
  const formattedLoadedTransferToData = formatTags(loadedTransferToData);

  const originalTransferFromData = useRef(formattedLoadedTransferFromData);
  const originalTransferToData = formattedLoadedTransferToData;
  const transferFromData = useRef(formattedLoadedTransferFromData);
  const [transferFrom, setTransferFrom] = useState(transferFromData.current);
  const [transferTo, setTransferTo] = useState(originalTransferToData);
  const [addTagDisabled, setAddTagDisabled] = useState(false);
  const [doneDisabled, setDoneDisabled] = useState(false);

  const transferListProps = {
    transferFromData,
    transferFrom,
    setTransferFrom,
    transferTo,
    setTransferTo,
    addTagDisabled,
    setAddTagDisabled,
    setDoneDisabled,
    tagGroupId,
    originalTransferFromData,
    dispatchToasterAction,
  };

  const onSubmit = async (csrfToken) => {
    setDoneDisabled(true);

    const toaster = {
      id: 'new-tag-group-toaster',
    };

    const url = `/api/v2/batch-update-tags`;

    const data = {};

    const ungroupedTagIds = getTagIds(transferFromData.current);
    const originalUngroupedTagIds = getTagIds(originalTransferFromData.current);

    const groupedTagIds = getTagIds(transferTo);
    const originalGroupedTagIds = getTagIds(originalTransferToData);

    //data did not change
    const ungroupedDataNotChanged = _.isEqual(ungroupedTagIds, originalUngroupedTagIds);
    const groupedDataNotChanged = _.isEqual(groupedTagIds, originalGroupedTagIds);
    if (ungroupedDataNotChanged || groupedDataNotChanged) {
      toaster.type = 'error';
      toaster.text = 'Error: No tags were added or removed';
      dispatchToasterAction(addToaster(toaster));
      setTimeout(() => {
        dispatchToasterAction(slideDownAndRemoveToaster(toaster));
      }, 2000);
      setDoneDisabled(false);
      return;
    }

    const ungroupedTagIdsToUpdate = idsToUpdate(originalUngroupedTagIds, ungroupedTagIds);
    const groupedTagIdsToUpdate = idsToUpdate(originalGroupedTagIds, groupedTagIds);

    if (ungroupedTagIdsToUpdate.length > 0) {
      data.ungrouped = { tag_ids: ungroupedTagIdsToUpdate };
    }

    if (groupedTagIdsToUpdate.length > 0) {
      data.grouped = { tag_ids: groupedTagIdsToUpdate, tag_group_id: tagGroupId };
    }

    await axios({
      method: 'patch',
      url,
      data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        let ungroupedTagsError = false;
        let groupedTagsError = false;
        const tags = response.data.data;
        if ('ungrouped' in tags && tags.ungrouped.length > 0) {
          ungroupedTagsError = checkForErrorCodes(tags.ungrouped);
        }
        if ('grouped' in tags && tags.grouped.length > 0) {
          groupedTagsError = checkForErrorCodes(tags.grouped);
        }
        if (ungroupedTagsError || groupedTagsError) {
          toaster.type = 'error';
          toaster.text = 'Error: Not all tags updated successfully';
        } else {
          toaster.type = 'success';
          toaster.text = 'Tags updated successfully';
        }
        handleClose();
      })
      .catch((error) => {
        toaster.type = 'error';
        toaster.text = 'Error: ' + error.response.data.errors[0].message;
      });

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
    setDoneDisabled(false);
  };

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {/* eslint-disable-next-line */}
        {(csrfToken) => {
          return (
            <Modal isOpen={isOpen} className="tagsTransferListModal">
              <ModalHeader title={headerTitle} handleClose={handleClose} />
              <ModalBody>
                <TransferList {...transferListProps} />
              </ModalBody>
              <ModalFooter>
                <PrimaryButton
                  label="Add Tags in Group"
                  onClick={() => onSubmit(csrfToken)}
                  disabled={doneDisabled}
                >
                  Done
                </PrimaryButton>
              </ModalFooter>
            </Modal>
          );
        }}
      </CsrfContextConsumer>
    </CsrfProvider>
  );
};

//repeated tag prop
const tagPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

TagsTransferListModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  tagGroupId: PropTypes.number.isRequired,
  tagGroupName: PropTypes.string.isRequired,
  loadedTransferFromData: tagPropType,
  loadedTransferToData: tagPropType,
  dispatchToasterAction: PropTypes.func,
};

export default TagsTransferListModal;
