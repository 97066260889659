import PropTypes from 'prop-types';
import React from 'react';

import StackedTile from './StackedTile';

const { string, bool, func, number } = PropTypes;

const propTypes = {
  bottomText: string,
  disabled: bool,
  id: number.isRequired,
  onClick: func,
  selected: bool,
  thumbnailUrl: string,
  title: string.isRequired,
  tooltip: string,
  type: string.isRequired,
};

const defaultProps = {
  bottomText: null,
  disabled: false,
  onClick: () => {},
  selected: false,
  thumbnailUrl: null,
  tooltip: '',
};

const StreamTile = ({ ...props }) => <StackedTile {...props} entity="stream" />;

StreamTile.propTypes = propTypes;
StreamTile.defaultProps = defaultProps;

export default StreamTile;
