import './linkInput.less';
import PropTypes from 'prop-types';
import React from 'react';
import SelectDropdown from '../selectDropdown/SelectDropdown';
import TextInput from '../textInput/TextInput';

const propTypes = {
  handleChangePrefix: PropTypes.func,
  handleChangeUrl: PropTypes.func,
  prefixValue: PropTypes.string,
  urlValue: PropTypes.string,
};

const LinkInput = ({ ...props }) => {
  const { handleChangePrefix, handleChangeUrl, prefixValue, urlValue } = props;
  const items = [
    {
      text: 'https://',
      value: 'https://',
    },
    {
      text: 'http://',
      value: 'http://',
    },
    {
      text: 'mailto:',
      value: 'mailto:',
    },
    {
      text: 'tel:',
      value: 'tel:',
    },
    {
      text: 'sms:',
      value: 'sms:',
    },
  ];
  return (
    <>
      <div className="link-input-label">Link to</div>
      <div className="link-input">
        <SelectDropdown
          id="link-prefix-list"
          items={items}
          handleChange={handleChangePrefix}
          submitData={() => {}}
          value={prefixValue}
        />
        <TextInput id="link-url" handleChange={handleChangeUrl} value={urlValue} />
      </div>
    </>
  );
};

LinkInput.propTypes = propTypes;

export default LinkInput;
