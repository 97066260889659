import PropTypes from 'prop-types';
import React from 'react';

import './editableText.less';
import InlineEditButton from './InlineEditButton';
import InlineSubmitButton from './InlineSubmitButton';
import InlineTextInput from './InlineTextInput';

class EditableText extends React.Component {
  constructor(props) {
    super();
    this.state = {
      editing: false,
      value: props.text,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  handleSubmit(e, value) {
    e.preventDefault();
    if (value === null || value === undefined) {
      this.reset();
      return;
    }
    const { onSubmit, text, nullable, onSubmitCallback } = this.props;
    const { namespace, func } = onSubmitCallback;

    const valueTrimmed = value.trim();
    const textTrimmed = text ? text.trim() : '';
    const hasChanged = valueTrimmed !== textTrimmed;
    const valid = nullable || valueTrimmed;
    if (hasChanged && valid) {
      if (onSubmit) onSubmit(value);

      // use global callback if provided
      if (onSubmitCallback && window[namespace]) {
        window[namespace][func](value);
      }

      this.setState({
        editing: false,
        value,
      });
    } else {
      this.reset();
    }
  }

  reset() {
    this.setState({
      editing: false,
      value: this.props.text,
    });
  }

  render() {
    const { className, textType, size, placeholder, editable, showCharacterCounter, maxLength } =
      this.props;
    const { editing, value } = this.state;
    let button;
    if (editable) {
      if (editing) {
        button = (
          <InlineSubmitButton
            ariaLabel={`submit ${textType}`}
            size={size}
            handleClick={this.handleSubmit}
          />
        );
      } else {
        button = (
          <InlineEditButton
            ariaLabel={`edit ${textType}`}
            size={size}
            handleClick={() => this.setState({ editing: true })}
          />
        );
      }
    }

    const classes = ['editable-text', `${size}-text`, className, editing && 'editing-mode'].filter(
      (val) => !!val,
    );

    const label = value || (editable && placeholder);

    return (
      <div className={classes.join(' ')}>
        {editing ? (
          <InlineTextInput
            ariaLabel={`input ${textType}`}
            text={value}
            size={size}
            handleSubmit={this.handleSubmit}
            handleEscape={this.reset}
            showCharacterCounter={showCharacterCounter}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        ) : (
          <div role="heading" className="editable-text-static-header">
            {label}
          </div>
        )}

        {button}
      </div>
    );
  }
}

EditableText.defaultProps = {
  className: '',
  editable: false,
  nullable: false,
  onSubmit: null,
  onSubmitCallback: {},
  placeholder: '',
  size: 'large',
  text: '',
};

EditableText.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  maxLength: PropTypes.number,
  nullable: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  showCharacterCounter: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'small']),
  text: PropTypes.string,
  /** what is being edited, for the aria-labels */
  textType: PropTypes.string.isRequired,
};

export default EditableText;
