import PropTypes from 'prop-types';
import React from 'react';

import './sidenav.less';
import Actions from './Actions';
import Header from './Header';
import Menu from './menu/Menu';
import shapes from './propShapes';

const Sidenav = ({ brandColor, header, actions, menu, footer }) => (
  <div id="sidenav">
    {header && <Header {...header} brandColor={brandColor} />}

    {actions && <Actions actions={actions} brandColor={brandColor} />}

    <Menu menu={menu} brandColor={brandColor} hasFooter={footer && footer.length > 0} />

    {footer && <Actions footer actions={footer} brandColor={brandColor} />}
  </div>
);

// note: destructuring here breaks hypernova
const menuItem = shapes.menuItem;
menuItem.subMenu = PropTypes.arrayOf(PropTypes.shape(Object.assign({}, menuItem)));

Sidenav.defaultProps = {
  actions: null,
  brandColor: '#ce0058',
  footer: null,
};

Sidenav.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(shapes.actionIcon)),
  brandColor: PropTypes.string,
  footer: PropTypes.arrayOf(PropTypes.shape(shapes.actionIcon)),
  header: PropTypes.shape(shapes.header).isRequired,
  menu: PropTypes.arrayOf(PropTypes.shape(menuItem)).isRequired,
};

export default Sidenav;
