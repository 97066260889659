import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddWebsiteWidgetTemplatesModal from './AddWebsiteWidgetTemplatesModal';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';
import WebsiteWidgetTemplatesButtons from '@/components/datatables/websiteWidgetTemplates/WebsiteWidgetTemplatesButtons';

const propTypes = {
  hasAddPermission: PropTypes.bool.isRequired,
  hasDeletePermission: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
};

const cellClass = (key) => `ufr-dt-website-widget-templates-${key}-cell`;
const headerClass = (key) => `ufr-dt-website-widget-templates-${key}-header`;

const entityName = {
  plural: 'Templates',
  singular: 'Template',
};

const createWebsiteWidgetTemplateActionButton = (setIsAddTemplateModalOpen) => ({
  className: 'ufr-btn ufr-btn-primary',
  id: 'create-website-widget-template',
  onClick: () => setIsAddTemplateModalOpen(true),
  text: 'Build Template',
});

const searchPlaceholder = (websiteWidgetTemplateCount) =>
  `Search ${websiteWidgetTemplateCount} ${
    entityName[websiteWidgetTemplateCount === 1 ? 'singular' : 'plural']
  }`;

/* eslint-disable sort-keys */
const columns = ({ dispatchToasterAction, hasDeletePermission, hasEditPermission }) => [
  {
    Header: 'Template Name',
    accessor: 'name',
    id: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    Cell: (cell) =>
      cell.original.name ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-template-name-tooltip" placement="top">
              {cell.original.name}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.name}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    Cell: (cell) =>
      cell.original.description ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-template-description-tooltip" placement="top">
              {cell.original.description}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.description}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
    sortable: false,
  },
  {
    Header: 'Rule Count',
    accessor: 'rule_count',
    id: 'rule_count',
    className: cellClass('rule-count'),
    headerClassName: headerClass('rule-count'),
    Cell: (cell) => <>{cell.original.rule_count}</>,
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    width: 100,
    Cell: (cell) => (
      <WebsiteWidgetTemplatesButtons
        hasDeletePermission={hasDeletePermission}
        hasEditPermission={hasEditPermission}
        hubId={cell.original.hub_id}
        websiteWidgetTemplateId={cell.original.id}
        websiteWidgetTemplateLockDelete={cell.original.lock_delete}
        websiteWidgetTemplateName={cell.original.name}
        websiteWidgetTemplateRuleCount={cell.original.rule_count}
        triggerRefresh={cell.refresh}
        decrementWebsiteWidgetTemplateCount={cell.decrementRecommendationTemplateCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ),
  },
];
/* eslint-enable sort-keys */

const getEndpointBuilder = (hubId) => ({
  getWebsiteWidgetTemplates: () => `/api/v2/hubs/${hubId}/website-widget-templates`,
});

const WebsiteWidgetTemplatesDatatable = ({
  hasAddPermission,
  hasDeletePermission,
  hasEditPermission,
  hubId,
}) => {
  const [websiteWidgetTemplateCount, setWebsiteWidgetTemplateCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);

  const { getWebsiteWidgetTemplates } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getWebsiteWidgetTemplates(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setWebsiteWidgetTemplateCount(meta.count);
    }
    return { data, meta };
  };

  const modals = () => [
    <AddWebsiteWidgetTemplatesModal
      handleClose={() => setIsAddTemplateModalOpen(false)}
      hubId={hubId}
      isAddTemplateModalOpen={isAddTemplateModalOpen}
    />,
  ];

  return (
    <CsrfProvider>
      <DataTable
        id="website-widget-templates"
        useStateHandling
        entityName={entityName}
        columns={columns({ dispatchToasterAction, hasDeletePermission, hasEditPermission })}
        getData={getData}
        actionButtons={
          hasAddPermission
            ? [createWebsiteWidgetTemplateActionButton(setIsAddTemplateModalOpen)]
            : []
        }
        searchPlaceholder={searchPlaceholder(websiteWidgetTemplateCount)}
        noSearchResultsMessage={<p>{isInitialLoad ? ' ' : 'No templates match your search.'}</p>}
        initialSort={{ desc: false, id: 'name' }}
        showLoadingState={isInitialLoad}
        getCellProps={() => ({
          decrementWebsiteWidgetTemplateCount: () => {
            setWebsiteWidgetTemplateCount(websiteWidgetTemplateCount - 1);
          },
        })}
        Modals={modals}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

WebsiteWidgetTemplatesDatatable.propTypes = propTypes;

export default WebsiteWidgetTemplatesDatatable;
