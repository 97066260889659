import './mediaModal.less';
import Button from '../../shared/button/Button';
import ExternalUrl, { ExternalUrlConfig } from './MediaUploadTypes/ExternalUrl';
import handleCallback from '@/utils/handleCallback';
import MediaLibrary, { MediaLibraryConfig } from './MediaUploadTypes/MediaLibrary';
import Modal from '../Modal/Modal';
import ModalBody from '../ModalBody/ModalBody';
import ModalSideNav from '../ModalSideNav/ModalSideNav';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ExternalUrlMediaType = PropTypes.shape({
  displayName: PropTypes.string,
  props: PropTypes.shape({
    ...ExternalUrlConfig,
  }),
  type: PropTypes.oneOf(['ExternalUrl']),
});

const MediaLibraryType = PropTypes.shape({
  displayName: PropTypes.string,
  props: PropTypes.shape({
    ...MediaLibraryConfig,
  }),
  type: PropTypes.oneOf(['MediaLibrary']),
});

const propTypes = {
  buttonLabel: PropTypes.string,
  description: PropTypes.string,
  hideButton: PropTypes.bool,
  id: PropTypes.string,
  mediaTypes: PropTypes.arrayOf(PropTypes.oneOfType([ExternalUrlMediaType, MediaLibraryType])),
  submitCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
};

const mediaTypeComponentMap = {
  ExternalUrl,
  MediaLibrary,
};

const defaultProps = {
  buttonLabel: 'Select Image',
  description: 'Select from',
  hideButton: false,
  id: 'ufr-media-modal-button',
  mediaTypes: [
    {
      displayName: 'External Link',
      type: 'ExternalUrl',
    },
  ],
  submitCallback: {},
};

const MediaModal = ({ buttonLabel, id, hideButton, submitCallback, description, mediaTypes }) => {
  const [selectedIndex, setSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setSelected(-1);
    setIsOpen(false);
  };
  const handleOpen = () => {
    setSelected(0);
    setIsOpen(true);
  };
  const handleToggle = () => {
    isOpen ? handleClose() : handleOpen();
  };

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '');
  }, [isOpen]);

  const handleSelect = (index) => {
    setSelected(index);
  };

  const handleSubmit = async (data) => {
    const handled = await handleCallback({ callback: submitCallback, data });
    if (handled) {
      // this will set everything to inactive so that they can be reset
      setSelected(-1);
      setIsOpen(false);
    }
  };

  const sideNavData = [];
  const bodyData = [];
  mediaTypes.forEach((mediaType, index) => {
    const key = mediaType.type;
    const internalName = `${key}-${index}`;
    sideNavData.push({
      displayName: mediaType.displayName,
      internalName,
    });
    bodyData.push({
      component: mediaTypeComponentMap[key],
      componentProps: mediaType.props,
      internalName,
    });
  });

  return (
    <>
      <Button
        id={id}
        overrideId={true}
        className={hideButton ? 'hidden' : ''}
        onClick={handleToggle}
        label={buttonLabel}
      >
        {buttonLabel}
      </Button>
      <Modal
        alwaysRenderBody={true}
        size="full"
        isOpen={isOpen}
        handleClose={handleClose}
        className={'ufr-media-modal'}
      >
        <ModalSideNav
          handleClose={handleClose}
          handleSelect={handleSelect}
          data={sideNavData}
          selected={selectedIndex}
          description={description}
        />
        <ModalBody>
          {bodyData.map((d, i) => {
            const MediaComponent = d.component;
            let active = selectedIndex === i;

            return (
              <MediaComponent
                submitCallback={handleSubmit}
                key={`ufr-media-modal-body-${id}-${d.internalName}`}
                active={active}
                id={id}
                {...d.componentProps}
              />
            );
          })}
        </ModalBody>
      </Modal>
    </>
  );
};

MediaModal.propTypes = propTypes;
MediaModal.defaultProps = defaultProps;
export default MediaModal;
