import './modal.less';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import ModalHeader from '../ModalHeader/ModalHeader';
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';

const widths = {
  full: '100%',
  medium: 810,
  small: 600,
};

const modalOpenClass = 'ufr-modal-is-open';

const getScrollProp = () => (window.scrollY ? 'scrollY' : 'pageYOffset'); // IE uses "pageYOffset"

const windowExists = () => typeof window !== 'undefined';

class Modal extends Component {
  state = {
    animation: '',
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.setupModal();
    }
  }

  componentDidUpdate({ isOpen }) {
    const hasChanged = isOpen !== this.props.isOpen;
    if (!hasChanged) return;

    if (isOpen) {
      this.setState({ animation: 'slide-in' });
      this.setupModal();
      return;
    }

    this.startClosingAnimation();
  }

  componentWillUnmount() {
    this.cleanUpModal();
  }

  /**
   * When the modal opens, lock scroll on the body but remember where it was scrolled to.
   * Listen for ESC presses
   */
  setupModal = () => {
    // don't blow up hypernova
    if (!windowExists()) return;

    window.addEventListener('keydown', this.handleEscapeKeyPress, false);
    this.scrollPosition = window[getScrollProp()];
    document.body.classList.add(modalOpenClass);
  };

  /**
   * When the modal closes, re-allow scrolling and return to the previous scroll position.
   * Remove esc key listener.
   */
  cleanUpModal = () => {
    // don't blow up hypernova
    if (!windowExists()) return;

    window.removeEventListener('keydown', this.handleEscapeKeyPress, false);
    document.body.classList.remove(modalOpenClass);
    if (this.scrollPosition) {
      window[getScrollProp()] = this.scrollPosition;
    }
  };

  handleEscapeKeyPress = ({ keyCode }) => {
    if (keyCode === 27 && this.props.handleClose) {
      this.props.handleClose();
    }
  };

  startClosingAnimation = () => {
    this.setState({ animation: 'slide-out' });
  };

  handleAnimationEnd = () => {
    const { animation } = this.state;

    if (animation === 'slide-out') {
      this.setState({ animation: '' });
      this.cleanUpModal();
    } else if (animation === 'slide-in') {
      this.setState({ animation: '' });
    }

    this.props.handleAnimationEnd?.(animation);
  };

  /**
   * ModalHeader needs a 'handleClose' prop to make the modal 'X' button work.
   * Pass it down automagically but allow it to be overridden if passed explicitly to ModalHeader.
   */
  getChildren = () =>
    React.Children.map(this.props.children, (child) => {
      if (child.type === ModalHeader && !child.props.handleClose) {
        return React.cloneElement(child, { handleClose: this.props.handleClose });
      }
      return child;
    });

  render() {
    const { size, isOpen, handleClose, className, alwaysRenderBody } = this.props;

    const { animation } = this.state;

    const maxWidth = widths[size];

    const classes = ['ufr-modal-dialog', `${this.state.animation}`];

    let hiddenModal = false;
    if (!isOpen && animation === '') {
      if (alwaysRenderBody) {
        hiddenModal = true;
      } else {
        return null;
      }
    }

    return (
      <FocusTrap active={!hiddenModal}>
        <div
          className={`ufr-modal ufr-modal-outer ${className} ${
            hiddenModal ? 'ufr-modal-hidden-modal' : ''
          }`}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className={classNames(classes)}
            style={{ maxWidth }}
            onAnimationEnd={this.handleAnimationEnd}
            role="document"
          >
            <div className="ufr-modal-content">{this.getChildren()}</div>
          </div>

          {/*
            use <button> for overlay, not div[role="button"].
            screenreaders will be able to interact with it as another close button.
          */}
          <button
            type="button"
            className="ufr-modal-overlay"
            aria-label="Close"
            onClick={handleClose}
          />
        </div>
      </FocusTrap>
    );
  }
}

Modal.defaultProps = {
  alwaysRenderBody: false,
  className: '',
  handleAnimationEnd: null,
  handleClose: null,
  isOpen: false,
  size: 'small',
};

const { oneOfType, element, string, arrayOf, bool, oneOf, func } = PropTypes;

Modal.propTypes = {
  alwaysRenderBody: bool,
  children: oneOfType([element, string, arrayOf(oneOfType([element, string, bool]))]).isRequired,
  className: string,
  handleAnimationEnd: func,
  handleClose: func,
  isOpen: bool,
  size: oneOf(['small', 'medium', 'full']),
};

export default Modal;

export const useModal = (ModalComponent) => {
  const [isOpen, setIsOpen] = useState(false);

  return [
    (props) => <ModalComponent {...props} isOpen={isOpen} handleClose={() => setIsOpen(false)} />,
    () => setIsOpen(true),
  ];
};
