import './form.less';
import cloneDeep from 'lodash/cloneDeep';
import handleInputReady from '../../utils/handleInputReady';
import PropTypes from 'prop-types';
import React from 'react';

import ActionDropdown from '@/components/formInputs/actionDropdown/ActionDropdown';
import Button from '../shared/button/Button';
import Checkbox from '../formInputs/checkbox/Checkbox';
import ColorPicker from '../formInputs/colorPicker/ColorPicker';
import ImageUploader from '../imageUploader/ImageUploader';
import NumberInput from '../formInputs/numberInput/NumberInput';
import SectionHeader from '../formInputs/sectionHeader/SectionHeader';
import SelectDropdown from '../formInputs/selectDropdown/SelectDropdown';
import Slider from '../formInputs/slider/Slider';
import TextArea from '../formInputs/textArea/TextArea';
import TextInput from '../formInputs/textInput/TextInput';
import Toggle from '../formInputs/toggle/Toggle';

const propTypes = {
  // disable the entire form
  actionDropdownOptions: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      inputProps: PropTypes.shape({
        componentType: PropTypes.string,
      }),
      showReset: PropTypes.bool,
    }),
  ).isRequired,
  headerText: PropTypes.string,
  hubId: PropTypes.number,
  resetAll: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    resetParams: PropTypes.shape({
      appInstallationId: PropTypes.string,
      category: PropTypes.string,
    }),
    show: PropTypes.bool,
    submitReset: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        func: PropTypes.string,
        namespace: PropTypes.string,
      }),
    ]),
    text: PropTypes.string,
  }),
};

const defaultProps = {
  actionDropdownOptions: {},
  disabled: false,
  headerText: '',
  resetAll: {
    className: '',
    id: '',
    label: '',
    resetParams: {
      appInstallationId: '',
      category: '',
    },
    show: false,
    submitReset: () => {},
    text: '',
  },
};

const byOrdinal = (a, b) => a.inputProps.ordinal - b.inputProps.ordinal;

const typeMapping = {
  checkbox: Checkbox,
  color_picker: ColorPicker,
  image_uploader: ImageUploader,
  number: NumberInput,
  select_dropdown: SelectDropdown,
  slider: Slider,
  textarea: TextArea,
  textbox: TextInput,
  toggle: Toggle,
};

const Form = ({ fields, headerText, disabled, resetAll, actionDropdownOptions }) => (
  <div className="ufr-form">
    {headerText && <SectionHeader text={headerText} />}
    {resetAll.show ? (
      <span className="ufr-app-options-menu">
        <Button
          className={resetAll.className}
          id={resetAll.id}
          label={resetAll.label}
          onClick={() => handleInputReady(resetAll.submitReset, resetAll.resetParams)}
        >
          {resetAll.text}
        </Button>
        {actionDropdownOptions && <ActionDropdown options={actionDropdownOptions} />}
      </span>
    ) : (
      <></>
    )}
    {cloneDeep(fields)
      .sort(byOrdinal)
      .map((field) => {
        const { componentType, inputProps, showReset } = field;
        const Component = typeMapping[componentType];
        const fieldProps = {
          ...inputProps,
          key: inputProps.id,
          showReset: showReset,
        };

        // Only overwrite Field's disabled attribute if Form is disabled,
        // fallback to Field's disabled attribute when Form is enabled.
        if (disabled) {
          fieldProps.disabled = disabled;
        }
        return <Component {...fieldProps} />;
      })}
  </div>
);

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
