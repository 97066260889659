import axios from 'axios/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './langLinksDatatable.less';
import { CsrfProvider } from '../../../DataProviders/CsrfProvider';
import { useModal } from '../../modal/Modal/Modal';
import Avatar from '../../shared/avatar/Avatar';
import DataTable from '../../datatable/DataTable';
import DeleteFromGroupModal from './DeleteFromGroupModal';
import LangLinkDeleteButton from './LangLinkDeleteButton';
import LinkableHubsModal from './LinkablesModal/LinkableHubsModal';
import SetDefaultModal from './SetDefaultModal';
import ToasterStack from '../../toaster/ToasterStack';
import usePopToast from '../../toaster/usePopToast';
import useToasterStack from '../../toaster/useToasterStack';

const getEndpointBuilder = (hubId) => ({
  deleteLink: (id) => `/api/v2/hubs/${id}/language-links`,
  getGroup: () => `/api/v2/hubs/${hubId}/language-link-group`,
  getHubs: () => `/api/v2/hubs/${hubId}/language-linkable-hubs`,
  getLinks: () => `/api/v2/hubs/${hubId}/language-links`,
  getLocales: () => `/api/v2/hubs/${hubId}/language-linkable-locales`,
  linkHub: (id) => `/api/v2/hubs/${hubId}/language-links/${id}`,
  patchDefault: (id) => `/api/v2/hubs/${id}/language-links`,
});

const entityName = {
  plural: 'Hub Language Links',
  singular: 'Hub Language Link',
};

const cellClass = (key) => `ufr-dt-langlink-${key}-cell`;
const headerClass = (key) => `ufr-dt-langlink-${key}-header`;

/* eslint-disable sort-keys */
const columns = [
  {
    Header: 'Hub Name',
    accessor: 'hub',
    id: 'hub.name', // The id must match the name of the column we want to sort by on the api
    className: cellClass('hubname'),
    headerClassName: headerClass('hubname'),
    Cell: ({ value: hub }) => [
      <Avatar key="1" size="medium" firstName={hub.name} avatarUrl={hub.thumbnail_url} />,
      <div key="2" className="ufr-link-title">
        {hub.name}
      </div>,
    ],
  },
  {
    Header: 'Locale',
    accessor: 'hub',
    id: 'hub.locale.display_name',
    className: cellClass('locale'),
    headerClassName: headerClass('locale'),
    Cell: ({ value: hub }) => hub.locale.display_name,
  },
  {
    Header: 'Date Created',
    accessor: 'created_at',
    className: cellClass('created'),
    headerClassName: headerClass('created'),
    Cell: ({ value: date }) => date.slice(0, 10),
  },
  {
    Header: 'Is Default',
    accessor: 'default',
    id: 'default',
    className: cellClass('isdefault'),
    headerClassName: headerClass('isdefault'),
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    Header: '',
    accessor: 'hub',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    /* eslint-disable react/prop-types */
    Cell: ({ value: hub, popToast, readonly, refresh, deleteLink }) =>
      !readonly && (
        <LangLinkDeleteButton
          entityId={hub.id}
          header="Delete Hub link?"
          message={`Are you sure you want to delete this Hub's link with Hub ${hub.name}?`}
          getEndpoint={deleteLink}
          showMessage={popToast}
          triggerRefresh={refresh}
        />
      ),
    /* eslint-enable react/prop-types */
  },
];
/* eslint-enable sort-keys */

const HubLangLinksDatatable = ({ hub, readonly }) => {
  const { patchDefault, linkHub, deleteLink, getLinks, getLocales, getHubs, getGroup } =
    getEndpointBuilder(hub.id);

  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);

  const [linkableHubs, setLinkableHubs] = useState([]);
  const [linkableLocales, setLinkableLocales] = useState([]);
  const [allHubLinks, setAllHubLinks] = useState([]);
  const [defaultHubId, setDefaultHubId] = useState(null);

  const getModalData = async () => {
    const allLinksPromise = axios.get(getLinks(), { params: { limit: 100 } });
    const linkableHubsPromise = axios.get(getHubs(), { params: { limit: 100 } });
    const linkableLocalesPromise = axios.get(getLocales());

    const [
      {
        data: { data: allLinks },
      },
      {
        data: { data: hubs },
      },
      { data: locales },
    ] = await Promise.all([allLinksPromise, linkableHubsPromise, linkableLocalesPromise]);

    setAllHubLinks(allLinks);
    setLinkableHubs(hubs);
    setLinkableLocales(locales);

    if (allLinks.length > 0) {
      const { data: linkGroup } = await axios.get(getGroup());
      setDefaultHubId(linkGroup.default_hub_id);
    }
  };

  const getTableData = async (params) => {
    const {
      data: { data, meta },
    } = await axios.get(getLinks(), { params });
    return { data, meta };
  };

  const [DefaultModal, openDefaultModal] = useModal(SetDefaultModal);
  const [LinkablesModal, openLinkablesModal] = useModal(LinkableHubsModal);
  const [HandledDeleteFromGroupModal, openDeleteFromGroupModal] = useModal(DeleteFromGroupModal);

  const newLinkButton = {
    icon: 'plus',
    id: 'add-hub-link',
    onClick: openLinkablesModal,
    text: 'Add Hub Link',
  };

  const dropdownMenuItems =
    allHubLinks.length > 0
      ? [
          {
            id: 'set-default',
            onClick: openDefaultModal,
            text: 'Manage Group Default',
          },
          {
            id: 'remove',
            onClick: openDeleteFromGroupModal,
            text: 'Remove this Hub from the Group',
          },
        ]
      : undefined;

  const currentHubLink = {
    default: defaultHubId === hub.id,
    hub,
  };

  const modals = ({ tableProps: { refresh } }) => {
    const shared = {
      refreshTable: refresh,
      showMessage: popToast,
    };
    return [
      <DefaultModal
        {...shared}
        key="default-modal"
        optionData={[currentHubLink, ...allHubLinks]}
        defaultHubId={defaultHubId}
        endpointBuilder={{ patchDefault }}
      />,
      <LinkablesModal
        {...shared}
        key="linkables-modal"
        linkedLocaleIds={allHubLinks.map((link) => link.hub.locale.id)}
        linkableHubs={linkableHubs}
        linkableLocales={linkableLocales}
        getLinkHubUrl={linkHub}
      />,
      <HandledDeleteFromGroupModal {...shared} key="delete-modal" url={deleteLink(hub.id)} />,
    ];
  };

  return (
    <CsrfProvider>
      <DataTable
        entityName={entityName}
        id="lang-links"
        useStateHandling
        actionButtons={[newLinkButton]}
        readonly={readonly}
        dropdownMenuItems={dropdownMenuItems}
        showLoadingState
        columns={columns}
        getData={getTableData}
        refreshModalData={getModalData}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({
          deleteLink,
          popToast,
        })}
        Modals={modals}
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

HubLangLinksDatatable.propTypes = {
  hub: PropTypes.shape({
    id: PropTypes.number,
    locale: PropTypes.shape({
      display_name: PropTypes.string,
    }),
    name: PropTypes.string,
  }).isRequired,
  readonly: PropTypes.bool,
};

HubLangLinksDatatable.defaultProps = {
  readonly: true,
};

export default HubLangLinksDatatable;
