import Button from '@/components/shared/button/Button';
import PrivacyGroupsDeleteButton from './PrivacyGroupsDeleteButton';
import PrivacyGroupsRefreshButton from './PrivacyGroupsRefreshButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  decrementPrivacyGroupsCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  incrementTriggerRefreshCounter: PropTypes.func.isRequired,
  privacyGroupId: PropTypes.number.isRequired,
  privacyGroupName: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const PrivacyGroupsButtons = ({
  decrementPrivacyGroupsCount,
  dispatchToasterAction,
  hubId,
  privacyGroupId,
  privacyGroupName,
  triggerRefresh,
  incrementTriggerRefreshCounter,
}) => (
  <>
    <PrivacyGroupsRefreshButton
      hubId={hubId}
      privacyGroupId={privacyGroupId}
      dispatchToasterAction={dispatchToasterAction}
    />
    <Button
      id={`edit-privacy-group-${privacyGroupId}`}
      label="Edit"
      icon="pencil"
      title="Edit"
      href={`/hubs/privacy/groups/edit/${hubId}/${privacyGroupId}`}
      className="ufr-edit-button"
    />
    <PrivacyGroupsDeleteButton
      hubId={hubId}
      privacyGroupId={privacyGroupId}
      decrementPrivacyGroupsCount={decrementPrivacyGroupsCount}
      dispatchToasterAction={dispatchToasterAction}
      triggerRefresh={triggerRefresh}
      privacyGroupName={privacyGroupName}
      incrementTriggerRefreshCounter={incrementTriggerRefreshCounter}
    />
  </>
);

PrivacyGroupsButtons.propTypes = propTypes;

export default PrivacyGroupsButtons;
