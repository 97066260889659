/**
 * Callback handler for any callback needs
 */
const handleCallback = ({ data, callback }) => {
  const { namespace, func } = callback;

  // use global callback if provided
  if (namespace && window[namespace]) {
    return window[namespace][func](data);
  } else if ((!namespace || namespace === 'window') && window[func]) {
    return window[func](data);
  }
  return false;
};

export default handleCallback;
