import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  highlightColor: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // ...
    }),
  ).isRequired,
};

const defaultProps = {};

const SubMenu = ({ highlightColor, items }) => (
  <ul>
    {items.map((item) => {
      const { id, url, text, active } = item;

      const style = active ? { color: highlightColor } : {};

      return (
        <li id={`${id}-menu-child`} key={id + url} className="sidenav-menu-child">
          <a
            href={url}
            onClick={(e) => {
              if (window.location.href === url) {
                history.pushState({}, '');
              }
              e.stopPropagation();
            }}
          >
            <div style={style}>
              <span className="narrow-sidenav-icon">&#8226;</span>
              <span>{text}</span>
            </div>
          </a>
        </li>
      );
    })}
  </ul>
);

SubMenu.propTypes = propTypes;
SubMenu.defaultProps = defaultProps;

export default SubMenu;
