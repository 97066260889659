import './CustomLabelsDatatable.less';
import { CsrfProvider } from '../../../DataProviders/CsrfProvider';
import axios from 'axios/index';
import CustomLabelTableCell from './CustomLabelTableCell';
import DataTable from '../../datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '../../toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '../../toaster/useToasterStack';

let timer = null;

const getEffectiveLabelsEndpointBuilder = (hubId, streamId, itemId) => {
  if (streamId && itemId) {
    return {
      delete: (id) => `/api/v2/items/${itemId}/labels/${id}`,
      get: () => `/api/v2/streams/${streamId}/items/${itemId}/effective-labels`,
      patch: (id) => `/api/v2/items/${itemId}/labels/${id}`,
    };
  }

  if (streamId) {
    return {
      delete: (id) => `/api/v2/streams/${streamId}/labels/${id}`,
      get: () => `/api/v2/streams/${streamId}/effective-labels`,
      patch: (id) => `/api/v2/streams/${streamId}/labels/${id}`,
    };
  }

  if (hubId) {
    return {
      delete: (id) => `/api/v2/hubs/${hubId}/labels/${id}`,
      get: () => `/api/v2/hubs/${hubId}/effective-labels`,
      patch: (id) => `/api/v2/hubs/${hubId}/labels/${id}`,
    };
  }

  throw new Error('You must supply hubId, streamId, and/or itemId to create the endpoint builder');
};

/* eslint-disable sort-keys */
const columns = [
  {
    id: 'hub_label_category_id', // The id must match the name of the column we want to sort by on the api
    Header: 'Category',
    accessor: 'category',
    className: 'ufr-dt-label-category',
    Cell: ({ value: category }) => category.name,
  },
  {
    id: 'description', // The id must match the name of the column we want to sort by on the api
    Header: 'Standard Label',
    accessor: 'description',
    className: 'ufr-dt-label-description',
    Cell: ({ value }) => value,
  },
  {
    Header: 'Custom Label',
    className: 'ufr-dt-label-value',
    Cell: CustomLabelTableCell,
    sortable: false,
  },
];
/* eslint-enable sort-keys */

const transformCategory = (category) => ({
  categoryId: category.id.toString(),
  id: category.code.toString().toLowerCase(),
  queryValue: category.code,
  text: category.name,
});

const filterDropdowns = (items) => [
  {
    items,
    queryKey: 'category_id',
    toggleId: 'label-category',
    toggleText: 'Filter Labels',
  },
];

const filterLabelForAll = {
  categoryId: null,
  id: 'all',
  queryValue: null,
  text: 'All',
};

const getCategories = () => {
  if (getCategories.result) {
    return getCategories.result;
  }
  const promise = axios.get('/api/v2/metadata/label-categories');
  promise.then((res) => {
    getCategories.result = res;
  });
  return promise;
};

const CustomLabelsDatatable = ({ hubId, isReadonly, streamId, itemId }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [categoryItems, setCategoryItems] = useState([]);

  const allCategories = [filterLabelForAll, ...categoryItems];

  const endpointBuilder = getEffectiveLabelsEndpointBuilder(hubId, streamId, itemId);

  const getData = async (queries) => {
    const labelPromise = axios.get(endpointBuilder.get(), {
      params: queries,
    });
    const categoryPromise = getCategories();

    const [
      { data: labels },
      {
        data: { data: categories },
      },
    ] = await Promise.all([labelPromise, categoryPromise]);
    setCategoryItems(categories.map(transformCategory));

    const tableData = labels.data.map((label) => ({
      ...label,
      category: categories.find((c) => c.id === label.category_id),
    }));

    labels.data = tableData;
    return labels;
  };

  const handleError = (err) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    const toaster = {
      id: 'custom-labels-table-error',
      text: err.message,
      type: 'error',
    };

    dispatchToasterAction(addToaster(toaster));
    timer = setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
  };

  return (
    <CsrfProvider>
      <DataTable
        id="hub-labels"
        useStateHandling
        columns={columns}
        getData={getData}
        showLoadingState
        filterDropdowns={filterDropdowns(allCategories)}
        handleError={handleError}
        initialSort={{
          desc: false,
          id: 'description',
        }}
        getCellProps={() => ({
          dispatchToasterAction,
          endpointBuilder,
          isReadonly,
        })}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

CustomLabelsDatatable.propTypes = {
  hubId: PropTypes.number,
  isReadonly: PropTypes.bool.isRequired,
  itemId(props, propName) {
    if (!props.streamId && props[propName]) {
      return new Error(
        "you must provide both 'streamId' & 'itemId' when using itemId as props to the custom labels datatable",
      );
    }

    const isValidId = typeof props[propName] === 'number' || !Number.isNaN(props[propName]);
    if (!props.hubId && !isValidId) {
      return new Error('itemId must be a number or numeric string');
    }

    return null;
  },
  streamId(props, propName) {
    if (!props.hubId && !props[propName]) {
      return new Error(
        "you must provide either 'hubId', 'streamId', or both 'streamId' & 'itemId' as props to the custom labels datatable",
      );
    }

    if (props.hubId && props[propName]) {
      return new Error('you cannot provide both a streamId and a hubId prop');
    }

    const isValidId = typeof props[propName] === 'number' || !Number.isNaN(props[propName]);
    if (!props.hubId && !isValidId) {
      return new Error('streamId must be a number or numeric string');
    }

    return null;
  },
};

CustomLabelsDatatable.defaultProps = {
  hubId: null,
  itemId: null,
  streamId: null,
};

export default CustomLabelsDatatable;
