import { Modal, ModalBody, ModalFooter, ModalHeader, PrimaryButton } from '@/components/modal';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const propTypes = {
  created: PropTypes.string,
  createdBy: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  hubId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  itemsCount: PropTypes.number,
  streamsCount: PropTypes.number,
};

const defaultProps = {
  handleClose: () => {},
  isOpen: false,
};

const StreamsListingAboutHubModal = ({
  hubId,
  created,
  createdBy,
  streamsCount,
  itemsCount,
  isOpen,
  handleClose,
}) => {
  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '');
  }, [isOpen]);

  return (
    <Modal size="small" isOpen={isOpen} handleClose={handleClose} className="ufr-about-hub-modal">
      <ModalHeader title="About this Hub" />
      <ModalBody>
        <p>
          Hub ID: <b>{hubId}</b>
        </p>
        <p>
          Created: <b>{created}</b>
        </p>
        <p>
          Created By: <b>{createdBy}</b>
        </p>
        <p>
          Streams: <b>{streamsCount}</b>
        </p>
        <p>
          Items: <b>{itemsCount}</b>
        </p>
      </ModalBody>
      <ModalFooter>
        <PrimaryButton id="close" label="close" onClick={handleClose}>
          Close
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};
StreamsListingAboutHubModal.propTypes = propTypes;

StreamsListingAboutHubModal.defaultProps = defaultProps;

export default StreamsListingAboutHubModal;
