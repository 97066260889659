import './toaster.less';
import { Transition } from 'react-transition-group';
import getIcon from './getIcon';
import PropTypes from 'prop-types';
import React from 'react';

const ToasterStack = ({ toasters, dispatchToasterAction }) =>
  toasters.map(({ type, text, isVisible, id }, i) => {
    const Icon = getIcon(type);

    const activeStateStyles = {
      bottom: `${(i + 1) * 60}px'`,
    };

    /* eslint-disable sort-keys */
    const transitionStyles = {
      entering: { bottom: '-45px' },
      entered: { bottom: `${(i + 1) * 60}px` },
      exiting: { bottom: '-45px' },
      exited: { bottom: '-45px' },
    };
    /* eslint-enable sort-keys */

    return (
      <Transition
        in={isVisible}
        timeout={400}
        appear
        unmountOnExit
        key={`transition-element-${id}`}
      >
        {(state) => {
          const styles = { ...activeStateStyles, ...transitionStyles[state] };
          return (
            <div
              key={`ufr-toaster-${id}`}
              className={`ufr-toaster ${type}`}
              role="alert"
              style={styles}
              onTransitionEnd={() =>
                dispatchToasterAction({
                  action: 'transitionEnd',
                  toasterId: id,
                  transitionState: state,
                })
              }
            >
              <Icon />
              <p>{text}</p>
            </div>
          );
        }}
      </Transition>
    );
  });

export const toastersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    /**
     * the id keeps track of the popup,
     * so we can change it from 'info'->'success' without
     * adding another toast to the stack
     * */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isVisible: PropTypes.bool,
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'failure', 'error', 'info', 'refresh']).isRequired,
  }),
);

ToasterStack.propTypes = {
  dispatchToasterAction: PropTypes.func.isRequired,
  toasters: toastersPropType.isRequired,
};

export default ToasterStack;
