import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './topnavLink.less';
import handleClick from '../../utils/handleClick';
import shapes from './propShapes';
import TopnavButton from './TopnavButton';

const TopnavLink = ({ item, user, forDropdown, isRightMenu }) => {
  const { id, text, icon, url, onClick, active, children, onClickCallback } = item;

  const hasActiveChild = (arr) =>
    arr && arr.reduce((result, child) => result || child.active, false);
  const isActive = active || hasActiveChild(children);

  const click = (e) => handleClick({ e, onClick, onClickCallback });
  const hasUrl = url && url !== '#';

  const classes = [isRightMenu ? '' : 'topnav-link', isActive ? 'topnav-link-active' : ''];

  const renderLink = () => (
    <a
      id={`${id}-link`}
      className={classNames(classes)}
      href={hasUrl ? url : undefined}
      onClick={!hasUrl ? click : undefined}
    >
      <TopnavButton
        id={id}
        icon={icon}
        text={text}
        active={isActive}
        forDropdown={forDropdown}
        user={user}
      />
    </a>
  );

  if (forDropdown) {
    return renderLink();
  } // else

  const liClasses = ['topnav-link', 'topnav-link-no-dropdown', isRightMenu && 'topnav-link-right'];

  return (
    <li key={id} id={id} className={classNames(liClasses)}>
      {renderLink()}
    </li>
  );
};

TopnavLink.defaultProps = {
  forDropdown: false,
  isRightMenu: false,
};

TopnavLink.propTypes = {
  forDropdown: PropTypes.bool,
  isRightMenu: PropTypes.bool,
  item: PropTypes.shape(shapes.item).isRequired,
  user: PropTypes.shape(shapes.user).isRequired,
};

export default TopnavLink;
