import '../advancedOptions/AdvancedSeoOptions.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import React from 'react';
import SeoOptions from './SeoOptions';

class AdvancedSeoOptionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      seoOptions: [props],
    };
  }

  hideComponent() {
    const { open } = this.state;

    this.setState({
      open: !open,
    });
  }

  render() {
    const { open } = this.state;

    return (
      <CsrfProvider>
        <div className="advanced-options-position">
          <div className="advanced-options-seo-accordion" onClick={() => this.hideComponent()}>
            <div className="advanced-options-seo-label">Advanced SEO Options</div>
            <span className={`ic halflings halflings-chevron-${open ? 'up' : 'down'} `} />
          </div>
          {open && <SeoOptions data={this.state.seoOptions} />}
        </div>
      </CsrfProvider>
    );
  }
}

export default AdvancedSeoOptionsPage;
