import './actionButton.less';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '@/components/shared/icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

const defaultProps = {
  disabled: false,
};

const ActionButton = ({ className, id, text, icon, onClick, disabled, refreshData, tooltip }) => {
  const button = (
    <li className="ufr-action-button" id={`ufr-${id}-action-button`}>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <button
            type="button"
            disabled={disabled}
            className={className}
            onClick={() => onClick(refreshData, csrfToken)}
          >
            {text && <span className="button-text">{text}</span>}
            {icon && <Icon icon={icon} />}
          </button>
        )}
      </CsrfContextConsumer>
    </li>
  );

  if (tooltip) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-internal-stream-name-tooltip" placement="top">
            {tooltip}
          </Tooltip>
        }
      >
        {button}
      </OverlayTrigger>
    );
  }

  return button;
};

ActionButton.propTypes = propTypes;
ActionButton.defaultProps = defaultProps;

export default ActionButton;
