import { BasicModal, SalesforceModal } from '..';
import Icon from '../../shared/icon/Icon';
import OpenButton from './OpenButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * This component is only neccesary because we
 * are attempting to open react modals from outside
 * of react. Once the entire app is react, or if
 * you are opening a modal from another react context,
 * don't use this!
 */

const modals = {
  modal: (props) => <BasicModal {...props} />,
  salesforce: (props) => <SalesforceModal {...props} />,
};

class ModalOpener extends Component {
  state = {
    additionalModalProps: {}, // HACK to allow us to pass props from jquery to react
    error: '',
    isLoading: false,
    isOpen: false,
  };

  componentDidMount() {
    if (
      typeof window === 'undefined' || // don't blow up hypernova
      !window.CurrentPage
    ) {
      return;
    }

    if (window.CurrentPage && !window.CurrentPage.modals) {
      window.CurrentPage.modals = {};
    }
    const { id } = this.props;

    /**
     * Since we need to interact with react
     * from the dynamically generated API-datatable
     * we need to expose this component for buttons to
     * interact with it. This is bad.
     * FIXME: When the page/table is react
     */
    window.CurrentPage.modals[id] = this;
  }

  componentWillUnmount() {
    const { id } = this.props;
    if (
      typeof window !== 'undefined' &&
      window.CurrentPage &&
      window.CurrentPage.modals &&
      window.CurrentPage.modals[id]
    ) {
      window.CurrentPage.modals[id] = undefined;
    }
  }

  handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      error: '',
      isOpen: false,
    });
  };

  handleOpen = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      isOpen: true,
    });
  };

  handleSubmit = ({ e, ...data }) => {
    e.preventDefault();

    const { globalOnSubmitFunction } = this.props;
    if (
      typeof window !== 'undefined' && // don't blow up hypernova
      window.CurrentPage &&
      window.CurrentPage[globalOnSubmitFunction]
    ) {
      this.setState({ isLoading: true });
      const submitFunc = window.CurrentPage[globalOnSubmitFunction];

      submitFunc(data)
        .then(() => {
          this.setState({
            isLoading: false,
          });
          this.handleClose();
        })
        .catch((err) => {
          this.setState({
            error: err,
            isLoading: false,
          });
        });
    }
  };

  render() {
    const { id, modalType, buttonText, icon, buttonClass, title, ...props } = this.props;

    const { additionalModalProps, error, isOpen, isLoading } = this.state;

    const ChosenModal = modals[modalType];
    return (
      <div id={id}>
        <OpenButton onClick={this.handleOpen} buttonClass={buttonClass} modalType={modalType}>
          {buttonText}
          {icon && <Icon className={icon} icon="plus" />}
        </OpenButton>
        <ChosenModal
          isOpen={isOpen}
          isLoading={isLoading}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          title={title}
          error={error}
          {...props}
          {...additionalModalProps}
        />
      </div>
    );
  }
}

ModalOpener.defaultProps = {
  buttonClass: '',
  icon: '',
};

ModalOpener.propTypes = {
  buttonClass: PropTypes.string,
  buttonText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]).isRequired,
  globalOnSubmitFunction: PropTypes.string.isRequired, // HACK due to having react components floating in a sea of plain html
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  modalType: PropTypes.oneOf(Object.keys(modals)).isRequired,
  PrimaryButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModalOpener;
