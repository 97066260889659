import './CustomLabelTableCell.less';
import {
  addToaster,
  slideDownAndRemoveToaster,
  updateToaster,
} from '../../toaster/useToasterStack';
import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const getInitialValue = (customLabel) => {
  const { inherited_value: inheritedValue, value, default_value: defaultValue } = customLabel;
  if (inheritedValue && inheritedValue !== value) {
    return value;
  }

  if (!inheritedValue && defaultValue !== value) {
    return value;
  }

  return '';
};

const updateCustomLabel = ({ endpointBuilder, customLabel, newValue, csrfToken }) => {
  const trimmedNewValue = newValue.trim();
  const method = trimmedNewValue ? 'PATCH' : 'DELETE';
  const url = endpointBuilder[method.toLowerCase()](customLabel.id);
  const headers = { 'X-CSRF-TOKEN': csrfToken };
  const data = trimmedNewValue ? { ...customLabel, value: trimmedNewValue } : {};

  /* eslint-disable sort-keys */
  return axios({
    method,
    url,
    headers,
    data,
  });
  /* eslint-enable sort-keys */
};

const getPlaceholder = (inheritedValue, defaultValue) => {
  if (inheritedValue) {
    return inheritedValue.trim();
  }

  return defaultValue.trim();
};

const hasValueChanged = (customLabel, newValue) => {
  const trimmedNewValue = newValue.trim();
  const labelHasntChanged = customLabel.value.trim() === trimmedNewValue;
  const labelIsSameAsDefault =
    trimmedNewValue === '' && customLabel.value === customLabel.default_value;
  const labelIsSameAsInherited =
    trimmedNewValue === '' && customLabel.value === customLabel.inherited_value;
  return labelHasntChanged || labelIsSameAsDefault || labelIsSameAsInherited;
};

const timers = {};

const CustomLabelTableCell = ({
  original,
  dispatchToasterAction,
  endpointBuilder,
  refresh,
  setLoading,
  isReadonly,
}) => {
  const [inputValue, setInputValue] = useState(getInitialValue(original));

  useEffect(() => {
    // This function is run when original.id changes (meaning a new customLabel is
    // occupying this input), resetting the stored form value
    setInputValue(getInitialValue(original));
  }, [original.id]);

  const setToasterToClose = (toasterId) => {
    if (timers[toasterId]) {
      clearTimeout(timers[toasterId]);
    }

    timers[toasterId] = setTimeout(() => {
      dispatchToasterAction(
        slideDownAndRemoveToaster({
          id: toasterId,
        }),
      );
    }, 2000);
  };

  const saveInput = async (customLabel, newValue, csrfToken) => {
    const id = customLabel.code;

    if (hasValueChanged(customLabel, newValue)) {
      return; // If the value hasn't changed, no need to save
    }

    setLoading(true); // disable table naigation while requests complete

    dispatchToasterAction(
      addToaster({
        id,
        text: 'Saving...',
        type: 'info',
      }),
    );

    try {
      await updateCustomLabel({
        csrfToken,
        customLabel,
        endpointBuilder,
        newValue,
      });
      await refresh(); // set the loading state to false upon completion

      dispatchToasterAction(
        updateToaster({
          id,
          text: `Label "${customLabel.description}" updated successfully!`,
          type: 'success',
        }),
      );
    } catch (err) {
      // Since we don't know if the refresh completed, we better clear the loading state
      setLoading(false);
      dispatchToasterAction(
        updateToaster({
          id,
          text: `Error: ${err.message}`,
          type: 'error',
        }),
      );
    }

    setToasterToClose(id);
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => {
        const debouncedSaveInput = debounce(saveInput, 400);
        const placeholder = getPlaceholder(original.inherited_value, original.default_value);
        return (
          <input
            id={original.id}
            key={original.id}
            className="ufr-custom-labels-input"
            type="text"
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={() => debouncedSaveInput(original, inputValue, csrfToken)}
            disabled={isReadonly}
            autoComplete="new-password" // The spec says "off" should work here, but it is not implemented in chrome or IE, so we have to use this hack for now
          />
        );
      }}
    </CsrfContextConsumer>
  );
};

CustomLabelTableCell.propTypes = {
  dispatchToasterAction: PropTypes.func.isRequired,
  endpointBuilder: PropTypes.shape({
    // todo
  }).isRequired,
  isReadonly: PropTypes.bool.isRequired,
  original: PropTypes.shape({
    code: PropTypes.string,
    default_value: PropTypes.string,
    id: PropTypes.string,
    inherited_value: PropTypes.string,
  }).isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default CustomLabelTableCell;
