import classNames from 'classnames';
import Input from '../input/Input';
import PropTypes from 'prop-types';
import React from 'react';

const SliderInputs = ({
  min,
  max,
  value,
  step,
  handleChange,
  handleDone,
  disabled,
  postfix,
  style,
  labelExpectsId,
}) => (
  <div className="ufr-slider">
    <input
      className={classNames('ufr-slider-input', { 'ufr-input-component--disabled': disabled })}
      type="range"
      min={min}
      max={max}
      value={value}
      step={step}
      onChange={handleChange}
      disabled={disabled}
      tabIndex="-1"
    />
    <div
      className={classNames('ufr-slider-number-input', {
        'ufr-input-container--disabled': disabled,
      })}
      aria-disabled={disabled}
    >
      <Input
        id={labelExpectsId}
        type="number"
        postfix={postfix}
        style={style}
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        handleChange={handleChange}
        onDone={handleDone}
      />
    </div>
  </div>
);

SliderInputs.defaultProps = {
  disabled: false,
  labelExpectsId: '',
  max: 100,
  min: 0,
  postfix: '%',
  step: 1,
  style: {},
  value: 50,
};

SliderInputs.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleDone: PropTypes.func,
  labelExpectsId: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  postfix: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['any'])]),
  style: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.number,
};

export default SliderInputs;
