import axios from 'axios/index';
import PropTypes from 'prop-types';
import React from 'react';

import './enrichmentSourcesImportSummaryDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import DataTable from '@/components/datatable/DataTable';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import ToasterStack from '@/components/toaster/ToasterStack';
import usePopToast from '@/components/toaster/usePopToast';
import useToasterStack from '@/components/toaster/useToasterStack';

const getEndpointBuilder = (accountId, integrationId) => ({
  getEnrichmentImportSummary: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationId}/import-summary`,
});

const entityName = {
  plural: 'Enrichment Data Import Summary',
  singular: 'Enrichment Data Import Summary',
};

const cellClass = (key) => `ufr-dt-enrichment-sources-import-summary-${key}-cell`;
const headerClass = (key) => `ufr-dt-enrichment-sources-import-summary-${key}-header`;

const renderSummary = (value, integrationName) => {
  const analyticsVisitors = value.leadsCreated + value.leadsUpdated;
  return (
    <div>
      <div className="import-summary-title">
        <span className="import-summary-prefix">
          {analyticsVisitors} Uberflip Analytics visitors
        </span>{' '}
        were enriched with {integrationName} data:
      </div>
      <ul className="import-summary-list">
        <li>
          <span className="import-summary-prefix">{value.leadsCreated} new person records</span>{' '}
          were imported from {integrationName} into Uberflip
        </li>
        <li>
          <span className="import-summary-prefix">
            {value.leadsUpdated} existing person records
          </span>{' '}
          were updated from {integrationName} into Uberflip
        </li>
        <li>
          <span className="import-summary-prefix">
            {value.userDefinedFieldsUpdated} user defined mapped fields
          </span>{' '}
          were included in this update
        </li>
      </ul>
    </div>
  );
};

const EnrichmentSourcesImportSummaryDatatable = ({ accountId, integrationId, integrationName }) => {
  const { getEnrichmentImportSummary } = getEndpointBuilder(accountId, integrationId);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);

  const getTableData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getEnrichmentImportSummary(), { params: queries });
    return { data, meta };
  };

  /* eslint-disable sort-keys */
  const columns = [
    {
      Header: 'Import Summary',
      accessor: 'leadsCreated',
      id: 'leadsCreated',
      className: cellClass('leadsCreated'),
      headerClassName: headerClass('leadsCreated'),
      Cell: ({ original }) => renderSummary(original, integrationName),
      sortable: false,
    },
    {
      Header: 'Date',
      accessor: 'created',
      id: 'created',
      className: cellClass('created'),
      headerClassName: headerClass('created'),
      Cell: ({ value }) => getHumanReadableDate(value, true),
    },
  ];
  /* eslint-enable sort-keys */

  return (
    <CsrfProvider>
      <DataTable
        entityName={entityName}
        id="enrichment-sources-import-summary"
        showLoadingState
        columns={columns}
        getData={getTableData}
        initialSort={{ desc: true, id: 'created' }}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({
          popToast,
        })}
        hideControlBar
        noSearchResultsMessage={
          <p>
            Looks like there is no Enrichment activity to display here yet. Please check back later.
          </p>
        }
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

EnrichmentSourcesImportSummaryDatatable.propTypes = {
  accountId: PropTypes.number.isRequired,
  integrationId: PropTypes.number.isRequired,
  integrationName: PropTypes.string.isRequired,
};

export default EnrichmentSourcesImportSummaryDatatable;
