import InputContainer from '../inputContainer/InputContainer';
import PropTypes from 'prop-types';
import React from 'react';

import './radioButtons.less';

const propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  // List of items from API, formatted structured should be: {text: 'string', value: 'int|string'}
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ).isRequired,
  submitData: PropTypes.func.isRequired,
  title: PropTypes.string,
  // Optional: Should match a string value in the items array {text: 'ABM'}, pass in 'ABM'
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

const defaultProps = {
  description: '',
  disabled: false,
  title: '',
  value: '',
};

// eslint-disable-next-line react/prop-types
const RadioButton = ({ value, text, checked, handleChange }) => {
  // eslint-disable-next-line react/prop-types
  const id = `ufr-${text.split().join('-')}-radio`;
  return (
    <div className="ufr-radio-button">
      <input
        id={id}
        type="radio"
        value={value}
        name={text}
        checked={checked}
        onChange={() => 'eslint dummy'}
        onClick={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleChange(event);
          }
        }}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

const RadioButtons = ({
  id,
  title,
  description,
  options,
  value: selectedValue,
  submitData,
  disabled,
}) => {
  const handleChange = ({ target }) => {
    submitData(target.value);
  };

  const noneSelected = !selectedValue;
  const isSelected = (value) =>
    value === selectedValue || value === parseInt(selectedValue, 10) || (!value && noneSelected);

  return (
    <InputContainer
      id={`ufr-${id}-radio`}
      className="ufr-radio-button-input"
      title={title}
      description={description}
      disabled={disabled}
    >
      {options.map(({ text, value }) => (
        <RadioButton
          key={text + value}
          checked={isSelected(value)}
          {...{
            handleChange,
            text,
            value,
          }}
        />
      ))}
    </InputContainer>
  );
};

RadioButtons.propTypes = propTypes;
RadioButtons.defaultProps = defaultProps;

export default RadioButtons;
