import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  decrementRecommendationRuleCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  recommendationRuleId: PropTypes.number.isRequired,
  recommendationRuleName: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const RecommendationRulesDeleteButton = ({
  decrementRecommendationRuleCount,
  dispatchToasterAction,
  recommendationRuleId,
  recommendationRuleName,
  triggerRefresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteRecommendationRule = async (csrfToken) => {
    try {
      setIsDeleting(true);
      /* eslint-disable sort-keys */
      await axios({
        method: 'DELETE',
        url: `/api/v2/recommendation-rules/${recommendationRuleId}`,
        headers: { 'X-Csrf-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: recommendationRuleId,
          text: 'Recommendation Rule was deleted!',
          type: 'success',
        }),
      );
      decrementRecommendationRuleCount?.();
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: recommendationRuleId,
          text: 'Unable to delete Recommendation Rule.',
          type: 'error',
        }),
      );
    }
    setIsDeleting(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: recommendationRuleId }));
    }, 2000);
  };

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              handleConfirm={() => deleteRecommendationRule(csrfToken)}
              header="Delete Recommendation Rule?"
              body={
                <>
                  Are you sure you want to <b>permanently</b> delete Recommendation Rule{' '}
                  <b>&quot;{recommendationRuleName}&quot;</b>?
                </>
              }
              primaryButtonText="Delete Rule"
            />

            <Button
              icon="bin"
              title="Delete Rule"
              label="Delete Rule"
              id={`delete-recommendation-rule-${recommendationRuleName}`}
              className="ufr-delete-button"
              disabled={isDeleting}
              onClick={() => setIsModalOpen(true)}
            />
          </>
        )}
      </CsrfContextConsumer>
    </>
  );
};

RecommendationRulesDeleteButton.propTypes = propTypes;

export default RecommendationRulesDeleteButton;
