import axios from 'axios/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CsrfProvider } from '../../../DataProviders/CsrfProvider';
import { useModal } from '../../modal/Modal/Modal';
import AddEmbedParameterModal from './AddEmbedParameterModal';
import DataTable from '../../datatable/DataTable';
import EmbedParameterButtons from './EmbedParameterButtons';
import EmbedParameterStatusToggle from './EmbedParameterStatusToggle';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import ToasterStack from '../../toaster/ToasterStack';
import usePopToast from '../../toaster/usePopToast';
import useToasterStack from '../../toaster/useToasterStack';

const getEndpointBuilder = (hubId) => ({
  addParam: () => `/api/v2/hubs/${hubId}/embed-parameters`,
  deleteParam: (paramId) => `/api/v2/hubs/${hubId}/embed-parameters/${paramId}`,
  editParam: (paramId) => `/api/v2/hubs/${hubId}/embed-parameters/${paramId}`,
  getParams: () => `/api/v2/hubs/${hubId}/embed-parameters`,
});

const entityName = {
  plural: 'Embed Parameters',
  singular: 'Embed Parameter',
};

const cellClass = (key) => `ufr-dt-embed-param-${key}-cell`;
const headerClass = (key) => `ufr-dt-embed-param-${key}-header`;

/* eslint-disable sort-keys */
const columns = [
  {
    Header: 'Parameter Name',
    accessor: 'parameter_name',
    id: 'parameter-name',
    className: cellClass('parameter-name'),
    headerClassName: headerClass('parameter-name'),
    sortable: false,
  },
  {
    Header: 'Parameter Value',
    accessor: 'parameter_value',
    id: 'parameter-value',
    className: cellClass('parameter-value'),
    headerClassName: headerClass('parameter-value'),
    sortable: false,
  },
  {
    Header: 'Created Date',
    accessor: 'created',
    id: 'created',
    className: cellClass('created'),
    headerClassName: headerClass('created'),
    Cell: ({ value }) => getHumanReadableDate(value),
    sortable: false,
  },
  {
    Header: 'Last Updated',
    accessor: 'modified',
    id: 'modified',
    className: cellClass('modified'),
    headerClassName: headerClass('modified'),
    Cell: ({ value }) => getHumanReadableDate(value),
    sortable: false,
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    id: 'status',
    className: cellClass('status'),
    headerClassName: headerClass('status'),
    Cell: ({ original, popToast, editParam, permissions }) => [
      <EmbedParameterStatusToggle
        entityId={original.id}
        entityName={original.parameter_name}
        isActive={original.enabled}
        endpoint={editParam}
        showMessage={popToast}
        disabled={!permissions.update}
      />,
    ],
    sortable: false,
  },
  {
    Header: '',
    accessor: 'hub_id',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    /* eslint-disable react/prop-types */
    Cell: ({ original, popToast, refresh, editParam, deleteParam, permissions }) => (
      <EmbedParameterButtons
        paramId={original.id}
        paramName={original.parameter_name}
        paramValue={original.parameter_value}
        editEndpoint={editParam}
        delEndpoint={deleteParam}
        showMessage={popToast}
        triggerRefresh={refresh}
        permissions={permissions}
      />
    ),
    /* eslint-enable react/prop-types */
    sortable: false,
  },
];
/* eslint-enable sort-keys */

const HubEmbedParametersDatatable = ({ hub, permissions }) => {
  const { getParams, addParam, editParam, deleteParam } = getEndpointBuilder(hub.id);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);
  const [paramCount, setParamCount] = useState(0);

  const getTableData = async (params) => {
    const {
      data: { data, meta },
    } = await axios.get(getParams(), { params });
    setParamCount(data.length);
    return { data, meta };
  };

  const [AddModal, openAddModal] = useModal(AddEmbedParameterModal);

  const newParamButton = {
    className: 'ufr-btn ufr-btn-primary',
    id: 'add-embed-param',
    onClick: openAddModal,
    text: 'Add Parameter',
  };

  const modals = ({ tableProps: { refresh } }) => {
    const shared = {
      refreshTable: refresh,
      showMessage: popToast,
    };
    return [
      <AddModal {...shared} hubId={hub.id} key="add-embed-param-modal" endpoint={addParam} />,
    ];
  };

  return (
    <CsrfProvider>
      <DataTable
        entityName={entityName}
        id="embed-parameters"
        actionButtons={[newParamButton]}
        showLoadingState
        columns={columns}
        getData={getTableData}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({ deleteParam, editParam, permissions, popToast })}
        Modals={modals}
        readonly={!permissions.create}
        searchPlaceholder={`Search ${paramCount} Embed Parameters`}
      />

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

HubEmbedParametersDatatable.propTypes = {
  hub: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  permissions: PropTypes.shape({
    create: PropTypes.bool,
    delete: PropTypes.bool,
    update: PropTypes.bool,
  }).isRequired,
};

export default HubEmbedParametersDatatable;
