import './functionalityListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import FunctionalityListingPrivacyGroupSelect from '@/components/datatables/privacyGroups/FunctionalityListingPrivacyGroupSelect';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ServiceIcon from '@/components/shared/serviceIcon/ServiceIcon';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hubId: PropTypes.number.isRequired,
  privacyGroups: PropTypes.array.isRequired,
  triggerRefreshCounter: PropTypes.number.isRequired,
};

const entityName = {
  plural: 'Functionalities',
  singular: 'Functionality',
};

const searchPlaceholder = (functionalitiesCount) =>
  `Search ${functionalitiesCount} ${
    functionalitiesCount === 1 ? entityName.singular : entityName.plural
  }`;

const cellClass = (key) => `ufr-dt-privacy-groups-functionality-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-privacy-groups-functionality-listing-${key}-header`;
const uberflipServices = ['UBERFLIP', 'UBERFLIPUI'];

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const columns = (
  hubId,
  privacyGroups,
  dispatchToasterAction,
  setSelectedPrivacyGroups,
  selectedPrivacyGroups,
) => [
  {
    id: 'name',
    Header: 'Service',
    accessor: 'name',
    className: cellClass('service-name'),
    headerClassName: headerClass('service-name'),
    Cell: ({ original }) => {
      const isUberflip = uberflipServices.indexOf(original.code) !== -1;
      return (
        <div className="ufr-service">
          <ServiceIcon
            service={isUberflip ? 'uberflip' : original.name.toLowerCase().replace(/\s+|-/g, '')}
          />
          <div className="ufr-service-name">{original.name}</div>
        </div>
      );
    },
  },
  {
    Header: 'Privacy Group',
    accessor: 'privacy-group-select',
    className: cellClass('privacy-group-select'),
    headerClassName: headerClass('privacy-group-select'),
    sortable: false,
    Cell: ({ original }) => (
      <div className="ufr-privacy-group-selector">
        <FunctionalityListingPrivacyGroupSelect
          apiEntityName="functionality"
          functionalityId={original.id}
          functionalityType="hub"
          hubId={hubId}
          privacyGroups={privacyGroups}
          selectedPrivacyGroupId={original.privacy_group_id}
          dispatchToasterAction={dispatchToasterAction}
          setSelectedPrivacyGroup={(privacyGroupName, privacyGroupId) =>
            setSelectedPrivacyGroups({
              ...selectedPrivacyGroups,
              [original.id]: { label: privacyGroupName, value: privacyGroupId },
            })
          }
          selectedPrivacyGroup={selectedPrivacyGroups[original.id]}
        />
      </div>
    ),
  },
];
/* eslint-enable sort-keys */
/* eslint-enable react/prop-types */

const getEndpointBuilder = (hubId) => ({
  getFunctionalities: () => `/api/v2/hubs/${hubId}/functionalities`,
});

const FunctionalityListingDatatable = ({ hubId, privacyGroups, triggerRefreshCounter }) => {
  const [functionalitiesCount, setFunctionalitiesCount] = useState(0);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [selectedPrivacyGroups, setSelectedPrivacyGroups] = useState({});

  const { getFunctionalities } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getFunctionalities(), {
      params: queries,
    });
    setFunctionalitiesCount(meta.count);
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="privacy-group-functionality"
        columns={columns(
          hubId,
          privacyGroups,
          dispatchToasterAction,
          setSelectedPrivacyGroups,
          selectedPrivacyGroups,
        )}
        searchPlaceholder={searchPlaceholder(functionalitiesCount)}
        showLoadingState
        getData={getData}
        initialSort={{ desc: false, id: 'name' }}
        triggerRefreshCounter={triggerRefreshCounter}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

FunctionalityListingDatatable.propTypes = propTypes;

export default FunctionalityListingDatatable;
