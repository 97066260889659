import './CheckBoxModal.less';
import Checkbox from '@/components/formInputs/checkbox/Checkbox';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const propTypes = {
  checkBoxLabel: PropTypes.string.isRequired,
  checkBoxTitle: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
  ...ConfirmationModal.propTypes,
};

const defaultProps = {
  body: <></>,
};

const CheckBoxModal = ({ checkBoxTitle, description, checkBoxLabel, ...props }) => {
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    setIsConfirm(false);
  }, [props.isOpen]);

  const body = ({ checkBoxTitle, description, checkBoxLabel }) => (
    <>
      <p className="ufr-checkbox-modal__description">{description}</p>
      <h5 className="ufr-checkbox-question">{checkBoxTitle}</h5>
      <Checkbox
        variant="modal"
        id="ufr-checkboxmodal-confirm-checkbox"
        title={checkBoxLabel}
        value={isConfirm}
        onChange={(value) => setIsConfirm(value)}
      />
    </>
  );

  return (
    <ConfirmationModal
      {...props}
      body={body({ checkBoxLabel, checkBoxTitle, description })}
      primaryButtonEnabled={isConfirm}
      className="ufr-checkbox-modal"
    />
  );
};

CheckBoxModal.propTypes = propTypes;
CheckBoxModal.defaultProps = defaultProps;

export default CheckBoxModal;
