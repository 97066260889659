import { bool, func } from 'prop-types';
import Checkbox from '@/components/formInputs/checkbox/Checkbox';
import React, { useEffect } from 'react';

const UberflipActivity = ({ useCustomFields, selectSalesforceIntegrationItem }) => {
  const title = 'Store activity in Salesforce custom fields';
  const description =
    'Use custom fields instead of a single comments field in Salesforce to store all Uberflip activity.';

  useEffect(() => {
    selectSalesforceIntegrationItem({ useCustomFields: useCustomFields });
  }, []);

  return (
    <fieldset className="sfi-field-section">
      <h3 className="sfi-field-section-header">Uberflip Activity</h3>
      <div className="sfi-field-section-body">
        <Checkbox
          id="sfi-uf-activity"
          title={title}
          description={description}
          value={useCustomFields}
          onChange={() => selectSalesforceIntegrationItem({ useCustomFields: !useCustomFields })}
        />
      </div>
    </fieldset>
  );
};
UberflipActivity.defaultProps = {
  useCustomFields: true,
};

UberflipActivity.propTypes = {
  selectSalesforceIntegrationItem: func.isRequired,
  useCustomFields: bool.isRequired,
};

export default UberflipActivity;
