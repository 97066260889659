import Button from '@/components/shared/button/Button';
import CustomCodeListingDeleteButton from './CustomCodeListingDeleteButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canDeleteCustomCode: PropTypes.bool.isRequired,
  canViewCustomCode: PropTypes.bool.isRequired,
  customCodeEditLink: PropTypes.func.isRequired,
  customCodeId: PropTypes.number.isRequired,
  customCodeName: PropTypes.string.isRequired,
  decrementCustomCodeCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  getDeleteCustomCodeEndpoint: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const CustomCodeListingButtons = ({
  decrementCustomCodeCount,
  canDeleteCustomCode,
  customCodeId,
  customCodeName,
  triggerRefresh,
  canViewCustomCode,
  customCodeEditLink,
  getDeleteCustomCodeEndpoint,
  dispatchToasterAction,
}) => (
  <>
    {canViewCustomCode && (
      <Button
        href={customCodeEditLink(customCodeId)}
        icon="wrench"
        title="Edit Custom Code"
        label="Edit Custom Code"
        id={`edit-custom-code-${customCodeId}`}
        className="ufr-edit-button"
      />
    )}
    {canDeleteCustomCode && (
      <CustomCodeListingDeleteButton
        decrementCustomCodeCount={decrementCustomCodeCount}
        customCodeId={customCodeId}
        customCodeName={customCodeName}
        triggerRefresh={triggerRefresh}
        getDeleteCustomCodeEndpoint={getDeleteCustomCodeEndpoint}
        dispatchToasterAction={dispatchToasterAction}
      />
    )}
  </>
);

CustomCodeListingButtons.propTypes = propTypes;

export default CustomCodeListingButtons;
