import {
  addToaster,
  slideDownAndRemoveToaster,
  updateToaster,
} from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  decrementItemsCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  itemTitle: PropTypes.string,
  streamId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const modalBody = (itemTitle) => (
  <p>
    Are you sure you want to delete <b>&quot;{itemTitle}&quot;</b>?
  </p>
);

const DeleteItemButton = ({
  itemId,
  itemTitle,
  hubId,
  triggerRefresh,
  decrementItemsCount,
  dispatchToasterAction,
}) => {
  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);

  /* eslint-disable sort-keys */
  const deleteItem = async (csrfToken, itemId) => {
    const formData = new FormData();
    formData.append('deleteItemId', itemId);
    dispatchToasterAction(
      addToaster({
        id: itemId,
        type: 'info',
        text: 'Deleting...',
      }),
    );

    try {
      await axios({
        method: 'post',
        url: `/hubs/ajax_deleteHubItem/${hubId}`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': csrfToken,
        },
        data: formData,
      });
      dispatchToasterAction(
        updateToaster({
          id: itemId,
          text: 'Deleted!',
          type: 'success',
        }),
      );
      decrementItemsCount?.();
      triggerRefresh();
    } catch {
      dispatchToasterAction(
        updateToaster({
          id: itemId,
          text: 'Sorry, we were unable to delete that item.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: itemId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <>
            <ConfirmationModal
              isOpen={isDeleteItemModalOpen}
              handleClose={() => setIsDeleteItemModalOpen(false)}
              handleConfirm={() => deleteItem(csrfToken, itemId)}
              header={'Delete Item?'}
              body={modalBody(itemTitle)}
              primaryButtonText="OK"
            />

            <Button
              className="ufr-delete-button"
              label="Delete Item"
              title="Delete Item"
              id={`delete-item-${itemId}`}
              icon="bin"
              onClick={() => setIsDeleteItemModalOpen(true)}
            />
          </>
        )}
      </CsrfContextConsumer>
    </>
  );
};

DeleteItemButton.propTypes = propTypes;

export default DeleteItemButton;
