import './streamsListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { filterStreamDropdowns, getColumns, searchPlaceholder } from './helpers';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import handleClick from '@/utils/handleClick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import StreamsListingAboutHubModal from './modals/StreamsListingAboutHubModal';
import StreamsListingNoStreamsMessage from './StreamsListingNoStreamsMessage';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  canCreateStreams: PropTypes.bool.isRequired,
  collectionsCount: PropTypes.number.isRequired,
  collectionsCountByType: PropTypes.objectOf(PropTypes.number).isRequired,
  created: PropTypes.string,
  createdBy: PropTypes.string,
  currentUserId: PropTypes.number.isRequired,
  hubId: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  itemsCount: PropTypes.number,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  streamPermissions: PropTypes.objectOf(
    PropTypes.exact({
      marketing: PropTypes.bool.isRequired,
      native: PropTypes.bool.isRequired,
      sales: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  streamTypes: PropTypes.objectOf(
    PropTypes.exact({
      getItems: PropTypes.bool.isRequired,
      getTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const entityName = {
  plural: 'Streams',
  singular: 'Stream',
};

const newStreamActionButton = (onClickCallback, canCreateStreams) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: !canCreateStreams,
  id: 'create-stream',
  onClick: () => handleClick({ onClickCallback }),
  text: 'New Stream',
});

const columns = (
  hubId,
  isSearchDisabled,
  isPrimary,
  streamPermissions,
  currentUserId,
  dispatchToasterAction,
) => {
  const allColumns = getColumns(
    hubId,
    isSearchDisabled,
    isPrimary,
    streamPermissions,
    currentUserId,
    dispatchToasterAction,
  );
  /* Removed Authentication column per ENG-1338 */
  return [
    allColumns.NAME,
    allColumns.TITLE,
    allColumns.OWNER,
    allColumns.MODIFIED_AT,
    allColumns.ITEM_COUNT,
    allColumns.SEO_STATUS,
    allColumns.ID,
    allColumns.CREATED_AT,
    allColumns.CONTROLS,
  ];
};

const getEndpointBuilder = (hubId) => ({
  getStreams: () => `/api/v2/hubs/${hubId}/streams?fbp=1`,
});

const StreamsListingDatatable = ({
  hubId,
  streamTypes,
  collectionsCountByType,
  created,
  createdBy,
  itemsCount,
  onClickCallback,
  canCreateStreams,
  collectionsCount,
  streamPermissions,
  isPrimary,
  currentUserId,
}) => {
  const [streamsCount, setStreamsCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isAboutHubModalOpen, setIsAboutHubModalOpen] = useState(false);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const { getStreams } = getEndpointBuilder(hubId);

  if (isInitialLoad) {
    setIsInitialLoad(false);
    collectionsCount === 0 ? setIsSearchDisabled(true) : setIsSearchDisabled(false);
  }

  const aboutHubModalProps = {
    created: created,
    createdBy: createdBy,
    handleClose: () => setIsAboutHubModalOpen(false),
    hubId: hubId,
    isOpen: isAboutHubModalOpen,
    itemsCount: itemsCount,
    streamsCount: streamsCount,
  };

  const dropdownMenuItems = [
    {
      id: 'about',
      onClick: () => setIsAboutHubModalOpen(true),
      text: 'About',
    },
    {
      id: 'archived-streams',
      text: 'Manage Archived Streams',
      url: `/hubs/archived_streams/${hubId}`,
    },
  ];

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getStreams(), {
      params: queries,
    });
    setStreamsCount(meta.count);
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="streams"
        useStateHandling
        entityName={entityName}
        actionButtons={[newStreamActionButton(onClickCallback, canCreateStreams)]}
        filterDropdowns={filterStreamDropdowns(streamTypes, collectionsCountByType)}
        searchPlaceholder={searchPlaceholder(streamsCount, entityName)}
        noSearchResultsMessage={
          <StreamsListingNoStreamsMessage
            isSearchDisabled={isSearchDisabled}
            onClickCallback={onClickCallback}
            canCreateStreams={canCreateStreams}
          />
        }
        columns={columns(
          hubId,
          isSearchDisabled,
          isPrimary,
          streamPermissions,
          currentUserId,
          dispatchToasterAction,
        )}
        getData={getData}
        initialSort={{ desc: true, id: 'created_at' }}
        showLoadingState
        hidePagination={isSearchDisabled}
        dropdownMenuItems={dropdownMenuItems}
        disableSearch={isSearchDisabled}
        getCellProps={() => ({
          decrementStreamsCount: () => {
            setStreamsCount(streamsCount - 1);
          },
        })}
      />
      <StreamsListingAboutHubModal {...aboutHubModalProps} />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

StreamsListingDatatable.propTypes = propTypes;

export default StreamsListingDatatable;
