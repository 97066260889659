import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../../shared/button/Button';
import ConfirmationModal from '../../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  endpoint: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showMessage: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const defaultProps = {};

const EmbedParameterDeleteButton = ({
  entityId,
  header,
  message,
  endpoint,
  showMessage,
  triggerRefresh,
  disabled,
}) => {
  const deleteParam = async (csrfToken) => {
    showMessage('info', 'Deleting...', entityId);

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'delete',
        url: endpoint(entityId),
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      showMessage('success', 'Embed parameter deleted.', entityId);
      triggerRefresh();
    } catch {
      showMessage('error', 'There was an error deleting the embed parameter.', entityId);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
            handleConfirm={() => deleteParam(csrfToken)}
            body={message}
            header={header}
            primaryButtonText="Delete Parameter"
          />

          <Button
            label="delete embed parameter"
            icon="bin"
            id={`delete-embed-param-${entityId}`}
            onClick={() => setIsOpen(true)}
            disabled={disabled}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

EmbedParameterDeleteButton.propTypes = propTypes;
EmbedParameterDeleteButton.defaultProps = defaultProps;

export default EmbedParameterDeleteButton;
