import './CtaEditPage.less';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import AppearanceAndFieldsTab from '../cta/tabs/AppearanceAndFieldsTab';
import AppearanceTab from '../cta/tabs/AppearanceTab';
import axios from 'axios';
import Cta15ListingCopyButton from '@/components/datatables/cta15Listing/Cta15ListingCopyButton';
import Cta15ListingStatusButton from '@/components/datatables/cta15Listing/Cta15ListingStatusButton';
import CtaEmbedButton from '../ctaActionButtons/CtaEmbedButton';
import CtaListingDeleteButton from '@/components/ctaListingDeleteButton/CtaListingDeleteButton';
import CtaPreview from '@/components/ctaPreview/CtaPreview';
import EditableText from '@/components/shared/editableText/EditableText';
import Integration from '../cta/integrations/Integration';
import Page from '../page/Page';
import PlacementTab from '../cta/placementTab/PlacementTab';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import CtaContinueButton from '@/components/cta/CtaContinueButton';
import TabsContainer from '@/components/tabs/TabsContainer';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
  updateToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  cta: PropTypes.shape({
    HubCta: PropTypes.shape({
      background_color: PropTypes.string.isRequired,
      button_background_color: PropTypes.string.isRequired,
      button_font_color: PropTypes.string.isRequired,
      button_label: PropTypes.string.isRequired,
      font_aspect_ratio: PropTypes.string.isRequired,
      font_color: PropTypes.string.isRequired,
      has_background: PropTypes.string.isRequired,
      hub_id: PropTypes.string.isRequired,
      tagline: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    HubCtaFormFields: PropTypes.arrayOf(
      PropTypes.shape({
        HubCtaFormField: PropTypes.shape({
          active: PropTypes.string.isRequired,
          default_value: PropTypes.string.isRequired,
          display_conditionally: PropTypes.string.isRequired,
          field_type: PropTypes.string.isRequired,
          is_conditional_parent: PropTypes.bool.isRequired,
          label: PropTypes.string.isRequired,
          label_only_value: PropTypes.string.isRequired,
          locked: PropTypes.string.isRequired,
          required: PropTypes.string.isRequired,
          validation: PropTypes.string,
        }),
      }),
    ),
    HubCtaFormFieldValues: PropTypes.object,
    HubCtasForm: PropTypes.shape({
      custom_form_handler: PropTypes.string.isRequired,
      custom_form_handler_url: PropTypes.string,
      never_hide: PropTypes.string.isRequired,
      never_hide_cta_action: PropTypes.string.isRequired,
      opt_in_label: PropTypes.string.isRequired,
      opt_in_required: PropTypes.string.isRequired,
      progressive_profiling: PropTypes.string.isRequired,
      send_event: PropTypes.string.isRequired,
      service_type: PropTypes.string.isRequired,
      success_button_label: PropTypes.string.isRequired,
      success_button_url: PropTypes.string.isRequired,
      success_link_active: PropTypes.string.isRequired,
      success_message: PropTypes.string.isRequired,
    }),
    HubCtasUrl: PropTypes.shape({
      lead_url: PropTypes.string,
    }),
  }),
  ctaId: PropTypes.number.isRequired,
  ctaName: PropTypes.string.isRequired,
  ctaStatus: PropTypes.number.isRequired,
  domainsConfig: PropTypes.string.isRequired,
  hasCustomFormHandler: PropTypes.bool,
  hubId: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tabContentId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  title: 'Internal Name',
};

const getCtaStatus = (ctaStatus) => {
  let status;

  switch (ctaStatus) {
    case 1:
      status = 'ACTIVE';
      break;
    case 2:
      status = 'DRAFT';
      break;
    default:
      status = 'INACTIVE';
      break;
  }

  return status;
};

const CtaEditPage = ({
  title,
  cta,
  ctaName,
  ctaStatus,
  ctaId,
  hubId,
  domainsConfig,
  items,
  hasCustomFormHandler,
  ...props
}) => {
  const { type: ctaType } = cta.HubCta;
  const [currentInternalName, setInternalName] = useState(title);
  const [currentCtaName, setCtaName] = useState(ctaName);
  const [toasterTimers, setToasterTimers] = useState({});
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [selectedTabIndex, setSelectedTabIndex] = useState(false);
  const [status, setStatus] = useState(() => getCtaStatus(ctaStatus));

  // used to programmatically select and fetch the current tab
  const tabsListRef = useRef();

  // store a modified version of items
  const itemsData = useRef(
    items?.map((item, i) => {
      item.onClick = () => {
        setSelectedTabIndex(i);
      };
      return item;
    }) || [],
  );

  const handleToasterLifecycle = (toaster, toasterId) => {
    dispatchToasterAction(addToaster(toaster));

    if (toasterTimers[toasterId]) {
      clearTimeout(toasterTimers[toasterId]);
    }

    setToasterTimers((prevState) => ({
      ...prevState,
      [toasterId]: setTimeout(() => {
        dispatchToasterAction(
          slideDownAndRemoveToaster({
            id: toasterId,
          }),
        );

        setToasterTimers(({ [toasterId]: _, ...rest }) => ({
          ...rest,
        }));
      }, 2000),
    }));
  };

  const handleSubmit = (type, csrfToken) => async (value) => {
    const formData = new FormData();
    formData.append(`data[CTA][${type}]`, value);

    dispatchToasterAction(
      addToaster({
        id: ctaId,
        text: 'Saving...',
        type: 'info',
      }),
    );

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: `/hubs/ajax_autoUpdateField/${hubId}/CTA/${ctaId}`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': csrfToken,
        },
        data: formData,
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        updateToaster({
          id: ctaId,
          text: 'Saved!',
          type: 'success',
        }),
      );
      switch (type) {
        case 'name':
          setCtaName(value);
          break;
        case 'internal_name':
          setInternalName(value);
          break;
      }
    } catch (error) {
      dispatchToasterAction(
        updateToaster({
          id: ctaId,
          text: `Error: ${error.data}`,
          type: 'failure',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(
        slideDownAndRemoveToaster({
          id: ctaId,
        }),
      );
    }, 2000);
  };

  const triggerRefresh = () => window.location.reload(false);

  const actionButtons = () => (
    <div className={'cta-edit-page-action-button'}>
      <div className={'cta-edit-page-action-button-container'}>
        <CtaPreview
          ctaIdEditPage={ctaId}
          ctaTitle={ctaName}
          icon={false}
          isCtaEditPage={true}
          src={`${domainsConfig}hubsFront/embed_cta/${ctaId}`}
        />
        <Cta15ListingStatusButton
          ctaId={ctaId}
          ctaName={ctaName}
          ctaStatus={ctaStatus}
          handleToasterLifecycle={handleToasterLifecycle}
          isCtaEditPage={true}
          triggerRefresh={triggerRefresh}
        />
        <Cta15ListingCopyButton
          ctaId={ctaId}
          ctaName={ctaName}
          hubId={hubId}
          handleToasterLifecycle={handleToasterLifecycle}
          isCtaEditPage={true}
          triggerRefresh={triggerRefresh}
        />
        <CtaEmbedButton ctaId={ctaId} ctaName={ctaName} domainsConfig={domainsConfig} />
        <CtaListingDeleteButton
          ctaId={ctaId}
          ctaName={ctaName}
          handleToasterLifecycle={handleToasterLifecycle}
          isCtaEditPage={true}
          triggerRefresh={triggerRefresh}
        />
        <CtaContinueButton
          id="cta-edit-button-next"
          isLastTab={selectedTabIndex + 1 === itemsData?.current?.length}
          showActivate={status === 'DRAFT'}
          ctaId={ctaId}
          onActivate={(ctaData) => {
            // update status
            if (ctaData?.active !== undefined) {
              setStatus(getCtaStatus(ctaData.active));
            }
          }}
          onNext={() => {
            const nextTabIndex = selectedTabIndex + 1;
            if (nextTabIndex < itemsData?.current?.length) {
              tabsListRef?.current?.handleSelect(itemsData?.current?.[nextTabIndex]?.id);
            }
          }}
        />
      </div>
    </div>
  );

  const ctaHeader = (csrfToken) => (
    <div className={'cta-edit-page-header'}>
      <div className={'cta-edit-page-editable-text-container'}>
        <EditableText
          onSubmit={handleSubmit('internal_name', csrfToken)}
          textType={'title'}
          size={'large'}
          text={currentInternalName}
          editable={true}
          placeholder="Enter Internal Name"
          className="ufr-page-header-cta-internal-name"
          maxLength={140}
        />
        <EditableText
          onSubmit={handleSubmit('name', csrfToken)}
          textType={'title'}
          size={'small'}
          text={currentCtaName}
          editable={true}
          showCharacterCounter={true}
          placeholder="Enter CTA Name"
          className="ufr-page-header-cta-name"
          maxLength={140}
        />
      </div>
      <div className={'cta-edit-additional-info'}>
        <div>Status: {status}</div>
        <div>ID: {ctaId}</div>
      </div>
    </div>
  );

  useEffect(() => {
    // check active tab on mount
    // the component will load a saved active tab state on reload (history.state)
    const activeTab = tabsListRef?.current?.getActiveTab();
    const selectedTab = itemsData?.current?.findIndex(({ id }) => id === activeTab);

    if (selectedTab) {
      setSelectedTabIndex(selectedTab);
    }
  }, []);

  /* eslint-disable sort-keys */
  /* eslint-disable react/prop-types */

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <div className={'cta-edit-page'}>
            <Page>
              {ctaHeader(csrfToken)}
              <TabsContainer tabsListRef={tabsListRef} items={itemsData?.current} {...props} />
              <div className={'cta-edit-page-content'}>
                <div id={'hub-edit-cta-tab-integrations'} className={'hidden'}>
                  <Integration
                    csrfToken={csrfToken}
                    cta={cta}
                    dispatchToasterAction={dispatchToasterAction}
                  />
                </div>
                <div id={'hub-edit-cta-tab-appearance'} className={'hidden'}>
                  {ctaType === 'url' ? (
                    <AppearanceTab
                      cta={{
                        ...cta.HubCta,
                        embed_url: `${domainsConfig}hubsFront/embed_cta/${ctaId}`,
                        font_aspect_ratio: parseFloat(cta.HubCta.font_aspect_ratio),
                        has_background: !!parseInt(cta.HubCta.has_background),
                        lead_url: cta.HubCtasUrl.lead_url,
                      }}
                      {...{ csrfToken, ctaId, handleToasterLifecycle }}
                    />
                  ) : (
                    <AppearanceAndFieldsTab
                      cta={{
                        ...cta.HubCta,
                        font_aspect_ratio: parseFloat(cta.HubCta.font_aspect_ratio),
                        has_background: !!parseInt(cta.HubCta.has_background),
                      }}
                      formCta={{
                        ...cta.HubCtasForm,
                        ctaFormFields: cta.HubCtaFormFields.map(
                          ({ HubCtaFormField: formField }) => ({
                            HubCtaFormField: {
                              ...formField,
                              active: !!parseInt(formField.active),
                              display_conditionally: !!parseInt(formField.display_conditionally),
                              locked: !!parseInt(formField.locked),
                              progressive_profiling: !!parseInt(formField.progressive_profiling),
                              required: !!parseInt(formField.required),
                            },
                          }),
                        ),
                        ctaFormFieldValues: cta.HubCtaFormFieldValues,
                        custom_form_handler: parseInt(cta.HubCtasForm.custom_form_handler),
                        hasCustomFormHandler: hasCustomFormHandler,
                        never_hide: parseInt(cta.HubCtasForm.never_hide),
                        never_hide_cta_action: parseInt(cta.HubCtasForm.never_hide_cta_action),
                        opt_in_required: parseInt(cta.HubCtasForm.opt_in_required),
                        progressive_profiling: parseInt(cta.HubCtasForm.progressive_profiling),
                        send_event: parseInt(cta.HubCtasForm.send_event),
                        success_link_active: parseInt(cta.HubCtasForm.success_link_active),
                      }}
                      {...{ csrfToken, ctaId, handleToasterLifecycle }}
                    />
                  )}
                </div>
                <div id={'hub-edit-cta-tab-placements'} className={'hidden'}>
                  <PlacementTab {...props} ctaId={ctaId} />
                </div>
              </div>
              {actionButtons()}
            </Page>

            <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
          </div>
        )}
      </CsrfContextConsumer>
    </CsrfProvider>
  );
};

CtaEditPage.propTypes = propTypes;
CtaEditPage.defaultProps = defaultProps;

export default CtaEditPage;
