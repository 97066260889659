import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import TabsContainer from '@/components/tabs/TabsContainer';
import TagGroupListingDatatable from '@/components/datatables/tags/TagGroupListingDatatable';
import TagsListingDatatable from '@/components/datatables/tags/TagsListingDatatable';

/* eslint-disable sort-keys */
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,

  // tabs
  activeTab: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tabContentId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,

  // tags datatable
  hubId: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,

  //tag groups datatable
  tagGroups: PropTypes.array.isRequired,
};

const TagPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    title: { text: title },
    subtitle: { text: subtitle },
  };
  const tagGroupListingDatatableRef = useRef();
  const tagsListingDatatableRef = useRef();

  return (
    <Page>
      <PageHeader {...headerText} />
      <TabsContainer {...props} />
      <div id="hub-tag-tab-groups" className="hidden">
        <TagGroupListingDatatable
          {...props}
          tagGroupListingDatatableRef={tagGroupListingDatatableRef}
          tagsListingDatatableRef={tagsListingDatatableRef}
        />
      </div>
      <div id="hub-tag-tab-tags" className="hidden">
        <TagsListingDatatable
          {...props}
          tagGroupListingDatatableRef={tagGroupListingDatatableRef}
          tagsListingDatatableRef={tagsListingDatatableRef}
        />
      </div>
    </Page>
  );
};
TagPage.propTypes = propTypes;

export default TagPage;
