import CustomCodeListingHubDatatable from '../datatables/customCode/hub/CustomCodeListingHubDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canCreateCustomCode: PropTypes.bool.isRequired,
  canDeleteCustomCode: PropTypes.bool.isRequired,
  canEditCustomCode: PropTypes.bool.isRequired,
  canEnableCustomCode: PropTypes.bool.isRequired,
  canViewCustomCode: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const CustomCodePage = ({ title, subtitle, ...props }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <CustomCodeListingHubDatatable {...props} />
    </Page>
  );
};

CustomCodePage.propTypes = propTypes;

export default CustomCodePage;
