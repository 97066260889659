import Button from '../../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const PrimaryButton = ({ onClick, children, label, ...props }) => (
  <Button
    id="modal-primary"
    className="modal-primary"
    onClick={onClick}
    ufrStyle="primary"
    label={label}
    {...props}
  >
    {children}
  </Button>
);

PrimaryButton.defaultProps = {
  onClick: null,
};

PrimaryButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default PrimaryButton;
