import AppFunctionalityListingDatatable from '@/components/datatables/privacyGroups/appFunctionality/AppFunctionalityListingDatatable';
import FunctionalityListingDatatable from '../datatables/privacyGroups/functionality/FunctionalityListingDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PrivacyGroupsListingDatatable from '../datatables/privacyGroups/groups/PrivacyGroupsListingDatatable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TabsContainer from '@/components/tabs/TabsContainer';

/* eslint-disable sort-keys */
const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,

  // tabs
  activeTab: PropTypes.string,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      tabContentId: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,

  // Data tables
  hubId: PropTypes.number.isRequired,
};

const PrivacyGroupsPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    title: { text: title },
    subtitle: { text: subtitle },
  };

  const [triggerRefreshCounter, setTriggerRefreshCounter] = useState(0);
  const [privacyGroups, setPrivacyGroups] = useState([]);

  return (
    <Page>
      <PageHeader {...headerText} />
      <TabsContainer {...props} />
      <div id="groups-tab" className="hidden">
        <PrivacyGroupsListingDatatable
          {...props}
          incrementTriggerRefreshCounter={() => setTriggerRefreshCounter(triggerRefreshCounter + 1)}
          setPrivacyGroups={setPrivacyGroups}
        />
      </div>
      <div id="functionality-tab" className="hidden">
        <FunctionalityListingDatatable
          {...props}
          triggerRefreshCounter={triggerRefreshCounter}
          privacyGroups={privacyGroups}
        />
      </div>
      <div id="app-functionality-tab" className="hidden">
        <AppFunctionalityListingDatatable
          {...props}
          triggerRefreshCounter={triggerRefreshCounter}
          privacyGroups={privacyGroups}
        />
      </div>
    </Page>
  );
};

PrivacyGroupsPage.propTypes = propTypes;

export default PrivacyGroupsPage;
