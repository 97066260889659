import './appearanceTab.less';
import { removeBackground, updateCta, uploadBackground } from './AppearanceTab';
import axios from 'axios';
import ButtonCtaAccordion from '../accordions/ButtonCtaAccordion/ButtonCtaAccordion';
import CtaItem from '../CtaItem';
import FormFieldAccordion from '../accordions/formFieldAccordion/FormFieldAccordion';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SuccessCtaAccordion from '../accordions/successCtaAccordion/SuccessCtaAccordion';
import TileDesignAccordion from '../accordions/TileDesignAccordion';

const propTypes = {
  csrfToken: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    background_color: PropTypes.string.isRequired,
    button_background_color: PropTypes.string.isRequired,
    button_font_color: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    font_aspect_ratio: PropTypes.number.isRequired,
    font_color: PropTypes.string.isRequired,
    has_background: PropTypes.bool.isRequired,
    lead_url: PropTypes.string,
    tagline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  ctaId: PropTypes.string.isRequired,
  formCta: PropTypes.shape({
    ctaFormFields: PropTypes.arrayOf(
      PropTypes.shape({
        HubCtaFormField: PropTypes.shape({
          active: PropTypes.bool.isRequired,
          label: PropTypes.string.isRequired,
          locked: PropTypes.bool.isRequired,
        }),
      }),
    ),
    ctaFormFieldValues: PropTypes.object.isRequired,
    custom_form_handler: PropTypes.number.isRequired,
    custom_form_handler_url: PropTypes.string,
    never_hide: PropTypes.number.isRequired,
    never_hide_cta_action: PropTypes.number.isRequired,
    opt_in_label: PropTypes.string.isRequired,
    opt_in_required: PropTypes.number.isRequired,
    progressive_profiling: PropTypes.number,
    send_event: PropTypes.number,
    service_type: PropTypes.string.isRequired,
    success_button_label: PropTypes.string.isRequired,
    success_button_url: PropTypes.string.isRequired,
    success_link_active: PropTypes.number.isRequired,
    success_message: PropTypes.string.isRequired,
  }),
  handleToasterLifecycle: PropTypes.func,
};

/* eslint-disable sort-keys */
const updateFormField = async (ctaId, prevData, newData, setData, csrfToken) => {
  const res = await axios({
    method: 'patch',
    url: `/api/v2/form-cta/${ctaId}/form-field/${newData.fieldId}`,
    data: { active: parseInt(newData.active) },
    headers: {
      'X-Csrf-Token': csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  setData({
    ...prevData,
    ctaFormFields: prevData.ctaFormFields.map(({ HubCtaFormField }) => ({
      HubCtaFormField:
        HubCtaFormField.id === newData.fieldId
          ? { ...HubCtaFormField, active: +res.data.active }
          : { ...HubCtaFormField },
    })),
  });
};

const updateFormCta = async (ctaId, prevData, newData, setData, csrfToken) => {
  const res = await axios({
    method: 'patch',
    url: `/api/v2/form-cta/${ctaId}`,
    data: newData,
    headers: {
      'X-Csrf-Token': csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  setData({ ...prevData, ...res.data });
};
/* eslint-enable sort-keys */

const AppearanceAndFieldsTab = ({ cta, csrfToken, ctaId, formCta, handleToasterLifecycle }) => {
  const [data, setData] = useState(cta);
  const [formCtaData, setFormCtaData] = useState(formCta);
  const [openAccordion, setOpenAccordion] = useState('tile-design');
  const getCtaState = () => {
    switch (openAccordion) {
      case 'tile-design':
        return 'default';
      case 'success':
        return 'success';
      default:
        return 'focus';
    }
  };

  const handleSubmit = async (newData, action, csrfToken) => {
    let toaster = {
      id: ctaId,
    };
    try {
      switch (action) {
        case 'update-form-field':
          await updateFormField(ctaId, formCtaData, newData, setFormCtaData, csrfToken);
          break;
        case 'update-form-cta':
          await updateFormCta(ctaId, formCtaData, newData, setFormCtaData, csrfToken);
          break;
        case 'upload-bg':
          await uploadBackground(ctaId, data, newData, setData, csrfToken);
          break;
        case 'remove-bg':
          await removeBackground(ctaId, data, setData, csrfToken);
          break;
        default:
          await updateCta(ctaId, newData, setData, csrfToken);
      }

      toaster = {
        ...toaster,
        text: 'Saved',
        type: 'success',
      };
    } catch (e) {
      toaster = {
        ...toaster,
        text: 'Something went wrong. Please try again.',
        type: 'error',
      };
    }
    if (handleToasterLifecycle) handleToasterLifecycle(toaster, ctaId);
  };

  return (
    <div className="tab-container">
      <div className="cta-accordions">
        <TileDesignAccordion
          form={{
            background_color: data.background_color,
            font_aspect_ratio: data.font_aspect_ratio,
            font_color: data.font_color,
            has_background: data.has_background,
            tagline: data.tagline,
          }}
          handleSubmit={(form, action) => handleSubmit(form, action, csrfToken)}
          isOpen={openAccordion === 'tile-design'}
          setOpenAccordion={setOpenAccordion}
        />
        <FormFieldAccordion
          ctaId={ctaId}
          serviceType={formCta.service_type}
          form={{
            formFields: formCta.ctaFormFields.map(({ HubCtaFormField: field }) => ({
              active: field.active,
              id: field.id,
              label: field.label,
              locked: field.locked,
            })),
            opt_in_label: formCta.opt_in_label,
            opt_in_required: formCta.opt_in_required,
            progressive_profiling: formCta.progressive_profiling,
          }}
          handleSubmit={(form, action) => handleSubmit(form, action, csrfToken)}
          isManageFieldsDisabled={false}
          isOpen={openAccordion === 'form-field'}
          setOpenAccordion={setOpenAccordion}
        />
        <ButtonCtaAccordion
          ctaType={cta.type}
          data={{
            button_background_color: data.button_background_color,
            button_font_color: data.button_font_color,
            button_label: data.button_label,
          }}
          handleSubmit={(form) => handleSubmit(form, '', csrfToken)}
          isOpen={openAccordion === 'button'}
          setOpenAccordion={setOpenAccordion}
        />
        <SuccessCtaAccordion
          ctaId={data.id}
          form={{
            custom_form_handler: parseInt(formCtaData.custom_form_handler),
            custom_form_handler_url: formCtaData.custom_form_handler_url,
            never_hide: parseInt(formCtaData.never_hide),
            never_hide_cta_action: parseInt(formCtaData.never_hide_cta_action),
            send_event: parseInt(formCtaData.send_event),
            success_button_label: formCtaData.success_button_label,
            success_button_url: formCtaData.success_button_url,
            success_link_active: parseInt(formCtaData.success_link_active),
            success_message: formCtaData.success_message,
          }}
          handleSubmit={(form, action) => handleSubmit(form, action, csrfToken)}
          hasCustomFormHandler={formCtaData.hasCustomFormHandler}
          isOpen={openAccordion === 'success'}
          setOpenAccordion={setOpenAccordion}
        />
      </div>
      <div className="preview-container">
        <h3 className="preview-text">Preview</h3>
        <CtaItem cta={data} formCta={formCtaData} state={getCtaState()} width={250} height={330} />
      </div>
    </div>
  );
};

AppearanceAndFieldsTab.propTypes = propTypes;

export default AppearanceAndFieldsTab;
