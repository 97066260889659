import axios from 'axios/index';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import React from 'react';
import uniq from 'lodash/uniq';

import './linkablesModal.less';
import {
  allLocalesItem,
  get,
  setUrlToProxy,
  transformToDropdownItem,
} from './linkablesModalHelpers';
import { linkablesRequestLimit } from '../LangLinkDatatable';
import { Modal, ModalBody, ModalHeader } from '../../../modal';
import ControlBarSearch from '../../../datatable/controlBar/ControlBarSearch';
import LinkablesModalFooter from './LinkablesModalFooter';
import SelectDropdown from '../../../formInputs/selectDropdown/SelectDropdown';
import Spinner from '../../../modal/Spinner/Spinner';
import StreamTile from '../../../tiles/StreamTile';

const { bool, func, arrayOf, number, string } = PropTypes;
const propTypes = {
  getCreateLinkUrl: func.isRequired,
  getLinkablesUrl: string.isRequired,
  handleClose: func,
  isOpen: bool,
  linkableLocales: PropTypes.arrayOf(
    PropTypes
      .shape
      // todo
      (),
  ),
  linkables: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes
        .shape
        // todo
        (),
    ),
    meta: PropTypes.shape({
      next_page: PropTypes.string,
    }),
  }),
  linkedLocaleIds: arrayOf(number),
  refreshTable: func.isRequired,
  showMessage: func.isRequired,
};

const defaultProps = {
  handleClose: () => {},
  isOpen: false,
  linkableLocales: [],
  linkables: [],
  linkedLocaleIds: [],
};

/**
 * Modal to select lang-linkables.
 */
class LinkableStreamsModal extends React.Component {
  state = {
    disabledLocaleIds: [],
    linkables: this.props.linkables.data,
    loading: false,
    nextPage: this.props.linkables.meta.next_page || null,
    queries: {
      limit: linkablesRequestLimit,
    },
    selectedIds: [],
  };

  close = () => {
    this.props.handleClose();
  };

  /**
   * Toggle the presence of an id in the selectedIds array
   */
  updateSelection =
    ({ entityId, localeId }) =>
    () => {
      if (!entityId || !localeId) return;

      const { selectedIds, disabledLocaleIds } = this.state;

      if (selectedIds.includes(entityId)) {
        this.setState({
          disabledLocaleIds: disabledLocaleIds.filter((id) => id !== localeId),
          selectedIds: selectedIds.filter((id) => id !== entityId),
        });
      } else {
        this.setState({
          disabledLocaleIds: uniq([...disabledLocaleIds, localeId]),
          selectedIds: [...selectedIds, entityId],
        });
      }
    };

  renderTiles = () => {
    const { linkedLocaleIds } = this.props;
    const { linkables, selectedIds, disabledLocaleIds } = this.state;

    const noStreams = !linkables.length;
    if (noStreams) {
      return 'There is currently nothing that can be linked.';
    }

    return linkables.map(({ id, title, service, thumbnail_url: thumbnailUrl, locale }) => {
      const disabledLocales = [...linkedLocaleIds, ...disabledLocaleIds];
      const selected = selectedIds.includes(id);
      const disabled = disabledLocales.includes(locale.id) && !selected;
      const tooltip = disabled
        ? 'You have already linked one Stream of this locale to the current Stream.'
        : 'Select Streams';
      return (
        <StreamTile
          id={id}
          title={title}
          type={service}
          thumbnailUrl={thumbnailUrl}
          key={id}
          tooltip={tooltip}
          disabled={disabled}
          onClick={this.updateSelection({
            entityId: id,
            localeId: locale.id,
          })}
          selected={selected}
          bottomText={locale.display_name}
        />
      );
    });
  };

  saveSelected = async (csrfToken) => {
    const { getCreateLinkUrl, showMessage, refreshTable } = this.props;
    const {
      selectedIds: [firstId, ...restIds],
    } = this.state;
    if (!firstId) return;

    /* eslint-disable sort-keys */
    const saveLink = (id) =>
      axios({
        method: 'post',
        url: getCreateLinkUrl(id),
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
    /* eslint-enable sort-keys */

    const toasterId = 'save-links';
    try {
      showMessage('info', 'Saving...', toasterId);
      this.close();

      // launch this one first so that we don't have a race condition with the link group
      await saveLink(firstId);

      if (restIds.length) {
        const remainingPromises = restIds.map((id) => saveLink(id));
        await Promise.all(remainingPromises);
      }

      showMessage('success', 'Item Links Saved!', toasterId);
      refreshTable();
    } catch {
      this.close();
      showMessage('error', 'There was an error saving one or more of your Item Links.', toasterId);
      // some of the requests might have succeeded
      refreshTable();
    }
  };

  fetchError = () =>
    this.props.showMessage('error', 'There was an error fetching linkable Streams.');

  fetch = async (newQuery = {}) => {
    const { queries } = this.state;
    const { getLinkablesUrl } = this.props;
    const newQueries = { ...queries, ...newQuery };

    try {
      this.setState({
        loading: true,
        nextPage: null,
      });
      const [linkables, meta] = await get(getLinkablesUrl, newQueries);

      this.setState({
        linkables,
        loading: false,
        nextPage: meta && meta.next_page,
        queries: newQueries,
      });
    } catch {
      this.fetchError();
    }
  };

  fetchMore = async () => {
    const { nextPage } = this.state;
    if (!nextPage) return;

    // change next_url to use the API proxy so it will work in storybook
    const url = setUrlToProxy(nextPage, '/streams');

    try {
      const [linkableData, meta] = await get(url);

      // add the new stuff onto our existing state
      this.setState((prevState) => ({
        linkables: [...prevState.linkables, ...linkableData],
        nextPage: meta.next_page || null,
      }));
    } catch {
      this.fetchError();
    }
  };

  render() {
    const { isOpen, linkableLocales } = this.props;
    const { loading, selectedIds, nextPage } = this.state;

    const dropdownLocales = [allLocalesItem, ...transformToDropdownItem(linkableLocales)];
    const disableDropdown = loading || dropdownLocales.length <= 2;

    return (
      <Modal
        size="medium"
        isOpen={isOpen}
        handleClose={this.close}
        className="ufr-linkable-items-modal ufr-linkables-modal"
      >
        <ModalHeader title="Link This Stream to Other Streams" />

        <ModalBody>
          <ControlBarSearch
            onReady={({ queryValue: query }) => this.fetch({ query })}
            disabled={loading}
          />
          <SelectDropdown
            id="locale"
            disabled={disableDropdown}
            items={dropdownLocales}
            submitData={({ value: locale }) => this.fetch({ locale })}
          />
          <InfiniteScroll
            loader={<Spinner />}
            loadMore={this.fetchMore}
            hasMore={!!nextPage}
            useWindow={false}
          >
            <div className="ufr-lang-linkables-area">
              {loading ? <Spinner /> : this.renderTiles()}
            </div>
          </InfiniteScroll>
        </ModalBody>

        <LinkablesModalFooter
          onCancel={this.close}
          onConfirm={this.saveSelected}
          loading={loading}
          numberSelected={selectedIds.length}
          entityName="Item"
        />
      </Modal>
    );
  }
}

LinkableStreamsModal.propTypes = propTypes;
LinkableStreamsModal.defaultProps = defaultProps;

export default LinkableStreamsModal;
