import './ctaItem.less';
import CtaItemFormField from './CtaItemFormField';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  cta: PropTypes.shape({
    background_color: PropTypes.string.isRequired,
    background_url: PropTypes.string,
    button_background_color: PropTypes.string.isRequired,
    button_font_color: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    font_aspect_ratio: PropTypes.number.isRequired,
    font_color: PropTypes.string.isRequired,
    has_background: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  formCta: PropTypes.shape({
    ctaFormFields: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        default_value: PropTypes.string,
        display_conditionally: PropTypes.bool,
        field_type: PropTypes.string,
        is_conditional_parent: PropTypes.bool,
        label: PropTypes.string,
        label_only_value: PropTypes.string,
        locked: PropTypes.bool,
        progressive_profiling: PropTypes.bool,
        required: PropTypes.bool,
        validation: PropTypes.string,
      }),
    ),
    ctaFormFieldValues: PropTypes.object,
    opt_in_label: PropTypes.string,
    opt_in_required: PropTypes.number,
    progressive_profiling: PropTypes.number,
    success_button_label: PropTypes.string,
    success_button_url: PropTypes.string,
    success_link_active: PropTypes.number,
    success_message: PropTypes.string,
  }),
  height: PropTypes.number.isRequired,
  linkCta: PropTypes.shape({
    lead_url: PropTypes.string.isRequired,
  }),
  state: PropTypes.string,
  width: PropTypes.number.isRequired,
};

// Given a hex color code, return a hsl color value that is brighter by the specified amount.
const adjustBrightness = (hexCode, amount = 0) => {
  // Convert hex to RGB
  let r = 0,
    g = 0,
    b = 0;
  if (hexCode.length === 4) {
    r = '0x' + hexCode[1] + hexCode[1];
    g = '0x' + hexCode[2] + hexCode[2];
    b = '0x' + hexCode[3] + hexCode[3];
  } else if (hexCode.length === 7) {
    r = '0x' + hexCode[1] + hexCode[2];
    g = '0x' + hexCode[3] + hexCode[4];
    b = '0x' + hexCode[5] + hexCode[6];
  }
  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100 + amount).toFixed(1);

  return 'hsl(' + h + ',' + s + '%,' + l + '%)';
};

const CtaItem = ({ cta, formCta, linkCta, width, height, state }) => {
  const fontSize = Math.floor(cta.font_aspect_ratio * (width + height));
  const displayCss = (displayElement) => ({ display: displayElement ? 'block' : 'none' });
  const backgroundStyle = cta.has_background
    ? {
        background: `${cta.background_color} url(${cta.background_url}) center top / cover`,
        borderBottom: 0,
      }
    : {
        backgroundColor: cta.background_color,
        borderBottom: '1px solid ' + adjustBrightness(cta.background_color, -5),
      };

  return cta.type === 'url' ? (
    <div
      className={`tile single cta cta-website item ${state === 'focus' ? 'setValues' : ''}`}
      id={`hub-cta-${cta.id}`}
      style={{ height: `${height}px`, width: `${width}px`, ...backgroundStyle }}
    >
      <div style={{ height: `1px` }}></div>
      <p style={{ color: cta.font_color, fontSize: fontSize, marginTop: fontSize - 1 }}>
        {cta.tagline}
      </p>
      <a
        style={{
          backgroundColor: cta.button_background_color,
          color: cta.button_font_color,
          position: 'relative',
          top: `${146 - fontSize}px`,
        }}
        className="cta-button accent-button"
        href={linkCta.lead_url}
      >
        {cta.button_label}
      </a>
    </div>
  ) : (
    <div
      className="tile single cta cta-form item"
      id={`hub-cta-${cta.id}`}
      style={{
        height: `${height}px`,
        position: state === 'success' || state === 'default' ? 'relative' : 'inherit',
        width: `${width}px`,
        ...backgroundStyle,
      }}
    >
      <div
        className="hide-preview-cancel"
        style={{ ...displayCss(state === 'focus'), position: 'relative' }}
      >
        <div className="cta-close">&times;</div>
      </div>
      <div
        className="run-away"
        style={{
          borderBottom: backgroundStyle.borderBottom,
          height: `${height * 0.74}px`,
          width: `${width}px`,
          ...displayCss(state === 'default'),
        }}
      >
        <p style={{ color: cta.font_color, fontSize: fontSize, paddingTop: fontSize }}>
          {cta.tagline}
        </p>
      </div>
      <div
        className={`hidden-cta-fields ps-container ${state === 'focus' ? 'fields-revealed' : ''}`}
        style={{
          overflowY: 'auto',
          position: 'inherit',
          top: state === 'focus' && '25px',
          WebkitScrollbarTrack: {
            background: 'green',
          },
        }}
      >
        <form>
          <div
            className="cta-button-container cta-activate-button-container"
            style={{ width: '250px', ...displayCss(state === 'default') }}
          >
            <div className="cta-field-section email-field">
              <span className="cta-field-name" style={{ color: cta.font_color }}>
                {
                  formCta.ctaFormFields.find(
                    ({ HubCtaFormField }) =>
                      HubCtaFormField.active && HubCtaFormField.field_type !== 'hidden',
                  ).HubCtaFormField.label
                }
              </span>
              <input type="text" className="activate-button" />
            </div>
          </div>
          <div className="form-fields" style={displayCss(state === 'focus')}>
            {formCta.ctaFormFields.map((field) => (
              <CtaItemFormField
                key={field.HubCtaFormField.id}
                {...{
                  ...field.HubCtaFormField,
                  active: !!field.HubCtaFormField.active,
                  display_conditionally: !!field.HubCtaFormField.display_conditionally,
                  is_conditional_parent: !!field.HubCtaFormField.is_conditional_parent,
                  locked: !!field.HubCtaFormField.locked,
                  progressive_profiling: !!field.HubCtaFormField.progressive_profiling,
                  required: !!field.HubCtaFormField.required,
                }}
                cta={cta}
                formFieldValues={formCta.ctaFormFieldValues}
              />
            ))}
            {!!formCta.opt_in_required && (
              <div className="cta-field-section one-line opt-in-section">
                <div className="opt-in-required">
                  <span>!</span>
                </div>
                <label htmlFor="opt-in">
                  <input type="checkbox" className="opt-in" />
                  <span className="cta-field-name" style={{ color: cta.font_color }}>
                    {formCta.opt_in_label}
                  </span>
                </label>
              </div>
            )}
            <div className="cta-button-container">
              <input
                type="submit"
                value={cta.button_label}
                className="cta-button cta-submit-form accent-button"
                style={{
                  backgroundColor: cta.button_background_color,
                  color: cta.button_font_color,
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <div
        className="cta-form-success cta-form-response"
        style={{
          backgroundColor: cta.background_color,
          color: cta.font_color,
          height: `${height}px`,
          width: `${width}px`,
          ...displayCss(state === 'success'),
        }}
      >
        <div className="cta-form-message-container">
          <div className="cta-sprite cta-sprite-check cta-form-message-sprite"></div>
          <div className="cta-form-message">
            <span>{formCta.success_message}</span>
          </div>
        </div>
        <div className={`cta-button-container ${formCta.success_link_active ? '' : 'hidden'}`}>
          <a
            className="cta-button accent-button"
            href={formCta.success_button_url}
            style={{ backgroundColor: cta.button_background_color, color: cta.button_font_color }}
          >
            {formCta.success_button_label}
          </a>
        </div>
      </div>
    </div>
  );
};

CtaItem.propTypes = propTypes;

export default CtaItem;
