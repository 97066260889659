import axios from 'axios/index';
import PropTypes from 'prop-types';
import React from 'react';

import './textAutosuggest.less';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';

const propTypes = {
  accountId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  enrichmentFieldName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  initialValue: PropTypes.string,
  integrationCode: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  resetFields: PropTypes.func,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]).isRequired,
};

const defaultProps = {
  initialValue: '',
  placeholder: '',
  resetFields: undefined,
};

class TextAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      noSuggestions: false,
      suggestions: [],
      value: props.initialValue,
    };

    this.onSuggestionsFetchRequested;
  }

  getSuggestions = async (value) => {
    const { accountId, integrationCode } = this.props;
    const escapedValue = value.trim();
    const response = await axios.get(
      `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationCode}/visitor-profile-fields/${escapedValue}`,
    );

    const suggestions = response.data;
    const isInputBlank = value.trim() === '';
    const noSuggestions = !isInputBlank && suggestions.length === 0;

    this.setState({
      noSuggestions,
      suggestions,
    });
  };

  componentDidMount() {
    this.InitDebouncedOnSuggestionsFetchRequested();
  }

  InitDebouncedOnSuggestionsFetchRequested = () => {
    const delayRequestInMs = 1000;
    this.onSuggestionsFetchRequested = debounce(async ({ value }) => {
      await this.getSuggestions(value);
    }, delayRequestInMs);
  };

  getSuggestionValue = (suggestion) => suggestion.displayName;

  renderSuggestion = (suggestion) => <span>{suggestion.displayName}</span>;

  handlePageChange = () => {
    this.setState({ value: this.props.initialValue });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id || prevProps.initialValue !== this.props.initialValue) {
      this.handlePageChange();
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onBlur = () => {
    const state = {};
    if (this.props.initialValue) {
      state.value = this.props.initialValue;
    }
    if (this.state.value === '' || this.props.initialValue) {
      state.noSuggestions = false;
    }
    this.setState((prevState) => ({
      ...state,
      active: !prevState.active,
    }));
  };

  onFocus = () => {
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { submitData, resetFields, id, csrfToken, enrichmentFieldName } = this.props;
    suggestion.integrationFieldsId = id;
    submitData(suggestion, resetFields, csrfToken, enrichmentFieldName);

    this.setState({
      value: suggestion.displayName,
    });
  };

  render() {
    const { value, suggestions, noSuggestions, active } = this.state;
    const { placeholder, id } = this.props;
    const inputProps = {
      onBlur: this.onBlur,
      onChange: this.onChange,
      onFocus: this.onFocus,
      placeholder,
      value,
    };

    return (
      <div
        className={`ufr-autosuggest-input-container ufr-autosuggest-input-container-${id} ${
          active ? 'ufr-autosuggest-active' : 'ufr-autosuggest-inactive'
        } ${noSuggestions ? 'no-suggestions' : ''}`}
      >
        <Autosuggest
          id={`${id}`}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          inputProps={inputProps}
        />
        {noSuggestions && (
          <div className="ufr-autosuggest-no-suggestions">
            <div className="no-suggestions-active">
              <p>No results. Please enter field name exactly how it appears in the integration.</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
TextAutosuggest.defaultProps = defaultProps;
TextAutosuggest.propTypes = propTypes;

export default TextAutosuggest;
