import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import StreamsListingArchiveButton from './StreamsListingArchiveButton';
import userHasPermissionTo from '@/utils/userHasPermissionTo';

const propTypes = {
  currentUserId: PropTypes.number.isRequired,
  decrementStreamsCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  ownerId: PropTypes.number.isRequired,
  streamId: PropTypes.number.isRequired,
  streamName: PropTypes.string,
  streamPermissions: PropTypes.object.isRequired,
  streamType: PropTypes.string.isRequired,
  streamUrl: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const StreamsListingButtons = ({
  hubId,
  streamType,
  streamName,
  streamId,
  streamUrl,
  triggerRefresh,
  streamPermissions,
  ownerId,
  currentUserId,
  isPrimary,
  decrementStreamsCount,
  dispatchToasterAction,
}) => (
  <>
    {userHasPermissionTo(
      'view',
      streamType,
      ownerId,
      currentUserId,
      streamPermissions,
      isPrimary,
    ) ? (
      <Button
        href={`/hubs/manage/${hubId}/${streamType}/${streamId}`}
        icon="wrench"
        id={`edit-stream-${streamId}`}
        label="manage stream"
        title="Manage Stream"
      />
    ) : (
      ''
    )}
    <Button
      href={streamUrl}
      target="_blank"
      icon="share"
      id={`open-stream-${streamId}`}
      label="open stream"
      title="Open Stream"
    />
    {userHasPermissionTo(
      'delete',
      streamType,
      ownerId,
      currentUserId,
      streamPermissions,
      isPrimary,
    ) ? (
      <StreamsListingArchiveButton
        hubId={hubId}
        streamId={streamId}
        streamType={streamType}
        streamName={streamName}
        triggerRefresh={triggerRefresh}
        decrementStreamsCount={decrementStreamsCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ) : (
      ''
    )}
  </>
);

StreamsListingButtons.propTypes = propTypes;

export default StreamsListingButtons;
