import EmbedParameterDeleteButton from './EmbedParameterDeleteButton';
import EmbedParameterEditButton from './EmbedParameterEditButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  delEndpoint: PropTypes.func.isRequired,
  editEndpoint: PropTypes.func.isRequired,
  paramId: PropTypes.number.isRequired,
  paramName: PropTypes.string,
  paramValue: PropTypes.string,
  permissions: PropTypes.shape({
    delete: PropTypes.bool,
    update: PropTypes.bool,
  }).isRequired,
  showMessage: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const defaultProps = {
  paramName: '',
  paramValue: '',
};

const EmbedParameterButtons = ({
  paramId,
  paramName,
  paramValue,
  editEndpoint,
  delEndpoint,
  showMessage,
  triggerRefresh,
  permissions,
}) => (
  <>
    <EmbedParameterEditButton
      entityId={paramId}
      entityName={paramName}
      entityValue={paramValue}
      header="Edit Embed Parameter"
      endpoint={editEndpoint}
      showMessage={showMessage}
      triggerRefresh={triggerRefresh}
      disabled={!permissions.update}
    />
    <EmbedParameterDeleteButton
      entityId={paramId}
      header="Delete Embed Parameter?"
      message="Are you sure you want to delete this embed parameter?"
      endpoint={delEndpoint}
      showMessage={showMessage}
      triggerRefresh={triggerRefresh}
      disabled={!permissions.delete}
    />
  </>
);

EmbedParameterButtons.propTypes = propTypes;
EmbedParameterButtons.defaultProps = defaultProps;

export default EmbedParameterButtons;
