import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  ctaStatus: PropTypes.number.isRequired,
  handleToasterLifecycle: PropTypes.func.isRequired,
  isCtaEditPage: PropTypes.bool,
  triggerRefresh: PropTypes.func.isRequired,
};

const Cta15ListingStatusButton = ({
  ctaId,
  ctaName,
  ctaStatus,
  handleToasterLifecycle,
  isCtaEditPage = false,
  triggerRefresh,
}) => {
  let addtnlProps;

  if (isCtaEditPage) {
    addtnlProps = {
      hide: {
        text: 'Hide',
        ufrStyle: 'secondary',
      },
      show: {
        text: 'Show',
        ufrStyle: 'secondary',
      },
    };
  } else {
    addtnlProps = {
      hide: {
        ufrStyle: 'primary',
      },
      show: {
        ufrStyle: 'primary',
      },
    };
  }

  const toggleCtaStatus = async (csrfToken) => {
    let toaster = {
      id: ctaId,
    };

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: `/api/v2/cta/${ctaId}`,
        data: { active: ctaStatus === 1 ? 0 : 1 },
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */

      toaster = {
        ...toaster,
        text: `"${ctaName}" is now ${ctaStatus === 1 ? 'hidden' : 'shown'}.`,
        type: 'success',
      };

      triggerRefresh();
    } catch {
      toaster = {
        ...toaster,
        text: 'Something went wrong. Please try again.',
        type: 'error',
      };
    }

    handleToasterLifecycle(toaster, ctaId);
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          {ctaStatus === 1 ? (
            <Button
              icon="eye-close"
              id={`hide-call-to-action-${ctaId}`}
              isIconText={isCtaEditPage}
              label="make call-to-action hidden"
              onClick={() => toggleCtaStatus(csrfToken)}
              title="Hide CTA"
              {...addtnlProps.hide}
            />
          ) : (
            <Button
              icon="eye-open"
              id={`show-call-to-action-${ctaId}`}
              isIconText={isCtaEditPage}
              label="make call-to-action shown"
              onClick={() => toggleCtaStatus(csrfToken)}
              title="Show CTA"
              disabled={ctaStatus === 2}
              {...addtnlProps.show}
            />
          )}
        </>
      )}
    </CsrfContextConsumer>
  );
};

Cta15ListingStatusButton.propTypes = propTypes;

export default Cta15ListingStatusButton;
