import './streamsListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { filterStreamDropdowns, getColumns, searchPlaceholder } from './helpers';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  collectionsCountByType: PropTypes.objectOf(PropTypes.number).isRequired,
  currentUserId: PropTypes.number.isRequired,
  hubId: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  streamPermissions: PropTypes.objectOf({
    marketing: PropTypes.bool.isRequired,
    native: PropTypes.bool.isRequired,
    sales: PropTypes.bool.isRequired,
  }).isRequired,
  streamTypes: PropTypes.objectOf(
    PropTypes.exact({
      getItems: PropTypes.bool.isRequired,
      getTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const entityName = {
  plural: 'Archived Streams',
  singular: 'Archived Stream',
};

const columns = (hubId, isSearchDisabled, isPrimary, streamPermissions, currentUserId) => {
  const allColumns = getColumns(
    hubId,
    isSearchDisabled,
    isPrimary,
    streamPermissions,
    currentUserId,
    null,
    true,
  );
  return [
    allColumns.NAME,
    allColumns.TITLE,
    allColumns.OWNER,
    allColumns.ITEM_COUNT,
    allColumns.CREATED_AT,
    {
      ...allColumns.MODIFIED_AT,
      Header: 'Archived',
    },
    allColumns.CONTROLS,
  ];
};

const getEndpointBuilder = (hubId) => ({
  getArchivedStreams: () => `/api/v2/hubs/${hubId}/streams/archived`,
});

const ArchivedStreamsDatatable = ({
  collectionsCountByType,
  currentUserId,
  hubId,
  isPrimary,
  streamPermissions,
  streamTypes,
}) => {
  const [archivedStreamCount, setArchivedStreamCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const { getArchivedStreams } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getArchivedStreams(), {
      params: queries,
    });
    setArchivedStreamCount(meta.count);
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setIsSearchDisabled(meta.count === 0);
    }
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="archived-streams"
        useStateHandling
        entityName={entityName}
        filterDropdowns={filterStreamDropdowns(streamTypes, collectionsCountByType)}
        columns={columns(hubId, isSearchDisabled, isPrimary, streamPermissions, currentUserId)}
        getData={getData}
        searchPlaceholder={searchPlaceholder(archivedStreamCount, entityName)}
        noSearchResultsMessage={
          <p>
            {isSearchDisabled
              ? 'There are no Archive Streams in this Hub yet.'
              : 'No results to display. Please try adjusting your search terms or filters.'}
          </p>
        }
        initialSort={{ desc: false, id: 'name' }}
        showLoadingState={isInitialLoad}
        hidePagination={isSearchDisabled}
        disableSearch={isSearchDisabled}
      />
    </CsrfProvider>
  );
};

ArchivedStreamsDatatable.propTypes = propTypes;

export default ArchivedStreamsDatatable;
