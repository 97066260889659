import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddWebsiteWidgetRulesModal from './AddWebsiteWidgetRulesModal';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';
import WebsiteWidgetRulesButtons from '@/components/datatables/websiteWidgetRules/WebsiteWidgetRulesButtons';
import WebsiteWidgetRulesEnabled from '@/components/datatables/websiteWidgetRules/WebsiteWidgetRulesEnabled';
import WebsiteWidgetRulesPriority from '@/components/datatables/websiteWidgetRules/WebsiteWidgetRulesPriority';

const propTypes = {
  hasAddPermission: PropTypes.bool.isRequired,
  hasDeletePermission: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
};

const cellClass = (key) => `ufr-dt-website-widget-rules-${key}-cell`;
const headerClass = (key) => `ufr-dt-website-widget-rules-${key}-header`;

const entityName = {
  plural: 'Rules',
  singular: 'Rule',
};

const createWebsiteWidgetRuleActionButton = (setIsAddRuleModalOpen) => ({
  className: 'ufr-btn ufr-btn-primary',
  id: 'create-website-widget-rule',
  onClick: () => setIsAddRuleModalOpen(true),
  text: 'Add Rule',
});

const searchPlaceholder = (websiteWidgetRuleCount) =>
  `Search ${websiteWidgetRuleCount} ${
    entityName[websiteWidgetRuleCount === 1 ? 'singular' : 'plural']
  }`;

const formatPlacement = (rule) => {
  switch (rule.show_on) {
    case 'page_url_includes':
      return `URL Contains "${rule.url}"`;
    case 'page_url_is_exactly':
      return `Exact URL "${rule.url}"`;
    default:
      return 'Any Page';
  }
};

const formatContent = (rule) => {
  switch (rule.recommendation_engine) {
    case 'ai':
      switch (rule.recommendation_engine_ai_content) {
        case 'all_streams':
          return 'A.I. (All Streams)';
        case 'select_streams':
          return 'A.I. (Select Streams)';
        case 'select_tags':
          return 'A.I. (Select Tags)';
        default:
          return '';
      }
    case 'single_stream':
      return rule.recommendation_engine_stream?.title || 'No Stream Selected';
    default:
      return '';
  }
};

/* eslint-disable sort-keys */
const columns = ({ dispatchToasterAction, hasDeletePermission, hasEditPermission }) => [
  {
    Header: 'Order',
    accessor: 'priority',
    id: 'priority',
    className: cellClass('priority'),
    headerClassName: headerClass('priority'),
    width: 80,
    Cell: (cell) =>
      hasEditPermission ? (
        <WebsiteWidgetRulesPriority
          key={`website-widget-rule-${cell.original.id}-priority`}
          priority={cell.original.priority}
          websiteWidgetRuleId={cell.original.id}
          triggerRefresh={cell.refresh}
        />
      ) : (
        cell.original.priority
      ),
  },
  {
    Header: 'Rule Name',
    accessor: 'name',
    id: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    Cell: (cell) =>
      cell.original.name ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-name-tooltip" placement="top">
              {cell.original.name}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.name}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    sortable: false,
    Cell: (cell) =>
      cell.original.description ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-description-tooltip" placement="top">
              {cell.original.description}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.description}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
  },
  {
    Header: 'Placement',
    accessor: 'placement',
    id: 'placement',
    className: cellClass('placement'),
    headerClassName: headerClass('placement'),
    sortable: false,
    Cell: (cell) => {
      const formattedPlacement = formatPlacement(cell.original);
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-placement-tooltip" placement="top">
              {formattedPlacement}
            </Tooltip>
          }
        >
          <div className="truncate">{formattedPlacement}</div>
        </OverlayTrigger>
      );
    },
  },
  {
    Header: 'Content',
    accessor: 'content',
    id: 'content',
    className: cellClass('content'),
    headerClassName: headerClass('content'),
    sortable: false,
    Cell: (cell) => {
      const formattedContent = formatContent(cell.original);
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-content-tooltip" placement="top">
              {formattedContent}
            </Tooltip>
          }
        >
          <div className="truncate">{formattedContent}</div>
        </OverlayTrigger>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    id: 'enabled',
    className: cellClass('enabled'),
    headerClassName: headerClass('enabled'),
    width: 80,
    Cell: (cell) => (
      <WebsiteWidgetRulesEnabled
        disabled={!hasEditPermission}
        enabled={!!cell.original.enabled}
        websiteWidgetRuleId={cell.original.id}
      />
    ),
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    width: 100,
    Cell: (cell) => (
      <WebsiteWidgetRulesButtons
        hasDeletePermission={hasDeletePermission}
        hasEditPermission={hasEditPermission}
        hubId={cell.original.hub_id}
        websiteWidgetRuleId={cell.original.id}
        websiteWidgetRuleName={cell.original.name}
        triggerRefresh={cell.refresh}
        decrementWebsiteWidgetRuleCount={cell.decrementWebsiteWidgetRuleCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ),
  },
];
/* eslint-enable sort-keys */

const getEndpointBuilder = (hubId) => ({
  getWebsiteWidgetRules: () => `/api/v2/hubs/${hubId}/website-widget-rules`,
});

const WebsiteWidgetRulesDatatable = ({
  hasAddPermission,
  hasDeletePermission,
  hasEditPermission,
  hubId,
}) => {
  const [websiteWidgetRuleCount, setWebsiteWidgetRuleCount] = useState(0);
  const [isAddRuleModalOpen, setIsAddRuleModalOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const { getWebsiteWidgetRules } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getWebsiteWidgetRules(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setWebsiteWidgetRuleCount(meta.count);
    }
    return { data, meta };
  };

  const modals = () => [
    <AddWebsiteWidgetRulesModal
      handleClose={() => setIsAddRuleModalOpen(false)}
      hubId={hubId}
      isAddRuleModalOpen={isAddRuleModalOpen}
    />,
  ];

  return (
    <CsrfProvider>
      <DataTable
        id="website-widget-rules"
        useStateHandling
        entityName={entityName}
        columns={columns({ dispatchToasterAction, hasDeletePermission, hasEditPermission })}
        getData={getData}
        actionButtons={
          hasAddPermission ? [createWebsiteWidgetRuleActionButton(setIsAddRuleModalOpen)] : []
        }
        searchPlaceholder={searchPlaceholder(websiteWidgetRuleCount)}
        noSearchResultsMessage={<p>{isInitialLoad ? ' ' : 'No rules match your search.'}</p>}
        initialSort={{ desc: false, id: 'priority' }}
        showLoadingState={isInitialLoad}
        getCellProps={() => ({
          decrementWebsiteWidgetRuleCount: () => {
            setWebsiteWidgetRuleCount(websiteWidgetRuleCount - 1);
          },
        })}
        Modals={modals}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

WebsiteWidgetRulesDatatable.propTypes = propTypes;

export default WebsiteWidgetRulesDatatable;
