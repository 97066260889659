import './modalSideNav.less';
import PropTypes from 'prop-types';
import React from 'react';

const ModalSideNav = ({ handleClose, handleSelect, data, description, selected }) => (
  <div className={'ufr-modal-navbar-container'}>
    <div className={'ufr-modal-close-container'}>
      <button className={'ufr-modal-close-button'} onClick={handleClose}>
        <svg
          width="20"
          height="20"
          stroke="#030D36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="close-icon"
        >
          <path
            d="M15.6 4.4 4.4 15.6m0-11.2 11.2 11.2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <p className={'ufr-modal-close-button-text'}>Close</p>
      </button>
    </div>
    <div className={'ufr-modal-sections-container'}>
      {description && (
        <div className={'ufr-modal-sections-text-container'}>
          <p className={'ufr-modal-sections-text'}>{description}</p>
        </div>
      )}
      <div className={'ufr-modal-sections-pages-container'}>
        {data.map((d, i) => {
          let active = selected === i;

          return (
            <div
              key={`side-nav-list-${d.internalName}`}
              className={'ufr-modal-sections-page-outer-container'}
            >
              <div
                className={`${'ufr-modal-sections-page-middle-container'} ${
                  active ? 'ufr-modal-container-active' : ''
                }`}
              >
                <button
                  className={'ufr-modal-sections-page-inner-container'}
                  onClick={() => handleSelect(i)}
                >
                  <p
                    className={`${'ufr-modal-sections-page-text'} ${
                      active ? 'ufr-modal-text-active' : ''
                    }`}
                  >
                    {d.displayName}
                  </p>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

ModalSideNav.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      internalName: PropTypes.string.isRequired,
    }),
  ),
  description: PropTypes.string,
  handleClose: PropTypes.func,
  handleSelect: PropTypes.func,
  selected: PropTypes.number,
};

export default ModalSideNav;
