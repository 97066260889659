import PropTypes from 'prop-types';
import React from 'react';

import StackedTile from './StackedTile';

const { string, bool, func } = PropTypes;

const propTypes = {
  bottomText: string,
  disabled: bool,
  onClick: func,
  selected: bool,
  thumbnailUrl: string,
  title: string,
  tooltip: string,
};

const defaultProps = {
  bottomText: null,
  disabled: false,
  onClick: () => {},
  selected: false,
  thumbnailUrl: null,
  title: undefined,
  tooltip: '',
};

const HubTile = (props) => <StackedTile {...props} type="hub" entity="hub" />;

HubTile.propTypes = propTypes;
HubTile.defaultProps = defaultProps;

export default HubTile;
