import './formField.less';

import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@/components/formInputs/checkbox/Checkbox';

const propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
};

const FormField = ({ active, disabled, fieldId, label, submitData }) => (
  <div className="ufr-form-fields-field">
    <Checkbox
      id={`ufr-form-field-${fieldId}`}
      name={fieldId}
      title={label}
      value={active}
      disabled={disabled}
      submitData={submitData}
    />
  </div>
);

FormField.propTypes = propTypes;

export default FormField;
