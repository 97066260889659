import './TagModal.less';
import Modal from '@/components/modal/Modal/Modal';
import ModalBody from '@/components/modal/ModalBody/ModalBody';
import ModalFooter from '@/components/modal/ModalFooter/ModalFooter';
import ModalHeader from '@/components/modal/ModalHeader/ModalHeader';
import PrimaryButton from '@/components/modal/PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TextArea from '@/components/formInputs/textArea/TextArea';
import TextInput from '@/components/formInputs/textInput/TextInput';

const propTypes = {
  initialData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const orderedFormFieldNames = ['name', 'description'];

/**
 * converts form into an ordered array of formFields to be used with FormModal
 * @param {*} form
 * @param {*} orderedFormFieldNames
 * @returns
 */
const transformFormToFormFields = (form, orderedKeys) =>
  orderedKeys.map((key) => {
    const formField = form[key];
    formField.reactProps.name = key;
    return formField;
  });

const formFields = transformFormToFormFields(
  {
    description: {
      reactComponent: TextArea,
      reactProps: {
        id: 'tag-modal-description',
        maxLength: 255,
        placeholder: '',
        title: 'Description (optional)',
      },
    },
    name: {
      reactComponent: TextInput,
      reactProps: {
        id: 'tag-modal-name',
        maxLength: 75,
        placeholder: '',
        title: 'Tag Name',
      },
    },
  },
  orderedFormFieldNames,
);

const TagModal = ({ initialData, isOpen, onClose, onSubmit, title }) => {
  const [data, setData] = useState(initialData ? initialData : {});

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
    if (isOpen) {
      setData(initialData);
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    data.name = data.name.trim();
    if (data.description) {
      data.description = data.description.trim();
    }

    onSubmit(data);
  };

  return (
    <Modal id="tag-modal" isOpen={isOpen} handleClose={onClose}>
      <ModalHeader title={title} />
      <ModalBody>
        {formFields &&
          Array.isArray(formFields) &&
          formFields.map(({ reactComponent, reactProps }, i) => {
            const { name } = reactProps;

            return React.createElement(reactComponent, {
              ...reactProps,
              handleChange,
              key: `tag-modal-field-${i}`,
              value: data[name] ? data[name] : '',
            });
          })}
      </ModalBody>
      <ModalFooter>
        <PrimaryButton label="Save" onClick={() => handleSubmit()}>
          Save
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

TagModal.formFieldMapping = {
  tagDescription: 'description',
  tagName: 'name',
};

TagModal.propTypes = propTypes;

export default TagModal;
