import '../carousel/Carousel.less';
import CarouselItem from './CarouselItem';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const propTypes = {
  activeIdx: PropTypes.number.isRequired,
  itemHeight: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
  itemShift: PropTypes.number,
  itemWidth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  ratio: PropTypes.number,
};

const Carousel = forwardRef(
  (
    { activeIdx, itemHeight = 330, items, itemShift = 0, itemWidth = 250, onChange, ratio = 0.75 },
    ref,
  ) => {
    const listWidth = (items.length - 1) * itemWidth * 2;

    const calcItemTranslateX = (idx) => itemWidth + (idx - activeIdx) * itemWidth - itemShift;

    return (
      <div
        ref={ref}
        className="carousel-container"
        style={{ height: `${itemHeight}px`, width: `${itemWidth * 3}px` }}
      >
        <ul className="carousel-list" style={{ width: listWidth }}>
          {items.map((item, idx) => (
            <CarouselItem
              key={idx}
              className={`${itemShift !== 0 ? 'override-animation' : ''}`}
              style={{
                transform: `translateX(${calcItemTranslateX(idx)}px)`,
              }}
              {...{ activeIdx, idx, item, itemHeight, itemWidth, onChange, ratio }}
              activeIdx={activeIdx}
            />
          ))}
        </ul>
      </div>
    );
  },
);

Carousel.propTypes = propTypes;

export default Carousel;
