import PropTypes from 'prop-types';
import React from 'react';

import './serviceIcon.less';
import serviceTypes from '../../../constants/hubServices';

import ActOn from './icons/ActOn.svg';
import Blog from './icons/Blog.svg';
import Bombora from './icons/Bombora.svg';
import Brightcove from './icons/Brightcove.svg';
import BrightInfo from './icons/BrightInfo.svg';
import CTA from './icons/CTA.svg';
import Custom from './icons/Custom.svg';
import Disqus from './icons/Disqus.svg';
import Ebook from './icons/Ebook.svg';
import Eloqua from './icons/Eloqua.svg';
import Facebook from './icons/Facebook.svg';
import GoogleAnalytics from './icons/GoogleAnalytics.svg';
import GoogleTagManager from './icons/GoogleTagManager.svg';
import Hub from './icons/Hub.svg';
import Hubspot from './icons/Hubspot.svg';
import Instagram from './icons/Instagram.svg';
import MailChimp from './icons/MailChimp.svg';
import Marketo from './icons/Marketo.svg';
import Optimizely from './icons/Optimizely.svg';
import Pardot from './icons/Pardot.svg';
import Pinterest from './icons/Pinterest.svg';
import Sales from './icons/Sales.svg';
import Slideshare from './icons/Slideshare.svg';
import Twitter from './icons/Twitter.svg';
import Uberflip from './icons/Uberflip.svg';
import VerticalDots from './icons/VerticalDots.svg';
import Vidyard from './icons/Vidyard.svg';
import Vimeo from './icons/Vimeo.svg';
import Wistia from './icons/Wistia.svg';
import Youtube from './icons/Youtube.svg';

const components = {
  // not sure if these are the 'constant' names...
  // these need to be added to hubServices.js before we can use them.
  acton: ActOn,
  bombora: Bombora,
  brightinfo: BrightInfo,
  cta: CTA,
  disqus: Disqus,
  ebook: Ebook,
  eloqua: Eloqua,
  googleanalytics: GoogleAnalytics,
  googletagmanager: GoogleTagManager,
  hub: Hub,
  hubspot: Hubspot,
  mailchimp: MailChimp,
  marketo: Marketo,
  optimizely: Optimizely,
  pardot: Pardot,
  pinterest: Pinterest,

  // these are the 'constant' names.
  // eslint-disable-next-line sort-keys
  blogpost: Blog,
  brightcove: Brightcove,
  custom: Custom,
  facebook: Facebook,
  instagram: Instagram,
  slideshare: Slideshare,
  targeted: Sales,
  twitter: Twitter,
  uberflip: Uberflip,
  verticaldots: VerticalDots,
  vidyard: Vidyard,
  vimeo: Vimeo,
  wistiar: Wistia,
  youtube: Youtube,
};

const formats = {
  large: {
    height: 120,
    iconWidth: 50,
    width: 120,
  },
  narrow: {
    height: 40,
    iconWidth: 12,
    width: 16,
  },
  small: {
    height: 35,
    iconWidth: 30,
    width: 35,
  },
};

const iconWidthExceptions = {
  facebook: {
    large: 27,
    narrow: 7,
    small: 9,
  },
  youtube: {
    narrow: 0,
  },
};

const backgrounColorExceptions = {
  youtube: {
    narrow: '#ff0000',
  },
};

const ServiceIcon = ({ service, format }) => {
  if (!components[service] || !serviceTypes[service]) return null;

  const dimensions = formats[format];
  const { width, height } = dimensions;

  const exceptionBackground =
    backgrounColorExceptions[service] && backgrounColorExceptions[service][format];
  const backgroundColor =
    exceptionBackground !== undefined ? exceptionBackground : serviceTypes[service].color;

  const exceptionDimensions = iconWidthExceptions[service] && iconWidthExceptions[service][format];
  const iconWidth = exceptionDimensions !== undefined ? exceptionDimensions : dimensions.iconWidth;

  const Icon = components[service];

  return (
    <div
      className={`ufr-service-icon ufr-service-${service}`}
      style={{ backgroundColor, height, width }}
    >
      <Icon height={dimensions.height} width={iconWidth} />
    </div>
  );
};

ServiceIcon.defaultProps = {
  format: 'small',
};

ServiceIcon.propTypes = {
  format: PropTypes.oneOf(['small', 'large', 'narrow']),
  /** the constant name of the service */
  service: PropTypes.oneOf(Object.keys(components)).isRequired,
};

export default ServiceIcon;
