import React from 'react';

import './page.less';
import PropTypes from 'prop-types';

/**
 * This component manages the container and padding for a normal uberflip backend page
 */
const Page = (props) => <div className="ufr-page">{props.children}</div>;

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool])),
  ]).isRequired,
};

export default Page;
