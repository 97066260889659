import './recommendationRulesDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddRecommendationRuleModal from './AddRecommendationRuleModal';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RecommendationRulesButtons from '@/components/datatables/recommendationRules/RecommendationRulesButtons';
import RecommendationRulesEnabled from '@/components/datatables/recommendationRules/RecommendationRulesEnabled';
import RecommendationRulesPriority from '@/components/datatables/recommendationRules/RecommendationRulesPriority';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hubId: PropTypes.number.isRequired,
};

const cellClass = (key) => `ufr-dt-recommendation-rules-${key}-cell`;
const headerClass = (key) => `ufr-dt-recommendation-rules-${key}-header`;

const createRecommendationRuleActionButton = (setIsAddRuleModalOpen) => ({
  className: 'ufr-btn ufr-btn-primary',
  id: 'create-recommendation-rule',
  onClick: () => setIsAddRuleModalOpen(true),
  text: 'Add Rule',
});

const entityName = {
  plural: 'Rules',
  singular: 'Rule',
};

const searchPlaceholder = (recommendationRuleCount) =>
  `Search ${recommendationRuleCount} ${
    entityName[recommendationRuleCount === 1 ? 'singular' : 'plural']
  }`;

const aiContentLabels = {
  all_streams: 'All Streams',
  select_streams: 'Select Streams',
  select_tags: 'Select Tags',
};

const renderContent = (rule) => {
  let contentStr = '';
  if (rule.recommendation_engine === 'ai') {
    contentStr = `A.I. (${aiContentLabels[rule.recommendation_engine_ai_content] || ''})`;
  } else if (rule.recommendation_engine === 'single_stream') {
    if (rule.recommendation_engine_stream?.title) {
      contentStr = rule.recommendation_engine_stream.title;
    }
  }

  return contentStr;
};

/* eslint-disable sort-keys */
const columns = (dispatchToasterAction) => [
  {
    Header: 'Order',
    accessor: 'priority',
    id: 'priority',
    className: cellClass('priority'),
    headerClassName: headerClass('priority'),
    width: 80,
    Cell: (cell) => (
      <RecommendationRulesPriority
        key={`recommendation-rule-${cell.original.id}-priority`}
        priority={cell.original.priority}
        recommendationRuleId={cell.original.id}
        triggerRefresh={cell.refresh}
      />
    ),
  },
  {
    Header: 'Rule Name',
    accessor: 'name',
    id: 'name',
    className: cellClass('name'),
    headerClassName: headerClass('name'),
    minWidth: 150,
    Cell: (cell) =>
      cell.original.name ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-name-tooltip" placement="top">
              {cell.original.name}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.name}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    minWidth: 300,
    Cell: (cell) =>
      cell.original.description ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-description-tooltip" placement="top">
              {cell.original.description}
            </Tooltip>
          }
        >
          <div className="truncate">{cell.original.description}</div>
        </OverlayTrigger>
      ) : (
        ''
      ),
    sortable: false,
  },
  {
    Header: 'Placement',
    accessor: 'placement',
    className: cellClass('placement'),
    headerClassName: headerClass('placement'),
    width: 120,
    Cell: (cell) => (
      <>{cell.original.show_on === 'all_streams' ? 'All Streams' : 'Select Streams'}</>
    ),
    sortable: false,
  },
  {
    Header: 'Content',
    accessor: 'content',
    className: cellClass('content'),
    headerClassName: headerClass('content'),
    minWidth: 150,
    Cell: (cell) => {
      const content = renderContent(cell.original);
      return content ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-rule-content-tooltip" placement="top">
              {content}
            </Tooltip>
          }
        >
          <div className="truncate">{content}</div>
        </OverlayTrigger>
      ) : (
        ''
      );
    },
    sortable: false,
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    className: cellClass('enabled'),
    headerClassName: headerClass('enabled'),
    width: 80,
    Cell: (cell) => (
      <RecommendationRulesEnabled
        enabled={!!cell.original.enabled}
        recommendationRuleId={cell.original.id}
      />
    ),
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    width: 100,
    Cell: (cell) => (
      <RecommendationRulesButtons
        hubId={cell.original.hub_id}
        recommendationRuleId={cell.original.id}
        recommendationRuleName={cell.original.name}
        triggerRefresh={cell.refresh}
        decrementRecommendationRuleCount={cell.decrementRecommendationRuleCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ),
  },
];
/* eslint-disable */

const getEndpointBuilder = (hubId) => ({
  getRecommendationRules: () => `/api/v2/hubs/${hubId}/recommendation-rules`,
});

const RecommendationRulesDatatable = ({ hubId }) => {
  const [recommendationRuleCount, setRecommendationRuleCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [isAddRuleModalOpen, setIsAddRuleModalOpen] = useState(false);

  const { getRecommendationRules } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getRecommendationRules(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setRecommendationRuleCount(meta.count);
    }
    return { data, meta };
  };

  const modals = () => [
    <AddRecommendationRuleModal
      hubId={hubId}
      isAddRuleModalOpen={isAddRuleModalOpen}
      handleClose={() => setIsAddRuleModalOpen(false)}
    />
  ];

  return (
    <CsrfProvider>
      <DataTable
        id="recommendation-rules"
        useStateHandling
        entityName={entityName}
        columns={columns(dispatchToasterAction)}
        getData={getData}
        actionButtons={[createRecommendationRuleActionButton(setIsAddRuleModalOpen)]}
        searchPlaceholder={searchPlaceholder(recommendationRuleCount)}
        noSearchResultsMessage={<p>{isInitialLoad ? ' ' : 'No rules match your search.'}</p>}
        initialSort={{ id: 'priority', desc: false }}
        showLoadingState={isInitialLoad}
        getCellProps={() => ({
          decrementRecommendationRuleCount: () => {
            setRecommendationRuleCount(recommendationRuleCount - 1);
          },
        })}
        Modals={modals}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

RecommendationRulesDatatable.propTypes = propTypes;

export default RecommendationRulesDatatable;
