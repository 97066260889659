import { CsrfProvider } from '../../../DataProviders/CsrfProvider';
import { useModal } from '../../modal/Modal/Modal';
import axios from 'axios';
import DataTable from '../../datatable/DataTable';
import DeleteFromGroupModal from './DeleteFromGroupModal';
import LinkableItemsModal from './LinkablesModal/LinkableItemsModal';
import LinkableStreamsModal from './LinkablesModal/LinkableStreamsModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useToaster from '../../toaster/useToaster';

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // see docs for react-table column props
    }),
  ).isRequired,
  endpointBuilder: PropTypes.shape({
    createLink: PropTypes.func,
    deleteLink: PropTypes.func,
    deleteSelf: PropTypes.func,
    getLinkables: PropTypes.func,
    getLinks: PropTypes.func,
    getLocales: PropTypes.func,
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['item', 'stream']).isRequired,
};

const defaultProps = {};

// set the flow rate of streams in the modal's inifite scroll
export const linkablesRequestLimit = 30;

const limitParams = (limit = 100) => ({ params: { limit } });

const modalFor = {
  item: LinkableItemsModal,
  stream: LinkableStreamsModal,
};

const LangLinkDatatable = ({ endpointBuilder, type, columns, readonly }) => {
  const { createLink, deleteLink, deleteSelf, getLinks, getLocales, getLinkables } =
    endpointBuilder;

  const [linkables, setLinkables] = useState({ data: [], meta: {} });
  const [linkableLocales, setLinkableLocales] = useState([]);
  const [allLinks, setAllLinks] = useState([]);

  const getModalData = async () => {
    const allLinksPromise = axios.get(getLinks(), limitParams());
    const linkablesPromise = axios.get(getLinkables(), limitParams(linkablesRequestLimit));
    const linkableLocalesPromise = axios.get(getLocales());

    const [
      {
        data: { data: linksData },
      },
      {
        data: { data: linkablesData, meta },
      },
      { data: localesData },
    ] = await Promise.all([allLinksPromise, linkablesPromise, linkableLocalesPromise]);

    setAllLinks(linksData);
    setLinkables({ data: linkablesData, meta });
    setLinkableLocales(localesData);
  };

  const getTableData = async (params) => {
    const {
      data: { data, meta },
    } = await axios.get(getLinks(), { params });
    return { data, meta };
  };

  const [LinkablesModal, openLinkablesModal] = useModal(modalFor[type]);
  const [HandledDeleteFromGroupModal, openDeleteFromGroupModal] = useModal(DeleteFromGroupModal);

  const entityName = type.charAt(0).toUpperCase() + type.slice(1);

  const entityLabels = {
    plural: `${entityName} Language Links`,
    singular: `${entityName} Language Link`,
  };

  const newLinkButton = {
    icon: 'plus',
    id: `add-${type}-link`,
    onClick: openLinkablesModal,
    text: `Add ${entityName} Link`,
  };

  const dropdownMenu =
    allLinks.length > 0
      ? [
          {
            id: 'remove',
            onClick: openDeleteFromGroupModal,
            text: `Remove this ${entityName} from the Group`,
          },
        ]
      : undefined;

  const [popToast, Toaster] = useToaster();

  const modals = ({ tableProps: { refresh } }) => {
    const shared = {
      refreshTable: refresh,
      showMessage: popToast,
    };
    return [
      <LinkablesModal
        {...shared}
        key="linkables-modal"
        linkedLocaleIds={allLinks.map((link) => link[type].locale.id)}
        linkables={linkables}
        linkableLocales={linkableLocales}
        getCreateLinkUrl={createLink}
        getLinkablesUrl={getLinkables()}
      />,
      <HandledDeleteFromGroupModal {...shared} key="delete-modal" url={deleteSelf()} />,
    ];
  };

  return (
    <CsrfProvider>
      <DataTable
        id="lang-links"
        useStateHandling
        entityName={entityLabels}
        actionButtons={[newLinkButton]}
        readonly={readonly}
        dropdownMenuItems={dropdownMenu}
        showLoadingState
        columns={columns}
        getData={getTableData}
        refreshModalData={getModalData}
        handleError={({ message }) => {
          if (message) popToast('error', message, 'table-error');
        }}
        getCellProps={() => ({
          deleteLink,
          popToast,
        })}
        Modals={modals}
      />

      {Toaster}
    </CsrfProvider>
  );
};

LangLinkDatatable.propTypes = propTypes;
LangLinkDatatable.defaultProps = defaultProps;

export default LangLinkDatatable;
