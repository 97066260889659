import './placeCtaModal.less';
import Button from '@/components/shared/button/Button';
import Carousel from '@/components/carousel/Carousel';
import Cta from '@/components/cta/Cta';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import Modal from '../Modal/Modal';
import ModalBody from '../ModalBody/ModalBody';
import ModalFooter from '../ModalFooter/ModalFooter';
import ModalHeader from '../ModalHeader/ModalHeader';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import SelectDropdown from '@/components/formInputs/selectDropdown/SelectDropdown';
import Slider from '@/components/shared/slider/Slider';
import TextInput from '@/components/formInputs/textInput/TextInput';

const propTypes = {
  ctaHeight: PropTypes.number.isRequired,
  ctas: PropTypes.arrayOf(PropTypes.shape(Cta.propTypes.cta)).isRequired,
  ctaWidth: PropTypes.number.isRequired,
  handleAnimationEnd: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  handleCtaTypeChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const distanceToChangeSlide = 1 / 3;

const PlaceCtaModal = ({
  title,
  handleClose,
  handleAnimationEnd,
  handleSubmit,
  hubId,
  isOpen,
  ctas,
  ctaHeight,
  ctaWidth,
  handleSearchChange,
  handleCtaTypeChange,
}) => {
  const [selectedCta, setSelectedCta] = useState(ctas.length ? ctas[0] : undefined);
  const [activeIdx, setActiveIdx] = useState(0);
  const [carouselItemShift, setCarouselItemShift] = useState(0);
  const [sliderOuterPaddingX, setSliderOuterPaddingX] = useState(0);

  const carouselContainerRef = useRef();

  const carouselRef = useCallback(
    (node) => {
      if (node !== null) {
        setSliderOuterPaddingX(Math.floor(node.offsetWidth * 0.05));
      }
    },
    [setSliderOuterPaddingX],
  );

  const ctaTypes = [
    { text: `All`, value: 'all' },
    { text: `Form CTA`, value: 'form' },
    { text: `Link CTA`, value: 'url' },
  ];

  const items = useMemo(
    () => ctas.map((cta) => <Cta cta={cta} width={ctaWidth} height={ctaHeight} />),
    [ctas],
  );

  const handleOnButtonClick = () => {
    handleSubmit(selectedCta.id);
    handleClose();
  };

  const handleCarouselOnChange = (index) => {
    const cta = ctas[index];
    setActiveIdx(index);
    setSelectedCta(cta);
  };

  const handleCarouselWheel = useCallback(
    (e) => {
      e.preventDefault();

      if (e.deltaY > 0) {
        if (activeIdx < items.length - 1) {
          handleCarouselOnChange(activeIdx + 1);
        }
      } else {
        if (activeIdx > 0) {
          handleCarouselOnChange(activeIdx - 1);
        }
      }
    },
    [activeIdx, items, handleCarouselOnChange],
  );

  const handleSliderMove = (displacement) => {
    setCarouselItemShift(ctaWidth * displacement);
  };

  useEffect(() => {
    setActiveIdx(0);
    setSelectedCta(ctas[0]);
  }, [ctas]);

  useEffect(() => {
    // allows to prevent mousewheel from propogating to any parent scroll elements
    if (carouselContainerRef && carouselContainerRef.current) {
      const carousel = carouselContainerRef.current;

      carousel.addEventListener('mousewheel', handleCarouselWheel, false);

      return () => carousel.removeEventListener('mousewheel', handleCarouselWheel, false);
    }
  }, [handleCarouselWheel]);

  return (
    <div id="place-cta-modal">
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        handleAnimationEnd={handleAnimationEnd}
        size="medium"
      >
        <ModalHeader title={title} />
        <ModalBody>
          <SelectDropdown
            id="place-cta-types"
            title="CTA Types"
            items={ctaTypes}
            submitData={handleCtaTypeChange}
            value="All"
          />

          <div className="search-input">
            <span className="glyphicons glyphicons-search" aria-hidden="true" />
            <TextInput
              placeholder="Search"
              id="place-cta-search"
              name="place-cta-search"
              handleChange={handleSearchChange}
            />
          </div>

          <div ref={carouselContainerRef}>
            {items.length ? (
              <Carousel
                ref={carouselRef}
                itemShift={carouselItemShift}
                itemHeight={ctaHeight}
                itemWidth={ctaWidth}
                items={items}
                onChange={handleCarouselOnChange}
                activeIdx={activeIdx}
              />
            ) : (
              <div className="no-ctas-found">No CTAs found</div>
            )}
            {selectedCta && (
              <div className="selected-cta-info">
                <p className="name">{selectedCta.name}</p>
                <p className="modified">
                  Last modified: {getHumanReadableDate(selectedCta.modified_at)}
                </p>
                <p className="id">ID: {selectedCta.id}</p>
              </div>
            )}
          </div>
          {items.length > 1 && (
            <div
              className="carousel-slider-container"
              style={{
                padding: `25px ${sliderOuterPaddingX}px 25px ${sliderOuterPaddingX}px`,
              }}
            >
              <Slider
                sliderData={items}
                slideIdx={activeIdx}
                distanceToChangeSlide={distanceToChangeSlide}
                onChange={handleCarouselOnChange}
                onMove={handleSliderMove}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            id={`edit-cta`}
            href={selectedCta ? `/hubs/editCta/${hubId}/${selectedCta.id}` : ''}
            ufrStyle="secondary"
            label="Edit"
            disabled={selectedCta === undefined}
          >
            <span className="glyphicons glyphicons-pencil" aria-hidden="true" />
            Edit
          </Button>

          <PrimaryButton
            label="Place"
            onClick={handleOnButtonClick}
            disabled={selectedCta === undefined}
          >
            Place
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

PlaceCtaModal.propTypes = propTypes;

export default PlaceCtaModal;
