import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
import RecommendationTemplatesDatatable from '../datatables/recommendationTemplates/RecommendationTemplatesDatatable';

const propTypes = {
  hubId: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const RecommendationTemplatesPage = ({ title, subtitle, hubId }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <RecommendationTemplatesDatatable hubId={hubId} />
    </Page>
  );
};

RecommendationTemplatesPage.propTypes = propTypes;

export default RecommendationTemplatesPage;
