import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  tag: PropTypes.object.isRequired,
  triggerDelete: PropTypes.func.isRequired,
  triggerEdit: PropTypes.func.isRequired,
};

const modalBody = () => (
  <>
    <p>
      <strong>Are you sure you want to permanently delete this Tag?</strong>
    </p>
    <p>
      This action will remove the tag from all Items in all Hubs that currently use it as well as
      from any filter or scheduled task that references it.
    </p>
  </>
);

const TagsListingButtons = ({ tag, triggerDelete, triggerEdit }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { id, name } = tag;

  return (
    <>
      <Button
        onClick={triggerEdit}
        icon="pencil"
        id={`edit-tag-${id}`}
        label="edit tag"
        title="Edit Tag"
      />
      <Button
        onClick={() => setIsDeleteModalOpen(true)}
        icon="bin"
        id={`delete-tag-${id}`}
        label="delete tag"
        title="Delete Tag"
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={triggerDelete}
        header={`Delete Tag "${name}"`}
        body={modalBody()}
        primaryButtonText="Delete Tag"
      />
    </>
  );
};

TagsListingButtons.propTypes = propTypes;

export default TagsListingButtons;
