import PropTypes from 'prop-types';
import React from 'react';

import './topnavButton.less';
import Avatar from '../shared/avatar/Avatar';
import Icon from '../shared/icon/Icon';
import shapes from './propShapes';

const TopnavButton = ({ id, text, icon, active, forDropdown, user }) => {
  const renderIcon = () => {
    if (icon === 'account-avatar') {
      return <Avatar {...user} />;
    }

    if (icon) {
      return <Icon className="topnav-item-icon" icon={icon} />;
    }
    return null;
  };

  const classes = ['topnav-item-button', active ? 'topnav-item-button-active' : ''];

  return (
    <div key={id} className={classes.join(' ')}>
      {renderIcon()}

      <div className="topnav-button-text">{text}</div>

      {forDropdown && <Icon className="topnav-item-chevron" icon="chevron-down" />}
    </div>
  );
};

TopnavButton.defaultProps = {
  active: false,
  forDropdown: false,
  icon: false,
};

TopnavButton.propTypes = {
  active: PropTypes.bool,
  forDropdown: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  user: PropTypes.shape(shapes.user).isRequired,
};

export default TopnavButton;
