import PropTypes from 'prop-types';
import React from 'react';

import CtaListingDatatable from '../datatables/ctaListing/CtaListingDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';

const propTypes = {
  canCreateCta: PropTypes.bool.isRequired,
  ctaType: PropTypes.string.isRequired,
  hubId: PropTypes.number.isRequired,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalCtaCount: PropTypes.number.isRequired,
};

const CtaListingPage = ({ title, subtitle, ...props }) => {
  const headerText = {
    subtitle: { text: subtitle },
    title: { text: title },
  };

  return (
    <Page>
      <PageHeader {...headerText} />
      <CtaListingDatatable {...props} />
    </Page>
  );
};

CtaListingPage.propTypes = propTypes;

export default CtaListingPage;
