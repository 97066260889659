import Container from './TopnavContainer';
import Dropdown from './TopnavDropdown';
import Link from './TopnavLink';
import Logo from './TopnavLogo';
import PropTypes from 'prop-types';
import React from 'react';
import shapes from './propShapes';

// global styles
import './topnav.less';

const Topnav = (props) => {
  const { whitelabelled, user, menuLeft, menuRight, logoUrl } = props;

  const renderMenuItems = (menu, rightMenu) =>
    menu.map((item) => {
      const Component = item.children && item.children.length ? Dropdown : Link;
      return <Component key={item.id} item={item} user={user} isRightMenu={rightMenu} />;
    });

  return (
    <Container {...props}>
      {!whitelabelled && <Logo url={logoUrl} />}
      <ul>
        {renderMenuItems(menuLeft)}
        {renderMenuItems(menuRight, true)}
      </ul>
    </Container>
  );
};

Topnav.defaultProps = {
  whitelabelled: false,
};

Topnav.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  menuLeft: PropTypes.arrayOf(PropTypes.shape(shapes.item)).isRequired,
  menuRight: PropTypes.arrayOf(PropTypes.shape(shapes.item)).isRequired,
  user: PropTypes.shape(shapes.user).isRequired,
  whitelabelled: PropTypes.bool,
};

export default Topnav;
