import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './previewButtons.less';
import handleClick from '../../utils/handleClick';
import Icon from '../shared/icon/Icon';

const PreviewButtons = ({ actions }) => {
  const classes = ['preview-buttons'];

  return (
    <div className={classNames(classes)}>
      <span className="preview-text">Preview</span>
      {actions.map((action) => {
        const { id, url, icon, target, onClick, onClickCallback } = action;

        const boundHandleClick = (e) =>
          handleClick({
            e,
            id,
            onClick,
            onClickCallback,
            target,
            url,
          });

        return (
          <button
            type="button"
            id={`${id}-action`}
            key={id}
            className="preview-action"
            onClick={boundHandleClick}
          >
            <Icon icon={icon} />
          </button>
        );
      })}
    </div>
  );
};

PreviewButtons.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.string,
      onClick: PropTypes.func,
      onClickCallback: PropTypes.shape({
        func: PropTypes.string,
        namespace: PropTypes.string,
      }),
      target: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default PreviewButtons;
