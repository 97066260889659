import { defaultTemplateId } from '../../constants/constants';
import Form from '../form/Form';
import handleInputReady from '../../utils/handleInputReady';
import PropTypes from 'prop-types';
import React from 'react';
import SelectDropdown from '../formInputs/selectDropdown/SelectDropdown';

const propTypes = {
  /**
   * dynamic list of components to render
   */
  components: PropTypes.arrayOf(
    PropTypes.shape({
      inputProps: PropTypes.shape({
        componentType: PropTypes.string,
      }),
    }),
  ).isRequired,

  disabled: PropTypes.bool,

  /**
   * select list of templates
   */
  select: PropTypes.shape({
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        text: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    submitData: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        func: PropTypes.string,
        namespace: PropTypes.string,
      }),
    ]),
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,

  /**
   * selected template stored for stream
   */
  selectedTemplate: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const defaultProps = {
  disabled: false,
  selectedTemplate: {},
};

class AppearanceOptions extends React.Component {
  state = {
    components: this.props.components,
    select: this.props.select,
    selectedTemplate: this.props.selectedTemplate,
  };

  handleSelect = (selectedItem) => {
    const { submitData } = this.state.select;

    handleInputReady(submitData, selectedItem, (apiResponse) => {
      if (apiResponse.meta.status === 'success') {
        this.setState({
          components: apiResponse.response.componentList,
          selectedTemplate: selectedItem,
        });
      }
    });
  };

  render() {
    const { disabled } = this.props;
    const { selectedTemplate, select, components } = this.state;
    const { title, description, items, value } = select;

    const isDefaultTemplate = selectedTemplate && selectedTemplate.value === defaultTemplateId;

    return [
      <SelectDropdown
        key="selectDropDownKey"
        id="template-list"
        title={title}
        description={description}
        items={items}
        submitData={disabled ? () => {} : this.handleSelect}
        value={value}
        disabled={disabled}
      />,

      !isDefaultTemplate && (
        <div className="ufr-appearance-options" key="appearanceOptionsKey">
          <Form headerText="Appearance Options" fields={components} disabled={disabled} />
        </div>
      ),
    ];
  }
}

AppearanceOptions.propTypes = propTypes;
AppearanceOptions.defaultProps = defaultProps;

export default AppearanceOptions;
