import PropTypes from 'prop-types';
import React from 'react';

import './controlBar.less';
import ActionButton from './ActionButton';
import ButtonDropDown from '@/components/shared/buttonDropDown/ButtonDropDown';
import ControlBarSearch from './ControlBarSearch';
import FilterDropdown from './FilterDropdown';
import MenuDropDown from '@/components/shared/dropdown/MenuDropDown';

const { func, shape, string, arrayOf, bool } = PropTypes;

const propTypes = {
  actionButtons: arrayOf(
    shape({
      id: string,
      onClick: func,
      showAtEnd: bool,
      text: string,
      tooltip: string,
    }),
  ),
  buttonDropDowns: arrayOf(shape(ButtonDropDown.propTypes)),
  disabled: bool,
  dropdownMenuItems: arrayOf(
    shape({
      icon: string,
      id: string.isRequired,
      onClick: func,
      text: string.isRequired,
    }),
  ),
  filterDropdowns: arrayOf(
    shape({
      items: arrayOf(
        shape({
          id: string,
          queryValue: string,
          text: string,
        }),
      ),
      queryKey: string.isRequired,
      toggleId: string.isRequired,
      toggleText: string.isRequired,
    }),
  ),
  initialValues: shape({}),
  onChange: func.isRequired,
  readonly: bool,
  refreshData: func.isRequired,
  searchConfig: shape({
    placeholder: string,
  }),
};

const defaultProps = {
  actionButtons: [],
  buttonDropDowns: [],
  disabled: false,
  dropdownMenuItems: [],
  filterDropdowns: [],
  readonly: false,
  searchConfig: {
    placeholder: 'Search',
  },
};

const renderActionButton = ({ button: { id, ...rest }, disabled, readonly, refreshData }) => (
  <ActionButton
    key={id}
    id={id}
    disabled={disabled || readonly}
    refreshData={refreshData}
    {...rest}
  />
);

const renderButtonDropDown = ({ button: { id, ...rest }, disabled, readonly }) => (
  <ButtonDropDown key={id} id={id} disabled={disabled || readonly} {...rest} />
);

const ControlBar = ({
  onChange,
  refreshData,
  actionButtons,
  buttonDropDowns,
  filterDropdowns,
  dropdownMenuItems,
  searchConfig,
  disabled,
  readonly,
  initialValues,
}) => (
  <ul className="ufr-dt-control-bar ufr">
    {actionButtons
      .filter(({ showAtEnd }) => !showAtEnd)
      .map((button) => renderActionButton({ button, disabled, readonly, refreshData }))}

    {buttonDropDowns
      .filter(({ showAtEnd }) => !showAtEnd)
      .map((button) => renderButtonDropDown({ button, disabled, readonly }))}

    {filterDropdowns.map(({ queryKey, items, toggleId, toggleText }) => (
      <FilterDropdown
        key={toggleId}
        onSelect={onChange}
        toggle={{
          id: toggleId,
          text: toggleText,
        }}
        items={items}
        queryKey={queryKey}
        disabled={disabled}
        initialFilterValue={initialValues?.[queryKey]}
      />
    ))}

    <ControlBarSearch
      onReady={onChange}
      placeholder={searchConfig.placeholder}
      disabled={disabled}
      initialSearchQueryValue={initialValues?.query}
    />

    {actionButtons
      .filter(({ showAtEnd }) => showAtEnd)
      .map((button) => renderActionButton({ button, disabled, readonly, refreshData }))}

    {buttonDropDowns
      .filter(({ showAtEnd }) => showAtEnd)
      .map((button) => renderButtonDropDown({ button, disabled, readonly }))}

    {dropdownMenuItems.length > 0 && (
      <li className="ufr-control-bar-dropdown-toggle">
        <MenuDropDown items={dropdownMenuItems} disabled={disabled || readonly} />
      </li>
    )}
  </ul>
);

ControlBar.propTypes = propTypes;
ControlBar.defaultProps = defaultProps;

export default ControlBar;
