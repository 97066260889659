import PropTypes from 'prop-types';
import React from 'react';

import HubLangLinksDatatable from '../datatables/langLinks/HubLangLinksDatatable';
import Page from '../page/Page';
import PageHeader from '../pageHeader/PageHeader';

const { number, string, shape, bool, oneOfType } = PropTypes;

const propTypes = {
  hub: shape({
    id: number,
    locale: oneOfType([
      bool,
      shape({
        display_name: string,
        id: number,
      }),
    ]),
    name: string,
  }).isRequired,
  readonly: bool,
};

const defaultProps = {
  readonly: true,
};

const HubMultiLanguageOptionsPage = ({ hub: { locale, id, name }, readonly }) => {
  const hasLocale = !!locale;
  const headerText = {
    subtitle: { text: 'Connect multi-language Hubs with each other by linking them below.' },
    title: { text: 'Multi-Language' },
  };

  const Alert = (
    <div className="alert alert-info" role="alert">
      Cannot create Hub Language Links for this Hub because the Hub Locale is currently not
      specified. Please contact Customer Support to change this setting.
    </div>
  );

  return (
    <Page>
      <PageHeader {...headerText} />
      {hasLocale ? (
        <HubLangLinksDatatable hub={{ id, locale, name }} readonly={readonly || !hasLocale} />
      ) : (
        Alert
      )}
    </Page>
  );
};

HubMultiLanguageOptionsPage.propTypes = propTypes;
HubMultiLanguageOptionsPage.defaultProps = defaultProps;

export default HubMultiLanguageOptionsPage;
