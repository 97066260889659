import PropTypes from 'prop-types';
import React from 'react';

import './textInput.less';
import handleInputReady from '../../../utils/handleInputReady';
import Input from '../input/Input';
import InputContainer from '../inputContainer/InputContainer';

const propTypes = {
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,

  // Props that are unique to our component, or that will be transformed.
  // eslint-disable-next-line sort-keys
  description: PropTypes.string,
  handleChange: PropTypes.func,
  // required for acceptance tests and a11y decorators
  id: PropTypes.string.isRequired,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  // Add a readonly postfix to the input
  postfix: PropTypes.string,
  // Add a readonly prefix to the input
  prefix: PropTypes.string,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
  title: PropTypes.string,
  // Add a readonly validation rules object to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
};

const defaultProps = {
  description: '',
  disabled: false,
  handleChange: undefined,
  maxLength: undefined,
  name: '',
  placeholder: '',
  postfix: '',
  prefix: '',
  readOnly: false,
  submitData: {},
  title: '',
  validation_rules: {},
  value: '',
};

class TextInput extends React.Component {
  state = {
    errorMessage: '',
    status: '',
  };

  onReady = (value) => {
    const { submitData, name, validation_rules: validationRules, disabled } = this.props;

    // structure the component
    const componentData = {
      propertyName: name,
      propertyValue: value,
      validationRules,
    };

    if (!disabled) {
      handleInputReady(submitData, componentData, this.handleCallback);
    }
  };

  handleCallback = (error) => {
    if (error) {
      this.setState({ errorMessage: error, status: 'error' });
    } else {
      // flash success
      this.setState({ errorMessage: '', status: 'success' });
      setTimeout(() => this.setState({ status: '' }), 500);
    }
  };

  handleChange = (e) => {
    if (this.props.handleChange) {
      this.props.handleChange(e);
    }
  };

  render() {
    const { id, name, title, disabled, description, value, ...rest } = this.props;
    return (
      <InputContainer
        id={id}
        title={title}
        description={description}
        error={this.state.errorMessage}
        disabled={disabled}
      >
        <Input
          id={id}
          name={name}
          type="text"
          disabled={disabled}
          handleChange={this.handleChange}
          onReady={this.onReady}
          {...rest}
          validationState={this.state.status}
          value={value}
        />
      </InputContainer>
    );
  }
}

TextInput.defaultProps = defaultProps;
TextInput.propTypes = propTypes;

export default TextInput;
