import './modalBody.less';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../Spinner/Spinner';

const ModalBody = ({ children, isLoading }) => (
  <div className="ufr-modal-body">{isLoading ? <Spinner /> : children}</div>
);

ModalBody.defaultProps = {
  isLoading: false,
};

ModalBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]).isRequired,
  isLoading: PropTypes.bool,
};

export default ModalBody;
