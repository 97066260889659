import React from 'react';
import ToasterStack from './ToasterStack';
import usePopToast from './usePopToast';
import useToasterStack from './useToasterStack';

const useToaster = () => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const popToast = usePopToast(dispatchToasterAction);

  return [
    popToast,
    <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />,
  ];
};

export default useToaster;
