import axios from 'axios';
import pickBy from 'lodash/pickBy';

export const allLocalesItem = { text: 'All Locales', value: null };

export const transformToDropdownItem = (locales) =>
  locales.map(({ display_name: text, id }) => ({ text, value: id }));

export const get = async (url, params = {}) => {
  const cleanQueries = pickBy(params);
  const {
    data: { data: linkables, meta },
  } = await axios.get(url, { params: cleanQueries });
  return [linkables, meta];
};

export const setUrlToProxy = (url, glue = '/items') => {
  const proxy = '/api/v2';
  const parts = url.split(glue);

  if (parts.length === 1) {
    return `${proxy}${glue}`;
  }

  parts[0] = proxy;
  return parts.join(glue);
};
