import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../../shared/button/Button';
import ConfirmationModal from '../../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState } from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  handleToasterLifecycle: PropTypes.func.isRequired,
  hubId: PropTypes.string.isRequired,
  isCtaEditPage: PropTypes.bool,
  triggerRefresh: PropTypes.func.isRequired,
};

const Cta15ListingCopyButton = ({
  ctaId,
  ctaName,
  hubId,
  handleToasterLifecycle,
  isCtaEditPage = false,
  triggerRefresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCtaName, setNewCtaName] = useState('');

  let addtnlProps;

  if (isCtaEditPage) {
    addtnlProps = {
      text: 'Copy',
      ufrStyle: 'secondary',
    };
  } else {
    addtnlProps = {
      ufrStyle: 'primary',
    };
  }

  const modalBody = () => (
    <>
      <p>
        Copying a CTA will create a new CTA with all the same settings except any placements or
        variations.
      </p>
      <p>
        <input
          className="form-control"
          value={newCtaName}
          onInput={(e) => setNewCtaName(e.target.value)}
          id="new-cta-name"
          placeholder="My CTA"
        />
      </p>
    </>
  );

  const copyCta = async (csrfToken) => {
    let toaster = {
      id: ctaId,
    };

    try {
      /* eslint-disable sort-keys */
      const copy = await axios({
        method: 'post',
        url: `/hubs/ajax_copyCta/${hubId}`,
        data: qs.stringify({
          ctaId,
          ctaName: newCtaName,
          flashMessage: 0,
        }),
        headers: {
          'CONTENT-TYPE': 'application/x-www-form-urlencoded',
          'X-REQUESTED-WITH': 'XMLHttpRequest',
          'X-CSRF-TOKEN': csrfToken,
        },
      });

      await axios({
        method: 'post',
        url: `/api/v2/cta-background/${copy.data.response.ctaID}/copy/${ctaId}`,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */

      toaster = {
        ...toaster,
        text: `"${ctaName}" was successfully copied.`,
        type: 'success',
      };

      triggerRefresh();
    } catch {
      toaster = {
        ...toaster,
        text: 'Something went wrong. Please try again.',
        type: 'error',
      };
    }

    handleToasterLifecycle(toaster, ctaId);
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => copyCta(csrfToken)}
            header="Give your CTA a name"
            body={modalBody()}
            primaryButtonText="Copy CTA"
            cancelButton={false}
          />

          <Button
            label="copy call-to-action"
            id={`copy-call-to-action-${ctaId}`}
            icon="duplicate"
            isIconText={isCtaEditPage}
            onClick={() => setIsModalOpen(true)}
            title="Copy CTA"
            {...addtnlProps}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

Cta15ListingCopyButton.propTypes = propTypes;

export default Cta15ListingCopyButton;
