import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './header.less';
import DivLink from '../shared/divLink/DivLink';
import Icon from '../shared/icon/Icon';

const Header = ({ title, subtitle, noImgUrl, subtitleUrl, rssUrl, iconUrl, icon, brandColor }) => {
  const styles = {
    favicon: {
      background: `url(${iconUrl}) no-repeat center/cover`,
    },
  };

  let image;
  if (iconUrl) {
    image = <div className="sidenav-header-image" style={styles.favicon} />;
  } else if (icon) {
    image = <Icon icon={icon} color={brandColor} />;
  } else {
    image = (
      <DivLink className="empty-avatar-icon" url={noImgUrl}>
        <Icon icon="plus" />
      </DivLink>
    );
  }

  const subtitleClasses = ['sidenav-header-subtitle', rssUrl && 'sidenav-subtitle-with-rss'];

  return (
    <div className="sidenav-header">
      {image}

      <div className="sidenav-header-text">
        <div role="heading" title={title} className="sidenav-header-title">
          {title}
        </div>
        <DivLink className={classNames(subtitleClasses)} url={subtitleUrl} target="_blank">
          {subtitle}
        </DivLink>

        {rssUrl && (
          <DivLink className="sidenav-rss-link" url={rssUrl} target="_blank">
            (RSS)
          </DivLink>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = {
  icon: '',
  iconUrl: '',
  noImgUrl: '',
  rssUrl: '',
  subtitleUrl: '',
};

Header.propTypes = {
  brandColor: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconUrl: PropTypes.string,
  noImgUrl: PropTypes.string,
  rssUrl: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  subtitleUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Header;
