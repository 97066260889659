import '../carousel/Carousel.less';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  activeIdx: PropTypes.number.isRequired,
  className: PropTypes.string,
  idx: PropTypes.number.isRequired,
  item: PropTypes.element.isRequired,
  itemHeight: PropTypes.number.isRequired,
  itemWidth: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  ratio: PropTypes.number.isRequired,
  style: PropTypes.object,
};

const CarouselItem = ({
  activeIdx,
  idx,
  item,
  itemHeight,
  itemWidth,
  onChange,
  ratio,
  className,
  style,
}) => {
  const styling =
    item.type === 'iframe'
      ? {
          MozTransform: `scale(${ratio})`,
          MozTransformOrigin: '0 0',
          OTransform: `scale(${ratio})`,
          OTransformOrigin: '0 0',
          Transform: `scale(${ratio})`,
          TransformOrigin: '0 0',
          WebkitTransform: `scale(${ratio})`,
          WebkitTransformOrigin: '0 0',
        }
      : {
          MozTransform: `scale(${ratio})`,
          MozTransformOrigin: '50% 50%',
          OTransform: `scale(${ratio})`,
          OTransformOrigin: '50% 50%',
          Transform: `scale(${ratio})`,
          TransformOrigin: '50% 50%',
          WebkitTransform: `scale(${ratio})`,
          WebkitTransformOrigin: '50% 50%',
        };

  const getItemDimension = (scaleRatio) => ({
    height: `${itemHeight * scaleRatio}px`,
    width: `${itemWidth * scaleRatio}px`,
  });

  const getClickableAreaDisplacement = (scaleRatio) => {
    if (item.type !== 'iframe') {
      return {
        left: `${((1 - scaleRatio) * 100) / 2}%`,
        top: `${((1 - scaleRatio) * 100) / 2}%`,
      };
    }

    return {};
  };

  const isActive = activeIdx === idx;

  return (
    <li
      className={`carousel-list-item${className ? ' ' + className : ''}`}
      style={{ ...style, ...getItemDimension(1) }}
    >
      <div
        style={isActive || item.type !== 'iframe' ? getItemDimension(1) : getItemDimension(ratio)}
        className={isActive ? '' : 'inactive-item'}
      >
        {isActive ? (
          <div className="responsive">{item}</div>
        ) : (
          <>
            <div className="responsive" style={styling}>
              {item}
            </div>
            <a
              className="clickable-area"
              style={{
                ...getItemDimension(ratio),
                ...getClickableAreaDisplacement(ratio),
              }}
              onClick={(e) => onChange(idx, e)}
            ></a>
          </>
        )}
      </div>
    </li>
  );
};

CarouselItem.propTypes = propTypes;

export default CarouselItem;
