import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import { Modal } from '../../modal';
import axios from 'axios';
import CancelButton from '../../modal/SecondaryButton/CancelButton';
import ModalBody from '../../modal/ModalBody/ModalBody';
import ModalFooter from '../../modal/ModalFooter/ModalFooter';
import ModalHeader from '../../modal/ModalHeader/ModalHeader';
import PrimaryButton from '../../modal/PrimaryButton/PrimaryButton';
import TextInput from '../../formInputs/textInput/TextInput';

const propTypes = {
  endpoint: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshTable: PropTypes.func,
  showMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  refreshTable: null,
};

const AddEmbedParameterModal = ({
  isOpen,
  showMessage,
  handleClose,
  refreshTable,
  hubId,
  endpoint,
}) => {
  const close = () => handleClose();

  const [state, setState] = useState({
    name: '',
    value: '',
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const addEmailParam = async (csrfToken) => {
    showMessage('info', 'Adding...', hubId);
    const { name, value } = state;

    try {
      close();
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url: endpoint(hubId),
        data: { parameter_name: name, parameter_value: value },
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      showMessage('success', 'Embed parameter added.', hubId);
      refreshTable();
    } catch (error) {
      const errorMessage = error.response.data.errors[0].message;

      close();
      showMessage(
        'error',
        `An error occurred while adding the Embed Parameter: ${errorMessage}`,
        hubId,
      );
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={close}>
      <ModalHeader
        title="Add Embed Parameter"
        description="Set up a new parameter to add to the URLs of all embed links from this Hub."
      />
      <ModalBody>
        <TextInput
          title="Parameter Name"
          description="Set the name of this parameter. This is the part of the parameter before the = sign, e.g. utm_campaign."
          id="ufr-param-name"
          name="name"
          value={state.name}
          handleChange={handleChange}
          autoFocus
        />
        <TextInput
          title="Parameter Value"
          description="Set the value to assign to this parameter. This is the part of the parameter after the = sign, e.g. mycampaign."
          id="ufr-param-value"
          name="value"
          value={state.value}
          handleChange={handleChange}
        />
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} />
        <CsrfContextConsumer>
          {(csrfToken) => (
            <PrimaryButton label="Add Parameter" onClick={() => addEmailParam(csrfToken)}>
              Add Parameter
            </PrimaryButton>
          )}
        </CsrfContextConsumer>
      </ModalFooter>
    </Modal>
  );
};

AddEmbedParameterModal.propTypes = propTypes;
AddEmbedParameterModal.defaultProps = defaultProps;

export default AddEmbedParameterModal;
