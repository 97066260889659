import PropTypes from 'prop-types';
import React from 'react';

import './pageFilter.less';
import './tabs.less';
import MenuDropDown from '../shared/dropdown/MenuDropDown';
import TabsList from './TabsList';

const TabsContainer = ({ isMinimal, activeTab, menu, items, id, tabsListRef }) => {
  let tabLengthClass;
  if (items.length < 10) {
    tabLengthClass = 'streams-tabs';

    if (items.length < 8) {
      tabLengthClass = 'streams-tabs-wo-appearance';
    }
  } else {
    tabLengthClass = 'streams-tabs-w-sperf';
  }
  const className = isMinimal ? 'page-filter' : `tabs-list-container ${tabLengthClass}`;
  const showMenu = menu && menu.items.length;

  return (
    <div className={'tabs-container'}>
      <div className={className}>
        <TabsList ref={tabsListRef} id={id} items={items} activeTab={activeTab} />
      </div>
      <div className={'meatball-menu'}>{showMenu && <MenuDropDown {...menu} />}</div>
    </div>
  );
};

TabsContainer.defaultProps = {
  activeTab: null,
  isMinimal: false,
  menu: null,
};

const shapes = {
  button: {
    icon: PropTypes.string,
    id: PropTypes.string.isRequired,
  },

  menuItem: {
    id: PropTypes.string,
    onClick: PropTypes.func,
    onClickCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    target: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  },

  tab: {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onClickCallback: PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
    tabContentId: PropTypes.string,
    text: PropTypes.string.isRequired,
  },
};

TabsContainer.propTypes = {
  activeTab: PropTypes.string,
  id: PropTypes.string.isRequired,
  isMinimal: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(shapes.tab)).isRequired,
  menu: PropTypes.shape({
    button: PropTypes.shape(shapes.button),
    items: PropTypes.arrayOf(PropTypes.shape(shapes.menuItem)),
  }),
  tabsListRef: PropTypes.shape({ current: PropTypes.any }),
};

export default TabsContainer;
