import './characterCount.less';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  count: PropTypes.number,
  max: PropTypes.number.isRequired,
};

const defaultProps = {
  count: 0,
};

const CharacterCount = ({ max, count }) => (
  <p className="ufr-character-count">{`${count || '0'} / ${max}`}</p>
);

CharacterCount.propTypes = propTypes;
CharacterCount.defaultProps = defaultProps;

export default CharacterCount;
