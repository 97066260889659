import './formFieldAccordion.less';

import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import _ from 'lodash';
import Accordion from '@/components/shared/accordion/Accordion';
import Checkbox from '@/components/formInputs/checkbox/Checkbox';
import FormField from './FormField';
import handleInputReady from '@/utils/handleInputReady';
import TextInput from '@/components/formInputs/textInput/TextInput';

const propTypes = {
  form: PropTypes.shape({
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        locked: PropTypes.bool.isRequired,
      }),
    ),
    opt_in_label: PropTypes.string.isRequired,
    opt_in_required: PropTypes.number.isRequired,
    progressive_profiling: PropTypes.number.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  isManageFieldsDisabled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  serviceType: PropTypes.string.isRequired,
  setOpenAccordion: PropTypes.func.isRequired,
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]),
};

const title = 'Form Fields';

const FormFieldAccordion = ({
  serviceType,
  form,
  isManageFieldsDisabled,
  handleSubmit,
  isOpen,
  setOpenAccordion,
  submitData,
}) => {
  const formData = useRef(form);
  const _handleSubmit = (data, action) => {
    const { propertyName, propertyValue } = data;
    formData.current[propertyName] = propertyValue;

    if (handleSubmit) handleSubmit(formData.current, action);
    if (submitData) handleInputReady(submitData, data);
  };

  return (
    <Accordion
      id="ufr-form-field-accordion"
      title={title}
      isOpen={isOpen}
      onClick={() => setOpenAccordion('form-field')}
    >
      <div className="ufr-form-field-body">
        <div className="ufr-opt-in">
          <div className="ufr-form-field-header">
            <p className="ufr-field-title">Opt-in</p>
          </div>
          <div className="ufr-opt-in-fields">
            <Checkbox
              id="opt-in-checkbox"
              name="opt_in_required"
              value={!!formData.current.opt_in_required}
              submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
            />
            <TextInput
              id="opt-in-url"
              name="opt_in_label"
              placeholder="Opt-in To Future Emails"
              value={formData.current.opt_in_label}
              submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
            />
          </div>
        </div>
        <div className="ufr-form-field-header">
          <p className="ufr-field-title">Form Fields </p>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip placement="top" className="in" id="tooltip-top">
                Add, Delete and Re-arrange Fields
              </Tooltip>
            }
          >
            <a
              className={`manage-form-fields ${isManageFieldsDisabled ? 'disabled' : ''}`}
              tabIndex="0"
            >
              Manage Fields
            </a>
          </OverlayTrigger>
        </div>
        <div>
          <p>
            {serviceType === 'form_cta_type' ? 'Custom Integration' : _.capitalize(serviceType)}{' '}
            Form
          </p>
        </div>

        <div className="ufr-form-field-fields">
          {formData.current.formFields &&
            formData.current.formFields.map((field, i) => (
              <FormField
                key={i}
                {...field}
                fieldId={field.id}
                disabled={field.locked}
                submitData={({ propertyName, propertyValue }) => {
                  if (handleSubmit)
                    handleSubmit(
                      { active: propertyValue, fieldId: propertyName },
                      'update-form-field',
                    );
                }}
              />
            ))}
        </div>
        <div className="ufr-progressive-profiling">
          <div className="ufr-form-field-header">
            <p className="ufr-field-title">Progressive Profiling</p>
          </div>
          <Checkbox
            id="progressive-profiling-checkbox"
            name="progressive_profiling"
            value={!!formData.current.progressive_profiling}
            title="If values are known, do not show form fields"
            submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
          />
        </div>
      </div>
    </Accordion>
  );
};

FormFieldAccordion.propTypes = propTypes;

export default FormFieldAccordion;
