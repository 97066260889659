import Button from '@/components/shared/button/Button';
import CheckBoxModal from '@/components/modal/CheckBoxModal/CheckBoxModal';
import InputContainer from '@/components/formInputs/inputContainer/InputContainer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SectionHeader from '@/components/formInputs/sectionHeader/SectionHeader';

const { string, func, array } = PropTypes;

const propTypes = {
  description: PropTypes.oneOfType([string, array]),
  handleConfirm: func.isRequired,
  header: string.isRequired,
  id: string.isRequired,
  modalBody: string.isRequired,
  modalBodyLabel: string.isRequired,
  modalBodyTitle: string.isRequired,
  modalButtonText: string.isRequired,
  modalHeader: string.isRequired,
  title: string.isRequired,
};

const RemovalConfirmation = ({
  id,
  header,
  title,
  description,
  modalHeader,
  modalBody,
  modalBodyTitle,
  modalBodyLabel,
  modalButtonText,
  handleConfirm,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SectionHeader text={header} />
      <InputContainer id={id} title={title} description={description}>
        <CheckBoxModal
          isOpen={isOpen}
          header={modalHeader}
          description={modalBody}
          checkBoxTitle={modalBodyTitle}
          checkBoxLabel={modalBodyLabel}
          primaryButtonText={modalButtonText}
          handleClose={() => setIsOpen(false)}
          handleConfirm={handleConfirm}
        />
        <Button id={`${id}-data`} label={`${id} data`} onClick={() => setIsOpen(true)}>
          Remove Data
        </Button>
      </InputContainer>
    </>
  );
};

RemovalConfirmation.propTypes = propTypes;

export default RemovalConfirmation;
