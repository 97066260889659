const services = {
  acton: {
    color: '#80c343',
    label: 'ActOn',
    noun: 'ActOn',
  },
  blogpost: {
    color: '#f7931d',
    label: 'Blog',
    noun: 'Article',
  },
  bombora: {
    color: '#5192bf',
    label: 'Bombora',
    noun: 'Bombora',
  },
  brightcove: {
    color: '#ed7e22',
    label: 'Brightcove',
    noun: 'Video',
  },
  brightinfo: {
    color: '#f03f39',
    label: 'Brightinfo',
    noun: 'Brightinfo',
  },
  custom: {
    color: '#ce0258',
    label: 'Custom',
    noun: 'Custom Item',
  },
  disqus: {
    color: '#2c9fff',
    label: 'Disqus',
    noun: 'Disqus',
  },
  eloqua: {
    color: '#e6293d',
    label: 'Eloqua',
    noun: 'Eloqua',
  },
  facebook: {
    color: '#3d599b',
    label: 'Facebook',
    noun: 'Post',
  },
  googleanalytics: {
    color: '#f37722',
    label: 'Google Analytics',
    noun: 'Google Analytics',
  },
  googletagmanager: {
    color: '#3f81ec',
    label: 'Google Tag Manager',
    noun: 'Google Tag Manager',
  },
  hub: {
    color: '#ce0258',
    label: 'Hub',
    noun: 'Hub',
  },
  hubspot: {
    color: '#f37722',
    label: 'HubSpot',
    noun: 'Hub',
  },
  instagram: {
    color: '#e4405f',
    label: 'Instagram',
    noun: 'Post',
  },
  marketo: {
    color: '#5944b0',
    label: 'Marketo',
    noun: 'Marketo',
  },
  optimizely: {
    color: '#0135f6',
    label: 'Optimizely',
    noun: 'Optimizely',
  },
  pardot: {
    color: '#007DAA',
    label: 'Pardot',
    noun: 'Pardot',
  },
  pinterest: {
    color: '#de1a25',
    label: 'Pinterest',
    noun: 'Pinterest',
  },
  slideshare: {
    color: '#01a7a9',
    label: 'SlideShare',
    noun: 'Presentation',
  },
  targeted: {
    color: '#ce0258',
    label: 'Digital sales room',
    noun: 'Sales Stream',
  },
  twitter: {
    color: '#79caee',
    label: 'Twitter',
    noun: 'Tweet',
  },
  uberflip: {
    color: '#ce0058',
    label: 'Uberflip',
    noun: 'Uberflip',
  },
  verticaldots: {
    color: '#F6EEF1',
    label: 'Menu',
    noun: 'Menu',
  },
  vidyard: {
    color: '#71ac4a',
    label: 'Vidyard',
    noun: 'Video',
  },
  vimeo: {
    color: '#1dafeb',
    label: 'Vimeo',
    noun: 'Video',
  },
  wistiar: {
    color: '#1a6d88',
    label: 'Wistia',
    noun: 'Video',
  },
  youtube: {
    color: '#ffffff',
    label: 'YouTube',
    noun: 'Video',
  },
};

export default services;
