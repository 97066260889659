import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import handleClick from '@/utils/handleClick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RedirectRulesButtons from './RedirectRulesButtons';
import RedirectRulesEnabled from './RedirectRulesEnabled';
import RedirectRulesPriority from './RedirectRulesPriority';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hasManagePermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  onCreateCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
};

const cellClass = (key) => `ufr-dt-redirect-rules-${key}-cell`;
const headerClass = (key) => `ufr-dt-redirect-rules-${key}-header`;

const entityName = {
  plural: 'Redirect Rules',
  singular: 'Redirect Rule',
};

const syntaxLabels = {
  exact: 'Matches exactly',
  regex: 'Matches Regular Expression',
  starts: 'Starts with',
};

const redirectTypes = {
  permanent: 'Permanent (301)',
  temporary: 'Temporary (302)',
};

const createRedirectRuleActionButton = (hubId, hasManagePermission) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: !hasManagePermission,
  id: 'create-redirect-rule',
  onClick: () => handleClick({ url: `/redirect_rules/add/${hubId}` }),
  text: 'Add Rule',
});

const exportRedirectRulesActionButton = (onClickCallback) => ({
  icon: 'download-alt',
  id: 'export-redirect-rules',
  onClick: () => handleClick({ onClickCallback }),
  showAtEnd: true,
  text: '',
  tooltip: 'Export to CSV',
});

const searchPlaceholder = (redirectRuleCount) =>
  `Search ${redirectRuleCount} ${entityName[redirectRuleCount === 1 ? 'singular' : 'plural']}`;

/* eslint-disable sort-keys */
const columns = (dispatchToasterAction) => [
  {
    Header: 'Syntax',
    accessor: 'syntax',
    id: 'syntax',
    className: cellClass('syntax'),
    headerClassName: headerClass('syntax'),
    width: 200,
    Cell: (cell) => <>{syntaxLabels[cell.original.syntax]}</>,
  },
  {
    Header: 'Source Path',
    accessor: 'source_path_pattern',
    id: 'source_path_pattern',
    className: cellClass('source-path'),
    headerClassName: headerClass('source-path'),
    minWidth: 120,
    Cell: (cell) => <>{cell.original.source_path_pattern || '—'}</>,
  },
  {
    Header: 'Destination',
    accessor: 'destination_path_template',
    id: 'destination_text',
    className: cellClass('destination-path'),
    headerClassName: headerClass('destination-path'),
    minWidth: 150,
    Cell: (cell) => <>{cell.original.destination_text || '—'}</>,
  },
  {
    Header: 'Redirect Type',
    accessor: 'redirect_type',
    id: 'redirect_type',
    className: cellClass('redirect-type'),
    headerClassName: headerClass('redirect-type'),
    width: 150,
    Cell: (cell) => <>{redirectTypes[cell.original.redirect_type]}</>,
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    id: 'priority',
    className: `${cellClass('priority')}`,
    headerClassName: headerClass('priority'),
    width: 80,
    Cell: (cell) => (
      <RedirectRulesPriority
        priority={cell.original.priority}
        hubId={cell.original.hub_id}
        redirectRuleId={cell.original.id}
      />
    ),
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    id: 'enabled',
    className: cellClass('enabled'),
    headerClassName: headerClass('enabled'),
    width: 80,
    Cell: (cell) => (
      <RedirectRulesEnabled enabled={!!cell.original.enabled} redirectRuleId={cell.original.id} />
    ),
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    width: 100,
    Cell: (cell) => (
      <RedirectRulesButtons
        hasManagePermission={cell.hasManagePermission}
        hubId={cell.original.hub_id}
        redirectRuleId={cell.original.id}
        triggerRefresh={cell.refresh}
        decrementRedirectRuleCount={cell.decrementRedirectRuleCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ),
  },
];
/* eslint-enable sort-keys */

const getEndpointBuilder = (hubId) => ({
  getRedirectRules: () => `/api/v2/hubs/${hubId}/redirect-rules`,
});

const RedirectRulesDatatable = ({ hasManagePermission, hubId }) => {
  const [redirectRuleCount, setRedirectRuleCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const { getRedirectRules } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getRedirectRules(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setRedirectRuleCount(meta.count);
    }
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="redirect-rules"
        useStateHandling
        entityName={entityName}
        columns={columns(dispatchToasterAction)}
        getData={getData}
        actionButtons={[
          createRedirectRuleActionButton(hubId, hasManagePermission),
          exportRedirectRulesActionButton({
            func: 'downloadCSV',
            namespace: 'CurrentPage',
          }),
        ]}
        searchPlaceholder={searchPlaceholder(redirectRuleCount)}
        noSearchResultsMessage={
          <p>{isInitialLoad ? ' ' : 'No redirect rules match your search.'}</p>
        }
        initialSort={{ desc: false, id: 'priority' }}
        showLoadingState={isInitialLoad}
        getCellProps={() => ({
          decrementRedirectRuleCount: () => {
            setRedirectRuleCount(redirectRuleCount - 1);
          },
          hasManagePermission,
        })}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

RedirectRulesDatatable.propTypes = propTypes;

export default RedirectRulesDatatable;
