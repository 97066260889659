import './fontSlider.less';
import handleInputReady from '../../../utils/handleInputReady';
import React from 'react';
import Slider from './Slider';

const roundFloat = (value, step) =>
  // make sure value is within range and round to nearest step interval
  Math.round(value / step) * step;

const normalize = (value, min, max) => Math.min(Math.max(value, min), max);

class FontSlider extends Slider {
  state = {
    timeoutId: 0,
    value: parseFloat(this.props.value) || 0,
  };

  getWidth = () => 55;

  handleDone = (value) => {
    const {
      submitData,
      name,
      validation_rules: validationRules,
      max,
      min,
      step,
      disabled,
      debounceTime,
    } = this.props;
    if (disabled) {
      return;
    }

    let newValue = roundFloat(normalize(value, min, max), step);

    if ('inputHandleChange' in this.props && typeof this.props.inputHandleChange === 'function') {
      this.props.inputHandleChange(newValue);
    }

    if ('inputHandleChange' in this.props && typeof this.props.inputHandleChange === 'function') {
      this.props.inputHandleChange(newValue);
    }

    const { timeoutId } = this.state;

    const componentData = {
      propertyName: name,
      propertyValue: newValue / 1000,
      validationRules,
    };

    if (debounceTime) {
      // Debounce the slider
      clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(
        () => handleInputReady(submitData, componentData),
        debounceTime,
      );

      this.setState({
        timeoutId: newTimeoutId,
        value: newValue,
      });
    } else {
      handleInputReady(submitData, componentData);
      this.setState({
        value: newValue,
      });
    }
  };

  handleChange = (e) => {
    if (e.target.type === 'range') {
      // instant state changes for range input
      this.handleDone(e.target.value);
    }
  };

  render() {
    return <div className="ufr-font-slider">{super.render()}</div>;
  }
}

FontSlider.defaultProps = {
  ...FontSlider.defaultProps,
  max: 80,
  min: 10,
  postfix: null,
  step: 0.5,
};

export default FontSlider;
