import PropTypes from 'prop-types';
import React from 'react';

import './errorMessage.less';

const ErrorMessage = ({ message }) => <p className="ufr-input-error-message">{message}</p>;

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
