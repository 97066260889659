import './integrations.less';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SelectDropdown from '@/components/formInputs/selectDropdown/SelectDropdown';
import Spinner from '@/components/modal/Spinner/Spinner';

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const propTypes = {
  csrfToken: PropTypes.string,
  cta: PropTypes.shape({}),
  getCtaIntegrationLists: PropTypes.func,
  setCtaIntegrationLists: PropTypes.func,
};

const Pardot = ({ csrfToken, cta, getCtaIntegrationLists, setCtaIntegrationLists }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pardotDropdownData, setPardotDropdownData] = useState([]);
  const [listId, setListId] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    getCtaIntegrationLists(
      'Pardot',
      setPardotDropdownData,
      csrfToken,
      setIsLoading,
      setListId,
      cta.HubCtasForm.service_list_id,
      setErrorMessage,
      setLoadingMessage,
      null,
    );
  }, []);

  const submitDropdown = (selectedItem) => {
    setCtaIntegrationLists(
      selectedItem,
      cta.HubCta.hub_id,
      cta.HubCta.id,
      csrfToken,
      setIsLoading,
      'Pardot',
      setListId,
      setLoadingMessage,
      null,
    );
  };

  const renderPardotForm = () => {
    if (errorMessage.length > 1) {
      return <div>{errorMessage}</div>;
    }

    return (
      <SelectDropdown
        id="pardot-dropdown-id"
        items={pardotDropdownData}
        submitData={submitDropdown}
        value={listId.text}
      />
    );
  };

  return (
    <div className="integration-container" data-loading={isLoading}>
      {isLoading ? (
        <>
          <Spinner />
          <span>{loadingMessage}</span>
        </>
      ) : (
        <>
          <div className="integration-setup-sprite pardot-text" />
          <label className="integration-label" htmlFor="pardot-dropdown-id">
            Select a List (Optional)
          </label>

          <div id="pardot-form" className="integration-form">
            {renderPardotForm()}
          </div>
        </>
      )}
    </div>
  );
};

Pardot.propTypes = propTypes;

export default Pardot;
