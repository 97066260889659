import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import { Modal } from '../../modal';
import axios from 'axios';
import CancelButton from '../../modal/SecondaryButton/CancelButton';
import ModalBody from '../../modal/ModalBody/ModalBody';
import ModalFooter from '../../modal/ModalFooter/ModalFooter';
import ModalHeader from '../../modal/ModalHeader/ModalHeader';
import PrimaryButton from '../../modal/PrimaryButton/PrimaryButton';
import RadioButtons from '../../formInputs/radioButtons/RadioButtons';

const propTypes = {
  defaultHubId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  endpointBuilder: PropTypes.shape({
    patchDefault: PropTypes.func,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  optionData: PropTypes.arrayOf(
    PropTypes.shape({
      hub: PropTypes.shape({
        id: PropTypes.number,
        locale: PropTypes.shape({
          display_name: PropTypes.string,
        }),
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  refreshTable: PropTypes.func,
  showMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  defaultHubId: null,
  refreshTable: null,
};

const noneOption = {
  text: 'None',
  value: undefined,
};

const SetDefaultModal = ({
  isOpen,
  showMessage,
  handleClose,
  refreshTable,
  optionData,
  endpointBuilder,
  defaultHubId,
}) => {
  if (!optionData.length) {
    return null;
  }
  const [selectedHubId, setSelectedHubId] = useState(defaultHubId);

  const close = () => handleClose();
  const success = () => showMessage('success', 'Default link was saved', 'saving');
  const error = () =>
    showMessage('error', 'An error occurred while saving default Link.', 'saving');

  const setDefault = async (csrfToken) => {
    if (selectedHubId === defaultHubId) {
      success();
      close();
      return;
    }

    let url;
    let data;
    if (selectedHubId) {
      url = endpointBuilder.patchDefault(selectedHubId);
      data = { default_hub: true };
    } else {
      url = endpointBuilder.patchDefault(defaultHubId);
      data = { default_hub: false };
    }

    try {
      showMessage('info', 'Saving default...', 'saving');
      close();
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url,
        headers: { 'X-CSRF-TOKEN': csrfToken },
        data,
      });
      /* eslint-enable sort-keys */
      success();
      refreshTable();
    } catch {
      close();
      error();
    }
  };

  const hubLinks = optionData.map((link) => {
    const {
      hub: { id, name, locale },
      default: isDefault,
    } = link;
    return {
      text: `${name} (${locale.display_name}) ${isDefault ? '(Current Default)' : ''}`,
      value: id,
    };
  });

  const radioOptions = [noneOption, ...hubLinks];

  return (
    <Modal isOpen={isOpen} handleClose={close}>
      <ModalHeader title="Set Default Hub" />
      <ModalBody>
        <RadioButtons
          title="Set the Default Hub"
          description="the selected Hub will receive the x-default HREFLang attribute"
          submitData={(value) => setSelectedHubId(parseInt(value, 10))}
          id="select-default-hub-link"
          options={radioOptions}
          value={selectedHubId}
        />
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={close} />
        <CsrfContextConsumer>
          {(csrfToken) => (
            <PrimaryButton label="Confirm" onClick={() => setDefault(csrfToken)}>
              Confirm
            </PrimaryButton>
          )}
        </CsrfContextConsumer>
      </ModalFooter>
    </Modal>
  );
};

SetDefaultModal.propTypes = propTypes;
SetDefaultModal.defaultProps = defaultProps;

export default SetDefaultModal;
