import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import WebsiteWidgetRulesDeleteButton from '@/components/datatables/websiteWidgetRules/WebsiteWidgetRulesDeleteButton';

const propTypes = {
  decrementWebsiteWidgetRuleCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hasDeletePermission: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
  websiteWidgetRuleId: PropTypes.number.isRequired,
  websiteWidgetRuleName: PropTypes.string.isRequired,
};

const WebsiteWidgetRulesButtons = ({
  decrementWebsiteWidgetRuleCount,
  dispatchToasterAction,
  hasDeletePermission,
  hasEditPermission,
  hubId,
  triggerRefresh,
  websiteWidgetRuleId,
  websiteWidgetRuleName,
}) => (
  <>
    {hasEditPermission ? (
      <Button
        href={`/hubs/site_engager/rules/edit/${hubId}/${websiteWidgetRuleId}`}
        icon="wrench"
        title="Edit Rule"
        label="Edit Rule"
        id={`edit-website-widget-rule-${websiteWidgetRuleId}`}
        className="ufr-edit-button"
      />
    ) : null}
    {hasDeletePermission ? (
      <WebsiteWidgetRulesDeleteButton
        websiteWidgetRuleId={websiteWidgetRuleId}
        websiteWidgetRuleName={websiteWidgetRuleName}
        decrementWebsiteWidgetRuleCount={decrementWebsiteWidgetRuleCount}
        dispatchToasterAction={dispatchToasterAction}
        triggerRefresh={triggerRefresh}
      />
    ) : null}
  </>
);

WebsiteWidgetRulesButtons.propTypes = propTypes;

export default WebsiteWidgetRulesButtons;
