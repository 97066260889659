import './modalHeader.less';
import PropTypes from 'prop-types';
import React from 'react';

const ModalHeader = ({ title, description, handleClose }) => (
  <header className="ufr-modal-header">
    {handleClose && (
      <button type="button" className="close" aria-label="Close" onClick={handleClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    )}

    <h4 className="ufr-modal-header-title">{title}</h4>

    {description && <p className="ufr-modal-header-description">{description}</p>}
  </header>
);

ModalHeader.defaultProps = {
  description: '',
  handleClose: null,
};

ModalHeader.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.element, // If the modal should render react elements
    PropTypes.string, // If the modal should just render some text
  ]),
  /**
   * handleClose gets passed in automatically by Modal.jsx
   * only pass it in if you want to override the close handler passed to parent Modal
   */
  handleClose: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.element, // If the modal should render react elements
    PropTypes.string, // If the modal should just render some text
  ]).isRequired,
};

export default ModalHeader;
