import PropTypes from 'prop-types';
import React from 'react';

import '../formInputs.less';
import './colorPicker.less';
import ColorPickerCta from './ColorPickerCta';
import ColorPickerInput from './ColorPickerInput';
import handleInputReady from '../../../utils/handleInputReady';
import InputContainer from '../inputContainer/InputContainer';
import Reset from '@/components/formInputs/reset/Reset';

const propTypes = {
  colorFor: PropTypes.string,
  cta: PropTypes.bool,
  description: PropTypes.string,
  disableAlpha: PropTypes.bool,
  disabled: PropTypes.bool,
  // required for acceptance tests and accessibility decorators
  id: PropTypes.string.isRequired,
  // for app config settings
  inheritedValue: PropTypes.string,
  // function to call every time the input value changes
  inputHandleChange: PropTypes.func,
  // Add a readonly propertyName to the input
  name: PropTypes.string,
  resetData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  showReset: PropTypes.bool,
  // function or {namespace, func} object to call with data when ready to submit
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]).isRequired,
  title: PropTypes.string,
  // Add a readonly validation rules to the input
  validation_rules: PropTypes.objectOf(PropTypes.shape),
  // set initial color for the picker preview
  value: PropTypes.string,
};

const defaultProps = {
  description: '',
  disableAlpha: true,
  disabled: false,
  inheritedValue: '',
  name: '',
  resetData: {},
  showReset: false,
  title: '',
  validation_rules: {},
  value: '#d04059',
};

class ColorPicker extends React.Component {
  state = {
    color: this.props.value,
    colorHasChanged: false,
    showColorPicker: false,
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleToggle();
    }
  };

  handleToggle = () => {
    const { submitData, name, validation_rules: validationRules } = this.props;
    const { color, showColorPicker, colorHasChanged } = this.state;

    this.setState({ showColorPicker: !showColorPicker });

    const componentData = {
      propertyName: name,
      propertyValue: color,
      validationRules,
    };

    // register event only if picker was closed and color was updated
    if (showColorPicker && colorHasChanged) {
      handleInputReady(submitData, componentData);
      this.setState({ colorHasChanged: false });
    }
  };

  handleChange = ({ rgb }) => {
    const { r, g, b, a } = rgb;
    const { disableAlpha } = this.props;

    let newColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    if (disableAlpha) {
      newColor = `rgb(${r}, ${g}, ${b})`;
    }

    this.setState({ color: newColor, colorHasChanged: true });
  };

  handleReset = () => {
    const { name, resetData } = this.props;
    const componentData = {
      propertyName: name,
    };

    handleInputReady(resetData, componentData, this.finishReset);
  };

  handleChangeCta = (color) => {
    this.setState({ color: color.hex, colorHasChanged: true });
  };

  inputChangeCta = (event) => {
    const previewColor = event.target.value;

    this.setState({ color: previewColor, colorHasChanged: true });

    if ('inputHandleChange' in this.props && typeof this.props.inputHandleChange === 'function') {
      this.props.inputHandleChange(event);
    }
  };

  finishReset = () => {
    const { inheritedValue } = this.props;

    this.setState({ color: inheritedValue, colorHasChanged: true });
  };

  render() {
    const { color, showColorPicker } = this.state;
    const { id, title, description, disabled, disableAlpha, cta, colorFor, showReset } = this.props;

    return (
      <>
        {cta ? (
          <ColorPickerCta
            id={id}
            color={color}
            showColorPicker={showColorPicker}
            disabled={disabled}
            handleToggle={this.handleToggle}
            handleKeyDown={this.handleKeyDown}
            handleChange={this.handleChange}
            handleChangeCta={this.handleChangeCta}
            inputChangeCta={this.inputChangeCta}
            disableAlpha={disableAlpha}
            colorLabel={colorFor}
          />
        ) : (
          <InputContainer id={id} title={title} description={description}>
            <ColorPickerInput
              id={id}
              color={color}
              showColorPicker={showColorPicker}
              disabled={disabled}
              handleToggle={this.handleToggle}
              handleKeyDown={this.handleKeyDown}
              handleChange={this.handleChange}
              disableAlpha={disableAlpha}
              reset={showReset && <Reset handleReset={this.handleReset} id={id} />}
            />
          </InputContainer>
        )}
      </>
    );
  }
}

ColorPicker.defaultProps = defaultProps;
ColorPicker.propTypes = propTypes;

export default ColorPicker;
