import Button from '../../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const CancelButton = ({ children, ...props }) => (
  <Button id="cancel" className="cancel" ufrStyle="secondary" label="Cancel" {...props}>
    {children}
  </Button>
);

CancelButton.defaultProps = {
  children: 'Cancel',
  onClick: null,
};

CancelButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClick: PropTypes.func,
};

export default CancelButton;
