import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './Slider.less';

const propTypes = {
  distanceToChangeSlide: PropTypes.number, // how close to an index does the dot have to be to trigger a change
  onChange: PropTypes.func, // triggered when the slider index has been changed by user
  onMove: PropTypes.func, // triggered when the slider is moved by user
  slideIdx: PropTypes.number, // current selected index of sliderData
  sliderData: PropTypes.array.isRequired, // array of any elements, slider only looks at length and index
};

const defaultProps = {
  animationStep: 50,
  distanceToChangeSlide: 1 / 2,
  slideIdx: 0,
  sliderData: [],
};

/**
 * e.g. normalizeToUpperFraction(1.22, 1/4) rounds to 1.25
 * e.g. normalizeToUpperFraction(1.26, 1/4) rounds to 1.5
 * e.g. normalizeToUpperFraction(0.9, 1/4) rounds to 1
 * @param {*} floatNumber - any float
 * @param {*} fraction - fraction below zero to normalize to
 * @returns {float}
 */
export const normalizeToUpperFraction = (floatNumber, fraction) => {
  const multiplier = 1 / fraction;
  return Math.ceil(floatNumber * multiplier) / multiplier;
};

const CarouselSlider = ({ sliderData, slideIdx, distanceToChangeSlide, onChange, onMove }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);

  const barRef = useRef();
  const dotRef = useRef();

  const animationFrameRef = useRef();

  const initialLeftOffset = barRef.current?.offsetWidth
    ? (slideIdx / (sliderData.length - 1)) * barRef.current.offsetWidth
    : 0;

  // normalizes leftOffset from pixels to sliderData.length
  const calcDotSliderPosition = useCallback(
    (leftOffset) => {
      if (barRef.current) {
        const sliderWidth = barRef.current.offsetWidth;
        const chunkSize = sliderWidth / (sliderData.length - 1);
        return leftOffset / chunkSize;
      } else {
        return slideIdx;
      }
    },
    [barRef.current, sliderData],
  );

  const getClosestSlide = useCallback(
    (left) => {
      const normalized = normalizeToUpperFraction(
        calcDotSliderPosition(left),
        distanceToChangeSlide,
      );

      let newSlide;

      if (normalized % 1 <= distanceToChangeSlide) {
        // closer to the left data item
        newSlide = Math.floor(normalized);
      } else if (Math.abs(normalized - 1) % 1 <= distanceToChangeSlide) {
        // closer to the right data item
        newSlide = Math.ceil(normalized);
      } else {
        // neither so we want to retain current position
        newSlide = slideIdx;
      }

      return newSlide;
    },
    [slideIdx, calcDotSliderPosition],
  );

  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();

      setIsDragging(true);
    },
    [setIsDragging],
  );

  const handleMouseMove = useCallback(
    (e) => {
      e.preventDefault();

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      animationFrameRef.current = requestAnimationFrame(() => {
        // calculate relative leftOffset based on pointer position
        const newLeftOffset = Math.max(
          Math.min(
            e.clientX -
              barRef.current.getBoundingClientRect().left -
              dotRef.current.offsetWidth / 2,
            barRef.current.offsetWidth,
          ),
          0,
        );

        const dotSliderPosition = calcDotSliderPosition(newLeftOffset);

        // check every call to account for quick slider movements
        // use newIdx to determine unit vector below
        const newIdx = getClosestSlide(newLeftOffset);

        // send a negative unit vector if the dot slider is to the left of the current slide
        // send a positive unit vector if the dot slider is to the right of the current slide
        onMove?.(newIdx > dotSliderPosition ? (dotSliderPosition % 1) - 1 : dotSliderPosition % 1);

        if (newIdx !== slideIdx) {
          onChange(newIdx);
        }

        setLeftOffset(newLeftOffset);
      });
    },
    [animationFrameRef, onMove, setLeftOffset, getClosestSlide],
  );

  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();

      document.removeEventListener('mousemove', handleMouseMove);

      setIsDragging(false);
    },
    [setIsDragging],
  );

  useEffect(() => {
    // reset state if necessary (sometimes mousemove is called again after mouseup)
    if (!isDragging && leftOffset !== initialLeftOffset) {
      onMove?.(0);

      setLeftOffset(initialLeftOffset);
    }
  }, [isDragging, leftOffset, setLeftOffset, initialLeftOffset]);

  useEffect(() => {
    // these handlers will only be attached once when dragging is started
    // and detached once when dragging ends
    if (isDragging) {
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mouseleave', handleMouseUp);

      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mouseleave', handleMouseUp);
      };
    }
  }, [isDragging, handleMouseUp]);

  useEffect(() => {
    // these handlers will be attached and detached often with handleMouseMove's dependencies
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [isDragging, handleMouseMove]);

  return (
    <div className="slider-container">
      <div ref={barRef} className="slider-bar"></div>
      <div
        ref={dotRef}
        className={`slider-dot ${isDragging ? 'override-animation' : ''}`}
        style={{ left: `${leftOffset}px` }}
        onMouseDown={handleMouseDown}
      ></div>
    </div>
  );
};

CarouselSlider.propTypes = propTypes;
CarouselSlider.defaultProps = defaultProps;

export default CarouselSlider;
