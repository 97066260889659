import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import NameAndDescriptionModal from '@/components/modal/NameAndDescriptionModal/NameAndDescriptionModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  customCodeEditLink: PropTypes.func.isRequired,
  getAddCustomCodeEndpoint: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isAddCodeModalOpen: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
};

const AddCustomCodeModal = ({
  scope,
  getAddCustomCodeEndpoint,
  customCodeEditLink,
  isAddCodeModalOpen,
  handleClose,
}) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [data, setData] = useState({});

  /* eslint-disable sort-keys */
  const handleSubmit = async (csrfToken) => {
    const customCodeId = 'create-custom-code';

    // default placement to first option
    data[`${scope}_custom_code_placement_id`] = 1;

    try {
      await axios({
        method: 'post',
        url: getAddCustomCodeEndpoint(),
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: data,
      }).then((response) => {
        dispatchToasterAction(
          addToaster({
            id: customCodeId,
            text: 'Custom Code Created, redirecting...',
            type: 'success',
          }),
        );
        window.location.href = customCodeEditLink(response.data.id);
      });
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: customCodeId,
          text: error?.response?.data?.errors[0]?.message || 'Cannot create Custom Code.',
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: customCodeId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <>
      <CsrfContextConsumer>
        {(csrfToken) => (
          <NameAndDescriptionModal
            fieldIds={{
              descriptionFieldId: 'ufr-new-custom-code-description',
              nameFieldId: 'ufr-new-custom-code-name',
            }}
            fieldLabels={{ descriptionFieldLabel: 'Description', nameFieldLabel: 'Name' }}
            fieldNameAttributes={{
              descriptionFieldName: 'description',
              nameFieldName: 'name',
            }}
            title="Add New Code"
            handleSubmit={() => handleSubmit(csrfToken)}
            primaryButtonText="Add Code"
            handleClose={handleClose}
            isOpen={isAddCodeModalOpen}
            setData={setData}
            data={data}
            maxLength={200}
          />
        )}
      </CsrfContextConsumer>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

AddCustomCodeModal.propTypes = propTypes;

export default AddCustomCodeModal;
