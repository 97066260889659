import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '../../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired,
  handleToasterLifecycle: PropTypes.func.isRequired,
  isCtaActive: PropTypes.bool.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const CtaListingStatusButton = ({
  ctaId,
  ctaName,
  isCtaActive,
  handleToasterLifecycle,
  triggerRefresh,
}) => {
  const toggleCtaStatus = async (csrfToken) => {
    let toaster = {
      id: ctaId,
    };

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: `/api/v2/cta/${ctaId}`,
        data: { active: isCtaActive ? 0 : 1 },
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */

      toaster = {
        ...toaster,
        text: `"${ctaName}" is now ${isCtaActive ? 'hidden' : 'shown'}.`,
        type: 'success',
      };

      triggerRefresh();
    } catch {
      toaster = {
        ...toaster,
        text: 'Something went wrong. Please try again.',
        type: 'error',
      };
    }

    handleToasterLifecycle(toaster, ctaId);
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          {isCtaActive ? (
            <Button
              icon="eye-close"
              id={`hide-call-to-action-${ctaId}`}
              label="make call-to-action hidden"
              onClick={() => toggleCtaStatus(csrfToken)}
              title="Hide CTA"
            />
          ) : (
            <Button
              icon="eye-open"
              id={`show-call-to-action-${ctaId}`}
              label="make call-to-action shown"
              onClick={() => toggleCtaStatus(csrfToken)}
              title="Show CTA"
            />
          )}
        </>
      )}
    </CsrfContextConsumer>
  );
};

CtaListingStatusButton.propTypes = propTypes;

export default CtaListingStatusButton;
