import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios/index';
import Modal from '@/components/modal/Modal/Modal';
import ModalBody from '@/components/modal/ModalBody/ModalBody';
import ModalFooter from '@/components/modal/ModalFooter/ModalFooter';
import ModalHeader from '@/components/modal/ModalHeader/ModalHeader';
import TextInput from '@/components/formInputs/textInput/TextInput';

import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

const headerTitle = 'Create a New Tag Group';
const headerDescription =
  'Tag Groups organize tags of a similar theme together, making it easier to find and use related content.';

/* eslint-disable sort-keys */
const CreateTagGroupModal = ({ ...props }) => {
  const {
    isOpen,
    handleClose,
    tagsTransferListModalData,
    setIsTagsTransferListModalOpen,
    dispatchToasterAction,
    getAllPaginatedTags,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const tagGroupName = useRef('');

  const handleChange = (e) => {
    tagGroupName.current = e.target.value;
  };

  const onSubmit = async (csrfToken) => {
    setSubmitting(true);

    const toaster = {
      id: 'new-tag-group-toaster',
    };

    tagGroupName.current = tagGroupName.current.trim();

    if (tagGroupName.current.length === 0) {
      toaster.type = 'error';
      toaster.text = 'Error: Tag group field must not be empty';
      dispatchToasterAction(addToaster(toaster));
      setTimeout(() => {
        dispatchToasterAction(slideDownAndRemoveToaster(toaster));
      }, 2000);
      setSubmitting(false);
      return;
    }

    const url = `/api/v2/tag-groups`;

    const data = { name: tagGroupName.current, description: '' };

    await axios({
      method: 'post',
      url,
      data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'content-type': 'application/json',
      },
    })
      .then(async (response) => {
        if ('id' in response.data && typeof response.data.id !== 'undefined') {
          toaster.type = 'success';
          toaster.text = 'Tag group successfully added';

          tagsTransferListModalData.current.loadedTransferFromData = await getAllPaginatedTags(
            csrfToken,
            tagsTransferListModalData.current.ungroupedTagGroupId,
          );

          tagsTransferListModalData.current.loadedTransferToData = [];
          tagsTransferListModalData.current.tagGroupId = response.data.id;
          tagsTransferListModalData.current.tagGroupName = response.data.name;

          setIsTagsTransferListModalOpen(true);
        } else {
          toaster.type = 'error';
          toaster.text = 'Error: Unable to add tag group';
        }
      })
      .catch((error) => {
        toaster.type = 'error';
        toaster.text = 'Error: ' + error.response.data.errors[0].message;
      });
    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);

    setSubmitting(false);
    handleClose();
  };

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {/* eslint-disable-next-line */}
        {(csrfToken) => {
          return (
            <Modal isOpen={isOpen}>
              <ModalHeader
                title={headerTitle}
                description={headerDescription}
                handleClose={handleClose}
              />
              <ModalBody>
                <TextInput id="tag-group-name" title="Tag Group Name" handleChange={handleChange} />
              </ModalBody>
              <ModalFooter>
                <PrimaryButton
                  label="Add Tags in Group"
                  onClick={() => onSubmit(csrfToken)}
                  disabled={submitting}
                >
                  Add Tags in Group
                </PrimaryButton>
              </ModalFooter>
            </Modal>
          );
        }}
      </CsrfContextConsumer>
    </CsrfProvider>
  );
};

CreateTagGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  tagsTransferListModalData: PropTypes.bool,
  setIsTagsTransferListModalOpen: PropTypes.bool,
  dispatchToasterAction: PropTypes.func,
  getAllPaginatedTags: PropTypes.func,
};

export default CreateTagGroupModal;
