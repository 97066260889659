import './selectDropdown.less';
import 'core-js/shim';
import { fontColorDark, lightGray, uberPink } from '../../../constants/constants';
import classNames from 'classnames';
import Downshift from 'downshift';
import handleInputReady from '../../../utils/handleInputReady';
import Icon from '../../shared/icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import Reset from '@/components/formInputs/reset/Reset';

const propTypes = {
  defaultSelectedItem: PropTypes.string,
  disabled: PropTypes.bool,
  glyphicon: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  // for app config settings
  inheritedValue: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  resetData: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }),
  showReset: PropTypes.bool,
};

const defaultProps = {
  defaultSelectedItem: '',
  disabled: false,
  glyphicon: false,
  inheritedValue: null,
  items: [],
  name: '',
  placeholder: '',
  resetData: null,
  showReset: false,
};

class DownshiftInput extends React.Component {
  state = {
    inputValue: '',
    selectedItem: this.props.defaultSelectedItem || this.props.items[0].text,
  };

  listItems = (inputValue, selectedItem) => {
    const { items } = this.props;
    const showSearch = items.length > 10;

    if (showSearch && selectedItem !== inputValue) {
      return items.filter(
        (i) => !inputValue || i.text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1,
      );
    }

    return items;
  };

  handleReset = () => {
    const { name, resetData } = this.props;
    const componentData = {
      propertyName: name,
    };

    handleInputReady(resetData, componentData, this.finishReset);
  };

  finishReset = () => {
    const { inheritedValue, items, placeholder } = this.props;
    const selectedOptionText =
      !inheritedValue && inheritedValue !== 0 ? placeholder : items[inheritedValue].text;

    this.setState({ inputValue: selectedOptionText, selectedItem: selectedOptionText });
  };

  render() {
    const { items, glyphicon, disabled, showReset, id } = this.props;
    const showSearch = items.length > 10;
    const searchClassNames = ['ufr-dropdown-search-input', showSearch && 'show-search'];
    const dropDownContainerClassNames = [
      'ufr-select-dropdown-container',
      showSearch && 'show-search',
    ];

    const DownshiftInputClassname = ['ufr-select-dropdown', glyphicon && 'glyphicon-dropdown'];

    return (
      <div className="ufr-align-input-container">
        <Downshift
          id={`ds-${this.props.id}`}
          glyphicon={glyphicon}
          defaultSelectedItem={this.state.selectedItem}
          onSelect={disabled ? null : this.props.handleSelect}
          onStateChange={
            disabled
              ? null
              : (dsState) => {
                  // if we are opening the drop down or
                  // we trying to clear the input and there is still text in the state
                  if (
                    (dsState.isOpen && (!dsState.inputValue || dsState.inputValue === '')) ||
                    (dsState.inputValue === '' && this.state.inputValue)
                  ) {
                    return this.setState({ inputValue: '' });
                  }

                  return (
                    dsState.inputValue &&
                    dsState.selectedItem &&
                    this.setState({
                      inputValue: dsState.inputValue,
                      selectedItem: dsState.selectedItem,
                    })
                  );
                }
          }
          inputValue={this.state.inputValue}
          selectedItem={this.state.selectedItem}
          render={({
            getInputProps,
            getItemProps,
            getButtonProps,
            isOpen,
            selectedItem,
            inputValue,
            highlightedIndex,
          }) => (
            <div className={classNames(DownshiftInputClassname)}>
              <button
                type="button"
                disabled={disabled}
                className={classNames('ufr-dropdown-button', {
                  'ufr-input-component--disabled': disabled,
                })}
                {...getButtonProps({})}
              >
                <span className="ufr-dropdown-selected-item">
                  {glyphicon ? (
                    <i className={`glyphicons glyphicons-${selectedItem}`} />
                  ) : (
                    selectedItem || '...'
                  )}
                </span>
                {isOpen ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
              </button>
              {isOpen ? (
                <div className={classNames(dropDownContainerClassNames)}>
                  <div className={classNames(searchClassNames)}>
                    <input {...getInputProps({})} />
                    <Icon icon="search" />
                  </div>
                  <div className="ufr-select-dropdown-item-container">
                    {this.listItems(inputValue, selectedItem).map((item, index) => (
                      <div
                        {...getItemProps({
                          item: item.text,
                          style: {
                            backgroundColor: highlightedIndex === index ? lightGray : '',
                            color: selectedItem === item.text ? uberPink : fontColorDark,
                          },
                        })}
                        key={item.text}
                        className="ufr-select-dropdown-item"
                      >
                        {glyphicon ? (
                          <i className={`glyphicons glyphicons-${item.text}`} />
                        ) : (
                          item.text
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        />
        {showReset && <Reset handleReset={this.handleReset} id={id} />}
      </div>
    );
  }
}

DownshiftInput.propTypes = propTypes;
DownshiftInput.defaultProps = defaultProps;

export default DownshiftInput;
