import Button from '@/components/shared/button/Button';
import handleClick from '@/utils/handleClick';
import ItemsListingDeleteItemButton from './ItemsListingDeleteItemButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canAddTags: PropTypes.bool.isRequired,
  canDeleteItems: PropTypes.bool.isRequired,
  canEditItems: PropTypes.bool.isRequired,
  decrementItemsCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  itemTitle: PropTypes.string,
  itemUrl: PropTypes.string.isRequired,
  openTagsModal: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
  streamId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const ItemsListingButtons = ({
  hubId,
  streamId,
  itemId,
  itemTitle,
  itemUrl,
  triggerRefresh,
  openTagsModal,
  decrementItemsCount,
  canDeleteItems,
  canEditItems,
  canAddTags,
  dispatchToasterAction,
}) => (
  <>
    {canAddTags ? (
      <Button
        icon="tags"
        id={`tag-item-${itemId}`}
        itemId={itemId}
        label="Add Tags"
        title="Add Tags"
        onClick={() => handleClick({ openTagsModal })}
      />
    ) : (
      ''
    )}

    <Button
      href={itemUrl}
      target="_blank"
      icon="share"
      id={`open-item-${itemId}`}
      label="Open Item"
      title="Open Item"
    />

    {canEditItems ? (
      <Button
        href={`/hubs/itemEditContents/${hubId}/${itemId}/${streamId}`}
        icon="pencil"
        id={`edit-item-${itemId}`}
        label="Edit Item"
        title="Edit Item"
      />
    ) : (
      ''
    )}

    {canDeleteItems ? (
      <ItemsListingDeleteItemButton
        hubId={hubId}
        itemId={itemId}
        streamId={streamId}
        triggerRefresh={triggerRefresh}
        itemTitle={itemTitle}
        decrementItemsCount={decrementItemsCount}
        dispatchToasterAction={dispatchToasterAction}
      />
    ) : (
      ''
    )}
  </>
);

ItemsListingButtons.propTypes = propTypes;

export default ItemsListingButtons;
