import PropTypes from 'prop-types';
import React from 'react';

import ClearbitSearch from './ClearbitSearch';
import handleInputReady from '../../utils/handleInputReady';
import ImagePreview from './ImagePreview';
import UploadButton from '../shared/button/UploadButton';

import './imageUploader.less';
import ImageCropper from './ImageCropper';
import InputContainer from '../formInputs/inputContainer/InputContainer';

const propTypes = {
  clearbit: PropTypes.bool,
  /** restricts the cropper dimension ratio */
  cropAspectRatio: PropTypes.number,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  footerText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  removeData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]).isRequired,
  submitData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      func: PropTypes.string,
      namespace: PropTypes.string,
    }),
  ]).isRequired,
  title: PropTypes.string,
  uploadBtnLabel: PropTypes.string,
  useMediaModal: PropTypes.bool,
  value: PropTypes.string,
};

const defaultProps = {
  clearbit: false,
  cropAspectRatio: 0,
  description: '',
  disabled: false,
  footerText: '',
  name: '',
  title: '',
  uploadBtnLabel: 'Upload Image',
  value: '',
};

class ImageUploader extends React.Component {
  state = {
    errorMessage: '',
    imageData: null,
    img: this.props.value,
  };

  addImage = (img) => {
    const { name, submitData, disabled } = this.props;

    if (disabled) return;

    const fullImg = {};
    fullImg.propertyName = name;
    fullImg.propertyType = img.type;
    fullImg.propertyValue = img.val;

    handleInputReady(submitData, fullImg, this.handleCallback);

    // exit cropper, show preview.
    this.setState({ imageData: null });
  };

  removeImage = () => {
    const { name, removeData, disabled } = this.props;
    if (!disabled) {
      handleInputReady(removeData, name, this.handleCallback);
    }
  };

  openCropper = (fileObject) => {
    const file = fileObject.val;

    // Formats accepted by react-image-crop
    const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/gif'];
    if (acceptedFileTypes.indexOf(file.type) === -1) {
      this.setState({ errorMessage: 'Image must be a png, gif, or jpg.' });
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => this.setState({ imageData: reader.result });
    reader.readAsDataURL(file);
  };

  openMediaModal = (e) => {
    e.preventDefault();

    const { name } = this.props;
    handleInputReady(
      {
        func: 'openMediaModal',
        namespace: 'CurrentPage',
      },
      name,
      this.handleCallback,
    );
  };

  cancelCrop = () => this.setState({ imageData: null });

  handleCallback = (data, error) => {
    if (error) {
      this.setState({ errorMessage: error });
    } else {
      this.setState({ errorMessage: '', img: data });
    }
  };

  render() {
    const { img, imageData, errorMessage } = this.state;
    const {
      id,
      title,
      description,
      clearbit,
      footerText,
      uploadBtnLabel,
      useMediaModal,
      cropAspectRatio,
      disabled,
    } = this.props;

    const Inner = () => {
      if (imageData) {
        return (
          <div className="ufr-image-uploader">
            <ImageCropper
              id={id}
              base64Image={imageData}
              aspectRatio={cropAspectRatio}
              handleCancelClick={this.cancelCrop}
              handleSaveClick={this.addImage}
              disabled={disabled}
            />
          </div>
        );
      }

      if (img) {
        return (
          <div className="ufr-image-uploader">
            <ImagePreview
              id={id}
              img={img}
              handleDeleteClick={this.removeImage}
              disabled={disabled}
            />
          </div>
        );
      }
      return (
        <div className="ufr-image-uploader">
          <div className="ufr-upload-control-left">
            <UploadButton
              id={id}
              label={uploadBtnLabel}
              onClick={useMediaModal ? this.openMediaModal : this.openCropper}
              disabled={disabled}
              useMediaModal={useMediaModal}
            />

            {footerText && <p className="ufr-footer-text">{useMediaModal ? '' : footerText}</p>}
          </div>

          {clearbit && (
            <div className="ufr-upload-control-right">
              <span>or</span>
              <ClearbitSearch id={id} handleMenuClick={this.addImage} disabled={disabled} />
            </div>
          )}
        </div>
      );
    };

    return (
      <InputContainer
        id={id}
        title={title}
        description={description}
        error={errorMessage}
        disabled={disabled}
      >
        <Inner />
      </InputContainer>
    );
  }
}

ImageUploader.defaultProps = defaultProps;
ImageUploader.propTypes = propTypes;

export default ImageUploader;
