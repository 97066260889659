import '../salesforceDropdown.less';
import './searchableDropdown.less';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { defaultOption } from '../SalesforceDropdown';
import { Dropdown } from 'react-bootstrap';
import CustomToggle from './CustomToggle';
import React, { useEffect, useRef } from 'react';
import SearchableMenu from './SearchableMenu';

function useOutsideAlerter(ref, toggleMenu) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, toggleMenu]);
}

const SearchableDropdown = ({
  selected,
  loading,
  menuItems,
  selectDropdownItem,
  noDefault,
  error,
}) => {
  const [searchValue, setSearchValue] = React.useState('');

  const dropdownItems = noDefault ? menuItems : [defaultOption, ...(menuItems || [])];
  const currentValue =
    menuItems
      // value might be a number, but selected needs to be a string
      ?.find(({ value }) => String(value) === selected)?.label || defaultOption.label;

  const [menuIsOpen, toggleMenu] = React.useState(false);
  const wrapperRef = useRef(null);

  const relevantItems = dropdownItems.filter(
    ({ label }) => !searchValue || label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  useOutsideAlerter(wrapperRef, toggleMenu);

  return (
    <div ref={wrapperRef} className="dropdown-container">
      <Dropdown disabled={loading} className="salesforce-dropdown">
        <CustomToggle
          className={error && 'dropdownError'}
          toggleMenu={toggleMenu}
          menuIsOpen={menuIsOpen}
        >
          {currentValue || defaultOption.label}
        </CustomToggle>
        {!!menuItems?.length && menuIsOpen && (
          <SearchableMenu setSearchValue={setSearchValue} searchValue={searchValue}>
            {relevantItems.map(({ value, label }) => (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <li
                className="menu-item"
                key={value}
                onClick={() => {
                  selectDropdownItem(value);
                  toggleMenu(false);
                }}
              >
                <span className={selected === value && 'selected-item'}>{label}</span>
              </li>
            ))}
          </SearchableMenu>
        )}
      </Dropdown>
      {loading ? <div className="loading" /> : null}
    </div>
  );
};

SearchableDropdown.propTypes = {
  error: bool,
  loading: bool,
  menuItems: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ),
  noDefault: bool,
  selectDropdownItem: func.isRequired,
  selected: string,
};

SearchableDropdown.defaultProps = {
  error: false,
  loading: false,
  menuItems: [],
  noDefault: false,
  selected: null,
};

export default SearchableDropdown;
