import './mediaLibrary.less';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

require('../../../../../libs/js/cloudinary/all.js');

export const MediaLibraryConfig = {
  credentials: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    cloudName: PropTypes.string.isRequired,
  }).isRequired,
  mlConfig: PropTypes.shape({
    insertCaption: PropTypes.string,
  }),
};

const initMl = (containerId, credentials, submitCallback, mlConfig) => {
  const ml = window.cloudinary.createMediaLibrary(
    {
      api_key: credentials.apiKey,
      cloud_name: credentials.cloudName,
      inline_container: `#${containerId}`,
      insert_caption: mlConfig.insertCaption,
      max_files: 1,
      remove_header: true,
    },
    {
      insertHandler: (data) => {
        const [asset] = data.assets;
        const [derived] = asset.derived || [];
        const url = derived ? derived.secure_url : asset.secure_url;
        submitCallback({
          data,
          type: 'MediaLibrary',
          url,
        });
      },
    },
  );
  ml.show();
};

const MediaLibrary = ({ id, submitCallback, active, credentials, mlConfig }) => {
  const containerRef = useRef(null);
  const containerId = `ufr-media-library-container-${id}`;
  useEffect(() => {
    initMl(containerId, credentials, submitCallback, mlConfig);
  }, []);

  return (
    <div
      id={containerId}
      ref={containerRef}
      className={`ufr-media-library ufr-media-upload ${active ? 'active' : ''}`}
    ></div>
  );
};

const propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string,
  submitCallback: PropTypes.func,
  ...MediaLibraryConfig,
};

const defaultProps = {
  mlConfig: {
    insertCaption: 'Insert',
  },
};

MediaLibrary.propTypes = propTypes;
MediaLibrary.defaultProps = defaultProps;

export default MediaLibrary;
