import './inlineTextInput.less';
import CharacterCount from '../../formInputs/input/CharacterCount';
import PropTypes from 'prop-types';
import React, { createRef, useState } from 'react';

const defaultProps = {
  size: 'large',
  text: '',
};

const propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  handleEscape: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  minInputSize: PropTypes.number,
  placeholder: PropTypes.string,
  showCharacterCounter: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'small']),
  text: PropTypes.string,
};

const InlineTextInput = ({
  ariaLabel,
  size,
  handleSubmit,
  handleEscape,
  showCharacterCounter,
  placeholder,
  minInputSize = 30,
  maxLength,
  text,
}) => {
  const [value, setValue] = useState(text);

  const textInput = createRef();

  const focusTextInput = () => textInput.current.focus();
  const blurTextInput = () => textInput.current.blur();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const submit = (e, value) => {
    blurTextInput();
    handleSubmit(e, value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e, value);
    } else if (e.key === 'Escape') {
      handleEscape();
    }
  };

  const id = ariaLabel.split(' ').join('-');

  const inputLength = value ? value.length : 0;
  const showCounter = maxLength > 0 && showCharacterCounter;
  const inputSize = Math.max(inputLength, minInputSize);

  return (
    <div className={`inline-editable-text-input ${size}-inline-input`}>
      <label htmlFor={id} className="sr-only">
        {ariaLabel}
      </label>

      <input
        id={id}
        ref={textInput}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onFocus={focusTextInput}
        onKeyDown={handleKeyDown}
        onBlur={(e) => submit(e, value)}
        maxLength={maxLength}
        size={inputSize}
      />
      {showCounter && <CharacterCount max={maxLength} count={inputLength} />}
    </div>
  );
};

InlineTextInput.defaultProps = defaultProps;

InlineTextInput.propTypes = propTypes;

export default InlineTextInput;
