import axios from 'axios/index';
import PropTypes from 'prop-types';
import React from 'react';
import uniq from 'lodash/uniq';

import './linkablesModal.less';
import { allLocalesItem } from './linkablesModalHelpers';
import { Modal, ModalBody, ModalHeader } from '../../../modal';
import HubTile from '../../../tiles/HubTile';
import LinkablesModalFooter from './LinkablesModalFooter';
import SelectDropdown from '../../../formInputs/selectDropdown/SelectDropdown';
import Spinner from '../../../modal/Spinner/Spinner';

const { bool, func, arrayOf, number } = PropTypes;
const propTypes = {
  getLinkHubUrl: func.isRequired,
  handleClose: func,
  isOpen: bool,
  linkableHubs: PropTypes.arrayOf(
    PropTypes
      .shape
      // todo
      (),
  ),
  linkableLocales: PropTypes.arrayOf(
    PropTypes
      .shape
      // todo
      (),
  ),
  linkedLocaleIds: arrayOf(number),
  refreshTable: func.isRequired,
  showMessage: func.isRequired,
};

const defaultProps = {
  handleClose: () => {},
  isOpen: false,
  linkableHubs: [],
  linkableLocales: [],
  linkedLocaleIds: [],
};

const transformToDropdownItem = (locales) =>
  locales.map(({ display_name: text }) => ({ text, value: text }));

/**
 * Modal to select lang-linkable hubs.
 */
class LinkableHubsModal extends React.Component {
  state = {
    disabledLocaleIds: [],
    dropdownItems: [allLocalesItem, ...transformToDropdownItem(this.props.linkableLocales)],
    linkableHubs: this.props.linkableHubs,
    loading: false,
    selectedHubIds: [],
  };

  close = () => {
    this.props.handleClose();
  };

  /**
   * Toggle the presence of a hubId in the selectedHubs array
   */
  updateHubSelection =
    ({ hubId, localeId }) =>
    () => {
      if (!hubId || !localeId) return;

      const { selectedHubIds, disabledLocaleIds } = this.state;

      if (selectedHubIds.includes(hubId)) {
        this.setState({
          disabledLocaleIds: disabledLocaleIds.filter((id) => id !== localeId),
          selectedHubIds: selectedHubIds.filter((id) => id !== hubId),
        });
      } else {
        this.setState({
          disabledLocaleIds: uniq([...disabledLocaleIds, localeId]),
          selectedHubIds: [...selectedHubIds, hubId],
        });
      }
    };

  renderLinkableHubs = () => {
    const { linkedLocaleIds } = this.props;
    const { linkableHubs, selectedHubIds, disabledLocaleIds } = this.state;

    const noHubs = !linkableHubs.length;
    if (noHubs) {
      return 'There are currently no hubs that can be linked.';
    }

    return linkableHubs.map(({ id, name, thumbnail_url: thumbnailUrl, locale }) => {
      const disabledLocales = [...linkedLocaleIds, ...disabledLocaleIds];
      const selected = selectedHubIds.includes(id);
      const disabled = disabledLocales.includes(locale.id) && !selected;
      const tooltip = disabled
        ? 'You have already linked a hub of this locale to the current hub.'
        : 'Select Hub';
      return (
        <HubTile
          key={id}
          title={name}
          tooltip={tooltip}
          thumbnailUrl={thumbnailUrl}
          disabled={disabled}
          onClick={this.updateHubSelection({
            hubId: id,
            localeId: locale.id,
          })}
          selected={selected}
          bottomText={locale.display_name}
        />
      );
    });
  };

  saveSelectedHubs = async (csrfToken) => {
    const { getLinkHubUrl, showMessage, refreshTable } = this.props;
    const {
      selectedHubIds: [firstId, ...restIds],
    } = this.state;
    if (!firstId) return;

    /* eslint-disable sort-keys */
    const saveLink = (id) =>
      axios({
        method: 'post',
        url: getLinkHubUrl(id),
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
    /* eslint-enable sort-keys */

    const toasterId = 'save-links';
    try {
      showMessage('info', 'Saving...', toasterId);
      this.close();

      // launch this one first so that we don't have a race condition with the link group
      await saveLink(firstId);

      if (restIds.length) {
        const remainingPromises = restIds.map((id) => saveLink(id));
        await Promise.all(remainingPromises);
      }

      showMessage('success', 'Hub Links Saved!', toasterId);
      refreshTable();
    } catch {
      this.close();
      showMessage('error', 'There was an error saving one or more of your Hub Links.', toasterId);
      // some of the requests might have succeeded
      refreshTable();
    }
  };

  handleLocaleSelect = ({ value: locale }) => {
    const { linkableHubs } = this.props;
    const filteredHubs = linkableHubs.filter(
      (hub) => hub.locale.display_name === locale || !locale,
    );
    this.setState({ linkableHubs: filteredHubs });
  };

  render() {
    const { isOpen } = this.props;
    const { loading, selectedHubIds, dropdownItems } = this.state;

    const disableDropdown = loading || dropdownItems.length <= 2;

    return (
      <Modal size="medium" isOpen={isOpen} handleClose={this.close} className="ufr-linkables-modal">
        <ModalHeader title="Link This Hub to Other Hubs" />
        <ModalBody>
          <SelectDropdown
            id="locale"
            title="Select Hub Locale"
            disabled={disableDropdown}
            items={dropdownItems}
            submitData={this.handleLocaleSelect}
          />
          <div className="ufr-lang-linkables-area">
            {loading ? <Spinner /> : this.renderLinkableHubs()}
          </div>
        </ModalBody>

        <LinkablesModalFooter
          onCancel={this.close}
          onConfirm={this.saveSelectedHubs}
          loading={loading}
          numberSelected={selectedHubIds.length}
          entityName="Hub"
        />
      </Modal>
    );
  }
}

LinkableHubsModal.propTypes = propTypes;
LinkableHubsModal.defaultProps = defaultProps;

export default LinkableHubsModal;
