import './tileDesignAccordion.less';

import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import Accordion from '@/components/shared/accordion/Accordion';
import Button from '@/components/shared/button/Button';
import ColorPicker from '@/components/formInputs/colorPicker/ColorPicker';
import FontSlider from '@/components/formInputs/slider/FontSlider';
import handleInputReady from '@/utils/handleInputReady';
import TextArea from '@/components/formInputs/textArea/TextArea';
import ToasterStack from '@/components/toaster/ToasterStack';
import UploadButton from '@/components/shared/button/UploadButton';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  form: PropTypes.shape({
    background_color: PropTypes.string.isRequired,
    font_aspect_ratio: PropTypes.number.isRequired,
    font_color: PropTypes.string.isRequired,
    has_background: PropTypes.bool.isRequired,
    tagline: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  setOpenAccordion: PropTypes.func.isRequired,
  submitData: PropTypes.func,
};

const title = 'Tile Design';

const TileDesignAccordion = ({ form, handleSubmit, isOpen, setOpenAccordion, submitData }) => {
  const { tagline, font_color, font_aspect_ratio, background_color, has_background } = form;

  const formData = useRef(form);

  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const _handleSubmit = (data, action) => {
    const { propertyName, propertyValue } = data;
    formData.current[propertyName] = propertyValue;

    if (handleSubmit) handleSubmit(formData.current, action);
    if (submitData) handleInputReady(submitData, data);
  };

  const handleUpload = async ({ type, val }) => {
    let toaster;

    if (type === 'file') {
      const formData = new FormData();
      formData.append('background', val);

      _handleSubmit(
        {
          propertyName: 'file',
          propertyValue: formData,
          validationRules: {},
        },
        'upload-bg',
      );
    } else {
      toaster = {
        id: 'file-upload',
        text: 'Invalid type: "' + type + '"',
        type: 'error',
      };
    }

    if (toaster) {
      dispatchToasterAction(addToaster(toaster));

      setTimeout(() => dispatchToasterAction(slideDownAndRemoveToaster(toaster)), 2000);
    }
  };

  const handleRemove = () => {
    _handleSubmit(
      {
        propertyName: 'file',
        propertyValue: null,
        validationRules: {},
      },
      'remove-bg',
    );
  };

  return (
    <Accordion
      id="tile-design-accordion"
      isOpen={isOpen}
      onClick={() => setOpenAccordion('tile-design')}
      title={title}
    >
      <TextArea
        id="tile-design-button-label-text-area"
        name="tagline"
        value={tagline}
        submitData={_handleSubmit}
        rows={2}
        title="Tagline"
      />
      <ColorPicker
        id="tile-design-font-color-picker"
        name="font_color"
        value={font_color}
        cta={true}
        colorFor={'Font'}
        submitData={_handleSubmit}
      />
      <FontSlider
        id="tile-design-font-slider"
        name="font_aspect_ratio"
        value={font_aspect_ratio * 1000}
        title="Font Size"
        submitData={_handleSubmit}
      />
      <ColorPicker
        id="tile-design-button-color-picker"
        name="background_color"
        value={background_color}
        cta={true}
        colorFor={'Background'}
        submitData={_handleSubmit}
      />
      <div id="tile-design-background-image">
        <label htmlFor="tile-design-background-image-upload">Background Image</label>
        <div>
          <UploadButton id="tile-design-background-image" label="Upload" onClick={handleUpload} />
          {has_background && (
            <Button id="tile-design-background-image-remove" onClick={handleRemove}>
              Remove
            </Button>
          )}
        </div>
      </div>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </Accordion>
  );
};

TileDesignAccordion.propTypes = propTypes;

export default TileDesignAccordion;
