import Button from '@/components/shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import RedirectRulesDeleteButton from './RedirectRulesDeleteButton';

const propTypes = {
  decrementRedirectRuleCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hasManagePermission: PropTypes.bool.isRequired,
  hubId: PropTypes.number.isRequired,
  redirectRuleId: PropTypes.number.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const RedirectRulesButtons = ({
  decrementRedirectRuleCount,
  dispatchToasterAction,
  hasManagePermission,
  hubId,
  redirectRuleId,
  triggerRefresh,
}) =>
  hasManagePermission ? (
    <>
      <Button
        href={`/redirect_rules/edit/${hubId}/${redirectRuleId}`}
        icon="wrench"
        title="Edit Redirect Rule"
        label="Edit Redirect Rule"
        id={`edit-redirect-rule-${redirectRuleId}`}
        className="ufr-edit-button"
      />
      <RedirectRulesDeleteButton
        redirectRuleId={redirectRuleId}
        decrementRedirectRuleCount={decrementRedirectRuleCount}
        dispatchToasterAction={dispatchToasterAction}
        triggerRefresh={triggerRefresh}
      />
    </>
  ) : (
    ''
  );

RedirectRulesButtons.propTypes = propTypes;

export default RedirectRulesButtons;
