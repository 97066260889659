import './appFunctionalityListingDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import FunctionalityListingPrivacyGroupSelect from '@/components/datatables/privacyGroups/FunctionalityListingPrivacyGroupSelect';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hubId: PropTypes.number.isRequired,
  privacyGroups: PropTypes.array.isRequired,
  triggerRefreshCounter: PropTypes.number.isRequired,
};

const entityName = {
  plural: 'App Functionalities',
  singular: 'App Functionality',
};

const searchPlaceholder = (functionalitiesCount) =>
  `Search ${functionalitiesCount} ${
    functionalitiesCount === 1 ? entityName.singular : entityName.plural
  }`;

const cellClass = (key) => `ufr-dt-privacy-groups-app-functionality-listing-${key}-cell`;
const headerClass = (key) => `ufr-dt-privacy-groups-app-functionality-listing-${key}-header`;

/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
const columns = (
  hubId,
  privacyGroups,
  dispatchToasterAction,
  setSelectedPrivacyGroups,
  selectedPrivacyGroups,
) => [
  {
    id: 'app_name',
    Header: 'App',
    accessor: 'app_name',
    className: cellClass('app-name'),
    headerClassName: headerClass('app-name'),
    Cell: ({ original }) => {
      const style = {
        backgroundImage: `url(${original.app_icon_url})`,
      };
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ufr-app-name-tooltip" placement="top">
              {original.app_name}
            </Tooltip>
          }
        >
          <div className="ufr-app">
            <div className="ufr-app-image" style={style} />
            <div className="ufr-app-name truncate">{original.app_name}</div>
          </div>
        </OverlayTrigger>
      );
    },
  },
  {
    id: 'name',
    Header: 'Functionality',
    accessor: 'name',
    className: cellClass('functionality-name'),
    headerClassName: headerClass('functionality-name'),
    Cell: ({ original }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-functionality-name-tooltip" placement="top">
            {original.name}
          </Tooltip>
        }
      >
        <div className="truncate">{original.name}</div>
      </OverlayTrigger>
    ),
  },
  {
    Header: 'Privacy Group',
    accessor: 'privacy-group-select',
    className: cellClass('privacy-group-select'),
    headerClassName: headerClass('privacy-group-select'),
    sortable: false,
    Cell: ({ original }) => (
      <div className="ufr-privacy-group-selector">
        <FunctionalityListingPrivacyGroupSelect
          apiEntityName="app-functionality"
          functionalityId={original.id}
          functionalityType="app"
          hubId={hubId}
          privacyGroups={privacyGroups}
          selectedPrivacyGroupId={original.privacy_group_id}
          dispatchToasterAction={dispatchToasterAction}
          setSelectedPrivacyGroup={(privacyGroupName, privacyGroupId) =>
            setSelectedPrivacyGroups({
              ...selectedPrivacyGroups,
              [original.id]: { label: privacyGroupName, value: privacyGroupId },
            })
          }
          selectedPrivacyGroup={selectedPrivacyGroups[original.id]}
        />
      </div>
    ),
  },
];
/* eslint-enable sort-keys */
/* eslint-enable react/prop-types */

const getEndpointBuilder = (hubId) => ({
  getAppFunctionalities: () => `/api/v2/hubs/${hubId}/app-functionalities`,
});

const AppFunctionalityListingDatatable = ({ hubId, privacyGroups, triggerRefreshCounter }) => {
  const [functionalitiesCount, setFunctionalitiesCount] = useState(0);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [selectedPrivacyGroups, setSelectedPrivacyGroups] = useState({});

  const { getAppFunctionalities } = getEndpointBuilder(hubId);

  const getData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getAppFunctionalities(), {
      params: queries,
    });
    setFunctionalitiesCount(meta.count);
    return { data, meta };
  };

  return (
    <CsrfProvider>
      <DataTable
        id="privacy-group-app-functionality"
        columns={columns(
          hubId,
          privacyGroups,
          dispatchToasterAction,
          setSelectedPrivacyGroups,
          selectedPrivacyGroups,
        )}
        searchPlaceholder={searchPlaceholder(functionalitiesCount)}
        showLoadingState
        getData={getData}
        initialSort={{ desc: false, id: 'app_name' }}
        triggerRefreshCounter={triggerRefreshCounter}
      />
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

AppFunctionalityListingDatatable.propTypes = propTypes;

export default AppFunctionalityListingDatatable;
