/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Panel, PanelGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './menu.less';
import handleClick from '../../../utils/handleClick';
import Icon from '../../shared/icon/Icon';
import shapes from '../propShapes';
import SubMenu from './SubMenu';

const checkForActiveChild = (arr = []) => arr.reduce((res, { active }) => res || active, false);

const checkForInitallyOpenPanel = ({ menu }) =>
  menu.reduce((idOfOpenPanel, { id, subMenu }) => {
    const hasActiveChild = checkForActiveChild(subMenu);
    return hasActiveChild ? id : idOfOpenPanel;
  }, false);

class Menu extends React.Component {
  state = {
    expandedPanelId: checkForInitallyOpenPanel(this.props),
  };

  componentDidMount() {
    this.scrollBar.scrollTop(this.calcActiveItemScrollTop());
  }

  togglePanel = (id) => {
    const shouldClosePanel = id === this.state.expandedPanelId;
    this.setState({ expandedPanelId: shouldClosePanel ? false : id });
  };

  calcActiveItemScrollTop() {
    const { menu } = this.props;
    if (!menu || !menu.length) return 0;

    let activeItemIndex = null;
    menu.forEach((item, index) => {
      if (item.subMenu) {
        item.subMenu.forEach((subItem) => {
          if (subItem.active) {
            activeItemIndex = index;
          }
        });
      }
    });

    // height of menu items above the active submenu, minus one item
    const panelHeight = this.panel ? this.panel.clientHeight + 2 : 0;
    const itemsHeight = (activeItemIndex + 1) * panelHeight - panelHeight;

    return itemsHeight || 0;
  }

  render() {
    const { brandColor, menu, hasFooter } = this.props;
    const { expandedPanelId } = this.state;

    const classes = ['sidenav-menu', hasFooter && 'has-footer'];

    // TODO: extract into its own component DEV-13887
    const panelHeader = ({ id, color, icon, text, hasSubMenu }) => {
      const isExpanded = id === this.state.expandedPanelId;
      return (
        <div
          id={`${id}-panel-heading`}
          ref={(p) => {
            this.panel = p;
          }}
          className="panel-title"
        >
          <span className="panel-title-wrapper">
            <Icon key={1} icon={icon} color={color} />
            <div className="panel-label" key={2} style={{ color }}>
              {text}
            </div>
            {hasSubMenu && (
              <div
                className={`btn-expand glyphicons glyphicons-chevron-${isExpanded ? 'up' : 'down'}`}
              />
            )}
          </span>
        </div>
      );
    };

    // anchor wrapper for panelHeaders without submenus to give more contextmenu options on links
    const panelHeaderURLWrapper = ({ panelHeader, hasSubMenu, url }) => {
      if (!hasSubMenu && url) {
        return (
          <a
            href={url}
            onClick={(e) => {
              if (window.location.href === url) {
                history.pushState({}, '');
              }
              e.stopPropagation();
            }}
          >
            {panelHeader}
          </a>
        );
      }
      return panelHeader;
    };

    return (
      <div className={classNames(classes)}>
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          ref={(s) => {
            this.scrollBar = s;
          }}
          universal
        >
          <PanelGroup
            accordion
            id="sidenav-menu-items"
            activeKey={expandedPanelId}
            onSelect={() => {}}
          >
            {menu.map((item) => {
              const { id, url, icon, text, active, onClick, onClickCallback, subMenu } = item;

              const click = () => {
                if (window.location.href === url) {
                  history.pushState({}, '');
                }
                handleClick({
                  id,
                  onClick,
                  onClickCallback,
                  url,
                });
              };

              const hasSubMenu = subMenu && subMenu.length > 0;
              const panelClick = hasSubMenu ? () => this.togglePanel(id) : click;

              // header color
              const color = active ? brandColor : 'inherit';

              return (
                <Panel
                  key={id + url}
                  eventKey={id}
                  className={active ? 'panel-active' : ''}
                  onClick={panelClick}
                >
                  <Panel.Heading>
                    {panelHeaderURLWrapper({
                      hasSubMenu,
                      panelHeader: panelHeader({
                        color,
                        hasSubMenu,
                        icon,
                        id,
                        text,
                      }),
                      url,
                    })}
                  </Panel.Heading>

                  {hasSubMenu && (
                    <Panel.Body collapsible>
                      <SubMenu highlightColor={brandColor} items={subMenu} />
                    </Panel.Body>
                  )}
                </Panel>
              );
            })}
          </PanelGroup>
        </Scrollbars>
      </div>
    );
  }
}

// note: destructuring here breaks hypernova
const menuItem = shapes.menuItem;
menuItem.subMenu = PropTypes.arrayOf(PropTypes.shape(Object.assign({}, menuItem)));

Menu.defaultProps = {
  brandColor: '#ce0058',
  hasFooter: false,
};

Menu.propTypes = {
  brandColor: PropTypes.string,
  hasFooter: PropTypes.bool,
  menu: PropTypes.arrayOf(PropTypes.shape(menuItem)).isRequired,
};

export default Menu;
