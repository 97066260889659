import PassphrasesDeleteButton from './PassphrasesDeleteButton';
import PassphrasesEditButton from './PassphrasesEditButton';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  dispatchToasterAction: PropTypes.func.isRequired,
  modalData: PropTypes.exact({
    description: PropTypes.string,
    passphrase: PropTypes.string,
  }).isRequired,
  passphraseId: PropTypes.number.isRequired,
  refreshTable: PropTypes.func.isRequired,
  renderDeletePassphraseModal: PropTypes.func.isRequired,
  renderEditPassphraseModal: PropTypes.func.isRequired,
  streamId: PropTypes.number.isRequired,
};

const PassphrasesButtons = ({
  modalData,
  passphraseId,
  streamId,
  refreshTable,
  dispatchToasterAction,
  renderEditPassphraseModal,
  renderDeletePassphraseModal,
}) => (
  <>
    <PassphrasesEditButton
      modalData={modalData}
      passphraseId={passphraseId}
      streamId={streamId}
      refreshTable={refreshTable}
      dispatchToasterAction={dispatchToasterAction}
      renderEditPassphraseModal={renderEditPassphraseModal}
    />
    <PassphrasesDeleteButton
      modalData={modalData}
      passphraseId={passphraseId}
      streamId={streamId}
      refreshTable={refreshTable}
      dispatchToasterAction={dispatchToasterAction}
      renderDeletePassphraseModal={renderDeletePassphraseModal}
    />
  </>
);

PassphrasesButtons.propTypes = propTypes;

export default PassphrasesButtons;
