import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Icon from '../../shared/icon/Icon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  isTagGroup: PropTypes.bool,
  tagGroupListingDatatableRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  tagId: PropTypes.number.isRequired,
  tagName: PropTypes.string.isRequired,
  tagsListingDatatableRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

const getEndpointBuilder = () => ({
  editTagGroup: (id) => `/api/v2/tag-groups/${id}`,
});

const EditTagName = ({
  tagId,
  tagName,
  isTagGroup,
  tagGroupListingDatatableRef,
  tagsListingDatatableRef,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [previewTagName, setTagName] = useState(tagName);
  const [changedTagName, setChangedTagName] = useState(tagName);
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const { editTagGroup } = getEndpointBuilder();

  const saveTagName = async (name, csrfToken) => {
    const toaster = await axios
      .patch(
        isTagGroup ? editTagGroup(tagId) : '',
        {
          name: name,
        },
        {
          headers: {
            'X-CSRF-TOKEN': csrfToken,
          },
        },
      )
      .then(
        () => (
          setChangedTagName(name),
          {
            id: `edit-tag${isTagGroup ? '-group' : ''}-success`,
            text: `Tag ${isTagGroup ? 'group' : ''} name edited successfully!`,
            type: 'success',
          }
        ),
      )
      .catch(
        (error) => (
          setTagName(changedTagName),
          {
            id: `edit-tag${isTagGroup ? '-group' : ''}-error`,
            text: `Error: ${error.response.data.errors[0].message}`,
            type: 'error',
          }
        ),
      );

    dispatchToasterAction(addToaster(toaster));

    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);

    if (toaster.type === 'success') {
      tagGroupListingDatatableRef.current.refreshTable();
      tagsListingDatatableRef.current.refreshTable();
    }
  };

  const toggleEditMode = (editModeIsEditing, name, csrfToken) => {
    const trimmedName = name.trim();

    if (editModeIsEditing === true) {
      setIsEditing(false);

      if (trimmedName !== changedTagName) {
        setTagName(trimmedName);
        saveTagName(trimmedName, csrfToken);
      }

      return;
    }

    setIsEditing(true);
  };

  const tagNameToEditClickable = () => (
    <div className={'editable-tag-name'}>
      {previewTagName}
      <div className={'edit-tag-name'} onClick={() => toggleEditMode(false, previewTagName)}>
        <Icon
          icon="pencil"
          id={`edit-tag-name-icon-${tagId}`}
          key={`edit-tag-name-icon-${tagId}`}
          className={'edit-tag-name-icon'}
        />
      </div>
    </div>
  );

  const inputOnChangeEvent = (event) => {
    const inputTagName = event.target.value;

    setTagName(inputTagName);
  };

  const inputEditTagName = (inputIsEditing, inputPreviewTagName) => (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <div>
          <input
            type="text"
            className={'input-edit-tag-name'}
            value={inputPreviewTagName}
            key={`input-edit-${tagId}-tag-name`}
            onChange={inputOnChangeEvent}
            onBlur={() => toggleEditMode(inputIsEditing, inputPreviewTagName, csrfToken)}
            autoFocus
          />

          <div
            className="save-edit-tag-name"
            onClick={() => toggleEditMode(inputIsEditing, inputPreviewTagName, csrfToken)}
          >
            <Icon
              icon="ok"
              id={`edit-${inputPreviewTagName}`}
              key={`edit-${inputPreviewTagName}`}
              className={'save-tag-name'}
              label="Save Tag Name"
            />
          </div>
        </div>
      )}
    </CsrfContextConsumer>
  );

  const showTagNameComponent = isEditing
    ? inputEditTagName(isEditing, previewTagName)
    : tagNameToEditClickable();

  const componentId = `edit-tag-name-${tagId}`;

  return (
    <>
      <CsrfProvider>
        <div id={componentId} key={componentId}>
          {showTagNameComponent}
        </div>
      </CsrfProvider>
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </>
  );
};

EditTagName.propTypes = propTypes;

export default EditTagName;
