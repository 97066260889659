import 'whatwg-fetch';
import { Dropdown, MenuItem } from 'react-bootstrap';
import classNames from 'classnames';
import Icon from '../shared/icon/Icon';
import imageExists from '../../utils/imageExists';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './clearbitSearch.less';

class ClearbitSearch extends Component {
  state = {
    menuItems: [],
    value: '',
  };

  setSelection = (img) => {
    const { handleMenuClick } = this.props;

    this.setState({ menuItems: [], value: '' });

    const fileObject = {
      type: 'url',
      val: img,
    };

    handleMenuClick(fileObject);
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });

    if (event.target.value.length > 0) {
      fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${event.target.value}`)
        .then((response) => response.json())
        .then((json) => {
          // filter out non-resolving URLs
          const validItems = json.filter((item) => {
            const { logo } = item;
            return imageExists(logo);
          });

          this.setState({ menuItems: validItems });
        });
    } else {
      this.setState({ menuItems: [] });
    }
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      // ESC key
      this.setState({ menuItems: [] });
    }
  };

  render() {
    const { value, menuItems } = this.state;

    const { id, disabled } = this.props;

    const renderMenuItems = () =>
      menuItems.map((item) => {
        const { name, domain, logo } = item;

        return (
          <MenuItem
            key={domain}
            id={`${domain}-menu-item`}
            eventKey={name}
            onSelect={disabled ? null : () => this.setSelection(logo)}
            className={logo ? 'with-icon' : ''}
            disabled={disabled}
          >
            <div>
              <img src={logo} alt="" />
              <span className="menu-text">{name}</span>
            </div>
          </MenuItem>
        );
      });

    return (
      <div className="ufr-clearbit-search-box">
        <Icon icon="search" className="search-icon" />
        <input
          id={`ufr-${id}-clearbit-search-input`}
          type="text"
          className={classNames('search-input', { 'ufr-input-component--disabled': disabled })}
          placeholder="Search Clearbit"
          aria-label={`${id} clearbit search input`}
          value={value}
          onChange={disabled ? null : this.handleChange}
          onKeyDown={disabled ? null : this.handleKeyDown}
          disabled={disabled}
        />
        {menuItems.length > 0 && (
          <Dropdown.Menu id="search-results" className="react-dropdown-menu">
            {renderMenuItems()}
          </Dropdown.Menu>
        )}
      </div>
    );
  }
}

ClearbitSearch.defaultProps = {
  disabled: false,
};

ClearbitSearch.propTypes = {
  disabled: PropTypes.bool,
  handleMenuClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ClearbitSearch;
